import { request, success, failure } from "./index";
import {
  DASHBOARD_ACTIONS,
  LOADING_ACTIONS,
} from "../../constants/ActionConstants";
import { workorderService, dashboardService } from "../../sevices/api";
import { StatRequestModel } from "@ecodocs/common/src/models/Dashboard";
import {
  getTimePeriodDate,
  parseCustomDate,
} from "@ecodocs/common/src/helpers/DashboardHelper";
import StorageService from "../../sevices/storageService";
import ScheduledTaskManager from '../../database/dataManagers/ScheduledTaskManager';

export function getTaskSummaryStatistics() {
  return function (dispatch) {
    dispatch(request(DASHBOARD_ACTIONS.TASK_SUMMARY_STAT_REQUEST));
    dashboardService
      .getTaskSummaryStatistics()
      .then((data) => {
        dispatch(
          success(DASHBOARD_ACTIONS.TASK_SUMMARY_STAT_SUCCESS, { data })
        );
      })
      .catch((error) => {
        dispatch(
          failure(DASHBOARD_ACTIONS.TASK_SUMMARY_STAT_FAILURE, {
            error: error.toString(),
          })
        );
      });
  };
}

export function getTaskCompletionStatisticsGraph() {
  return function (dispatch) {
    dispatch(request(DASHBOARD_ACTIONS.TASK_COMPLETION_GRAPH_REQUEST));
    dashboardService
      .getTaskCompletionStatisticsGraph()
      .then((data) => {
        dispatch(
          success(DASHBOARD_ACTIONS.TASK_COMPLETION_GRAPH_SUCCESS, { data })
        );
      })
      .catch((error) => {
        dispatch(
          failure(DASHBOARD_ACTIONS.TASK_COMPLETION_GRAPH_FAILURE, {
            error: error.toString(),
          })
        );
      });
  };
}

export function getRecentSubmissions(params = {}) {
  return function (dispatch) {
    dispatch(success(LOADING_ACTIONS.RECENT_SUBMISSION_LOADING, true));
    dispatch(request(DASHBOARD_ACTIONS.GET_RECENT_SUBMISSIONS_REQUEST, params));
    dashboardService
      .getRecentSubmissions(params)
      .then((data) => {
        dispatch(success(LOADING_ACTIONS.RECENT_SUBMISSION_LOADING, false));
        dispatch(
          success(DASHBOARD_ACTIONS.GET_RECENT_SUBMISSIONS_SUCCESS, { data })
        );
      })
      .catch((error) => {
        dispatch(success(LOADING_ACTIONS.RECENT_SUBMISSION_LOADING, false));
        dispatch(
          failure(DASHBOARD_ACTIONS.GET_RECENT_SUBMISSIONS_FAILURE, {
            error: error.toString(),
          })
        );
      });
  };
}

export function getRuleViolations(params) {
  return function (dispatch) {
    dispatch(success(LOADING_ACTIONS.LIMIT_RULE_VIOLATION_LOADING, true));
    dispatch(request(DASHBOARD_ACTIONS.GET_RULE_VIOLATION_REQUEST, params));
    dashboardService
      .getRuleViolations(params)
      .then((data) => {
        dispatch(success(LOADING_ACTIONS.LIMIT_RULE_VIOLATION_LOADING, false));
        dispatch(
          success(DASHBOARD_ACTIONS.GET_RULE_VIOLATION_SUCCESS, { data })
        );
      })
      .catch((error) => {
        dispatch(success(LOADING_ACTIONS.LIMIT_RULE_VIOLATION_LOADING, false));
        dispatch(
          failure(DASHBOARD_ACTIONS.GET_RULE_VIOLATION_FAILURE, {
            error: error.toString(),
          })
        );
      });
  };
}

export function getActiveWorkers() {
  return function (dispatch) {
    dispatch(success(LOADING_ACTIONS.ACTIVE_WORKERS_LOADING, true));
    dispatch(request(DASHBOARD_ACTIONS.GET_ACTIVE_WORKER_REQUEST));
    dashboardService
      .getActiveWorkers()
      .then((data) => {
        dispatch(success(LOADING_ACTIONS.ACTIVE_WORKERS_LOADING, false));
        dispatch(
          success(DASHBOARD_ACTIONS.GET_ACTIVE_WORKER_SUCCESS, { data })
        );
      })
      .catch((error) => {
        dispatch(success(LOADING_ACTIONS.ACTIVE_WORKERS_LOADING, false));
        dispatch(
          failure(DASHBOARD_ACTIONS.GET_ACTIVE_WORKER_FAILURE, {
            error: error.toString(),
          })
        );
      });
  };
}

export function getMissedTasks() {
  return function (dispatch) {
    dispatch(request(DASHBOARD_ACTIONS.GET_MISSED_TASK_REQUEST));
    dashboardService
      .getMissedTasks()
      .then((data) => {
        dispatch(success(DASHBOARD_ACTIONS.GET_MISSED_TASK_SUCCESS, { data }));
      })
      .catch((error) => {
        dispatch(
          failure(DASHBOARD_ACTIONS.GET_MISSED_TASK_FAILURE, {
            error: error.toString(),
          })
        );
      });
  };
}

//params -> pagable data
export function getPendingWorkorders(params = {}) {
  return function (dispatch) {
    dispatch(success(LOADING_ACTIONS.PENDING_WORKORDER_LOADING, true));
    dispatch(request(DASHBOARD_ACTIONS.GET_PENDING_WORKORDER_REQUEST, params));
    dashboardService
      .getPendingWorkorders(params)
      .then((data) => {
        dispatch(success(LOADING_ACTIONS.PENDING_WORKORDER_LOADING, false));
        dispatch(
          success(DASHBOARD_ACTIONS.GET_PENDING_WORKORDER_SUCCESS, { data })
        );
      })
      .catch((error) => {
        dispatch(success(LOADING_ACTIONS.PENDING_WORKORDER_LOADING, false));
        dispatch(
          failure(DASHBOARD_ACTIONS.GET_PENDING_WORKORDER_FAILURE, {
            error: error.toString(),
          })
        );
      });
  };
}

export function getTaskCompletionStatistics(FromDate, ToDate) {
  return function (dispatch) {
    dispatch(
      request(DASHBOARD_ACTIONS.GET_TASK_COMPLETION_STATISTICS_REQUEST, {
        FromDate,
        ToDate,
      })
    );
    dashboardService
      .getTaskCompletionStatistics(FromDate, ToDate)
      .then((data) => {
        dispatch(
          success(DASHBOARD_ACTIONS.GET_TASK_COMPLETION_STATISTICS_SUCCESS, {
            data,
          })
        );
      })
      .catch((error) => {
        dispatch(
          failure(DASHBOARD_ACTIONS.GET_TASK_COMPLETION_STATISTICS_FAILURE, {
            error: error.toString(),
          })
        );
      });
  };
}

export function getTaskViolationsStatistics(FromDate, ToDate) {
  return function (dispatch) {
    dispatch(
      request(DASHBOARD_ACTIONS.GET_TASK_VIOLATION_STATISTICS_REQUEST, {
        FromDate,
        ToDate,
      })
    );
    dashboardService
      .getTaskViolationsStatistics(FromDate, ToDate)
      .then((data) => {
        dispatch(
          success(DASHBOARD_ACTIONS.GET_TASK_VIOLATION_STATISTICS_SUCCESS, {
            data,
          })
        );
      })
      .catch((error) => {
        dispatch(
          failure(DASHBOARD_ACTIONS.GET_TASK_VIOLATION_STATISTICS_FAILURE, {
            error: error.toString(),
          })
        );
      });
  };
}

export function getFilterDueTasks(
  paginationData,
  searchTerms,
  filters,
  sortObject,
  dueToday = true
) {
  return function (dispatch) {
    dispatch(
      request(DASHBOARD_ACTIONS.GET_DUE_TASK_REQUEST, {
        paginationData,
        searchTerms,
        filters,
        sortObject,
      })
    );
    dashboardService
      .getDueTasks(paginationData, searchTerms, filters, sortObject, dueToday)
      .then(
        (data) => {
          dispatch(
            success(DASHBOARD_ACTIONS.GET_DUE_TASK_SUCCESS, { data, dueToday })
          );
        },
        (error) => {
          dispatch(
            failure(DASHBOARD_ACTIONS.GET_DUE_TASK_FAILURE, {
              error: error.toString(),
              dueToday,
            })
          );
        }
      );
  };
}

export function getDueTasksForOffline(dueToday = true) {
  return async function (dispatch, getStore) {
    const store = getStore();
    dispatch(request(DASHBOARD_ACTIONS.GET_DUE_TASK_REQUEST, dueToday));
    dispatch(success(DASHBOARD_ACTIONS.GET_DUE_TASK_SUCCESS, {
      dueToday,
      data: { result: await ScheduledTaskManager.getDueTasks(dueToday) },
    }));
  };
}

export function getFilterDueTaskDesigner(
  paginationData,
  searchTerms,
  filters,
  sortObject,
  dueToday=true
) {
  return function (dispatch) {
    dispatch(
      request(DASHBOARD_ACTIONS.GET_DUE_TASK_REQUEST, {
        paginationData,
        searchTerms,
        filters,
        sortObject
      })
    );
    dashboardService.getDueTaskDesigner(
      paginationData,
      searchTerms,
      filters,
      sortObject,
      dueToday
    ).then(
      (data) => {
        dispatch(
          success(
            DASHBOARD_ACTIONS.GET_DUE_TASK_SUCCESS,
            {data, dueToday}
          )
        );
      },
      (error) => {
        dispatch(
          failure(
            DASHBOARD_ACTIONS.GET_DUE_TASK_FAILURE,
            { error: error.toString(), dueToday }
          )
        );
      }
    );
  };
}

export function getAllDueTasks(
  paginationData,
  searchTerms,
  filters,
  sortObject
) {
  return function (dispatch) {
    dispatch(
      request(DASHBOARD_ACTIONS.GET_ALL_DUE_TASK_REQUEST, {
        paginationData,
        searchTerms,
        filters,
        sortObject,
      })
    );
    dashboardService
      .getAllDueTasks(paginationData, searchTerms, filters, sortObject)
      .then(
        (data) => {
          dispatch(success(DASHBOARD_ACTIONS.GET_ALL_DUE_TASK_SUCCESS, data));
        },
        (error) => {
          dispatch(
            failure(
              DASHBOARD_ACTIONS.GET_ALL_DUE_TASK_FAILURE,
              error.toString()
            )
          );
        }
      );
  };
}

export function getAllDueTasksDesigner(
  paginationData,
  searchTerms,
  filters,
  sortObject,
) {
  return function (dispatch) {
    dispatch(
      request(DASHBOARD_ACTIONS.GET_ALL_DUE_TASK_REQUEST, {
        paginationData,
        searchTerms,
        filters,
        sortObject
      })
    );
    dashboardService.getAllDueTasksDesigner(
      paginationData,
      searchTerms,
      filters,
      sortObject
    ).then(
      (data) => {
        dispatch(
          success(DASHBOARD_ACTIONS.GET_ALL_DUE_TASK_SUCCESS,data)
        );
      },
      (error) => {
        dispatch(
          failure(DASHBOARD_ACTIONS.GET_ALL_DUE_TASK_FAILURE, error.toString())
        );
      }
    );
  };
}