import { FORM_SUBMISSION_MASTER_TYPE, GENERIC_CONSTANTS, WORKORDER_PERIOD_TYPES } from "../constants/GenericConstants";
import { parseDateForServer, parseDate, showFirstXCharacters, parseDateTime, parseDashboardRecentSubmissionTime, parseDateTime24Hours } from "./GeneralHelper";
import { getTaskPriority } from "./TaskDesignerHelper";
import _ from "lodash";
import React from "react";
import moment from "moment";

/**
 * @param {type} -> period type
 * @description -> returns start & end date wrt to period type.
 * NOTE: Handles for period type today as well by default
 */
export const getTimePeriodDate = (periodType) => {
  const date = new Date();
  const fullYear = date.getFullYear();
  const month = date.getMonth();

  let startDate = new Date();
  let endDate = new Date();

  if (periodType) {
    if (periodType === WORKORDER_PERIOD_TYPES.WEEK) {
      const first = date.getDate() - date.getDay(); // First day is the day of the month - the day of the week
      const last = first + 6; // last day is the first day + 6
      startDate = new Date(date.setDate(first)).toUTCString();
      endDate = new Date(date.setDate(last)).toUTCString();
    } else if (periodType === WORKORDER_PERIOD_TYPES.MONTH) {
      startDate = new Date(fullYear, month, 1);
      endDate = new Date(fullYear, month + 1, 0);
    } else if (periodType === WORKORDER_PERIOD_TYPES.YEARLY) {
      startDate = new Date("1/1/" + new Date().getFullYear());
      endDate = new Date("12/31/" + new Date().getFullYear());
    }
  }

  startDate = new Date(startDate).setHours(0, 0, 0, 0);
  endDate = new Date(endDate).setHours(23, 59, 59, 999);

  startDate = parseDateForServer(startDate);
  endDate = parseDateForServer(endDate);

  return { startDate, endDate };
};

export const getPeriodDates = (periodType) => {
  const date = new Date();
  const fullYear = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  let startDate = new Date();
  let endDate = new Date();

  if (periodType) {
    if (periodType === WORKORDER_PERIOD_TYPES.WEEK) {
      startDate = new Date(endDate.getTime() - 6 * 24 * 60 * 60 * 1000);
    } else if (periodType === WORKORDER_PERIOD_TYPES.MONTH) {
      let newMonth = month;
      let newYear = fullYear;
      if (newMonth === 0) {
        newMonth = 12;
        newYear = newYear - 1;
      }

      startDate = `${newMonth}/${day}/${newYear}`;
    } else if (periodType === WORKORDER_PERIOD_TYPES.YEARLY) {
      startDate = `${month + 1}/${day}/${fullYear - 1}`;
    }
  }

  startDate = parseDateForServer(startDate);
  endDate = parseDateForServer(endDate);

  return { startDate, endDate };
};

export const parseCustomDate = (sDate, eDate) => {
  let startDate = new Date(sDate).setHours(0, 0, 0, 0);
  let endDate = new Date(eDate).setHours(23, 59, 59, 999);

  startDate = parseDateForServer(startDate);
  endDate = parseDateForServer(endDate);

  return { startDate, endDate };
};

export const parseDueTaskData = (data, dueToday) => {
    return {
        title: data.taskName || "",
        number: data.taskNumber || "",
        workorder: data.workorderNumber || "",
        location: data.locationName,
        occurrence: data.occurenceEnumId || "",
        priority: data.priorityEnumId || "",
        dueBy: parseDate(data.submissionEndTime),
        startTime: data.submissionStartTime || "",
        endTime: data.submissionEndTime || "", // FIXME: getting typo in API
        id: data.submissionMasterId || 0,
        department: data.departmentName || "",
    };
}

export const parseDueByDate = (dueBy, startTime, endTime) => {
    let today = new Date(), dueByDate = new Date(dueBy), dateInfo = '';

    if (today.getDate() == dueByDate.getDate() && today.getMonth() == dueByDate.getMonth() && today.getFullYear() == dueByDate.getFullYear()) {
        let parsedStartTime = moment(startTime).format(GENERIC_CONSTANTS.TIME_FORMATE),
            parsedEndTime = moment(endTime).format(GENERIC_CONSTANTS.TIME_FORMATE);
        dateInfo = `${parsedStartTime} - ${parsedEndTime}`;
    }
    else {
        let rawDate = dueBy.split(' ');
        let month = rawDate[0].split('', 3).splice(0, 3).join('');
        let isYearSame = new Date().getFullYear() == rawDate[2] ? "" : rawDate[2] + ", "
        let parsedDate = [month, rawDate[1], isYearSame].join(' ');

        let parsedStartTime = moment(startTime).format(GENERIC_CONSTANTS.TIME_FORMATE);
        let parsedEndTime = moment(endTime).format(GENERIC_CONSTANTS.TIME_FORMATE);
        dateInfo = parsedDate + parsedStartTime + " - " + parsedEndTime;
    }

    return dateInfo;
}

/**
 * Parse Listing Data
 * @param {Array} taskListingData
 */
export function parseDueTaskListingData(taskListingData, ENUM = {}) {
  // let parsedTaskList = [];
  // const taskStatus = ENUM.SUBMISSION_STATUS;
  // const frequencyCalender = ENUM.FREQUENCY_CALENDAR;
  // const schedulerFrequency = ENUM.SCHEDULER_FREQUENCY;

  // if (!!taskListingData) {
  //   for (var i = 0; i < taskListingData.length; i++) {
  //     const data = taskListingData[i];
  //     let taskObj = {};
  //     let isFormFillable = data.isFormFillable || data.isAdhocEntry;
  //     // let isFormFillable = data.isFormFillable || 
  //     //   data.formSubmissionMasterTypeId === FORM_SUBMISSION_MASTER_TYPE.ADHOC;
  //     let today = new Date();
  //     let isStartTimeIsFuture = new Date(data.submissionStartTime) > today;
  //     let statusIcon = isFormFillable
  //       ? "activeIcon"
  //       : !!isStartTimeIsFuture
  //       ? "futureIcon"
  //       : "inactiveIcon";
  //     taskObj.taskNumber = (
  //       <span>
  //         {<i className={statusIcon}></i>}{" "}
  //         {showFirstXCharacters(
  //           data.taskNumber || LOCALIZATION_CONSTANTS.NA,
  //           15
  //         )}
  //       </span>
  //     );
  //     taskObj.isFormFillable = isFormFillable;
  //     taskObj.taskName = showFirstXCharacters(data.taskName, 110);
  //     // taskObj.taskNumber = showFirstXCharacters(data.taskNumber, 15);
  //     taskObj.workorderNumber = data.workorderNumber;
  //     taskObj.submissionEndTime = !!data.subissionEndTime
  //       ? parseDateTime(data.subissionEndTime)
  //       : "N/A";
  //     taskObj.submissionStartTime = !!data.submissionStartTime
  //       ? parseDateTime(data.submissionStartTime)
  //       : "N/A";
  //     taskObj.dueDate = !!data.dueDate ? parseDateTime(data.dueDate) : "N/A";

  //     let locations = data.locationName || "N/A";
  //     locations = locations.split(",");

  //     if (locations.length > 2) {
  //       let length = locations.length - 2;
  //       locations = locations.slice(0, 2);
  //       locations.push(length + " More...");
    let parsedTaskList = [];
    const taskStatus = ENUM.SUBMISSION_STATUS;
    const frequencyCalender = ENUM.FREQUENCY_CALENDAR;
    const schedulerFrequency = ENUM.SCHEDULER_FREQUENCY;
  
    if (!!taskListingData) {
      for (var i = 0; i < taskListingData.length; i++) {
        const data = taskListingData[i];
        let taskObj = {};
        //let isFormFillable = data.isFormFillable || data.isAdhocEntry;
        let isFormFillable = data.isFormFillable || data.formSubmissionMasterTypeId === FORM_SUBMISSION_MASTER_TYPE.ADHOC;
        let today = new Date();
        let isStartTimeIsFuture = new Date(data.submissionStartTime) > today;
        let statusIcon = isFormFillable ? "activeIcon" : !!isStartTimeIsFuture ? "futureIcon" : "inactiveIcon";
        taskObj.taskNumber = <span className="sch-title">{<i className={statusIcon}></i>} { showFirstXCharacters(data.taskNumber || LOCALIZATION_CONSTANTS.NA, 15) }</span>;
        taskObj.isFormFillable = isFormFillable;
        taskObj.taskName = showFirstXCharacters(data.taskName, 110);
        // taskObj.taskNumber = showFirstXCharacters(data.taskNumber, 15);
        taskObj.workorderNumber = data.workorderNumber; 
        taskObj.submissionEndTime = !!data.submissionEndTime? parseDateTime24Hours(data.submissionEndTime): "N/A";
        taskObj.submissionStartTime = !!data.submissionStartTime? parseDateTime24Hours(data.submissionStartTime): "N/A";
        taskObj.dueDate = !!data.dueDate? parseDateTime24Hours(data.dueDate): "N/A";
  
        let locations = data.locationName || "N/A";
        locations = locations.split(",");
  
        if (locations.length > 2) {
          let length = locations.length - 2;
          locations = locations.slice(0, 2);
          locations.push(length + " More...");
        }
  
        taskObj.locationName = locations.map((str) => {
          return <div>{ showFirstXCharacters(str, 15)}</div>;
        });

        // frequency
        // let frequencyObj =
        // _.find(frequencyCalender, (obj) => obj.value === data.frequency) || {};
        // taskObj.frequency =
        // !!frequencyObj && !!frequencyObj.label ? frequencyObj.label + `${!data.dueDate && parseInt(data.frequency) !== -1 ? " (Adhoc)": ""}` : "";
        let occurenceObj = _.find(schedulerFrequency, (obj) => obj.value === data.occurenceEnumId) || {label:"Adhoc"};
        taskObj.OccurenceEnumId = !!occurenceObj && !!occurenceObj.label && occurenceObj.label;
  
        taskObj.id = data.submissionMasterId;
        taskObj.PriorityEnumID = getTaskPriority(data);
  
        parsedTaskList.push(taskObj);
      }
    }
    return parsedTaskList;
}

/**
 * Parse Corrective Action Listing Data
 * @param {Array} taskListingData
 */
 export function parseCorrectiveActionTaskListingData(taskListingData, ENUM = {}) {
    let parsedTaskList = [];
    const taskStatus = ENUM.SUBMISSION_STATUS;
    const frequencyCalender = ENUM.FREQUENCY_CALENDAR;
    const schedulerFrequency = ENUM.SCHEDULER_FREQUENCY;
  
    if (!!taskListingData) {
      for (var i = 0; i < taskListingData.length; i++) {
        const data = taskListingData[i];
        let taskObj = {};
        //let isFormFillable = data.isFormFillable || data.isAdhocEntry;
        let isFormFillable = data.isFormFillable || data.formSubmissionMasterTypeId === FORM_SUBMISSION_MASTER_TYPE.ADHOC;
        let today = new Date();
        let isStartTimeIsFuture = new Date(data.submissionStartTime) > today;
        let statusIcon = "activeIcon";
        taskObj.taskNumber = <span className="sch-title">{/* {<i className={statusIcon}></i>} */} { showFirstXCharacters(data.taskNumber || LOCALIZATION_CONSTANTS.NA, 15) }</span>;
        taskObj.isFormFillable = isFormFillable;
        taskObj.taskName = showFirstXCharacters(data.taskName, 110);
        // taskObj.taskNumber = showFirstXCharacters(data.taskNumber, 15);
        taskObj.workorderNumber = data.workorderNumber; 
        taskObj.submissionEndTime = !!data.submissionEndTime? parseDateTime24Hours(data.submissionEndTime): "N/A";
        taskObj.submissionStartTime = !!data.submissionStartTime? parseDateTime24Hours(data.submissionStartTime): "N/A";
        taskObj.dueDate = !!data.dueDate? parseDateTime(data.dueDate): "N/A";
  
        let locations = data.locationName || "N/A";
        locations = locations.split(",");
  
        if (locations.length > 2) {
          let length = locations.length - 2;
          locations = locations.slice(0, 2);
          locations.push(length + " More...");
        }
  
        taskObj.locationName = locations.map((str) => {
          return <div>{ showFirstXCharacters(str, 15)}</div>;
        });

        // frequency
        // let frequencyObj =
        // _.find(frequencyCalender, (obj) => obj.value === data.frequency) || {};
        // taskObj.frequency =
        // !!frequencyObj && !!frequencyObj.label ? frequencyObj.label + `${!data.dueDate && parseInt(data.frequency) !== -1 ? " (Adhoc)": ""}` : "";
        let occurenceObj = _.find(schedulerFrequency, (obj) => obj.value === data.occurenceEnumId) || {label:"Adhoc"};
        taskObj.OccurenceEnumId = !!occurenceObj && !!occurenceObj.label && occurenceObj.label;
  
        taskObj.id = data.submissionMasterId;
        taskObj.PriorityEnumID = getTaskPriority(data);
  
        parsedTaskList.push(taskObj);
      }

      taskObj.locationName = locations.map((str) => {
        return <div>{showFirstXCharacters(str, 15)}</div>;
      });

      // frequency
      // let frequencyObj =
      // _.find(frequencyCalender, (obj) => obj.value === data.frequency) || {};
      // taskObj.frequency =
      // !!frequencyObj && !!frequencyObj.label ? frequencyObj.label + `${!data.dueDate && parseInt(data.frequency) !== -1 ? " (Adhoc)": ""}` : "";
      let occurenceObj = _.find(
        schedulerFrequency,
        (obj) => obj.value === data.occurenceEnumId
      ) || { label: "Adhoc" };
      taskObj.OccurenceEnumId =
        !!occurenceObj && !!occurenceObj.label && occurenceObj.label;

      taskObj.id = data.submissionMasterId;
      taskObj.PriorityEnumID = getTaskPriority(data);

      parsedTaskList.push(taskObj);
    }
  
  return parsedTaskList;
}
