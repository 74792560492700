import { parseDateForDatePicker } from "../helpers/GeneralHelper";
import { BM_LIST_ITEM_TYPES } from "../constants/GenericConstants";

const LOCALIZATION_CONSTANTS = window.LOCALIZATION_CONSTANTS;

export const RuleViolationItem = (item) => ({
    title: item.title || "",
    icon: "ecoIcon-alert",
    subTitle: LOCALIZATION_CONSTANTS.DASHBOARD.VIOLATIONS_FOUNDED,
    primarySubTitle: parseDateForDatePicker(new Date(item.updatedOn)) || "",
    id: item.id || null,
    type: BM_LIST_ITEM_TYPES.LIMIT_RULE_VIOLTION
});

export const PendingWorkorderItem = (item) => ({
    title: item.title || "",
    icon: "ecoIcon-alarm-clock",
    subTitle: LOCALIZATION_CONSTANTS.DASHBOARD.PENDING,
    primarySubTitle: parseDateForDatePicker(new Date(item.updatedOn)) || "",
    id: item.id || null,
    type: BM_LIST_ITEM_TYPES.PENDING_WORKORDERS
});

export const MissedWorkorderItem = (item) => ({
    title: item.title || "",
    icon: "ecoIcon-alert",
    subTitle: LOCALIZATION_CONSTANTS.DASHBOARD.MISSED,
    primarySubTitle: parseDateForDatePicker(new Date(item.updatedOn)) || "",
    id: item.id || null,
    type: BM_LIST_ITEM_TYPES.MISSED_WORKORDERS
});

export const StatRequestModel = (startDate, endDate) => ({
    startDate,
    endDate
});

export const BMProgressBarConf = (data) => ({
    val: +(data * 100).toFixed(2), //format to 2 decimal places
    percentage: +(data * 100).toFixed(2) //format to 2 decimal places
})