import {
  TASK_ACTIONS,
  PROGRAM_ACTIONS,
  WORKORDER_ACTIONS,
  BREAKCRUMB_ACTIONS,
} from "../../constants/ActionConstants";
import {
  taskService,
  programService,
  workorderService,
} from "../../sevices/api";
import { request, success, failure } from "./index";
import {
  GENERIC_CONSTANTS,
  TASK_VIEW_TYPE,
  FREQUENCY_CALENDAR,
} from "../../constants/GenericConstants";
import { PageData, SearchTerm } from "@ecodocs/common/src/models/General";
import { capitalLetter, getDetectedPlatformLocalization } from "@ecodocs/common/src/helpers/GeneralHelper";
import {
  TaskFilterModel,
  TaskSumbissionDetailsModel,
} from "@ecodocs/common/src/models/Task";

// localization service
import LocalizationService from "@ecodocs/common/src/sevices/localizationService";
import AdhocTaskManager from "../../database/dataManagers/AdhocTaskManager";
import ScheduledTaskManager from "../../database/dataManagers/ScheduledTaskManager";
import TaskHistoryManager from "../../database/dataManagers/TaskHistoryManager";
import { addMetaEnumToTasks } from "../../helpers/Database";
import { syncData } from "./dataSync";

const LOCALIZATION_CONSTANTS = getDetectedPlatformLocalization();
export function getTasksByWorkOrderId(workorderId) {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.GET_ALL_TASKS_REQUEST, workorderId));
    taskService.getTasksByWorkorderId(workorderId).then(
      (data) => {
        dispatch(success(TASK_ACTIONS.GET_ALL_TASKS_SUCCESS, data));
        dispatch(request(TASK_ACTIONS.ENABLE_TASKS_SELECT_LIST, true));
      },
      (error) => {
        dispatch(failure(TASK_ACTIONS.GET_ALL_TASKS_FAILURE, error.toString()));
      }
    );
  };
}

export function getAdhocTasks() {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.GET_ALL_ADHOC_TASKS_REQUEST, {}));
    taskService.getAdhocTasks().then(
      (data) => {
        dispatch(success(TASK_ACTIONS.GET_ALL_ADHOC_TASKS_SUCCESS, data));
      },
      (error) => {
        dispatch(
          failure(TASK_ACTIONS.GET_ALL_ADHOC_TASKS_FAILURE, error.toString())
        );
      }
    );
  };
}

export function getParentTaskDesignerDetails(id) {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.GET_PARENT_TASK_BY_ID_REQUEST, { id }));
    return new Promise((resolve, reject) => {
      taskService.getTaskDesignerDetails(id).then(
        (data) => {
          dispatch(success(TASK_ACTIONS.GET_PARENT_TASK_BY_ID_SUCCESS, data));
          const { result } = data;
          resolve(data);
        },
        (error) => {
          dispatch(
            failure(
              TASK_ACTIONS.GET_PARENT_TASK_BY_ID_FAILURE,
              error.toString()
            )
          );
          reject(error.toString());
        }
      );
    });
  };
}

export function enableDisableTasksSelectList(flag) {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.ENABLE_TASKS_SELECT_LIST, flag));
  };
}

export function emptyTasksList(value) {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.EMPTY_TASKS_LIST));
  };
}


export function getAllTasks(paginationData) {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.GET_ALL_TASKS_REQUEST, paginationData));
    taskService.getAllTasks(paginationData).then(
      (data) => {
        dispatch(success(TASK_ACTIONS.GET_ALL_TASKS_SUCCESS, data));
      },
      (error) => {
        dispatch(failure(TASK_ACTIONS.GET_ALL_TASKS_FAILURE, error.toString()));
      }
    );
  };
}

export function getTaskDesignerSubmissionDetails(id, breadcrumbArray = []) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(TASK_ACTIONS.GET_TASK_DESIGNER_BY_ID_REQUEST, { id }));
      taskService.getTaskDesignerSubmissionDetails(id).then(
        (data) => {
          dispatch(success(TASK_ACTIONS.GET_TASK_DESIGNER_BY_ID_SUCCESS, data));
          resolve(data);
        },
        (error) => {
          dispatch(
            failure(TASK_ACTIONS.GET_TASK_DESIGNER_BY_ID_FAILURE, error.toString())
          );
          reject(error);
        }
      );
    });
  };
}

export function getTaskDesignerByWorkOrderId(workorderId) {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.GET_ALL_TASKS_REQUEST, workorderId));
    taskService.getTaskDesignerByWorkorderId(workorderId).then(
      (data) => {
        dispatch(success(TASK_ACTIONS.GET_ALL_TASKS_SUCCESS, data));
        dispatch(request(TASK_ACTIONS.ENABLE_TASKS_SELECT_LIST, true));
      },
      (error) => {
        dispatch(failure(TASK_ACTIONS.GET_ALL_TASKS_FAILURE, error.toString()));
      }
    );
  };
}

export function getTaskDesignerByProgramId(programId) {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.GET_TASK_DESIGNER_BY_PROGRAM_ID_REQUEST, programId));
    taskService.getTaskDesignerByProgramId(programId).then(
      (data) => {
        dispatch(success(TASK_ACTIONS.GET_TASK_DESIGNER_BY_PROGRAM_ID_SUCCESS, data));
      },
      (error) => {
        dispatch(failure(TASK_ACTIONS.GET_TASK_DESIGNER_BY_PROGRAM_ID_FAILURE, error.toString()));
      }
    );
  };
}

export function associateWorkorderToTasks(workorderId, checkedRows) {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.ASSOCIATE_WORKORDER_TO_TASKS_REQUEST));

    taskService
      .associateWorkorderToTasks({
        tasks: checkedRows,
        workorderId: workorderId,
      })
      .then(
        (data) => {
          // dispatch(request(TASK_ACTIONS.HANDLE_ASSOCIATE_WORKORDER_TO_TASKS_SUCCESS, {
          //     message: LOCALIZATION_CONSTANTS.WORKORDER_ASSOCIATED_SUCCESFULLY,
          // }));
          dispatch(
            success(TASK_ACTIONS.ASSOCIATE_WORKORDER_TO_TASKS_SUCCESS, {
              message:
                LOCALIZATION_CONSTANTS.SUCCESS_MESSAGES
                  .WORKORDER_ASSOCIATED_SUCCESFULLY,
            })
          );

          //////reset table with new data page data
          const paginationData = new PageData();
          const searchTerms = new SearchTerm().SearchTerm;
          const filters = new TaskFilterModel();
          const sortObject = {
            sortColumn: GENERIC_CONSTANTS.DEFAULT_SORT_KEY,
            sortOrder: GENERIC_CONSTANTS.SORT_ORDER.DESC,
          };

          dispatch(
            request(TASK_ACTIONS.GET_ALL_TASKS_REQUEST, {
              paginationData,
              searchTerms,
              filters,
              sortObject,
            })
          );
          taskService
            .getFilteredTasks(paginationData, searchTerms, filters, sortObject)
            .then(
              (data) => {
                dispatch(success(TASK_ACTIONS.GET_ALL_TASKS_SUCCESS, data));
              },
              (error) => {
                dispatch(
                  failure(TASK_ACTIONS.GET_ALL_TASKS_FAILURE, error.toString())
                );
              }
            );
        },
        (error) => {
          dispatch(
            failure(
              TASK_ACTIONS.ASSOCIATE_WORKORDER_TO_TASKS_FAILURE,
              error.toString()
            )
          );
        }
      );
  };
}

export function submitTask(tasksData, isSubTask = false) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(TASK_ACTIONS.SUBMIT_TASK_REQUEST, { tasksData }));
      taskService.submitTaskData(tasksData).then(
        (data) => {
          dispatch(
            success(TASK_ACTIONS.SUBMIT_TASK_SUCCESS, {
              message: !!isSubTask
                ? capitalLetter(
                    LOCALIZATION_CONSTANTS.SUCCESS_MESSAGES
                      .SUBTASK_ADDED_SUCCESSFULLY
                  )
                : parseInt(tasksData.frequency) === -1 ||
                  parseInt(tasksData.status) === 2
                ? LOCALIZATION_CONSTANTS.SUCCESS_MESSAGES
                    .TASK_ADDED_SUCCESSFULLY
                : LOCALIZATION_CONSTANTS.SUCCESS_MESSAGES
                    .TASK_ADDED_SUCCESSFULLY_SCHEDULED,
            })
          );
          resolve(data);
          // let redirectId = data.result.parentTaskId;
          // if (!!redirectId) {
          //   history.push(`/${!!isSubTask ? "task/sub-" : ""}task/all/${redirectId}`);
          // }
        },
        (error) => {
          dispatch(
            failure(
              TASK_ACTIONS.SUBMIT_TASK_FAILURE,
              error.toString() || LOCALIZATION_CONSTANTS.SOMETHING_WENT_WRONG
            )
          );
          reject(error);
        }
      );
    });
  };
}

export function submitTaskDesigner(tasksData, isSubTask = false) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(TASK_ACTIONS.SUBMIT_TASK_REQUEST, { tasksData }));
      taskService.submitTaskDesigner(tasksData).then(
        (data) => {
          let frequency = tasksData.TaskDetails[0].Frequency || 0;
          let status = tasksData.TaskDetails[0].TaskActivityStatusEnumId || 0;

          dispatch(
            success(TASK_ACTIONS.SUBMIT_TASK_SUCCESS, {
              message: !!isSubTask
                ? capitalLetter(
                    LOCALIZATION_CONSTANTS.SUCCESS_MESSAGES
                      .SUBTASK_ADDED_SUCCESSFULLY
                  )
                : parseInt(frequency) === FREQUENCY_CALENDAR.Adhoc ||
                  status === GENERIC_CONSTANTS.TASK.STATUS.DRAFT
                ? LOCALIZATION_CONSTANTS.SUCCESS_MESSAGES
                    .TASK_ADDED_SUCCESSFULLY
                : LOCALIZATION_CONSTANTS.SUCCESS_MESSAGES
                    .TASK_ADDED_SUCCESSFULLY_SCHEDULED,
            })
          );
          // let redirectId = data.result.parentTaskId;
          // if (!!redirectId) {
          //   history.push(`/designer/all/${redirectId}`);
          // } else {
          //   history.push(`/designer/all`);
          // }

          resolve(data);
        },
        (error) => {
          let errorMessage =
            error.toString() || LOCALIZATION_CONSTANTS.SOMETHING_WENT_WRONG;
          dispatch(failure(TASK_ACTIONS.SUBMIT_TASK_FAILURE, errorMessage));
          reject(errorMessage);
        }
      );
    });
  };
}

export function editTask(tasksData, id, history, isSubTask = false) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(TASK_ACTIONS.SUBMIT_TASK_REQUEST, { tasksData }));

      taskService.editTask(tasksData, id).then(
        (data) => {
          dispatch(
            success(TASK_ACTIONS.SUBMIT_TASK_SUCCESS, {
              message: !!isSubTask
                ? capitalLetter(
                    LOCALIZATION_CONSTANTS.SUCCESS_MESSAGES
                      .SUBTASK_UPDATED_SUCCESFULLY
                  )
                : LOCALIZATION_CONSTANTS.SUCCESS_MESSAGES
                    .TASK_UPDATED_SUCCESFULLY,
            })
          );
          resolve(data);
        },
        (error) => {
          dispatch(
            failure(
              TASK_ACTIONS.SUBMIT_TASK_FAILURE,
              error.toString() || LOCALIZATION_CONSTANTS.SOMETHING_WENT_WRONG
            )
          );
          reject(error.toString());
        }
      );
    });
  };
}

export function editTaskDesigner(tasksData, id, isSubTask = false) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(TASK_ACTIONS.SUBMIT_TASK_REQUEST, { tasksData }));
      taskService.editTaskDesigner(tasksData, id).then(
        (data) => {
          dispatch(
            success(TASK_ACTIONS.SUBMIT_TASK_SUCCESS, {
              message: !!isSubTask
                ? capitalLetter(
                  LOCALIZATION_CONSTANTS.SUCCESS_MESSAGES
                  ? LOCALIZATION_CONSTANTS.SUCCESS_MESSAGES
                      .SUBTASK_UPDATED_SUCCESFULLY : ''
                  )
                : LOCALIZATION_CONSTANTS.SUCCESS_MESSAGES ?
                  LOCALIZATION_CONSTANTS.SUCCESS_MESSAGES.TASK_UPDATED_SUCCESFULLY : ''
            })
          );
          resolve(data);
        },
        (error) => {
          dispatch(
            failure(
              TASK_ACTIONS.SUBMIT_TASK_FAILURE,
              error.toString() || LOCALIZATION_CONSTANTS.SOMETHING_WENT_WRONG
            )
          );
          reject(error.toString());
        }
      );
    });
  };
}

export function duplicateTaskDesigner(params) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(TASK_ACTIONS.DUPLICATE_TASK_REQUEST, { params }));

      taskService.duplicateTaskDesigner(params).then(
        (data) => {
          dispatch(
            success(TASK_ACTIONS.DUPLICATE_TASK_SUCCESS, {
              message:
                LOCALIZATION_CONSTANTS.DUPLICATE_TASK.DUPLICATION_SUCCESS,
            })
          );
          resolve(data);
        },
        (error) => {
          dispatch(
            failure(TASK_ACTIONS.DUPLICATE_TASK_FAILURE, {
              message: LOCALIZATION_CONSTANTS.DUPLICATE_TASK.DUPLICATION_ERROR,
            })
          );
          reject(error.toString());
        }
      );
    });
  };
}

export function validateTaskNo(taskNoFieldId, taskNoFieldValue) {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.TASK_EXISTS_REQUEST, { taskNoFieldValue }));
    taskService.validateTaskNo(taskNoFieldValue).then(
      (data) => {
        dispatch(success(TASK_ACTIONS.TASK_EXISTS_SUCCESS, data));
      },
      (error) => {
        dispatch(failure(TASK_ACTIONS.TASK_EXISTS_FAILURE, error.toString()));
      }
    );
  };
}

export function getTaskSubmissionDetails(
  id,
  taskType = "",
  breadcrumbArray = []
) {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.GET_TASK_BY_ID_REQUEST, { id }));
    taskService.getTaskSubmissionDetails(id, taskType).then(
      (data) => {
        dispatch(success(TASK_ACTIONS.GET_TASK_BY_ID_SUCCESS, data));
      },
      (error) => {
        dispatch(
          failure(TASK_ACTIONS.GET_TASK_BY_ID_FAILURE, error.toString())
        );
      }
    );
  };
}

export function getTaskById(
  id,
  taskType = "",
  viewHistory,
  isSubTask = false,
  requestPage
) {
  return async function (dispatch, getStore) {
    const store = getStore();
    dispatch(request(TASK_ACTIONS.GET_TASK_BY_ID_REQUEST, { id }));

    try {
      // viewHistory => Get data from server in case of task history , since we dont save task history records in local database
      if (!!viewHistory && store && store.app && !!store.app.isConnected) {
        const response = await (!isSubTask
          ? taskService.getTaskSubmissionDetailsForOffline(id, requestPage)
          : taskService.getTaskDesignerSubmissionDetails(id));

        let result = !isSubTask
          ? response &&
            response.result &&
            response.result.elements &&
            response.result.elements[0]
          : response &&
            response.result && {
              ...response.result.task,
              formSubmission: response.result.formSubmission.formSubmissions,
            };

        //after optimization changes
        const taskDetailResponse = await taskService.getTaskDetailsById(
          result.taskId,
          requestPage,
          [result.taskDetailId]
        );

        const taskDetailsArray =
          (taskDetailResponse &&
            taskDetailResponse.result &&
            taskDetailResponse.result.elements &&
            taskDetailResponse.result.elements[0] &&
            taskDetailResponse.result.elements[0].taskDetails) ||
          [];
        let taskDetails = taskDetailsArray.filter(
          (td) => td.id === result.taskDetailId
        );

        if (taskDetails.length > 0) {
          result.taskDetails = taskDetails;
        }

        await addMetaEnumToTasks(Array.isArray(result) ? result : [result]);
        dispatch(success(TASK_ACTIONS.GET_TASK_BY_ID_SUCCESS, { result }));
      } else if (
        !!viewHistory &&
        store &&
        store.app &&
        !store.app.isConnected
      ) {
        const task = await TaskHistoryManager.getTaskById(id);
        dispatch(
          success(TASK_ACTIONS.GET_TASK_BY_ID_SUCCESS, { result: task })
        );
      } else {
        // Get data from local database in other cases.
        const task =
          taskType === TASK_VIEW_TYPE.ADHOC
            ? await AdhocTaskManager.getTaskById(id)
            : await ScheduledTaskManager.getTaskById(id);

        // if task detail is not yet synced, then get it from the API
        if (task && (!task.taskDetails || task.taskDetails.length === 0)) {
          const taskDetailResponse = await taskService.getTaskDetailsById(
            task.taskId,
            requestPage
          );
          const taskDetailsArray =
            (taskDetailResponse &&
              taskDetailResponse.result &&
              taskDetailResponse.result.elements &&
              taskDetailResponse.result.elements[0] &&
              taskDetailResponse.result.elements[0].taskDetails) ||
            [];

          task.taskDetails = taskDetailsArray.filter(
            (td) => td.id === task.taskDetailId
          );
          await addMetaEnumToTasks([task]);
        }
        dispatch(
          success(TASK_ACTIONS.GET_TASK_BY_ID_SUCCESS, { result: task })
        );
      }
    } catch (error) {
      console.log("error", error);
      dispatch(failure(TASK_ACTIONS.GET_TASK_BY_ID_FAILURE, error.toString()));
    }
  };
}

export function getTaskDesignerDetails(id, breadcrumbArray = []) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(TASK_ACTIONS.GET_TASK_BY_ID_REQUEST, { id }));
      taskService.getTaskDesignerDetails(id).then(
        (data) => {
          const { result } = data;
          breadcrumbArray.push({ label: !!result ? result.taskNumber : "" });
          dispatch(
            request(BREAKCRUMB_ACTIONS.UPDATE_BREADCRUMB_ARRAY, breadcrumbArray)
          );

          dispatch(success(TASK_ACTIONS.GET_TASK_BY_ID_SUCCESS, data));
          resolve(data);
        },
        (error) => {
          dispatch(
            failure(TASK_ACTIONS.GET_TASK_BY_ID_FAILURE, error.toString())
          );
          reject(error.toString());
        }
      );
    });
  };
}

export function getTaskDetails(id, breadcrumbArray = []) {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.GET_TASK_BY_ID_REQUEST, { id }));
    taskService.getTaskDetails(id).then(
      (data) => {
        const { result } = data;
        breadcrumbArray.push({ label: result.taskNumber || "" });
        dispatch(
          request(BREAKCRUMB_ACTIONS.UPDATE_BREADCRUMB_ARRAY, breadcrumbArray)
        );

        const workorderObject = !!result ? result.workorder : {};
        const programObject = !!workorderObject ? workorderObject.program : {};
        const divisionObject = !!programObject ? programObject.division : {};
        const divisionId = !!divisionObject ? divisionObject.id : null;
        const programId = !!programObject ? programObject.id : null;

        //nesting for programs
        dispatch(request(PROGRAM_ACTIONS.GET_PROGRAMS_REQUEST, divisionId));
        programService
          .getProgramsByDivisionId(divisionId)
          .then(
            (programs) => {
              dispatch(success(PROGRAM_ACTIONS.GET_PROGRAMS_SUCCESS, programs));

              //nesting for work orders
              dispatch(
                request(WORKORDER_ACTIONS.GET_WORKORDERS_REQUEST, programId)
              );
              workorderService.getWorkOrdersByProgramId(programId).then(
                (workorders) => {
                  dispatch(
                    success(
                      WORKORDER_ACTIONS.GET_WORKORDERS_SUCCESS,
                      workorders
                    )
                  );
                },
                (error) => {
                  dispatch(
                    failure(
                      WORKORDER_ACTIONS.GET_WORKORDERS_FAILURE,
                      error.toString()
                    )
                  );
                }
              );
            },
            (error) => {
              dispatch(
                failure(PROGRAM_ACTIONS.GET_PROGRAMS_FAILURE, error.toString())
              );
            }
          )
          .catch((error) => error);
        dispatch(success(TASK_ACTIONS.GET_TASK_BY_ID_SUCCESS, data));
      },
      (error) => {
        dispatch(
          failure(TASK_ACTIONS.GET_TASK_BY_ID_FAILURE, error.toString())
        );
      }
    );
  };
}

export function getTaskRuleDetails(id) {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.GET_TASK_RULE_BY_ID_REQUEST, { id }));
    taskService.getTaskRuleDetails(id).then(
      (data) => {
        dispatch(success(TASK_ACTIONS.GET_TASK_RULE_BY_ID_SUCCESS, data));
      },
      (error) => {
        dispatch(
          failure(TASK_ACTIONS.GET_TASK_RULE_BY_ID_FAILURE, error.toString())
        );
      }
    );
  };
}

export function getTaskWithSubmissionDetail(id, masterId) {
  return function (dispatch) {
    dispatch(
      request(TASK_ACTIONS.GET_TASK_DETAIL_WITH_SUBMISSION_BY_ID_REQUEST, {
        id,
        masterId,
      })
    );
    taskService.getTaskWithSubmissionDetail(id, masterId).then(
      (data) => {
        const { result } = data;
        dispatch(request(BREAKCRUMB_ACTIONS.UPDATE_BREADCRUMB_ARRAY));

        const workorderObject = !!result ? result.workorder : {};
        const programObject = !!workorderObject ? workorderObject.program : {};
        const divisionObject = !!programObject ? programObject.division : {};
        const divisionId = !!divisionObject ? divisionObject.id : null;
        const programId = !!programObject ? programObject.id : null;

        //nesting for programs
        dispatch(request(PROGRAM_ACTIONS.GET_PROGRAMS_REQUEST, divisionId));
        programService
          .getProgramsByDivisionId(divisionId)
          .then(
            (programs) => {
              dispatch(success(PROGRAM_ACTIONS.GET_PROGRAMS_SUCCESS, programs));

              //nesting for work orders
              dispatch(
                request(WORKORDER_ACTIONS.GET_WORKORDERS_REQUEST, programId)
              );
              workorderService.getWorkOrdersByProgramId(programId).then(
                (workorders) => {
                  dispatch(
                    success(
                      WORKORDER_ACTIONS.GET_WORKORDERS_SUCCESS,
                      workorders
                    )
                  );
                },
                (error) => {
                  dispatch(
                    failure(
                      WORKORDER_ACTIONS.GET_WORKORDERS_FAILURE,
                      error.toString()
                    )
                  );
                }
              );
            },
            (error) => {
              dispatch(
                failure(PROGRAM_ACTIONS.GET_PROGRAMS_FAILURE, error.toString())
              );
            }
          )
          .catch((error) => error);
        let taskSubmissionDetails = new TaskSumbissionDetailsModel(data);
        dispatch(
          success(
            TASK_ACTIONS.GET_TASK_DETAIL_WITH_SUBMISSION_BY_ID_SUCCESS,
            taskSubmissionDetails
          )
        );
      },
      (error) => {
        dispatch(
          failure(
            TASK_ACTIONS.GET_TASK_DETAIL_WITH_SUBMISSION_BY_ID_FAILURE,
            error.toString()
          )
        );
      }
    );
  };
}

export function getTasks(filterModel, workorderId, todaySubTaskParams) {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.ON_SORT_TASK_COLUMN_REQUEST, filterModel));
    taskService
      .getFilterTasks(filterModel, workorderId, todaySubTaskParams)
      .then(
        (data) => {
          dispatch(success(TASK_ACTIONS.ON_SORT_TASK_COLUMN_SUCCESS, data));
        },
        (error) => {
          dispatch(
            failure(TASK_ACTIONS.ON_SORT_TASK_COLUMN_FAILURE, error.toString())
          );
        }
      );
  };
}

export function getTaskDesigner(filterModel, workorderId, todaySubTaskParams) {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.ON_SORT_TASK_COLUMN_REQUEST, filterModel));
    taskService
      .getFilterTaskDesigner(filterModel, workorderId, todaySubTaskParams)
      .then(
        (data) => {
          dispatch(success(TASK_ACTIONS.ON_SORT_TASK_COLUMN_SUCCESS, data));
        },
        (error) => {
          dispatch(
            failure(TASK_ACTIONS.ON_SORT_TASK_COLUMN_FAILURE, error.toString())
          );
        }
      );
  };
}

export function getAllTaskNames() {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.GET_ALL_TASK_NAMES_REQUEST, {}));
    taskService.getAllTaskNames().then(
      (data) => {
        dispatch(success(TASK_ACTIONS.GET_ALL_TASK_NAMES_SUCCESS, data));
      },
      (error) => {
        dispatch(
          failure(TASK_ACTIONS.GET_ALL_TASK_NAMES_FAILURE, error.toString())
        );
      }
    );
  };
}

export function getTaskSubmissions(
  paginationData,
  searchTerms,
  filters,
  sortObject,
  TaskId
) {
  return function (dispatch) {
    dispatch(
      request(TASK_ACTIONS.ON_TASK_SUBMISSION_HISTORY_REQUEST, {
        paginationData,
        searchTerms,
        filters,
        sortObject,
      })
    );
    taskService
      .getFilterTaskSubmissions(
        paginationData,
        searchTerms,
        filters,
        sortObject,
        TaskId
      )
      .then(
        (data) => {
          dispatch(
            success(TASK_ACTIONS.ON_TASK_SUBMISSION_HISTORY_SUCCESS, data)
          );
        },
        (error) => {
          dispatch(
            failure(
              TASK_ACTIONS.ON_TASK_SUBMISSION_HISTORY_FAILURE,
              error.toString()
            )
          );
        }
      );
  };
}

export function getFilteredTasks(
  paginationData,
  searchTerms,
  filters,
  sortObject,
  workorderId,
  taskId,
  parentTaskOnly,
  todaySubTaskParams
) {
  return function (dispatch) {
    dispatch(
      request(TASK_ACTIONS.GET_ALL_TASKS_REQUEST, {
        paginationData,
        searchTerms,
        filters,
        sortObject,
      })
    );
    taskService
      .getFilteredTasks(
        paginationData,
        searchTerms,
        filters,
        sortObject,
        workorderId,
        taskId,
        parentTaskOnly,
        todaySubTaskParams
      )
      .then(
        (data) => {
          dispatch(success(TASK_ACTIONS.GET_ALL_TASKS_SUCCESS, data));
          if (process.env.REACT_APP_PLATFORM !== "WEB") {
            dispatch(success(TASK_ACTIONS.GET_ALL_TASKS_SUCCESS_MOBILE, data));
          }
        },
        (error) => {
          dispatch(
            failure(TASK_ACTIONS.GET_ALL_TASKS_FAILURE, error.toString())
          );
        }
      );
  };
}

export function getFilteredTaskDesigner(
  paginationData,
  searchTerms,
  filters,
  sortObject,
  workorderId,
  taskId,
  parentTaskOnly,
  todaySubTaskParams
) {
  return function (dispatch) {
    dispatch(
      request(TASK_ACTIONS.GET_ALL_TASKS_REQUEST, {
        paginationData,
        searchTerms,
        filters,
        sortObject,
      })
    );
    taskService
      .getFilteredTaskDesigner(
        paginationData,
        searchTerms,
        filters,
        sortObject,
        workorderId,
        taskId,
        parentTaskOnly,
        todaySubTaskParams
      )
      .then(
        (data) => {
          dispatch(success(TASK_ACTIONS.GET_ALL_TASKS_SUCCESS, data));
        },
        (error) => {
          dispatch(
            failure(TASK_ACTIONS.GET_ALL_TASKS_FAILURE, error.toString())
          );
        }
      );
  };
}

export function getTaskLocations(ids) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(TASK_ACTIONS.GET_ALL_TASKS_WITH_LOCATIONS_REQUEST));
      taskService.getTaskLocations(ids).then(
        (data) => {
          dispatch(
            success(TASK_ACTIONS.GET_ALL_TASKS_WITH_LOCATIONS_SUCCESS, data)
          );
          resolve(data);
        },
        (error) => {
          dispatch(
            failure(
              TASK_ACTIONS.GET_ALL_TASKS_WITH_LOCATIONS_FAILURE,
              error.toString()
            )
          );
          reject(error.toString());
        }
      );
    });
  };
}

export function handleUpsertTaskMessages(messageObject) {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.HANDLE_ADD_TASK_MESSAGE, messageObject));
  };
}

export function upsertTaskFilters(taskFilterParams) {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.UPSERT_TASK_LIST_FILTERS, taskFilterParams));
  };
}

export function removeTaskFilters() {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.REMOVE_TASK_LIST_FILTERS));
  };
}

export function upsertTaskSubmissionsFilters(taskSubmissionsFilterParams) {
  return function (dispatch) {
    dispatch(
      request(
        TASK_ACTIONS.UPSERT_TASK_SUBMISSIONS_LIST_FILTERS,
        taskSubmissionsFilterParams
      )
    );
  };
}

export function removeTaskSubmissionsFilters() {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.REMOVE_TASK_SUBMISSIONS_LIST_FILTERS));
  };
}

export function saveTaskId(checked, taskid) {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.SAVE_TASK_ID, { checked, taskid }));
  };
}

export function changeTaskState(id, value) {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.CHANGE_TASK_STATUS_REQUEST, { id, value }));
    taskService.changeState(id, value).then(
      (data) => {
        dispatch(
          success(TASK_ACTIONS.CHANGE_TASK_STATUS_SUCCESS, {
            message:
              LOCALIZATION_CONSTANTS.SUCCESS_MESSAGES.TASK_STATUS_UPDATED,
          })
        );
      },
      (error) => {
        dispatch(
          failure(TASK_ACTIONS.CHANGE_TASK_STATUS_FAILURE, error.toString())
        );
      }
    );
  };
}

export function changeTaskDesignerState(id, value) {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.CHANGE_TASK_STATUS_REQUEST, { id, value }));
    taskService.changeStateTaskDesigner(id, value).then(
      (data) => {
        dispatch(
          success(TASK_ACTIONS.CHANGE_TASK_STATUS_SUCCESS, {
            message:
              LOCALIZATION_CONSTANTS.SUCCESS_MESSAGES.TASK_STATUS_UPDATED,
            taskDetails: data.result,
          })
        );
      },
      (error) => {
        dispatch(
          failure(TASK_ACTIONS.CHANGE_TASK_STATUS_FAILURE, error.toString())
        );
      }
    );
  };
}

export function uploadImages(files, type) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(TASK_ACTIONS.UPLOAD_IMAGE_REQUEST));
      taskService.uploadImages(files, type).then(
        (data) => {
          dispatch(success(TASK_ACTIONS.UPLOAD_IMAGE_SUCCESS, data));
          resolve(data);
        },
        (error) => {
          dispatch(
            failure(TASK_ACTIONS.UPLOAD_IMAGE_FAILURE, error.toString())
          );
          reject(error.toString());
        }
      );
    });
  };
}

export function uploadImagesMobile(images) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(TASK_ACTIONS.UPLOAD_IMAGE_REQUEST));
      taskService.uploadImagesMobile(images).then(
        (response) => {
          dispatch(success(TASK_ACTIONS.UPLOAD_IMAGE_SUCCESS));
          resolve(response);
        },
        (error) => {
          dispatch(
            failure(TASK_ACTIONS.UPLOAD_IMAGE_FAILURE, error.toString())
          );
          reject(error.toString());
        }
      );
    });
  };
}

export function deleteImages(id) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(TASK_ACTIONS.DELETE_IMAGE_REQUEST));
      taskService.deleteImages(id).then(
        (data) => {
          dispatch(success(TASK_ACTIONS.DELETE_IMAGE_SUCCESS));
          resolve(data);
        },
        (error) => {
          dispatch(
            failure(TASK_ACTIONS.DELETE_IMAGE_FAILURE, error.toString())
          );
          reject(error.toString());
        }
      );
    });
  };
}

export function approveSubmission(body) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(TASK_ACTIONS.APPROVE_SUBMISSON_REQUEST));
      taskService.approveSubmission(body).then(
        (data) => {
          dispatch(success(TASK_ACTIONS.APPROVE_SUBMISSON_SUCCESS, data));
          resolve(data);
        },
        (error) => {
          dispatch(
            failure(TASK_ACTIONS.APPROVE_SUBMISSON_FAILURE, error.toString())
          );
          reject(error.toString());
        }
      );
    });
  };
}

export function resetTaskDetails() {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.RESET_TASK_DETAIL_STATE));
  };
}

export function markTaskAsNotPerformed(
  formSubmissionIds,
  reason,
  comment,
  isHistory = false
) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(TASK_ACTIONS.MARK_TASK_AS_NOT_PERFORMED_REQUEST));
      taskService
        .markTaskAsNotPerformed(formSubmissionIds, reason, comment)
        .then(
          (data) => {
            let isSkipped = data.message;
            let responseMessage = data.result || "";
            if (isSkipped == "Failed") {
              dispatch(
                failure(
                  TASK_ACTIONS.MARK_TASK_AS_NOT_PERFORMED_FAILURE,
                  responseMessage
                )
              );
            } else {
              dispatch(
                success(TASK_ACTIONS.MARK_TASK_AS_NOT_PERFORMED_SUCCESS, {
                  successMessage: responseMessage,
                })
              );
            }
            if (!isHistory) dispatch(syncData());
            resolve({ successMessage: responseMessage });
          },
          (error) => {
            dispatch(
              failure(
                TASK_ACTIONS.MARK_TASK_AS_NOT_PERFORMED_FAILURE,
                error.toString()
              )
            );
            reject(error.toString());
          }
        );
    });
  };
}

export function skipTasks(reasonId, comment, taskIds) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(TASK_ACTIONS.SKIP_TASK_REQUEST));
      let payload = {
        formSubmissionIds: taskIds,
        reason: reasonId,
        comment: comment
      };
      taskService.skipTasks(payload).then(
        (data) => {
          dispatch(success(TASK_ACTIONS.SKIP_TASK_SUCCESS, data));
          // if (BMToastUtil) {
          //   data.message == 'Success' && BMToastUtil.success(data.result)
          // }
          resolve(data);
        },
        (error) => {
          dispatch(
            failure(TASK_ACTIONS.SKIP_TASK_FAILURE, error.toString())
          );
          reject(error.toString());
        }
      );
    });
  };
}

export function GetElementByTaskGroupId(groupId, qaTaskForm = true) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(TASK_ACTIONS.GET_ELEMENT_BY_GROUP_ID_REQUEST));
      let payload = {
        "taskGroupId": groupId,
        "isPaginated": false,
      };
      taskService.getElementsByGroupId(payload).then(
        (data) => {
          dispatch(success(TASK_ACTIONS.GET_ELEMENT_BY_GROUP_ID_SUCCESS, {data, qaTaskForm}));
          resolve(data);
        },
        (error) => {
          dispatch(
            failure(TASK_ACTIONS.GET_ELEMENT_BY_GROUP_ID_FAILURE, error.toString())
          );
          reject(error.toString());
        }
      );
    });
  };
}
