import EcoDocsDB, { METADATA_SCHEMA_NAME } from "..";

class MetadataManager {
  async saveMetadata(meta, value) {
    const db = await EcoDocsDB.getConnection();
    db.write(() => {
      const normalizedValue = typeof value !== 'string' ? JSON.stringify(value) : value;
      db.create(METADATA_SCHEMA_NAME, { meta, value: normalizedValue, }, true);
    });
  }

  async getMetadata(meta) {
    const db = await EcoDocsDB.getConnection();
    const results = db.objects(METADATA_SCHEMA_NAME).filtered('meta == $0', meta);

    return JSON.parse(results[0].value);
  }
}

export default new MetadataManager();
