import {
  TASK_SUBMISSION_ACTIONS,
  USER_ACTIONS,
} from "../../constants/ActionConstants";
import { getTaskSubmissionFilterCount } from "@ecodocs/common/src/helpers/TaskSubmissionHelper";

export const initialState = {
  submissions: [],
  submissionsMobile: [],
  filters: {
    search: "",
    workorder: "",
    frequency: "",
    locationId: "",
    date: {
      startDate: "",
      endDate: "",
    },
  },
  isPendingSync: false,
  totalElements: 0,
  totalElementsForHeader: 0,
  pendingSyncElementsForHeader: 0,
  loading: false,
};

export function taskSubmission(state = initialState, action) {
  switch (action.type) {
    // Task submission listing data
    case TASK_SUBMISSION_ACTIONS.GET_ALL_TASK_SUBMISSIONS_REQUEST:
    case TASK_SUBMISSION_ACTIONS.GET_ALL_TASK_SUBMISSIONS_OFFLINE_REQUEST:
      return {
        ...state,
        loading: true,
        taskSubmissionListingError: false,
        submissions: [],
        // totalElements: 0,
      };

    case TASK_SUBMISSION_ACTIONS.GET_ALL_TASK_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        taskSubmissionListingError: false,
        submissions:
          !!action.payload.result && action.payload.result.elements
            ? action.payload.result.elements
            : [],
        totalElements:
          !!action.payload.result && !!action.payload.result.totalElements
            ? action.payload.result.totalElements
            : 0,
      };

    case TASK_SUBMISSION_ACTIONS.GET_PENDING_SYNC_TASK:
      return {
        ...state,
        loading: false,
        submissionsMobile: [...action.payload],
        totalElements: action.payload.length || 0,
        pendingSyncElementsForHeader: action.payload.length || 0,
      };

    case TASK_SUBMISSION_ACTIONS.GET_IS_PENDING_SYNC:
      return {
        ...state,
        isPendingSync: action.payload,
      };

    case TASK_SUBMISSION_ACTIONS.GET_ALL_TASK_SUBMISSIONS_SUCCESS_MOBILE:
      const { result, pageNumber } = action.payload;
      return {
        ...state,
        loading: false,
        submissionsMobile: [...state.submissionsMobile, ...result.elements],
        totalElements: result.totalElements || 0,
        totalElementsForHeader:
          result.totalElements + state.pendingSyncElementsForHeader || 0,
      };

    case TASK_SUBMISSION_ACTIONS.GET_ALL_TASK_SUBMISSIONS_OFFLINE_SUCCESS:
      return {
        ...state,
        loading: false,
        submissions: [...action.payload],
        totalElements: (action.payload && action.payload.length) || 0,
      };

    case TASK_SUBMISSION_ACTIONS.GET_TASK_HISTORY_OFFLINE_SUCCESS:
      return {
        ...state,
        loading: false,
        submissionsMobile: [...action.payload],
        totalElements: (action.payload && action.payload.length) || 0,
      };

    case TASK_SUBMISSION_ACTIONS.GET_ALL_TASK_SUBMISSIONS_FAILURE:
    case TASK_SUBMISSION_ACTIONS.GET_ALL_TASK_SUBMISSIONS_OFFLINE_FAILURE:
      return {
        ...state,
        loading: false,
        taskSubmissionListingError: true,
        submissions: [],
        totalElements: 0,
        totalElementsForHeader: 0,
        pendingSyncElementsForHeader: 0,
        error: action.payload,
      };
    case TASK_SUBMISSION_ACTIONS.GET_ALL_TASK_SUBMISSIONS_ADHOC_AUTOSAVE_OFFLINE_FAILURE:
      return {
        ...state,
        loading: false,
        taskSubmissionListingError: true,
        error: action.payload,
      };

    case TASK_SUBMISSION_ACTIONS.GET_TASK_HISTORY_OFFLINE_FAILURE:
      return {
        ...state,
        loading: false,
        submissionsMobile: [],
        totalElements: 0,
        error: action.payload,
      };
    // End - Task Submission listing data

    // Handle Task submission message
    case TASK_SUBMISSION_ACTIONS.HANDLE_ADD_TASK_SUBMISSION_MESSAGE:
      return {
        ...state,
        showMessage: action.payload.showMessage,
      };

    // End - Handle Task submission message

    // Add task submission filters
    case TASK_SUBMISSION_ACTIONS.UPSERT_TASK_SUBMISSION_LIST_FILTERS:
      return {
        ...state,
        taskSubmissionFilter: {
          filterCount: getTaskSubmissionFilterCount(action.payload),
          taskSubmissionFilterValues: action.payload,
        },
      };

    // Update task submission filters
    case TASK_SUBMISSION_ACTIONS.UPDATE_TASK_SUBMISSION_LIST_FILTERS:
      let taskSubmissionFilter = JSON.parse(
        JSON.stringify(state.taskSubmissionFilter || {})
      );
      taskSubmissionFilter.taskSubmissionFilterValues = action.payload;
      return {
        ...state,
        taskSubmissionFilter: taskSubmissionFilter,
      };

    // End - Add task submission filters

    // Remove Task submission filters
    case TASK_SUBMISSION_ACTIONS.REMOVE_TASK_LIST_SUBMISSION_FILTERS:
      return {
        ...state,
        taskSubmissionFilter: {
          filterCount: 0,
          taskSubmissionFilterValues: {},
        },
      };

    // End - Remove Task submission filters

    case TASK_SUBMISSION_ACTIONS.CHANGE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };

    case TASK_SUBMISSION_ACTIONS.RESET_FILTERS:
      return {
        ...state,
        filters: initialState.filters,
      };

    case TASK_SUBMISSION_ACTIONS.CLEAR_SUBMISSIONS:
      return {
        ...state,
        submissions: [],
        submissionsMobile: [],
        totalElements: 0,
      };

    default:
      return state;
  }
}
