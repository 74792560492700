import { APP_ACTIONS } from "../../constants/ActionConstants";

export function setAppConnectivityStatus(status) {
  return {
    type: APP_ACTIONS.SET_APP_CONNECTIVITY_STATUS_SUCCESS,
    payload: { isConnected: status },
  };
}

export function setIsSyncInProgress(syncing) {
  return { type: APP_ACTIONS.SET_IS_SYNC_IN_PROGRESS, payload: !!syncing };
}

export function setSyncingScheduledTasks(syncing) {
  return { type: APP_ACTIONS.SET_SYNCING_SCHEDULED_TASKS, payload: !!syncing };
}

export function setSyncingAutoSavedAdhocTasks(syncing) {
  return { type: APP_ACTIONS.SET_SYNCING_AUTO_SAVED_ADHOC_TASKS, payload: !!syncing };
}

export function setSyncingCorrectiveActions(syncing) {
  return {
    type: APP_ACTIONS.SET_SYNCING_CORRECTIVE_ACTIONS,
    payload: !!syncing,
  };
}

export function setSyncingAdhocTasks(syncing) {
  return { type: APP_ACTIONS.SET_SYNCING_ADHOC_TASKS, payload: !!syncing };
}

export function setSyncingAdhocTasksForCA(syncing) {
  return {
    type: APP_ACTIONS.SET_SYNCING_ADHOC_TASKS_FOR_CA,
    payload: !!syncing,
  };
}

export function setSyncingTaskHistory(syncing) {
  return { type: APP_ACTIONS.SET_SYNCING_TASK_HISTORY, payload: !!syncing };
}

export function setSyncingLocation(syncing) {
  return { type: APP_ACTIONS.SET_SYNCING_TASK_LOCATION, payload: !!syncing };
}

export function setSyncingWorkorder(syncing) {
  return { type: APP_ACTIONS.SET_SYNCING_TASK_WORKORDER, payload: !!syncing };
}

export function setSyncingProgram(syncing) {
  return { type: APP_ACTIONS.SET_SYNCING_TASK_PROGRAM, payload: !!syncing };
}

export function setPushInProgress(syncing) {
  return { type: APP_ACTIONS.SET_PUSH_IN_PROGRESS, payload: !!syncing };
}

export function setLastSyncUI(timestamp) {
  return { type: APP_ACTIONS.SET_LAST_SYNC_UI, payload: timestamp };
}

export function setSyncProgressAdhocTasks({ totalTasks, syncedTasks }) {
  return {
    type: APP_ACTIONS.SET_SYNC_PROGRESS_ADHOC_TASKS,
    payload: { totalTasks, syncedTasks },
  };
}

export function setSyncProgressAutoSavedAdhocTasks({ totalTasks, syncedTasks }) {
  return {
    type: APP_ACTIONS.SET_SYNC_PROGRESS_AUTO_SAVED_ADHOC_TASKS,
    payload: { totalTasks, syncedTasks },
  };
}

export function setSyncProgressScheduledTasks({ totalTasks, syncedTasks }) {
  return {
    type: APP_ACTIONS.SET_SYNC_PROGRESS_SCHEDULED_TASKS,
    payload: { totalTasks, syncedTasks },
  };
}

export function setSyncProgressCorrectiveActions({ totalTasks, syncedTasks }) {
  return {
    type: APP_ACTIONS.SET_SYNC_PROGRESS_CORRECTIVE_ACTIONS,
    payload: { totalTasks, syncedTasks },
  };
}

export function setSyncProgressTaskHistory({ totalTasks, syncedTasks }) {
  return {
    type: APP_ACTIONS.SET_SYNC_PROGRESS_TASK_HISTORY,
    payload: { totalTasks, syncedTasks },
  };
}
