export const USER_ACTIONS = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  AUTO_LOGGED_IN: "AUTO_LOGGED_IN",

  SET_DEPARTMENT: "SET_DEPARTMENT",

  LOGOUT: "LOGOUT",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",

  CHANGE_USER_STATUS_REQUEST: "CHANGE_USER_STATUS_REQUEST",
  CHANGE_USER_STATUS_SUCCESS: "CHANGE_USER_STATUS_SUCCESS",
  CHANGE_USER_STATUS_FAILURE: "CHANGE_USER_STATUS_FAILURE",

  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",

  GET_ALL_USERS_REQUEST: "GET_ALL_USERS_REQUEST",
  GET_ALL_USERS_SUCCESS: "GET_ALL_USERS_SUCCESS",
  GET_ALL_USERS_FAILURE: "GET_ALL_USERS_FAILURE",

  GET_ADMIN_USERS_REQUEST: "GET_ADMIN_USERS_REQUEST",
  GET_ADMIN_USERS_SUCCESS: "GET_ADMIN_USERS_SUCCESS",
  GET_ADMIN_USERS_FAILURE: "GET_ADMIN_USERS_FAILURE",

  GET_NON_ADMIN_USERS_REQUEST: "GET_NON_ADMIN_USERS_REQUEST",
  GET_NON_ADMIN_USERS_SUCCESS: "GET_NON_ADMIN_USERS_SUCCESS",
  GET_NON_ADMIN_USERS_FAILURE: "GET_NON_ADMIN_USERS_FAILURE",

  GET_ALL_MANAGERS_REQUEST: "GET_ALL_MANAGERS_REQUEST",
  GET_ALL_MANAGERS_SUCCESS: "GET_ALL_MANAGERS_SUCCESS",
  GET_ALL_MANAGERS_FAILURE: "GET_ALL_MANAGERS_FAILURE",

  SUBMIT_USER_REQUEST: "SUBMIT_USER_REQUEST",
  SUBMIT_USER_SUCCESS: "SUBMIT_USER_SUCCESS",
  SUBMIT_USER_FAILURE: "SUBMIT_USER_FAILURE",
  HANDLE_SUBMIT_USER_SUCCESS: "HANDLE_SUBMIT_USER_SUCCESS",

  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",

  HANDLE_CHANGE_PASSWORD_MESSAGE: "HANDLE_CHANGE_PASSWORD_MESSAGE",

  TOGGLE_NOTIFICATIONS_REQUEST: "TOGGLE_NOTIFICATIONS_REQUEST",
  TOGGLE_NOTIFICATIONS_SUCCESS: "TOGGLE_NOTIFICATIONS_SUCCESS",
  TOGGLE_NOTIFICATIONS_FAILURE: "TOGGLE_NOTIFICATIONS_FAILURE",

  GET_USER_BY_ID_REQUEST: "GET_USER_BY_ID_REQUEST",
  GET_USER_BY_ID_SUCCESS: "GET_USER_BY_ID_SUCCESS",
  GET_USER_BY_ID_FAILURE: "GET_USER_BY_ID_FAILURE",

  HANDLE_ADD_USER_MESSAGE: "HANDLE_ADD_USER_MESSAGE",
  HANDLE_EDIT_USER_MESSAGE: "HANDLE_EDIT_USER_MESSAGE",
  UPSERT_USER_LIST_FILTERS: "UPSERT_USER_LIST_FILTERS",
  REMOVE_USER_LIST_FILTERS: "REMOVE_USER_LIST_FILTERS",

  NOTIFY_ASSOCIATE_USER_TO_DIVISION_REQUEST_SUCCESS:
    "NOTIFY_ASSOCIATE_USER_TO_DIVISION_REQUEST_SUCCESS",
  ASSOCIATE_USER_TO_DIVISION_REQUEST: "ASSOCIATE_USER_TO_DIVISION_REQUEST",
  ASSOCIATE_USER_TO_DIVISION_SUCCESS: "ASSOCIATE_USER_TO_DIVISION_SUCCESS",
  ASSOCIATE_USER_TO_DIVISION_FAILURE: "ASSOCIATE_USER_TO_DIVISION_FAILURE",

  QA_FORM_SUBMSSION_REQUEST: "QA_FORM_SUBMSSION_REQUEST",
  QA_FORM_SUBMSSION_SUCCESS: "QA_FORM_SUBMSSION_SUCCESS",
  QA_FORM_SUBMSSION_FAILURE: "QA_FORM_SUBMSSION_FAILURE",

  ON_SORT_USER_COLUMN_REQUEST: "ON_SORT_USER_COLUMN_REQUEST",
  ON_SORT_USER_COLUMN_SUCCESS: "ON_SORT_USER_COLUMN_SUCCESS",
  ON_SORT_USER_COLUMN_FAILURE: "ON_SORT_USER_COLUMN_FAILURE",

  GET_USER_BY_LOCATION_WORKER_REQUEST: "GET_USER_BY_LOCATION_WORKER_REQUEST",
  GET_USER_BY_LOCATION_WORKER_SUCCESS: "GET_USER_BY_LOCATION_WORKER_SUCCESS",
  GET_USER_BY_LOCATION_WORKER_FAILURE: "GET_USER_BY_LOCATION_WORKER_FAILURE",

  RESET_USER_DETAIL_STATE: "RESET_USER_DETAIL_STATE",

  UPDATE_USER_DETAIL_REQUEST: "UPDATE_USER_DETAIL_REQUEST",

  EMPTY_ALL_USERS: "EMPTY_ALL_USERS",

  USER_SIGNUP_REQUEST: "USER_SIGNUP_REQUEST",
  USER_SIGNUP_SUCCESS: "USER_SIGNUP_SUCCESS",
  USER_SIGNUP_FAILURE: "USER_SIGNUP_FAILURE",
};

export const TABLE_ACTIONS = {
  SAVE_USER_ID: "SAVE_USER_ID",
  SAVE_TASK_ID: "SAVE_TASK_ID",
  SAVE_REVIEW_SUBMISSION_ID: "SAVE_REVIEW_SUBMISSION_ID",
  SAVE_CORRECTIVE_ACTION_ID: "SAVE_CORRECTIVE_ACTION_ID",
  SAVE_SCHEDULED_TASK_ID: "SAVE_SCHEDULED_TASK_ID",
  SAVE_SUBMISSION_TASK_ID: "SAVE_SUBMISSION_TASK_ID",
  SAVE_WORKORDER_ID: "SAVE_WORKORDER_ID",
  SAVE_PROGRAM_ID: "SAVE_PROGRAM_ID",
  SAVE_LOCATION_ID: "SAVE_LOCATION_ID",
};

export const QA_TASK_SPECIFICATION_ACTIONS = {
  UPDATE_FORMULA_ARRAY: "UPDATE_FORMULA_ARRAY",
};

export const BREAKCRUMB_ACTIONS = {
  UPDATE_BREADCRUMB_ARRAY: "UPDATE_BREADCRUMB_ARRAY",
};

export const TASK_ACTIONS = {
  DUPLICATE_TASK_REQUEST: "DUPLICATE_TASK_REQUEST",
  DUPLICATE_TASK_SUCCESS: "DUPLICATE_TASK_SUCCESS",
  DUPLICATE_TASK_FAILURE: "DUPLICATE_TASK_FAILURE",

  GET_ALL_TASKS_REQUEST: "GET_ALL_TASKS_REQUEST",
  GET_ALL_TASKS_SUCCESS: "GET_ALL_TASKS_SUCCESS",
  GET_ALL_TASKS_FAILURE: "GET_ALL_TASKS_FAILURE",

  GET_ELEMENT_BY_GROUP_ID_REQUEST: "GET_ELEMENT_BY_GROUP_ID_REQUEST",
  GET_ELEMENT_BY_GROUP_ID_SUCCESS: "GET_ELEMENT_BY_GROUP_ID_SUCCESS",
  GET_ELEMENT_BY_GROUP_ID_FAILURE: "GET_ELEMENT_BY_GROUP_ID_FAILURE",

  SKIP_TASK_REQUEST: "SKIP_TASK_REQUEST",
  SKIP_TASK_SUCCESS: "SKIP_TASK_SUCCESS",
  SKIP_TASK_FAILURE: "SKIP_TASK_FAILURE",

  GET_ALL_TASKS_OFFLINE_REQUEST: "GET_ALL_TASKS_OFFLINE_REQUEST",
  GET_ALL_TASKS_OFFLINE_SUCCESS: "GET_ALL_TASKS_OFFLINE_SUCCESS",
  GET_ALL_TASKS_OFFLINE_FAILURE: "GET_ALL_TASKS_OFFLINE_FAILURE",

  GET_ALL_ADHOC_TASKS_REQUEST: "GET_ALL_ADHOC_TASKS_REQUEST",
  GET_ALL_ADHOC_TASKS_SUCCESS: "GET_ALL_ADHOC_TASKS_SUCCESS",
  GET_ALL_ADHOC_TASKS_FAILURE: "GET_ALL_ADHOC_TASKS_FAILURE",
  GET_ALL_TASK_NAMES_REQUEST: "GET_ALL_TASK_NAMES_REQUEST",
  GET_ALL_TASK_NAMES_SUCCESS: "GET_ALL_TASK_NAMES_SUCCESS",
  GET_ALL_TASK_NAMES_FAILURE: "GET_ALL_TASK_NAMES_FAILURE",

  GET_TASK_DESIGNER_BY_ID_REQUEST: "GET_TASK_DESIGNER_BY_ID_REQUEST",
  GET_TASK_DESIGNER_BY_ID_SUCCESS: "GET_TASK_DESIGNER_BY_ID_SUCCESS",
  GET_TASK_DESIGNER_BY_ID_FAILURE: "GET_TASK_DESIGNER_BY_ID_FAILURE",

  GET_TASK_DESIGNER_BY_PROGRAM_ID_REQUEST: "GET_TASK_DESIGNER_BY_PROGRAM_ID_REQUEST",
  GET_TASK_DESIGNER_BY_PROGRAM_ID_SUCCESS: "GET_TASK_DESIGNER_BY_PROGRAM_ID_SUCCESS",
  GET_TASK_DESIGNER_BY_PROGRAM_ID_FAILURE: "GET_TASK_DESIGNER_BY_PROGRAM_ID_FAILURE",

  GET_TASK_BY_ID_REQUEST: "GET_TASK_BY_ID_REQUEST",
  GET_TASK_BY_ID_SUCCESS: "GET_TASK_BY_ID_SUCCESS",
  GET_TASK_BY_ID_FAILURE: "GET_TASK_BY_ID_FAILURE",

  GET_PARENT_TASK_BY_ID_REQUEST: "GET_PARENT_TASK_BY_ID_REQUEST",
  GET_PARENT_TASK_BY_ID_SUCCESS: "GET_PARENT_TASK_BY_ID_SUCCESS",
  GET_PARENT_TASK_BY_ID_FAILURE: "GET_PARENT_TASK_BY_ID_FAILURE",

  DELETE_IS_ADHOC_ENTRY_REQUEST: "DELETE_IS_ADHOC_ENTRY_REQUEST",
  DELETE_IS_ADHOC_ENTRY_SUCCESS: "DELETE_IS_ADHOC_ENTRY_SUCCESS",
  DELETE_IS_ADHOC_ENTRY_FAILURE: "DELETE_IS_ADHOC_ENTRY_FAILURE",

  GET_TASK_RULE_BY_ID_REQUEST: "GET_TASK_RULE_BY_ID_REQUEST",
  GET_TASK_RULE_BY_ID_SUCCESS: "GET_TASK_RULE_BY_ID_SUCCESS",
  GET_TASK_RULE_BY_ID_FAILURE: "GET_TASK_RULE_BY_ID_FAILURE",

  GET_TASK_DETAIL_WITH_SUBMISSION_BY_ID_REQUEST:
    "GET_TASK_DETAIL_WITH_SUBMISSION_BY_ID_REQUEST",
  GET_TASK_DETAIL_WITH_SUBMISSION_BY_ID_SUCCESS:
    "GET_TASK_DETAIL_WITH_SUBMISSION_BY_ID_SUCCESS",
  GET_TASK_DETAIL_WITH_SUBMISSION_BY_ID_FAILURE:
    "GET_TASK_DETAIL_WITH_SUBMISSION_BY_ID_FAILURE",

  SUBMIT_TASK_REQUEST: "SUBMIT_TASK_REQUEST",
  SUBMIT_TASK_SUCCESS: "SUBMIT_TASK_SUCCESS",
  SUBMIT_TASK_FAILURE: "SUBMIT_TASK_FAILURE",

  TASK_EXISTS_REQUEST: "TASK_EXISTS_REQUEST",
  TASK_EXISTS_SUCCESS: "TASK_EXISTS_SUCCESS",
  TASK_EXISTS_FAILURE: "TASK_EXISTS_FAILURE",

  HANDLE_ADD_TASK_MESSAGE: "HANDLE_ADD_TASK_MESSAGE",
  HANDLE_EDIT_TASK_MESSAGE: "HANDLE_EDIT_TASK_MESSAGE",
  UPSERT_TASK_LIST_FILTERS: "UPSERT_TASK_LIST_FILTERS",
  REMOVE_TASK_LIST_FILTERS: "REMOVE_TASK_LIST_FILTERS",

  UPSERT_TASK_SUBMISSIONS_LIST_FILTERS: "UPSERT_TASK_SUBMISSIONS_LIST_FILTERS",
  REMOVE_TASK_SUBMISSIONS_LIST_FILTERS: "REMOVE_TASK_SUBMISSIONS_LIST_FILTERS",

  SAVE_TASK_ID: "SAVE_TASK_ID",

  HANDLE_ASSOCIATE_WORKORDER_TO_TASKS_SUCCESS:
    "HANDLE_ASSOCIATE_WORKORDER_TO_TASKS_SUCCESS",
  ASSOCIATE_WORKORDER_TO_TASKS_REQUEST: "ASSOCIATE_WORKORDER_TO_TASKS_REQUEST",
  ASSOCIATE_WORKORDER_TO_TASKS_SUCCESS: "ASSOCIATE_WORKORDER_TO_TASKS_SUCCESS",
  ASSOCIATE_WORKORDER_TO_TASKS_FAILURE: "ASSOCIATE_WORKORDER_TO_TASKS_FAILURE",

  CHANGE_TASK_STATUS_REQUEST: "CHANGE_TASK_STATUS_REQUEST",
  CHANGE_TASK_STATUS_SUCCESS: "CHANGE_TASK_STATUS_SUCCESS",
  CHANGE_TASK_STATUS_FAILURE: "CHANGE_TASK_STATUS_FAILURE",

  ADD_RULE_FOR_FORM_FIELD_REQUEST: "ADD_RULE_TO_FORM_FIELD_REQUEST",
  ADD_RULE_FOR_FORM_FIELD_SUCCESS: "ADD_RULE_FOR_FORM_FIELD_SUCCESS",
  ADD_RULE_FOR_FORM_FIELD_FAILURE: "ADD_RULE_FOR_FORM_FIELD_FAILURE",

  UPDATE_RULE_FOR_FORM_FIELD_REQUEST: "UPDATE_RULE_TO_FORM_FIELD_REQUEST",
  UPDATE_RULE_FOR_FORM_FIELD_SUCCESS: "UPDATE_RULE_FOR_FORM_FIELD_SUCCESS",
  UPDATE_RULE_FOR_FORM_FIELD_FAILURE: "UPDATE_RULE_FOR_FORM_FIELD_FAILURE",

  DELETE_RULE_FOR_FORM_FIELD_REQUEST: "DELETE_RULE_FOR_FORM_FIELD_REQUEST",
  DELETE_RULE_FOR_FORM_FIELD_SUCCESS: "DELETE_RULE_FOR_FORM_FIELD_SUCCESS",
  DELETE_RULE_FOR_FORM_FIELD_FAILURE: "DELETE_RULE_FOR_FORM_FIELD_FAILURE",

  UPDATE_FORMULA_SPECIFICATIONS_ARRAY: "UPDATE_FORMULA_SPECIFICATIONS_ARRAY",

  ON_SORT_TASK_COLUMN_REQUEST: "ON_SORT_TASK_COLUMN_REQUEST",
  ON_SORT_TASK_COLUMN_SUCCESS: "ON_SORT_TASK_COLUMN_SUCCESS",
  ON_SORT_TASK_COLUMN_FAILURE: "ON_SORT_TASK_COLUMN_FAILURE",

  ON_TASK_SUBMISSION_HISTORY_REQUEST: "ON_TASK_SUBMISSION_HISTORY_REQUEST",
  ON_TASK_SUBMISSION_HISTORY_SUCCESS: "ON_TASK_SUBMISSION_HISTORY_SUCCESS",
  ON_TASK_SUBMISSION_HISTORY_FAILURE: "ON_TASK_SUBMISSION_HISTORY_FAILURE",

  UPLOAD_IMAGE_REQUEST: "UPLOAD_IMAGE_REQUEST",
  UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
  UPLOAD_IMAGE_FAILURE: "UPLOAD_IMAGE_FAILURE",
  RESET_MEDIA_IDS: "RESET_MEDIA_IDS",
  
  DELETE_IMAGE_REQUEST: "DELETE_IMAGE_REQUEST",
  DELETE_IMAGE_SUCCESS: "DELETE_IMAGE_SUCCESS",
  DELETE_IMAGE_FAILURE: "DELETE_IMAGE_FAILURE",

  APPROVE_SUBMISSON_REQUEST: "APPROVE_SUBMISSON_REQUEST",
  APPROVE_SUBMISSON_SUCCESS: "APPROVE_SUBMISSON_SUCCESS",
  APPROVE_SUBMISSON_FAILURE: "APPROVE_SUBMISSON_FAILURE",

  GET_ALL_TASKS_WITH_LOCATIONS_REQUEST: "GET_ALL_TASKS_WITH_LOCATIONS_REQUEST",
  GET_ALL_TASKS_WITH_LOCATIONS_SUCCESS: "GET_ALL_TASKS_WITH_LOCATIONS_SUCCESS",
  GET_ALL_TASKS_WITH_LOCATIONS_FAILURE: "GET_ALL_TASKS_WITH_LOCATIONS_FAILURE",

  RESET_TASK_DETAIL_STATE: "RESET_TASK_DETAIL_STATE",

  ENABLE_TASKS_SELECT_LIST: "ENABLE_TASKS_SELECT_LIST",

  GET_ALL_TASKS_SUCCESS_MOBILE: "GET_ALL_TASKS_SUCCESS_MOBILE",

  CLEAR_TASKS: "CLEAR_TASKS",

  MARK_TASK_AS_NOT_PERFORMED_REQUEST: "MARK_TASK_AS_NOT_PERFORMED_REQUEST",
  MARK_TASK_AS_NOT_PERFORMED_SUCCESS: "MARK_TASK_AS_NOT_PERFORMED_SUCCESS",
  MARK_TASK_AS_NOT_PERFORMED_FAILURE: "MARK_TASK_AS_NOT_PERFORMED_FAILURE",
  EMPTY_TASKS_LIST: "EMPTY_TASKS_LIST",
};

export const TASK_SUBMISSION_ACTIONS = {
  GET_ALL_TASK_SUBMISSIONS_REQUEST: "GET_ALL_TASK_SUBMISSIONS_REQUEST",
  GET_ALL_TASK_SUBMISSIONS_SUCCESS: "GET_ALL_TASK_SUBMISSIONS_SUCCESS",
  GET_ALL_TASK_SUBMISSIONS_SUCCESS_MOBILE:
    "GET_ALL_TASK_SUBMISSIONS_SUCCESS_MOBILE",
  GET_ALL_TASK_SUBMISSIONS_FAILURE: "GET_ALL_TASK_SUBMISSIONS_FAILURE",
  GET_PENDING_SYNC_TASK: "GET_PENDING_SYNC_TASK",
  GET_IS_PENDING_SYNC: "GET_IS_PENDING_SYNC",

  GET_ALL_TASK_SUBMISSIONS_OFFLINE_REQUEST:
    "GET_ALL_TASK_SUBMISSIONS_OFFLINE_REQUEST",
  GET_ALL_TASK_SUBMISSIONS_OFFLINE_SUCCESS:
    "GET_ALL_TASK_SUBMISSIONS_OFFLINE_SUCCESS",
  GET_ALL_TASK_SUBMISSIONS_OFFLINE_FAILURE:
    "GET_ALL_TASK_SUBMISSIONS_OFFLINE_FAILURE",

  GET_ALL_TASK_SUBMISSIONS_ADHOC_AUTOSAVE_OFFLINE_REQUEST:
    "GET_ALL_TASK_SUBMISSIONS_ADHOC_AUTOSAVE_OFFLINE_REQUEST",
  GET_ALL_TASK_SUBMISSIONS_ADHOC_AUTOSAVE_OFFLINE_SUCCESS:
    "GET_ALL_TASK_SUBMISSIONS_ADHOC_AUTOSAVE_OFFLINE_SUCCESS",
  GET_ALL_TASK_SUBMISSIONS_ADHOC_AUTOSAVE_OFFLINE_FAILURE:
    "GET_ALL_TASK_SUBMISSIONS_ADHOC_AUTOSAVE_OFFLINE_FAILURE",

  GET_TASK_HISTORY_OFFLINE_REQUEST: "GET_TASK_HISTORY_OFFLINE_REQUEST",
  GET_TASK_HISTORY_OFFLINE_SUCCESS: "GET_TASK_HISTORY_OFFLINE_SUCCESS",
  GET_TASK_HISTORY_OFFLINE_FAILURE: "GET_TASK_HISTORY_OFFLINE_FAILURE",

  HANDLE_ADD_TASK_SUBMISSION_MESSAGE: "HANDLE_ADD_TASK_SUBMISSION_MESSAGE",

  UPSERT_TASK_SUBMISSION_LIST_FILTERS: "UPSERT_TASK_SUBMISSION_LIST_FILTERS",

  UPDATE_TASK_SUBMISSION_LIST_FILTERS: "UPDATE_TASK_SUBMISSION_LIST_FILTERS",

  REMOVE_TASK_LIST_SUBMISSION_FILTERS: "REMOVE_TASK_LIST_SUBMISSION_FILTERS",

  CHANGE_FILTERS: "CHANGE_FILTERS",
  RESET_FILTERS: "RESET_FILTERS",
  CLEAR_SUBMISSIONS: "CLEAR_SUBMISSIONS",
};

export const WORKORDER_ACTIONS = {
  GET_WORKORDERS_REQUEST: "GET_WORKORDERS_REQUEST",
  GET_WORKORDERS_SUCCESS: "GET_WORKORDERS_SUCCESS",
  GET_WORKORDERS_FAILURE: "GET_WORKORDERS_FAILURE",
  ENABLE_WORKORDER_SELECT_LIST: "ENABLE_WORKORDER_SELECT_LIST",

  GET_WORKORDER_BY_ID_REQUEST: "GET_WORKORDER_BY_ID_REQUEST",
  GET_WORKORDER_BY_ID_SUCCESS: "GET_WORKORDER_BY_ID_SUCCESS",
  GET_WORKORDER_BY_ID_FAILURE: "GET_WORKORDER_BY_ID_FAILURE",

  EMPTY_WORKORDER_LIST: "EMPTY_WORKORDER_LIST",

  GET_ALL_WORKORDER_BY_FILTER_REQUEST: "GET_ALL_WORKORDER_BY_FILTER_REQUEST",
  GET_ALL_WORKORDER_BY_FILTER_SUCCESS: "GET_ALL_WORKORDER_BY_FILTER_SUCCESS",
  GET_ALL_WORKORDER_BY_FILTER_FAILURE: "GET_ALL_WORKORDER_BY_FILTER_FAILURE",

  SUBMIT_WORKORDER_REQUEST: "SUBMIT_WORKORDER_REQUEST",
  SUBMIT_WORKORDER_SUCCESS: "SUBMIT_WORKORDER_SUCCESS",
  SUBMIT_WORKORDER_FAILURE: "SUBMIT_WORKORDER_FAILURE",
  HANDLE_SUBMIT_WORKORDER_SUCCESS: "HANDLE_SUBMIT_WORKORDER_SUCCESS",

  REMOVE_WORKORDER_LIST_FILTERS: "REMOVE_WORKORDER_LIST_FILTERS",
  UPSERT_WORKORDER_LIST_FILTERS: "UPSERT_WORKORDER_LIST_FILTERS",
  HANDLE_ADD_WORKORDER_MESSAGE: "HANDLE_ADD_WORKORDER_MESSAGE",
  HANDLE_EDIT_WORKORDER_MESSAGE: "HANDLE_EDIT_WORKORDER_MESSAGE",

  SAVE_WORKORDER_ID: "SAVE_WORKORDER_ID",

  HANDLE_ASSOCIATE_PROGRAM_TO_WORKORDERS_SUCCESS:
    "HANDLE_ASSOCIATE_PROGRAM_TO_WORKORDERS_SUCCESS",
  ASSOCIATE_PROGRAM_TO_WORKORDERS_REQUEST:
    "ASSOCIATE_PROGRAM_TO_WORKORDERS_REQUEST",
  ASSOCIATE_PROGRAM_TO_WORKORDERS_SUCCESS:
    "ASSOCIATE_PROGRAM_TO_WORKORDERS_SUCCESS",
  ASSOCIATE_PROGRAM_TO_WORKORDERS_FAILURE:
    "ASSOCIATE_PROGRAM_TO_WORKORDERS_FAILURE",

  CHANGE_WORKORDER_STATUS_REQUEST: "CHANGE_WORKORDER_STATUS_REQUEST",
  CHANGE_WORKORDER_STATUS_SUCCESS: "CHANGE_WORKORDER_STATUS_SUCCESS",
  CHANGE_WORKORDER_STATUS_FAILURE: "CHANGE_WORKORDER_STATUS_FAILURE",

  ON_SORT_WORKORDER_COLUMN_REQUEST: "ON_SORT_WORKORDER_COLUMN_REQUEST",
  ON_SORT_WORKORDER_COLUMN_SUCCESS: "ON_SORT_WORKORDER_COLUMN_SUCCESS",
  ON_SORT_WORKORDER_COLUMN_FAILURE: "ON_SORT_WORKORDER_COLUMN_FAILURE",

  RESET_WORKORDER_DETAIL_STATE: "RESET_WORKORDER_DETAIL_STATE",

  /* Locations */
  GET_ALL_LOCATIONS_REQUEST: "GET_ALL_LOCATIONS_REQUEST",
  GET_ALL_LOCATIONS_SUCCESS: "GET_ALL_LOCATIONS_SUCCESS",
  GET_ALL_LOCATIONS_FAILURE: "GET_ALL_LOCATIONS_FAILURE",

  EMPTY_ALL_LOCATIONS: "EMPTY_ALL_LOCATIONS",

  EMPTY_WORKORDER_SELECT_LIST: "EMPTY_WORKORDER_SELECT_LIST",
  EMPTY_PROGRAM_SELECT_LIST: "EMPTY_PROGRAM_SELECT_LIST",
};

export const LIVESTOCK_ACTIONS = {
  SAVE_LIVESTOCK_REQUEST: "SAVE_LIVESTOCK_REQUEST",
  SAVE_LIVESTOCK_SUCCESS: "SAVE_LIVESTOCK_SUCCESS",
  SAVE_LIVESTOCK_FAILURE: "SAVE_LIVESTOCK_FAILURE",

  GET_LIVESTOCKS_REQUEST: "GET_LIVESTOCKS_REQUEST",
  GET_LIVESTOCKS_SUCCESS: "GET_LIVESTOCKS_SUCCESS",
  GET_LIVESTOCKS_FAILURE: "GET_LIVESTOCKS_FAILURE",

  GET_ALL_FILTERED_EAR_TAGS_REQUEST: "GET_ALL_FILTERED_EAR_TAGS_REQUEST",
  GET_ALL_FILTERED_EAR_TAGS_SUCCESS: "GET_ALL_FILTERED_EAR_TAGS_SUCCESS",
  GET_ALL_FILTERED_EAR_TAGS_FAILURE: "GET_ALL_FILTERED_EAR_TAGS_FAILURE",

  GET_LIVESTOCK_DETAILS_BY_ID_REQUEST: "GET_LIVESTOCK_DETAILS_BY_ID_REQUEST",
  GET_LIVESTOCK_DETAILS_BY_ID_SUCCESS: "GET_LIVESTOCK_DETAILS_BY_ID_SUCCESS",
  GET_LIVESTOCK_DETAILS_BY_ID_FAILURE: "GET_LIVESTOCK_DETAILS_BY_ID_FAILURE",

  HANDLE_MESSAGE: "HANDLE_MESSAGE",

  GET_ALL_EAR_TAGS_BY_LOCATION_ID_REQUEST:
    "GET_ALL_EAR_TAGS_BY_LOCATION_ID_REQUEST",
  GET_ALL_EAR_TAGS_BY_LOCATION_ID_SUCCESS:
    "GET_ALL_EAR_TAGS_BY_LOCATION_ID_SUCCESS",
  GET_ALL_EAR_TAGS_BY_LOCATION_ID_FAILURE:
    "GET_ALL_EAR_TAGS_BY_LOCATION_ID_FAILURE",

  GET_LIVESTOCK_DETAILS_BY_ID_REQUEST: "GET_LIVESTOCK_DETAILS_BY_ID_REQUEST",
  GET_LIVESTOCK_DETAILS_BY_ID_SUCCESS: "GET_LIVESTOCK_DETAILS_BY_ID_SUCCESS",
  GET_LIVESTOCK_DETAILS_BY_ID_FAILURE: "GET_LIVESTOCK_DETAILS_BY_ID_FAILURE",
};

export const SCHEDULER_ACTIONS = {
  GET_SCHEDULER_REQUEST: "GET_SCHEDULER_REQUEST",
  GET_SCHEDULER_SUCCESS: "GET_SCHEDULER_SUCCESS",
  GET_SCHEDULER_FAILURE: "GET_SCHEDULER_FAILURE",

  REMOVE_SCHEDULER_REQUEST: "REMOVE_SCHEDULER_REQUEST",

  GET_SCHEDULER_BY_ID_REQUEST: "GET_SCHEDULER_BY_ID_REQUEST",
  GET_SCHEDULER_BY_ID_SUCCESS: "GET_SCHEDULER_BY_ID_SUCCESS",
  GET_SCHEDULER_BY_ID_FAILURE: "GET_SCHEDULER_BY_ID_FAILURE",

  GET_TASK_SCHEDULERS_REQUEST: "GET_TASK_SCHEDULERS_REQUEST",
  GET_TASK_SCHEDULERS_SUCCESS: "GET_TASK_SCHEDULERS_SUCCESS",
  GET_TASK_SCHEDULERS_FAILURE: "GET_TASK_SCHEDULERS_FAILURE",

  ADD_SCHEDULER_REQUEST: "ADD_SCHEDULER_REQUEST",
  ADD_SCHEDULER_SUCCESS: "ADD_SCHEDULER_SUCCESS",
  ADD_SCHEDULER_FAILURE: "ADD_SCHEDULER_FAILURE",

  EDIT_SCHEDULER_REQUEST: "EDIT_SCHEDULER_REQUEST",
  EDIT_SCHEDULER_SUCCESS: "EDIT_SCHEDULER_SUCCESS",
  EDIT_SCHEDULER_FAILURE: "EDIT_SCHEDULER_FAILURE",

  HANDLE_ADD_SCHEDULER_MESSAGE: "HANDLE_ADD_SCHEDULER_MESSAGE",

  UPSERT_SCHEDULER_LIST_FILTERS: "UPSERT_SCHEDULER_LIST_FILTERS",

  REMOVE_SCHEDULER_LIST_FILTERS: "REMOVE_SCHEDULER_LIST_FILTERS",
};

export const PROGRAM_ACTIONS = {
  GET_PROGRAMS_REQUEST: "GET_PROGRAMS_REQUEST",
  GET_PROGRAMS_SUCCESS: "GET_PROGRAMS_SUCCESS",
  GET_PROGRAMS_FAILURE: "GET_PROGRAMS_FAILURE",
  EMPTY_PROGRAMS_LIST: "EMPTY_PROGRAMS_LIST",
  ENABLE_PROGRAMS_SELECT_LIST: "ENABLE_PROGRAMS_SELECT_LIST",

  SUBMIT_PROGRAM_REQUEST: "SUBMIT_PROGRAM_REQUEST",
  SUBMIT_PROGRAM_SUCCESS: "SUBMIT_PROGRAM_SUCCESS",
  SUBMIT_PROGRAM_FAILURE: "SUBMIT_PROGRAM_FAILURE",

  HANDLE_ADD_PROGRAM_MESSAGE: "HANDLE_ADD_PROGRAM_MESSAGE",
  HANDLE_EDIT_PROGRAM_MESSAGE: "HANDLE_EDIT_PROGRAM_MESSAGE",
  HANDLE_SUBMIT_PROGRAM_SUCCESS: "HANDLE_SUBMIT_PROGRAM_SUCCESS",

  GET_PROGRAM_BY_ID_REQUEST: "GET_PROGRAM_BY_ID_REQUEST",
  GET_PROGRAM_BY_ID_SUCCESS: "GET_PROGRAM_BY_ID_SUCCESS",
  GET_PROGRAM_BY_ID_FAILURE: "GET_PROGRAM_BY_ID_FAILURE",

  REMOVE_PROGRAM_LIST_FILTERS: "REMOVE_PROGRAM_LIST_FILTERS",
  UPSERT_PROGRAM_LIST_FILTERS: "UPSERT_PROGRAM_LIST_FILTERS",

  CHANGE_PROGRAM_STATUS_REQUEST: "CHANGE_PROGRAM_STATUS_REQUEST",
  CHANGE_PROGRAM_STATUS_SUCCESS: "CHANGE_PROGRAM_STATUS_SUCCESS",
  CHANGE_PROGRAM_STATUS_FAILURE: "CHANGE_PROGRAM_STATUS_FAILURE",

  HANDLE_ASSOCIATE_DIVISION_TO_PROGRAMS_SUCCESS:
    "HANDLE_ASSOCIATE_WORKORDER_TO_TASKS_SUCCESS",
  ASSOCIATE_DIVISION_TO_PROGRAMS_REQUEST:
    "ASSOCIATE_WORKORDER_TO_TASKS_REQUEST",
  ASSOCIATE_DIVISION_TO_PROGRAMS_SUCCESS:
    "ASSOCIATE_WORKORDER_TO_TASKS_SUCCESS",
  ASSOCIATE_DIVISION_TO_PROGRAMS_FAILURE:
    "ASSOCIATE_WORKORDER_TO_TASKS_FAILURE",

  ON_SORT_PROGRAM_COLUMN_REQUEST: "ON_SORT_PROGRAM_COLUMN_REQUEST",
  ON_SORT_PROGRAM_COLUMN_SUCCESS: "ON_SORT_PROGRAM_COLUMN_SUCCESS",
  ON_SORT_PROGRAM_COLUMN_FAILURE: "ON_SORT_PROGRAM_COLUMN_FAILURE",

  RESET_PROGRAM_DETAIL_STATE: "RESET_PROGRAM_DETAIL_STATE",

  GET_PROGRAMS_BY_LOCATION_REQUEST: "GET_PROGRAMS_BY_LOCATION_REQUEST",
  GET_PROGRAMS_BY_LOCATION_SUCCESS: "GET_PROGRAMS_BY_LOCATION_SUCCESS",
  GET_PROGRAMS_BY_LOCATION_FAILURE: "GET_PROGRAMS_BY_LOCATION_FAILURE",
};

export const PROGRAM_MANUAL_ACTIONS = {
  GET_PROGRAM_MANUALS_REQUEST: "GET_PROGRAM_MANUALS_REQUEST",
  GET_PROGRAM_MANUALS_SUCCESS: "GET_PROGRAM_MANUALS_SUCCESS",
  GET_PROGRAM_MANUALS_FAILURE: "GET_PROGRAM_MANUALS_FAILURE",

  EMPTY_PROGRAMS_LIST: "EMPTY_PROGRAMS_LIST",

  SUBMIT_PROGRAM_MANUAL_REQUEST: "SUBMIT_PROGRAM_MANUAL_REQUEST",
  SUBMIT_PROGRAM_MANUAL_SUCCESS: "SUBMIT_PROGRAM_MANUAL_SUCCESS",
  SUBMIT_PROGRAM_MANUAL_FAILURE: "SUBMIT_PROGRAM_MANUAL_FAILURE",

  HANDLE_ADD_PROGRAM_MANUAL_MESSAGE: "HANDLE_ADD_PROGRAM_MANUAL_MESSAGE",
  HANDLE_EDIT_PROGRAM_MANUAL_MESSAGE: "HANDLE_EDIT_PROGRAM_MANUAL_MESSAGE",
  HANDLE_SUBMIT_PROGRAM_MANUAL_SUCCESS: "HANDLE_SUBMIT_PROGRAM_MANUAL_SUCCESS",

  REMOVE_PROGRAM_MANUAL_LIST_FILTERS: "REMOVE_PROGRAM_MANUAL_LIST_FILTERS",
  UPSERT_PROGRAM_MANUAL_LIST_FILTERS: "UPSERT_PROGRAM_MANUAL_LIST_FILTERS",

  GET_PROGRAM_MANUAL_BY_ID_REQUEST: "GET_PROGRAM_MANUAL_BY_ID_REQUEST",
  GET_PROGRAM_MANUAL_BY_ID_SUCCESS: "GET_PROGRAM_MANUAL_BY_ID_SUCCESS",
  GET_PROGRAM_MANUAL_BY_ID_FAILURE: "GET_PROGRAM_MANUAL_BY_ID_FAILURE",
  CLEAR_PROGRAM_MANUAL_DETAIL: "CLEAR_PROGRAM_MANUAL_DETAIL",

  CHANGE_PROGRAM_MANUAL_STATUS_REQUEST: "CHANGE_PROGRAM_MANUAL_STATUS_REQUEST",
  CHANGE_PROGRAM_MANUAL_STATUS_SUCCESS: "CHANGE_PROGRAM_MANUAL_STATUS_SUCCESS",
  CHANGE_PROGRAM_MANUAL_STATUS_FAILURE: "CHANGE_PROGRAM_MANUAL_STATUS_FAILURE",

  RESET_MEDIA_IDS: "RESET_MEDIA_IDS",
  SET_MEDIA_IDS: "SET_MEDIA_IDS",
  UPLOAD_PROGRAM_MANUAL_IMAGE_REQUEST: "UPLOAD_PROGRAM_MANUAL_IMAGE_REQUEST",
  UPLOAD_PROGRAM_MANUAL_IMAGE_SUCCESS: "UPLOAD_PROGRAM_MANUAL_IMAGE_SUCCESS",
  UPLOAD_PROGRAM_MANUAL_IMAGE_FAILURE: "UPLOAD_PROGRAM_MANUAL_IMAGE_FAILURE",

  REMOVE_PROGRAM_MANUAL_IMAGE: "REMOVE_PROGRAM_MANUAL_IMAGE",

  EMPTY_PROGRAM_MANUALS_LIST: "EMPTY_PROGRAM_MANUALS_LIST",

  ON_SORT_PROGRAM_MANUAL_COLUMN_REQUEST: "ON_SORT_PROGRAM_MANUAL_COLUMN_REQUEST",
  ON_SORT_PROGRAM_MANUAL_COLUMN_SUCCESS: "ON_SORT_PROGRAM_MANUAL_COLUMN_SUCCESS",
  ON_SORT_PROGRAM_MANUAL_COLUMN_FAILURE: "ON_SORT_PROGRAM_MANUAL_COLUMN_FAILURE",
}

export const REVIEW_ACTIONS = {
  GET_REVIEWS_REQUEST: "GET_REVIEWS_REQUEST",
  GET_REVIEWS_SUCCESS: "GET_REVIEWS_SUCCESS",
  GET_REVIEWS_SUCCESS_MOBILE: "GET_REVIEWS_SUCCESS_MOBILE",
  CLEAR_REVIEWS_SUCCESS: "CLEAR_REVIEWS_SUCCESS",
  GET_REVIEWS_FAILURE: "GET_REVIEWS_FAILURE",

  RESET_REVIEW_DETAILS: "RESET_REVIEW_DETAILS",

  GET_REVIEW_SUBMISSIONS_REQUEST: "GET_REVIEW_SUBMISSIONS_REQUEST",
  GET_REVIEW_SUBMISSIONS_SUCCESS: "GET_REVIEW_SUBMISSIONS_SUCCESS",
  GET_REVIEW_SUBMISSIONS_FAILURE: "GET_REVIEW_SUBMISSIONS_FAILURE",

  SUBMIT_REVIEW_REQUEST: "SUBMIT_REVIEW_REQUEST",
  SUBMIT_REVIEW_SUCCESS: "SUBMIT_REVIEW_SUCCESS",
  SUBMIT_REVIEW_FAILURE: "SUBMIT_REVIEW_FAILURE",

  HANDLE_ADD_REVIEW_MESSAGE: "HANDLE_ADD_REVIEW_MESSAGE",
  HANDLE_EDIT_REVIEW_MESSAGE: "HANDLE_EDIT_REVIEW_MESSAGE",
  HANDLE_SUBMIT_REVIEW_SUCCESS: "HANDLE_SUBMIT_REVIEW_SUCCESS",

  REMOVE_REVIEW_LIST_FILTERS: "REMOVE_REVIEW_LIST_FILTERS",
  UPSERT_REVIEW_LIST_FILTERS: "UPSERT_REVIEW_LIST_FILTERS",

  REMOVE_REVIEW_SUBMISSION_LIST_FILTERS: "REMOVE_REVIEW_SUBMISSION_LIST_FILTERS",
  UPSERT_REVIEW_SUBMISSION_LIST_FILTERS: "UPSERT_REVIEW_SUBMISSION_LIST_FILTERS",

  RESET_REVIEW_DETAILS: "RESET_REVIEW_DETAILS",

  GET_REVIEW_BY_ID_REQUEST: "GET_REVIEW_BY_ID_REQUEST",
  GET_REVIEW_BY_ID_SUCCESS: "GET_REVIEW_BY_ID_SUCCESS",
  GET_REVIEW_BY_ID_FAILURE: "GET_REVIEW_BY_ID_FAILURE",

  GET_TASK_DETAILS_WITH_SUBMISSIONS_REQUEST:
    "GET_TASK_DETAILS_WITH_SUBMISSIONS_REQUEST",
  GET_TASK_DETAILS_WITH_SUBMISSIONS_SUCCESS:
    "GET_TASK_DETAILS_WITH_SUBMISSIONS_SUCCESS",
  GET_TASK_DETAILS_WITH_SUBMISSIONS_FAILURE:
    "GET_TASK_DETAILS_WITH_SUBMISSIONS_FAILURE",
    
  CLEAR_TASK_DETAILS_WITH_SUBMISSIONS: "CLEAR_TASK_DETAILS_WITH_SUBMISSIONS",

  CHANGE_REVIEW_STATUS_REQUEST: "CHANGE_REVIEW_STATUS_REQUEST",
  CHANGE_REVIEW_STATUS_SUCCESS: "CHANGE_REVIEW_STATUS_SUCCESS",
  CHANGE_REVIEW_STATUS_FAILURE: "CHANGE_REVIEW_STATUS_FAILURE",

  ON_SORT_REVIEWS_COLUMN_REQUEST: "ON_SORT_REVIEWS_COLUMN_REQUEST",
  ON_SORT_REVIEWS_COLUMN_SUCCESS: "ON_SORT_REVIEWS_COLUMN_SUCCESS",
  ON_SORT_REVIEWS_COLUMN_FAILURE: "ON_SORT_REVIEWS_COLUMN_FAILURE",
}

export const REVIEW_HISTORY_ACTIONS = {
  GET_REVIEW_HISTORY_REQUEST: "GET_REVIEW_HISTORY_REQUEST",
  GET_REVIEW_HISTORY_SUCCESS: "GET_REVIEW_HISTORY_SUCCESS",
  GET_REVIEW_HISTORY_FAILURE: "GET_REVIEW_HISTORY_FAILURE",

  GET_REVIEW_HISTORY_SUBMISSIONS_REQUEST:
    "GET_REVIEW_HISTORY_SUBMISSIONS_REQUEST",
  GET_REVIEW_HISTORY_SUBMISSIONS_SUCCESS:
    "GET_REVIEW_HISTORY_SUBMISSIONS_SUCCESS",
  GET_REVIEW_HISTORY_SUBMISSIONS_FAILURE:
    "GET_REVIEW_HISTORY_SUBMISSIONS_FAILURE",

  SUBMIT_REVIEW_HISTORY_REQUEST: "SUBMIT_REVIEW_HISTORY_REQUEST",
  SUBMIT_REVIEW_HISTORY_SUCCESS: "SUBMIT_REVIEW_HISTORY_SUCCESS",
  SUBMIT_REVIEW_HISTORY_FAILURE: "SUBMIT_REVIEW_HISTORY_FAILURE",

  HANDLE_ADD_REVIEW_HISTORY_MESSAGE: "HANDLE_ADD_REVIEW_HISTORY_MESSAGE",
  HANDLE_EDIT_REVIEW_HISTORY_MESSAGE: "HANDLE_EDIT_REVIEW_HISTORY_MESSAGE",
  HANDLE_SUBMIT_REVIEW_HISTORY_SUCCESS: "HANDLE_SUBMIT_REVIEW_HISTORY_SUCCESS",

  REMOVE_REVIEW_HISTORY_LIST_FILTERS: "REMOVE_REVIEW_HISTORY_LIST_FILTERS",
  UPSERT_REVIEW_HISTORY_LIST_FILTERS: "UPSERT_REVIEW_HISTORY_LIST_FILTERS",

  GET_REVIEW_HISTORY_BY_ID_REQUEST: "GET_REVIEW_HISTORY_BY_ID_REQUEST",
  GET_REVIEW_HISTORY_BY_ID_SUCCESS: "GET_REVIEW_HISTORY_BY_ID_SUCCESS",
  GET_REVIEW_HISTORY_BY_ID_FAILURE: "GET_REVIEW_HISTORY_BY_ID_FAILURE",

  CHANGE_REVIEW_HISTORY_STATUS_REQUEST: "CHANGE_REVIEW_HISTORY_STATUS_REQUEST",
  CHANGE_REVIEW_HISTORY_STATUS_SUCCESS: "CHANGE_REVIEW_HISTORY_STATUS_SUCCESS",
  CHANGE_REVIEW_HISTORY_STATUS_FAILURE: "CHANGE_REVIEW_HISTORY_STATUS_FAILURE",

  ON_SORT_REVIEW_HISTORY_COLUMN_REQUEST: "ON_SORT_REVIEW_HISTORY_COLUMN_REQUEST",
  ON_SORT_REVIEW_HISTORY_COLUMN_SUCCESS: "ON_SORT_REVIEW_HISTORY_COLUMN_SUCCESS",
  ON_SORT_REVIEW_HISTORY_COLUMN_FAILURE: "ON_SORT_REVIEW_HISTORY_COLUMN_FAILURE",
}

export const NOTIFICATION_ACTIONS = {
  GET_NOTIFICATIONS_REQUEST: "GET_NOTIFICATIONS_REQUEST",
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  GET_NOTIFICATIONS_FAILURE: "GET_NOTIFICATIONS_FAILURE",

  GET_HEADER_NOTIFICATIONS_REQUEST: "GET_HEADER_NOTIFICATIONS_REQUEST",
  GET_HEADER_NOTIFICATIONS_SUCCESS: "GET_HEADER_NOTIFICATIONS_SUCCESS",
  GET_HEADER_NOTIFICATIONS_FAILURE: "GET_HEADER_NOTIFICATIONS_FAILURE",

  MARK_ALL_NOTIFICATIONS_REQUEST: "MARK_ALL_NOTIFICATIONS_REQUEST",
  MARK_ALL_NOTIFICATIONS_SUCCESS: "MARK_ALL_NOTIFICATIONS_SUCCESS",
  MARK_ALL_NOTIFICATIONS_FAILURE: "MARK_ALL_NOTIFICATIONS_FAILURE",
};

export const ASSET_ACTIONS = {
  GET_FORM_BY_CATEGORY_REQUEST: "GET_FORM_BY_CATEGORY_REQUEST",
  GET_FORM_BY_CATEGORY_SUCCESS: "GET_FORM_BY_CATEGORY_SUCCESS",
  GET_FORM_BY_CATEGORY_FAILURE: "GET_FORM_BY_CATEGORY_FAILURE",
};

export const LOCATION_ACTIONS = {
  GET_ALL_LOCATION_REQUEST: "GET_ALL_LOCATION_REQUEST",
  GET_ALL_LOCATION_SUCCESS: "GET_ALL_LOCATION_SUCCESS",
  GET_ALL_LOCATION_FAILURE: "GET_ALL_LOCATION_FAILURE",

  GET_LOCATION_REQUEST: "GET_LOCATION_REQUEST",
  GET_LOCATION_SUCCESS: "GET_LOCATION_SUCCESS",
  GET_LOCATION_FAILURE: "GET_LOCATION_FAILURE",

  GET_ALL_LOCATION_TYPES_REQUEST: "GET_ALL_LOCATION_TYPES_REQUEST",
  GET_ALL_LOCATION_TYPES_SUCCESS: "GET_ALL_LOCATION_TYPES_SUCCESS",
  GET_ALL_LOCATION_TYPES_FAILURE: "GET_ALL_LOCATION_TYPES_FAILURE",

  SUBMIT_LOCATION_REQUEST: "SUBMIT_LOCATION_REQUEST",
  SUBMIT_LOCATION_SUCCESS: "SUBMIT_LOCATION_SUCCESS",
  SUBMIT_LOCATION_FAILURE: "SUBMIT_LOCATION_FAILURE",

  HANDLE_SUBMIT_LOCATION_SUCCESS: "HANDLE_SUBMIT_LOCATION_SUCCESS",

  EMPTY_LOCATIONS_LIST: "EMPTY_LOCATIONS_LIST",
  ENABLE_LOCATIONS_SELECT_LIST: "ENABLE_LOCATIONS_SELECT_LIST",

  HANDLE_ADD_LOCATION_MESSAGE: "HANDLE_ADD_LOCATION_MESSAGE",
  HANDLE_EDIT_LOCATION_MESSAGE: "HANDLE_EDIT_LOCATION_MESSAGE",
  HANDLE_SUBMIT_LOCATION_SUCCESS: "HANDLE_SUBMIT_LOCATION_SUCCESS",

  GET_LOCATION_BY_ID_REQUEST: "GET_LOCATION_BY_ID_REQUEST",
  GET_LOCATION_BY_ID_SUCCESS: "GET_LOCATION_BY_ID_SUCCESS",
  GET_LOCATION_BY_ID_FAILURE: "GET_LOCATION_BY_ID_FAILURE",

  REMOVE_LOCATION_LIST_FILTERS: "REMOVE_LOCATION_LIST_FILTERS",
  UPSERT_LOCATION_LIST_FILTERS: "UPSERT_LOCATION_LIST_FILTERS",

  CHANGE_LOCATION_STATUS_REQUEST: "CHANGE_LOCATION_STATUS_REQUEST",
  CHANGE_LOCATION_STATUS_SUCCESS: "CHANGE_LOCATION_STATUS_SUCCESS",
  CHANGE_LOCATION_STATUS_FAILURE: "CHANGE_LOCATION_STATUS_FAILURE",

  HANDLE_ASSOCIATE_DIVISION_TO_LOCATIONS_SUCCESS:
    "HANDLE_ASSOCIATE_WORKORDER_TO_TASKS_SUCCESS",
  ASSOCIATE_DIVISION_TO_LOCATIONS_REQUEST:
    "ASSOCIATE_WORKORDER_TO_TASKS_REQUEST",
  ASSOCIATE_DIVISION_TO_LOCATIONS_SUCCESS:
    "ASSOCIATE_WORKORDER_TO_TASKS_SUCCESS",
  ASSOCIATE_DIVISION_TO_LOCATIONS_FAILURE:
    "ASSOCIATE_WORKORDER_TO_TASKS_FAILURE",

  ON_SORT_LOCATION_COLUMN_REQUEST: "ON_SORT_LOCATION_COLUMN_REQUEST",
  ON_SORT_LOCATION_COLUMN_SUCCESS: "ON_SORT_LOCATION_COLUMN_SUCCESS",
  ON_SORT_LOCATION_COLUMN_FAILURE: "ON_SORT_LOCATION_COLUMN_FAILURE",

  RESET_LOCATION_DETAIL_STATE: "RESET_LOCATION_DETAIL_STATE",
};

export const DIVISION_ACTIONS = {
  GET_DIVISIONS_REQUEST: "GET_DIVISIONS_REQUEST",
  GET_DIVISIONS_SUCCESS: "GET_DIVISIONS_SUCCESS",
  GET_DIVISIONS_FAILURE: "GET_DIVISIONS_FAILURE",
};

export const ADUSERS_ACTIONS = {
  GET_ADUSERLIST_REQUEST: "GET_ADUSERLIST_REQUEST",
  GET_ADUSERLIST_SUCCESS: "GET_ADUSERLIST_SUCCESS",
  GET_ADUSERLIST_FAILURE: "GET_ADUSERLIST_FAILURE",
};

export const TASK_GROUP_ACTIONS = {
  GET_TASK_GROUPS_REQUEST: "GET_TASK_GROUPS_REQUEST",
  GET_TASK_GROUPS_SUCCESS: "GET_TASK_GROUPS_SUCCESS",
  GET_TASK_GROUPS_FAILURE: "GET_TASK_GROUPS_FAILURE",
};

export const ENUM_ACTIONS = {
  GET_ENUMS_REQUEST: "GET_ENUMS_REQUEST",
  GET_ENUMS_SUCCESS: "GET_ENUMS_SUCCESS",
  GET_ENUMS_FAILURE: "GET_ENUMS_FAILURE",

  GET_METADATA_VALUES_REQUEST: "GET_METADATA_VALUES_REQUEST",
  GET_METADATA_VALUES_SUCCESS: "GET_METADATA_VALUES_SUCCESS",
  GET_METADATA_VALUES_FAILURE: "GET_METADATA_VALUES_FAILURE",
};

export const DASHBOARD_ACTIONS = {
  WORKORDER_STAT_REQUEST: "WORKORDER_STAT_REQUEST",
  WORKORDER_STAT_SUCCESS: "WORKORDER_STAT_SUCCESS",
  WORKORDER_STAT_FAILURE: "WORKORDER_STAT_FAILURE",

  GET_RECENT_SUBMISSIONS_REQUEST: "GET_RECENT_SUBMISSIONS_REQUEST",
  GET_RECENT_SUBMISSIONS_SUCCESS: "GET_RECENT_SUBMISSIONS_SUCCESS",
  GET_RECENT_SUBMISSIONS_FAILURE: "GET_RECENT_SUBMISSIONS_FAILURE",

  GET_ACTIVE_WORKER_REQUEST: "GET_ACTIVE_WORKER_REQUEST",
  GET_ACTIVE_WORKER_SUCCESS: "GET_ACTIVE_WORKER_SUCCESS",
  GET_ACTIVE_WORKER_FAILURE: "GET_ACTIVE_WORKER_FAILURE",

  GET_PENDING_WORKORDER_REQUEST: "GET_PENDING_WORKORDER_REQUEST",
  GET_PENDING_WORKORDER_SUCCESS: "GET_PENDING_WORKORDER_SUCCESS",
  GET_PENDING_WORKORDER_FAILURE: "GET_PENDING_WORKORDER_FAILURE",

  GET_MISSED_WORKORDER_REQUEST: "GET_MISSED_WORKORDER_REQUEST",
  GET_MISSED_WORKORDER_SUCCESS: "GET_MISSED_WORKORDER_SUCCESS",
  GET_MISSED_WORKORDER_FAILURE: "GET_MISSED_WORKORDER_FAILURE",

  GET_MISSED_TASK_REQUEST: "GET_MISSED_TASK_REQUEST",
  GET_MISSED_TASK_SUCCESS: "GET_MISSED_TASK_SUCCESS",
  GET_MISSED_TASK_FAILURE: "GET_MISSED_TASK_FAILURE",

  GET_RULE_VIOLATION_REQUEST: "GET_RULE_VIOLATION_REQUEST",
  GET_RULE_VIOLATION_SUCCESS: "GET_RULE_VIOLATION_SUCCESS",
  GET_RULE_VIOLATION_FAILURE: "GET_RULE_VIOLATION_FAILURE",

  GET_TASK_COMPLETION_STATISTICS_REQUEST:
    "GET_TASK_COMPLETION_STATISTICS_REQUEST",
  GET_TASK_COMPLETION_STATISTICS_SUCCESS:
    "GET_TASK_COMPLETION_STATISTICS_SUCCESS",
  GET_TASK_COMPLETION_STATISTICS_FAILURE:
    "GET_TASK_COMPLETION_STATISTICS_FAILURE",

  GET_TASK_VIOLATION_STATISTICS_REQUEST:
    "GET_TASK_VIOLATION_STATISTICS_REQUEST",
  GET_TASK_VIOLATION_STATISTICS_SUCCESS:
    "GET_TASK_VIOLATION_STATISTICS_SUCCESS",
  GET_TASK_VIOLATION_STATISTICS_FAILURE:
    "GET_TASK_VIOLATION_STATISTICS_FAILURE",

  TASK_SUMMARY_STAT_REQUEST: "TASK_SUMMARY_STAT_REQUEST",
  TASK_SUMMARY_STAT_SUCCESS: "TASK_SUMMARY_STAT_SUCCESS",
  TASK_SUMMARY_STAT_FAILURE: "TASK_SUMMARY_STAT_FAILURE",
  TASK_COMPLETION_GRAPH_REQUEST: "TASK_COMPLETION_GRAPH_REQUEST",
  TASK_COMPLETION_GRAPH_SUCCESS: "TASK_COMPLETION_GRAPH_SUCCESS",
  TASK_COMPLETION_GRAPH_FAILURE: "TASK_COMPLETION_GRAPH_FAILURE",

  GET_DUE_TASK_REQUEST: "GET_DUE_TASK_REQUEST",
  GET_DUE_TASK_SUCCESS: "GET_DUE_TASK_SUCCESS",
  GET_DUE_TASK_FAILURE: "GET_DUE_TASK_FAILURE",

  GET_ALL_DUE_TASK_REQUEST: "GET_ALL_DUE_TASK_REQUEST",
  GET_ALL_DUE_TASK_SUCCESS: "GET_ALL_DUE_TASK_SUCCESS",
  GET_ALL_DUE_TASK_FAILURE: "GET_ALL_DUE_TASK_FAILURE",
};

export const LOADING_ACTIONS = {
  //dashboard loader actions
  WORKORDER_STATISTICS_LOADING: "WORKORDER_STATISTICS_LOADING",
  RECENT_SUBMISSION_LOADING: "RECENT_SUBMISSION_LOADING",
  PENDING_WORKORDER_LOADING: "PENDING_WORKORDER_LOADING",
  MISSED_WORKORDER_LOADING: "MISSED_WORKORDER_LOADING",
  LIMIT_RULE_VIOLATION_LOADING: "LIMIT_RULE_VIOLATION_LOADING",
  ACTIVE_WORKERS_LOADING: "ACTIVE_WORKERS_LOADING",
};

export const REPORT_ACTIONS = {
  GET_TASK_NAMES: "GET_TASK_NAMES",
  GET_REPORT_REQUEST: "GET_REPORT_REQUEST",
  GET_REPORT_SUCCESS: "GET_REPORT_SUCCESS",
  GET_REPORT_FAILURE: "GET_REPORT_FAILURE",

  GET_TASK_NAMES_REPORT_REQUEST_SUCCESS: "GET_TASK_NAMES_REPORT_REQUEST_SUCCESS",
  GET_TASK_NAMES_REPORT_REQUEST_FAILURE: "GET_TASK_NAMES_REPORT_REQUEST_FAILURE",

  GET_USER_SUBMISSION_REPORT_REQUEST: "GET_USER_SUBMISSION_REPORT_REQUEST",
  GET_USER_SUBMISSION_REPORT_SUCCESS: "GET_USER_SUBMISSION_REPORT_SUCCESS",
  GET_USER_SUBMISSION_REPORT_FAILURE: "GET_USER_SUBMISSION_REPORT_FAILURE",
  GET_TASK_SUBMISSION_REPORT_REQUEST: "GET_TASK_SUBMISSION_REPORT_REQUEST",
  GET_TASK_SUBMISSION_REPORT_SUCCESS: "GET_TASK_SUBMISSION_REPORT_SUCCESS",
  GET_TASK_SUBMISSION_REPORT_FAILURE: "GET_TASK_SUBMISSION_REPORT_FAILURE",
  GET_DAILY_SUBMISSION_REPORT_REQUEST: "GET_DAILY_SUBMISSION_REPORT_REQUEST",
  GET_DAILY_SUBMISSION_REPORT_SUCCESS: "GET_DAILY_SUBMISSION_REPORT_SUCCESS",
  GET_DAILY_SUBMISSION_REPORT_FAILURE: "GET_DAILY_SUBMISSION_REPORT_FAILURE",
};

export const APP_ACTIONS = {
  SET_APP_CONNECTIVITY_STATUS_REQUEST: "SET_APP_CONNECTIVITY_STATUS_REQUEST",
  SET_APP_CONNECTIVITY_STATUS_SUCCESS: "SET_APP_CONNECTIVITY_STATUS_SUCCESS",
  SET_APP_CONNECTIVITY_STATUS_FAILURE: "SET_APP_CONNECTIVITY_STATUS_FAILURE",

  SYNC_DATA_REQUEST: "SYNC_DATA_REQUEST",
  SYNC_DATA_SUCCESS: "SYNC_DATA_SUCCESS",
  SYNC_DATA_FAILURE: "SYNC_DATA_FAILURE",

  SET_IS_SYNC_IN_PROGRESS: "SET_IS_SYNC_IN_PROGRESS",
  SET_SYNCING_SCHEDULED_TASKS: "SET_SYNCING_SCHEDULED_TASKS",
  SET_SYNCING_AUTO_SAVED_ADHOC_TASKS: "SET_SYNCING_AUTO_SAVED_ADHOC_TASKS",
  SET_SYNCING_CORRECTIVE_ACTIONS: "SET_SYNCING_CORRECTIVE_ACTIONS",
  SET_SYNCING_ADHOC_TASKS: "SET_SYNCING_ADHOC_TASKS",
  SET_SYNCING_ADHOC_TASKS_FOR_CA: "SET_SYNCING_ADHOC_TASKS_FOR_CA",
  SET_SYNCING_TASK_HISTORY: "SET_SYNCING_TASK_HISTORY",
  SET_SYNCING_TASK_LOCATION: "SET_SYNCING_TASK_LOCATION",
  SET_SYNCING_TASK_WORKORDER: "SET_SYNCING_TASK_WORKORDER",
  SET_SYNCING_TASK_PROGRAM: "SET_SYNCING_TASK_PROGRAM",
  SET_PUSH_IN_PROGRESS: "SET_PUSH_IN_PROGRESS",
  SET_LAST_SYNC_UI: "SET_LAST_SYNC_UI",
  SET_SYNC_PROGRESS_ADHOC_TASKS: "SET_SYNC_PROGRESS_ADHOC_TASKS",
  SET_SYNC_PROGRESS_SCHEDULED_TASKS: "SET_SYNC_PROGRESS_SCHEDULED_TASKS",
  SET_SYNC_PROGRESS_AUTO_SAVED_ADHOC_TASKS:
    "SET_SYNC_PROGRESS_AUTO_SAVED_ADHOC_TASKSS",
  SET_SYNC_PROGRESS_CORRECTIVE_ACTIONS: "SET_SYNC_PROGRESS_CORRECTIVE_ACTIONS",
  SET_SYNC_PROGRESS_TASK_HISTORY: "SET_SYNC_PROGRESS_TASK_HISTORY",
};
