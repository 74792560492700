import { PageData, SortData, SearchTerm } from "./General";
import { GENERIC_CONSTANTS } from '../constants/GenericConstants';

export const LocationFilterModel = (filterData = {}, sortData = {}, pageData = {}, searchTerm = "") => {

  return ({
    ...new PageData(pageData),
    ...new SortData(sortData),
    ...new SearchTerm(searchTerm),
    locationTypeIds: filterData.locationTypeIds || [],
    managerIds: filterData.managerIds || [],
    status: filterData.status,
    workOrderIds: filterData.workOrderIds || [],
  })
};


export const LocationFilterModelForOffline = (timestamp) => {
  return ({
    ...new PageData({ pageNumber: 1, pageSize: GENERIC_CONSTANTS.TABLE_OPTIONS.MAX_PAGE_SIZE }),
    ...new SortData({ sortDirection: 1, orderByProp: "UpdatedOn" }),
    LatestByDate: timestamp
  })
};
