import React, { Component } from "react";
import "./App.scss";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { PrivateRoute } from "./components/PrivateRoute";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loadable from "react-loadable";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StorageService from "@ecodocs/common/src/sevices/storageService";
import { updateCurrentUserData, userAutoLoggedIn } from "@ecodocs/common/src/store/actions/UserActions";
import Loading from "@ecodocs/common/src/component/Loading/Loading";

const loading = () => (
  // <div className="animated fadeIn pt-3 text-center">Loading...</div>
  <Loading show={true} />
);

// QA Tech
const BaseLayout = Loadable({
  loader: () => import("./components/BaseLayout/BaseLayout"),
  loading,
});

// Pages
const Login = Loadable({
  loader: () => import("./components/Login/Login"),
  loading,
});

const Register = Loadable({
  loader: () => import("./components/Register/Register"),
  loading,
});

const Page404 = Loadable({
  loader: () => import("./components/Page404/Page404"),
  loading,
});

const ForgotPassword = Loadable({
  loader: () => import("./components/Login/ForgotPassword"),
  loading,
});

const ResetPassword = Loadable({
  loader: () => import("./components/Login/ResetPassword"),
  loading,
});

// FAQ
const FAQ = Loadable({
  loader: () => import("./components/FAQ/FAQ"),
  loading,
});

const WebsiteForms = Loadable({
  loader: () => import("./components/WebsiteForms/WebsiteForms"),
  loading,
});

const Signup = Loadable({
  loader: () => import("./components/Signup/Signup"),
  loading,
});

const ChangeDepartment = Loadable({
  loader: () => import("./components/ChangeDepartment/ChangeDepartmentPage"),
  loading,
});

class App extends Component {
  componentDidMount = () => {
    // if logged in user's data is not in redux
    const user = StorageService.instance.getUserData();
    const token = StorageService.instance.getAccessToken();
    const applicationConfiguration = StorageService.instance.getUserConfiguration();
    if (!!user) {
      this.props.userAutoLoggedIn({token, user, applicationConfiguration});
      //this.props.updateCurrentUserData(data);
    }
  };

  render() {
    return (
      <>
        <ToastContainer className="customToast" hideProgressBar={true} pauseOnFocusLoss={false}/>
        <BrowserRouter>
          <Switch>
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route
              exact
              path="/register"
              name="Register Page"
              component={Register}
            />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password"
              component={ForgotPassword}
            />
            <Route
              exact
              path="/reset-password"
              name="New Password"
              component={ResetPassword}
            />
            <Route
              path="/website-forms/:id"
              name="WebsiteForms"
              component={WebsiteForms}
            />
            <Route
              path="/change-department"
              name="Change Department"
              component={ChangeDepartment}
            />
            {/* <Route
              path="/signup"
              name="Signup "
              component={Signup}
            /> */}
              <Route
              exact
              path="/faq"
              name="FAQ"
              component={FAQ}
            />
            <PrivateRoute path="/" name="Home" component={BaseLayout} />
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateCurrentUserData,
      userAutoLoggedIn
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
