import {
  GENERIC_CONSTANTS,
  FormSubmissionStatusEnums,
  ADMIN_CONFIGURATION_KEYS,
  FORM_SUBMISSION_MASTER_TYPE,
} from "../constants/GenericConstants";
import {
  parseDateForServer,
  parseDateTime,
  parseDateForDatePicker,
  showFormulaField,
  getConfigValue,
  showFirstXCharacters,
  getConfigValueFromStore,
} from "./GeneralHelper";
import _ from "lodash";
import React from "react";
import moment from "moment";

/**
 * This method will parse data
 * for parseTaskRulesData data
 * @param {Object} taskData
 */
export function parseTaskRulesData(taskDetails) {
  let parsedResponse = {};
  if (!!taskDetails) {
    let formFields = taskDetails.formFields || [];
    parsedResponse.formFields = formFields;

    // add task/workorder/program info to show in listing screen
    const task = taskDetails || {};
    parsedResponse.task = task.title || "";

    const workorder = task.workorder || {};
    parsedResponse.workorder = workorder.title || "";

    const program = workorder.program || {};
    parsedResponse.program = program.title || "";

    const division = program.division || {};
    parsedResponse.division = division.title || "";
  }
  return parsedResponse;
}

export function parseTaskRules(taskDetails) {
  let parsedResponse = {};
  if (!!taskDetails) {
    parsedResponse.rules = taskDetails.elements.length
      ? taskDetails.elements
      : [];
    parsedResponse.rulesCount = taskDetails.totalElements
      ? taskDetails.totalElements
      : 0;
  }
  return parsedResponse;
}

/**
 * This method will parse data
 * for task/add data
 * @param {Object} taskData
 */
export function parseTaskData(formValues, status, isSubTask = false) {
  let parsedResponse = {};
  let formFieldList = formValues.formFieldList || [];

  //parsedResponse.requirement = formValues.requirement;
  parsedResponse.instruction = formValues.instruction;
  parsedResponse.revisionDateTime = parseDateForServer(
    formValues.revisionDateTime
  );
  parsedResponse.status = status;
  parsedResponse.revisionNote = formValues.revisionNote;

  let groupFormFields = [];

  for (let i = 0; i < formFieldList.length; i++) {
    let group = formFieldList[i];

    let formFields = !!group.formFields
      ? group.formFields.map((obj, index) => {
          return {
            fieldType: obj.fieldType,
            specification: obj.specification || "",
            isRequired: obj.isRequired || false,
            title: obj.title,
            order: index + 1,
            id: obj.id,
          };
        })
      : [];

    groupFormFields.push({
      groupName: group.groupName,
      order: i + 1,
      formFields: formFields || [],
    });
  }

  parsedResponse.groupFormFields = groupFormFields;
  parsedResponse.frequency = formValues.frequency;
  parsedResponse.submissionCount = formValues.submissionCount;
  parsedResponse.taskNumber = formValues.taskNumber;
  //parsedResponse.workorderId = formValues.workorder;
  parsedResponse.title = formValues.title;

  // parent task, isWorkorderVisible true/false
  if (!!isWorkorderVisible() && !isSubTask) {
    parsedResponse.workorderId = formValues.workorder;
  } else if (!isWorkorderVisible() && !isSubTask) {
    parsedResponse.workorderId = getConfigValue(
      ADMIN_CONFIGURATION_KEYS.DEFAULT_IDS.WORKORDER
    );
  }

  // for sub task
  if (!!isSubTask) {
    parsedResponse.parenttaskid = formValues.task;
  }

  return parsedResponse;
}
/**
 * Parsing Filter Params for TaskListing
 * @param {Object} filterParams
 */
export function parsedTaskFilters(filterParams) {
  let filters = {};
  let statuses = filterParams.status;
  let workorders = filterParams.workorders;
  let states = filterParams.state;
  let priority = filterParams.priority;
  let isDuplicate = filterParams.isDuplicate;

  priority = _.map(priority, "value");
  states = _.map(states, "value").filter((x) => x !== undefined);
  statuses = _.map(statuses, "value").filter((x) => x !== undefined);
  workorders = _.map(workorders, "value");

  filters.endDate = !!filterParams.endDate
    ? parseDateForServer(filterParams.endDate)
    : null;
  filters.startDate = !!filterParams.startDate
    ? parseDateForServer(filterParams.startDate)
    : null;
  filters.workorders = workorders;
  filters.status = statuses.length !== 0 ? statuses : [];
  filters.state = states.length !== 0 ? states : [true];
  filters.priority = priority;
  filters.isDuplicate = !!isDuplicate
    ? isDuplicate.value === true
      ? true
      : false
    : null;
  return filters;
}

/**
 * Payload Data For Edit Task Screen
 * @param {Object} taskData
 */
export function parseTaskEditFormData(taskData) {
  const parsedData = {};
  const taskObject = !!taskData.parentTask ? taskData.parentTask : taskData;
  const workorderObject =
    !!taskObject && taskObject.workorder ? taskObject.workorder : null;
  const programObject =
    !!workorderObject && workorderObject.program
      ? workorderObject.program
      : null;
  const divisionObject =
    !!programObject && programObject.division ? programObject.division : {};

  parsedData.instruction = taskData.instruction;
  //parsedData.requirement = taskData.requirement;
  parsedData.revisionDateTime = !!taskData.revisionDateTime
    ? parseDateForDatePicker(taskData.revisionDateTime)
    : "";
  parsedData.revisionNote = taskData.revisionNote;

  let formFieldList = [];
  if (!!taskData.groupFormFields && taskData.groupFormFields.length > 0) {
    for (let i = 0; i < taskData.groupFormFields.length; i++) {
      let group = taskData.groupFormFields[i];
      let formFields = group.formFields.map((obj, index) => {
        return {
          fieldType: obj.fieldType,
          specification: obj.specification || "",
          isRequired: obj.isRequired || false,
          title: obj.title,
          enabled: showFormulaField(obj.fieldType),
          groupIndex: i,
          id: obj.id || "",
        };
      });

      formFieldList.push({
        groupName: group.groupName,
        formFields: formFields,
      });
    }
  }

  parsedData.formFieldList = formFieldList;
  parsedData.division = !!divisionObject ? divisionObject.id : "";
  parsedData.frequency = taskData.frequency;
  parsedData.program = !!programObject ? programObject.id : "";
  parsedData.submissionCount = taskData.submissionCount;
  parsedData.taskNumber = taskData.taskNumber;
  parsedData.workorder = !!workorderObject ? workorderObject.id : "";
  parsedData.task = !!taskObject ? taskObject.id : "";
  parsedData.title = taskData.title;
  parsedData.isActive = taskData.isActive;

  // sublists
  parsedData.divisionSublist = !!divisionObject ? [divisionObject] : [];
  parsedData.programSublist = !!programObject ? [programObject] : [];
  parsedData.workorderSublist = !!workorderObject ? [workorderObject] : [];
  parsedData.taskSublist = !!taskObject ? [taskObject] : [];

  return parsedData;
}
/**
 * Payload Data For View Task Screen
 * @param {Object} taskData
 */
export function parseTaskViewFormData(taskData) {
  const parsedData = {};
  const workorderObject = taskData.workorder || {};
  const programObject = !!taskData.workorder ? taskData.workorder.program : {};
  const divisionObject = !!programObject ? programObject.division : {};

  parsedData.instruction = taskData.instruction || "No Description Entered";
  parsedData.requirement = taskData.requirement || "No Description Entered";

  parsedData.revisionDateTime = !!taskData.revisionDateTime
    ? parseDateForDatePicker(taskData.revisionDateTime)
    : "";

  parsedData.revisionNote = taskData.revisionNote;
  parsedData.formFields = [];

  if (!!taskData.formFields && taskData.formFields.length > 0) {
    parsedData.formFields = taskData.formFields.map((obj) => {
      let fieldsObject = {};
      fieldsObject.fieldType = obj.fieldType;
      fieldsObject.specification = obj.specification || "";
      fieldsObject.isRequired = obj.isRequired;
      fieldsObject.title = obj.title;
      fieldsObject.enabled = showFormulaField(obj.fieldType);
      fieldsObject.taskGroup = obj.taskGroupId;
      return fieldsObject;
    });
  }
  parsedData.totalActivities = !!taskData.formFields
    ? taskData.formFields.length
    : 0; //formFields applied
  parsedData.division = !!divisionObject ? divisionObject.id : "";
  parsedData.frequency = taskData.frequency;
  parsedData.isActive = taskData.isActive;
  parsedData.stateTitle = parsedData.isActive ? "Active" : "Inactive";
  parsedData.program = !!programObject ? programObject.id : "";
  parsedData.submissionCount = taskData.submissionCount;
  parsedData.status = taskData.status;
  parsedData.taskNumber = taskData.taskNumber;
  parsedData.workorder = !!workorderObject ? workorderObject.id : "";
  parsedData.title = taskData.title;

  parsedData.divisionSublist = [];
  parsedData.programSublist = [];
  parsedData.workorderSublist = [];
  if (!!workorderObject && workorderObject.id) {
    parsedData.workorderSublist.push({
      id: workorderObject.id,
      workorderNumber: workorderObject.workorderNumber,
    });
  }

  if (!!programObject && programObject.id) {
    parsedData.programSublist.push({
      id: programObject.id,
      programNumber: programObject.programNumber,
    });
  }
  if (!!divisionObject && divisionObject.id) {
    parsedData.divisionSublist.push({
      id: divisionObject.id,
      divisionNumber: divisionObject.divisionNumber,
    });
  }
  return parsedData;
}
/**
 * Parse Listing Data
 * @param {Array} taskListingData
 */
export function parseTaskListingData(taskListingData, ENUM = {}) {
  let parsedTaskList = [];
  const taskStatus = ENUM.STATUS;
  const frequencyCalender = ENUM.FREQUENCY_CALENDAR;

  if (!!taskListingData) {
    for (var i = 0; i < taskListingData.length; i++) {
      const data = taskListingData[i];
      let statusObj =
        _.find(taskStatus, (obj) => obj.value === data.status) || {};
      let taskObj = {};
      let isActive = data.isActive;
      let statusIcon = isActive ? "activeIcon" : "inactiveIcon";

      taskObj.taskNumber = (
        <div>
          <span className={statusIcon}></span>
          {data.taskNumber}
        </div>
      );

      taskObj.title = showFirstXCharacters(data.title, 15);
      taskObj.id = data.id;
      taskObj.revisionDateTime = !!data.revisionDateTime
        ? parseDateForDatePicker(data.revisionDateTime)
        : "N/A";
      taskObj.updatedOn = !!data.updatedOn
        ? moment(data.updatedOn).format(GENERIC_CONSTANTS.MMM_D_YYYY_LT)
        : "";
      taskObj.status = statusObj.label || "";
      taskObj.createdBy =
        !!data && !!data.createdByUser && !!data.createdByUser.fullName
          ? data.createdByUser.fullName
          : "";
      taskObj.workorder = !!data.workorder
        ? showFirstXCharacters(data.workorder.title, 15)
        : "";

      // frequency
      let frequencyObj =
        _.find(frequencyCalender, (obj) => obj.value === data.frequency) || {};
      taskObj.frequency =
        !!frequencyObj && !!frequencyObj.label ? frequencyObj.label : "N/A";

      //locations
      let locations =
        !!data && !!data.workorder && !!data.workorder.locationNames
          ? data.workorder.locationNames
          : "N/A";
      locations = locations.split(",");
      if (locations.length > 2) {
        let length = locations.length - 2;
        locations = locations.slice(0, 2);
        locations.push(length + " More...");
      }

      let locationValue = locations.map((str) => {
        return <div>{showFirstXCharacters(str, 15)}</div>;
      });
      taskObj.locationName = locationValue;

      parsedTaskList.push(taskObj);
    }
  }

  return parsedTaskList;
}

/**
 * Parse Listing Data
 * @param {Array} taskListingData
 */
export function parseTaskSubmissionHistoryData(taskListingData, ENUM = {}) {
  let parsedTaskList = [];
  const taskStatus = ENUM.SUBMISSION_STATUS;

  if (!!taskListingData) {
    for (var i = 0; i < taskListingData.length; i++) {
      const data = taskListingData[i];
      let taskObj = {};

      //locations
      let locations = data.locationName || "N/A";
      locations = locations.split(",");
      if (locations.length > 2) {
        let length = locations.length - 2;
        locations = locations.slice(0, 2);
        locations.push(length + " More...");
      }

      let locationValue = locations.map((str) => {
        return <div>{showFirstXCharacters(str, 15)}</div>;
      });
      taskObj.locationName = locationValue;

      let statusObj =
        _.find(taskStatus, (obj) => obj.value === data.status) || {};
      taskObj.status = statusObj.label || "";

      taskObj.completedBy = data.user || "";
      taskObj.completedOn = data.submissionDate || "";

      taskObj.id = data.id;

      parsedTaskList.push(taskObj);
    }
  }

  return parsedTaskList;
}

/**
 * Parse Listing Data
 * @param {Array} taskListingData
 */
export function parseTodaysTaskListingData(taskListingData, ENUM = {}) {
  let parsedTaskList = [];
  const submissionStatus = ENUM.SUBMISSION_STATUS;
  const frequencyCalender = ENUM.FREQUENCY_CALENDAR;

  if (!!taskListingData) {
    for (var i = 0; i < taskListingData.length; i++) {
      const data = taskListingData[i];

      let taskObj = {};

      taskObj.title =
        !!data && !!data.taskDetail && !!data.taskDetail.title
          ? showFirstXCharacters(data.taskDetail.title, 15)
          : "";
      taskObj.taskNumber =
        !!data &&
        !!data.taskDetail &&
        !!data.taskDetail.task &&
        !!data.taskDetail.task.taskNumber
          ? data.taskDetail.task.taskNumber
          : "";
      taskObj.updatedOn =
        !!data && !!data.submittedOn ? parseDateTime(data.submittedOn) : "";
      taskObj.dueDate =
        !!data && !!data.dueDate ? parseDateForDatePicker(data.dueDate) : "";

      let statusObj =
        _.find(
          submissionStatus,
          (obj) =>
            obj.value === data.formSubmissionStatusEnumId ||
            obj.value === data.status
        ) || {};

      taskObj.status = !!statusObj.label ? (
        <span className={statusObj.label.toLowerCase()}>{statusObj.label}</span>
      ) : (
        ""
      );

      taskObj.statusValue = data.formSubmissionStatusEnumId || data.status;

      // frequency
      let frequencyObj =
        !!data && !!data.taskDetail && !!data.taskDetail.frequency
          ? _.find(
              frequencyCalender,
              (obj) => obj.value === data.taskDetail.frequency
            )
          : {};
      taskObj.frequency =
        !!frequencyObj && !!frequencyObj.label ? frequencyObj.label : "N/A";

      taskObj.id = data.id;

      //locations
      let locations =
        !!data && !!data.locationNames ? data.locationNames : "N/A";
      locations = locations.split(",");
      if (locations.length > 2) {
        let length = locations.length - 2;
        locations = locations.slice(0, 2);
        locations.push(length + " More...");
      }

      let locationValue = locations.map((str) => {
        return <div>{showFirstXCharacters(str, 15)}</div>;
      });
      taskObj.locationName = locationValue;

      parsedTaskList.push(taskObj);
    }
  }

  return parsedTaskList;
}
/**
 * Parsing workorder array for multiselect array
 * @param {ObjectArray} workorders
 */
export function parseWorkordersForSelect(workorders) {
  let parsedWorKorders = [];

  if (!!workorders && workorders.length > 0) {
    for (var i = 0; i < workorders.length; i++) {
      parsedWorKorders.push({
        value: workorders[i].id,
        label: workorders[i].workorderNumber,
        color: "#00B8D9",
      });
    }
  }
  return parsedWorKorders;
}

export function parseLocationForSelect(locations) {
  let parsedData = [];

  if (!!locations && locations.length > 0) {
    for (var i = 0; i < locations.length; i++) {
      parsedData.push({
        value: locations[i].id,
        label: locations[i].locationName,
        color: "#00B8D9",
      });
    }
  }
  return parsedData;
}

export function parseTasksForSelect(tasks) {
  let parsedData = [];

  if (!!tasks && tasks.length > 0) {
    for (var i = 0; i < tasks.length; i++) {
      parsedData.push({
        value: tasks[i].id,
        label: `${tasks[i].taskNumber} | ${tasks[i].title}`,
        color: "#00B8D9",
      });
    }
  }
  return parsedData;
}
/**
 * Getting Filter Count For
 * Task List Page
 * @param {Object} filters
 */
export function getTaskFilterCount(filters) {
  let dateRangePresent = false;
  let filterCount = 0;

  _.each(filters, (obj, key) => {
    if (!!obj) {
      if (
        key === GENERIC_CONSTANTS.FIELD_NAME.START_DATE ||
        key === GENERIC_CONSTANTS.FIELD_NAME.END_DATE
      ) {
        dateRangePresent = true;
        filterCount += 1;
      } else if (Array.isArray(obj) && obj.length > 0) {
        filterCount += 1;
      } else if (!Array.isArray(obj)) {
        filterCount += 1;
      }
    }
  });
  if (dateRangePresent) {
    //subtracting 1 because using start date and end date as single filter
    filterCount -= 1;
  }
  return filterCount;
}
/**
 * Getting Filter Count For
 * Task Submission List Page
 * @param {Object} filters
 */
export function getTaskSubmissionsFilterCount(filters) {
  let dateRangePresent = false;
  let filterCount = 0;

  _.each(filters, (obj, key) => {
    if (!!obj) {
      if (
        key === GENERIC_CONSTANTS.FIELD_NAME.START_DATE ||
        key === GENERIC_CONSTANTS.FIELD_NAME.END_DATE
      ) {
        dateRangePresent = true;
        filterCount += 1;
      } else if (obj.length > 0) {
        filterCount += 1;
      }
    }
  });
  if (dateRangePresent) {
    //subtracting 1 because using start date and end date as single filter
    filterCount -= 1;
  }
  return filterCount;
}
/**
 * Parsing Filter Params for Task Submission
 * @param {Object} filterParams
 */
export function parsedTaskSubmissionsFilters(filterParams) {
  let filters = {};

  filters.endDate = !!filterParams.endDate
    ? parseDateForServer(filterParams.endDate)
    : null;
  filters.startDate = !!filterParams.startDate
    ? parseDateForServer(filterParams.startDate)
    : null;

  return filters;
}

/**
 * Parsing group Params for form fields counting
 * @param {number} number
 */
export function getEmtpyFormFields(group) {
  let count = 0;
  for (let i = 0; i < group.length; i++) {
    let formFields = group[i].formFields;
    count += formFields.length > 0 ? 0 : 1;
  }
  return count;
}

export function getFieldRowLabel(count) {
  // for parent
  let parentCount = -1;
  while (count > 25) {
    parentCount++;
    count -= 26;
  }

  // for parent parent
  let parentParentCount = -1;
  while (parentCount > 25) {
    parentParentCount++;
    parentCount -= 26;
  }

  return (
    (parentParentCount === -1
      ? ""
      : String.fromCharCode(97 + parentParentCount)) +
    (parentCount === -1 ? "" : String.fromCharCode(97 + parentCount)) +
    String.fromCharCode(97 + count)
  );
}

export function parseFormValues(fieldObject, valueObject) {
  //console.log(fieldObject, valueObject);

  const values = valueObject.value;
  const { FIELD_TYPES } = GENERIC_CONSTANTS;
  const fieldType = parseInt(fieldObject.fieldType);

  // for multi select
  if (fieldType === FIELD_TYPES.MULTI_SELECT) {
    let value = !!values ? values.split(",") : [];
    if (values) {
      return value.map((val) => {
        return { value: val, label: val };
      });
    } else {
      return "";
    }
  } else if (fieldType === FIELD_TYPES.BOOLEAN) {
    // for boolean
    return !!values ? values === "true" : "";
  } else if (fieldType === FIELD_TYPES.DATE) {
    // for date
    return parseDateForDatePicker(values);
  } else if (fieldType === FIELD_TYPES.IMAGE_UPLOAD) {
    // for image Upload
    let formSubmissionMedias = valueObject.formSubmissionMedias || [];
    let mediaValues = [];
    for (let i = 0; i < formSubmissionMedias.length; i++) {
      mediaValues.push(formSubmissionMedias[i].media);
    }

    return !!mediaValues.length ? mediaValues : "";
  }
  return values || "";
}

export function getTaskStatusLabel(status) {
  if (status === FormSubmissionStatusEnums.COMPLETED) {
    return {
      taskStatusLabel: "Completed",
      taskStatusLabelStyle: { color: "rgb(68,130,26)" },
    };
  } else if (status === FormSubmissionStatusEnums.INCOMPLETE) {
    return {
      taskStatusLabel: "Missed",
      taskStatusLabelStyle: { color: "#f6993f" },
    };
  } else if (status === FormSubmissionStatusEnums.PENDING) {
    return {
      taskStatusLabel: "Pending",
      taskStatusLabelStyle: { color: "rgb(205, 131, 1)" },
    };
  } else if (status === FormSubmissionStatusEnums.PENDING_SYNC) {
    return {
      taskStatusLabel: "Pending Sync",
      taskStatusLabelStyle: { color: "rgb(68,130,26)" },
    };
  } else if (status === FormSubmissionStatusEnums.PENDING_APPROVAL) {
    return {
      taskStatusLabel: "Pending Approval",
      taskStatusLabelStyle: { color: "#74716d" },
    };
  } else if (status === FormSubmissionStatusEnums.ACCEPTED) {
    return {
      taskStatusLabel: "Accepted",
      taskStatusLabelStyle: { color: "#0282d1" },
    };
  } else if (status === FormSubmissionStatusEnums.REJECTED) {
    return {
      taskStatusLabel: "Rejected",
      taskStatusLabelStyle: { color: "#e35759" },
    };
  } else if (status === FormSubmissionStatusEnums.NOT_PERFORMED) {
    return {
      taskStatusLabel: "Not Performed",
      taskStatusLabelStyle: { color: "#B07D62" },
    };
  }
  return {
    taskStatusLabel: "Undefined",
    taskStatusLabelStyle: { color: "rgb(205, 131, 1)" },
  };
}

export function isWorkorderVisible(fromStore, config) {
  let visibility = fromStore
    ? getConfigValueFromStore(config, ADMIN_CONFIGURATION_KEYS.WORKORDER.VISIBILITY) || "true"
    : getConfigValue(ADMIN_CONFIGURATION_KEYS.WORKORDER.VISIBILITY) || "true";
  return !!visibility && visibility.toLowerCase() === "true";
}

export function saveAllAdhocTasks(fromStore, config) {
  let visibility = fromStore
    ? getConfigValueFromStore(config, ADMIN_CONFIGURATION_KEYS.INCLUDE_ALL_PUBLISHED_TASKS_OFFLINE.VISIBILITY) || "true"
    : getConfigValue(ADMIN_CONFIGURATION_KEYS.INCLUDE_ALL_PUBLISHED_TASKS_OFFLINE.VISIBILITY) || "true";
  return !!visibility && visibility.toLowerCase() === "true";
}

export function parseDate(date, options) {
  return new Date(date).toLocaleString("en-us", options);
}

export function getSubFormSubmissionMasterTasks(task) {
  let subFormSubmissionMasterTasks = [];
  if (!task.isSubtask) {
    const { subTasks } = task;
    subTasks &&
      subTasks.length > 0 &&
      subTasks.forEach((subtask) => {
        const taskDetail =
          typeof subtask.taskDetails === "string"
            ? JSON.parse(subtask.taskDetails || "")
            : subtask.taskDetails;
        const submissions = subtask.formSubmissionsByParentMasterId;
        submissions &&
          submissions.length &&
          submissions.forEach((s) => {
            subFormSubmissionMasterTasks.push({
              formSubmissionMasterId: s.submissionMasterId,
              status: s.status,
              title:
                s.title ||
                (taskDetail && taskDetail[0] && taskDetail[0].title) ||
                (s.taskDetail && s.taskDetail.title),
              isAdhocEntry: s.isAdhocEntry,
              formSubmissionMasterTypeId: s.formSubmissionMasterTypeId,
            });
          });
      });
    return subFormSubmissionMasterTasks;
  }
}

export function canSubmit(task) {
  const { submissionStartTime, submissionEndTime } =
    task;
  const current = moment();

  return (
    (current.isSameOrAfter(submissionStartTime) && current.isSameOrBefore(submissionEndTime)) ||
    submissionStartTime == null //for handling autosaved tasks
  );
}

export function getDueDate(start, end) {
  const time = `${moment(start).format("HH:mm")} - ${moment(end).format(
    "HH:mm"
  )}`;

  if (moment().isSame(start, "day") && moment(start).isSame(end, "day")) {
    return `Today, ${time}`;
  } else if (
    Math.abs(moment().diff(start, "days")) < 1 &&
    moment(start).isSame(end, "day")
  ) {
    return `Tomorrow, ${time}`;
  } else if (
    moment(start).isSame(end, "day") &&
    moment().isSame(start, "year")
  ) {
    return `${moment(start).format("MMM DD")}, ${time}`;
  } else if (moment(start).isSame(end, "day")) {
    return `${moment(start).format("MMM DD[,] YYYY")}, ${time}`;
  } else {
    // return `${moment(start).format("MMM DD[,] YYYY HH:mm")} - ${moment(
    //   end
    // ).format("MMM DD[,] YYYY HH:mm")}`;
    return `${moment(end).format("MMM DD[,] YYYY HH:mm")}`;
  }
}
