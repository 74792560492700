import { PLATFORMS } from "../../constants/GenericConstants";
import ReactNavigationService from "./react";
import ReactNativeNavigationService from "./react-native";

export default class NavigationService {
  constructor(platform, navigator) {
    this.service =
      platform === PLATFORMS.WEB
        ? new ReactNavigationService(navigator)
        : new ReactNativeNavigationService(navigator);
  }

  redirectToLogin() {
    this.service.redirectToLogin();
  }
}
