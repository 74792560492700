
import { TABLE_ACTIONS } from '../../constants/ActionConstants';

const initial = {
    userTableCheckedRows: [],
    programTableCheckedRows: [],
    taskTableCheckedRows: [],
    workorderTableCheckedRows: [],
    programManualTableCheckedRows: [],
    reviewTableCheckedRows: [],
    reviewSubmissionTableCheckedRows: [],
    correctiveActionTableCheckedRows: [],
    ScheduledTaskTableCheckedRows: [],
    SubmissionTaskTableCheckedRows: [],
};

export function table(state = initial, action) {

    switch (action.type) {

        /**
         * USER TABLE ACTION
         */

        case TABLE_ACTIONS.SAVE_USER_ID:
            let lstCheckedUser = [...state.userTableCheckedRows];
            let indexUser = lstCheckedUser.indexOf(action.payload.userId);

            if (action.payload.checked) {
                if (indexUser < 0)
                    lstCheckedUser.push(action.payload.userId);
            }
            else {
                let copyList = lstCheckedUser.slice();
                copyList.splice(indexUser, 1);
                lstCheckedUser = copyList;
            }

            return {
                ...state,
                userTableCheckedRows: lstCheckedUser
            };

        //EO | USER TABLE ACTION

        case TABLE_ACTIONS.SAVE_PROGRAM_ID:
            let lstCheckedProgram = [...state.programTableCheckedRows];
            let indexProgram = lstCheckedProgram.indexOf(action.payload.programId);

            if (action.payload.checked) {
                if (indexProgram < 0)
                    lstCheckedProgram.push(action.payload.programId);
            }
            else {
                let copyList = lstCheckedProgram.slice();
                copyList.splice(indexProgram, 1);
                lstCheckedProgram = copyList;
            }

            return {
                ...state,
                programTableCheckedRows: lstCheckedProgram
            };

        //EO | USER TABLE ACTION

        case TABLE_ACTIONS.SAVE_TASK_ID:
            let lstCheckedTask = [...state.taskTableCheckedRows];
            let indexTask = lstCheckedTask.indexOf(action.payload.taskId);

            if (action.payload.checked) {
                if (indexTask < 0)
                    lstCheckedTask.push(action.payload.taskId);
            }
            else {
                let copyList = lstCheckedTask.slice();
                copyList.splice(indexTask, 1);
                lstCheckedTask = copyList;
            }

            return {
                ...state,
                taskTableCheckedRows: lstCheckedTask
            };

        //EO | REVIEW SUBMISSION TABLE ACTION

        case TABLE_ACTIONS.SAVE_REVIEW_SUBMISSION_ID:
            let lstCheckedReviewSubmission = [...state.reviewSubmissionTableCheckedRows];
            let indexReviewSubmission = lstCheckedReviewSubmission.indexOf(action.payload.submissionId);

            if (action.payload.checked) {
                if (indexReviewSubmission < 0)
                    lstCheckedReviewSubmission.push(action.payload.submissionId);
            }
            else {
                let copyList = lstCheckedReviewSubmission.slice();
                copyList.splice(indexReviewSubmission, 1);
                lstCheckedReviewSubmission = copyList;
            }

            return {
                ...state,
                reviewSubmissionTableCheckedRows: lstCheckedReviewSubmission
            };


        //EO | CORRECTIVE ACTION TABLE ACTION

        case TABLE_ACTIONS.SAVE_CORRECTIVE_ACTION_ID:
            let lstCheckedCorrectiveAction = [...state.correctiveActionTableCheckedRows];
            let indexCorrectiveAction = lstCheckedCorrectiveAction.indexOf(action.payload.submissionId);

            if (action.payload.checked) {
                if (indexCorrectiveAction < 0)
                    lstCheckedCorrectiveAction.push(action.payload.submissionId);
            }
            else {
                let copyList = lstCheckedCorrectiveAction.slice();
                copyList.splice(indexCorrectiveAction, 1);
                lstCheckedCorrectiveAction = copyList;
            }

            return {
                ...state,
                correctiveActionTableCheckedRows: lstCheckedCorrectiveAction
            };

        //EO | SCHEDULED TASK TABLE ACTION

        case TABLE_ACTIONS.SAVE_SCHEDULED_TASK_ID:
            let lstCheckedScheduledTask = [...state.ScheduledTaskTableCheckedRows];
            let indexScheduledTask = lstCheckedScheduledTask.indexOf(action.payload.submissionId);

            if (action.payload.checked) {
                if (indexScheduledTask < 0)
                    lstCheckedScheduledTask.push(action.payload.submissionId);
            }
            else {
                let copyList = lstCheckedScheduledTask.slice();
                copyList.splice(indexScheduledTask, 1);
                lstCheckedScheduledTask = copyList;
            }

            return {
                ...state,
                ScheduledTaskTableCheckedRows: lstCheckedScheduledTask
            };

        //EO | TASK HISTORY TASK TABLE ACTION

        case TABLE_ACTIONS.SAVE_SUBMISSION_TASK_ID:
            let lstCheckedSubmissionTask = [...state.SubmissionTaskTableCheckedRows];
            let indexSubmissionTask = lstCheckedSubmissionTask.indexOf(action.payload.submissionId);

            if (action.payload.checked) {
                if (indexSubmissionTask < 0)
                    lstCheckedSubmissionTask.push(action.payload.submissionId);
            }
            else {
                let copyList = lstCheckedSubmissionTask.slice();
                copyList.splice(indexSubmissionTask, 1);
                lstCheckedSubmissionTask = copyList;
            }

            return {
                ...state,
                SubmissionTaskTableCheckedRows: lstCheckedSubmissionTask
            };


        //EO | USER TABLE ACTION

        case TABLE_ACTIONS.SAVE_WORKORDER_ID:
            let lstCheckedWorkorder = [...state.workorderTableCheckedRows];
            let indexWorkroder = lstCheckedWorkorder.indexOf(action.payload.workorderId);

            if (action.payload.checked) {
                if (indexWorkroder < 0)
                    lstCheckedWorkorder.push(action.payload.workorderId);
            }
            else {
                let copyList = lstCheckedWorkorder.slice();
                copyList.splice(indexWorkroder, 1);
                lstCheckedWorkorder = copyList;
            }

            return {
                ...state,
                workorderTableCheckedRows: lstCheckedWorkorder
            };

        default:
            return state;
    }

}