import { GENERIC_CONSTANTS, FormSubmissionStatusEnums, FORM_SUBMISSION_MASTER_TYPE } from "../constants/GenericConstants";
import {
  parseDateForServer,
  parseDateForDatePicker,
  parseDate,
  showFirstXCharacters,
  parseDateTime24Hours,
} from "./GeneralHelper";
import _ from "lodash";
import React from "react";

/**
 * Parse Listing Data
 * @param {Array} taskListingData
 */
export function parseTaskSubmissionListingData(taskListingData, ENUM = {}) {
  let parsedTaskList = [];
  const taskStatus = ENUM.SUBMISSION_STATUS;
  const frequencyCalender = ENUM.FREQUENCY_CALENDAR;
  const schedulerFrequency = ENUM.SCHEDULER_FREQUENCY;

  if (!!taskListingData) {
    for (var i = 0; i < taskListingData.length; i++) {
      const data = taskListingData[i];
      let taskObj = {};
      taskObj.taskName = showFirstXCharacters(data.taskName, 110);
      taskObj.workorderNumber = data.workorderNumber;
      taskObj.signature = data.signature;

      let locations = data.locationName || "N/A";
      locations = locations.split(",");

      if (locations.length > 2) {
        let length = locations.length - 2;
        locations = locations.slice(0, 2);
        locations.push(length + " More...");
      }

      taskObj.locationName = locations.map((str, index) => {
        return <div key={index}>{showFirstXCharacters(str, 15)}</div>;
      });

      taskObj.taskType = data.taskType;
      taskObj.submittedBy = data.submittedBy || "N/A";
      taskObj.submittedOn = !!data.submittedOn
        ? parseDateTime24Hours(data.submittedOn)
        : "N/A";
      taskObj.recordClass = !!data.showViolations ? "rowViolation" : "";

      // let frequencyObj =
      //   _.find(frequencyCalender, (obj) => obj.value === data.frequency) || {};
      // taskObj.frequency =
      //   !!frequencyObj && !!frequencyObj.label ? frequencyObj.label : "N/A";

      let occurrenceObj =
        _.find(
          schedulerFrequency,
          (obj) => obj.value === data.occurenceEnumId
        ) || {};
      taskObj.OccurenceEnumId =
        !!occurrenceObj && !!occurrenceObj.label ? occurrenceObj.label : "N/A";

      let statusObj =
        _.find(taskStatus, (obj) => obj.value === data.status) || {};
      taskObj.statusEnumId = statusObj.value;
      taskObj.status = (
        <span className={`status-tag tag-${getStatusClass(statusObj.value)}`}>
          {" "}
          {statusObj.label || ""}
        </span>
      );
      taskObj.statusId = data.status || "";

      taskObj.id = data.submissionMasterId;

      // subtasks
      let subtasks = data.subFormSubmissionMasterTasks || [];
      let subtaskList = [];
      subtasks.forEach((subtask) => {
        if (subtask.status !== 1) {
          // only completed & missed
          let subtaskStatus =
            _.find(taskStatus, (obj) => obj.value === subtask.status) || {};
          subtaskList.push({
            title: subtask.title,
            status: subtaskStatus.label || "",
            status: subtaskStatus.label || "",
            showViolations: !!subtask.showViolations,
            id: subtask.formSubmissionMasterId,
          });
        }
      });
      taskObj.subtasks = subtaskList;

      parsedTaskList.push(taskObj);
    }
  }
  return parsedTaskList;
}

function getStatusClass(value){
  const { 
    PENDING: STATUS_OPEN, 
    COMPLETED: STATUS_COMPLETED, 
    INCOMPLETE: STATUS_MISSED, 
    PENDING_APPROVAL: STATUS_PENDING_APPROVAL, 
    REJECTED: STATUS_REJECTED, 
    ACCEPTED: STATUS_ACCEPTED,
    NOT_PERFORMED: STATUS_NOT_PERFORMED,
   } = FormSubmissionStatusEnums;

  switch(value){
    case STATUS_OPEN: return 'open'; break;
    case STATUS_COMPLETED: return 'completed'; break;
    case STATUS_MISSED: return 'missed'; break;
    case STATUS_PENDING_APPROVAL: return 'pending-approval'; break;
    case STATUS_REJECTED: return 'rejected'; break;
    case STATUS_ACCEPTED: return 'accepted'; break;
    case STATUS_NOT_PERFORMED: return 'not-performed'; break;
    default: return 'completed';
  }
}

/**
 * Getting Filter Count For
 * Task Submission List Page
 * @param {Object} filters
 */
export function getTaskSubmissionFilterCount(filters) {
  let dateRangePresent = false;
  let filterCount = 0;

  _.each(filters, (obj, key) => {
    if (!!obj) {
      if (
        key === GENERIC_CONSTANTS.FIELD_NAME.START_DATE ||
        key === GENERIC_CONSTANTS.FIELD_NAME.END_DATE
      ) {
        dateRangePresent = true;
      } else if (Array.isArray(obj) && obj.length > 0 && key != 'formSubmissionMasterTypes') {
        filterCount += 1;
      } else if(!Array.isArray(obj) && key != 'RequestPage'){
        filterCount += 1;
      }
    }
  });
  if (dateRangePresent) {
    filterCount += 1;
  }
  return filterCount;
}

/**
 * Parsing Filter Params for TaskSubmissionListing
 * @param {Object} filterParams
 */
export function parsedTaskSubmissionFilters(filterParams) {
  let filters = {};
  filters.RequestPage = !!filterParams.RequestPage? filterParams.RequestPage : 0;

  filters.endDate = !!filterParams.endDate
    ? parseDateForServer(filterParams.endDate)
    : null;
  filters.startDate = !!filterParams.startDate
    ? parseDateForServer(filterParams.startDate)
    : null;

  filters.workOrderIds = !!filterParams.workorder
    ? _.map(filterParams.workorder, "value")
    : [];
  filters.locationIds = !!filterParams.location
    ? _.map(filterParams.location, "value")
    : [];
  filters.submittedBy = !!filterParams.completedBy
    ? _.map(filterParams.completedBy, "value")
    : [];
  filters.status = !!filterParams.status
    ? _.map(filterParams.status, "value")
    : [];

  filters.reviewerIds = !!filterParams.reviewerId
    ? _.map(filterParams.reviewerId, "value")
    : [];

  filters.showViolation = !!filterParams.showViolation
    ? !!filterParams.showViolation.value
    : "";
  filters.isSubTask = !!filterParams.showSubtask
    ? !!filterParams.showSubtask.value
    : "";

  filters.DepartmentIds = !!filterParams.program
    ? _.map(filterParams.program, "value")
    : [];

  // filters.reviewerId = !!filterParams.reviewerId && filterParams.reviewerId.value
  //   ? filterParams.reviewerId.value
  //   : "";

  let taskType = !!filterParams.taskType ? filterParams.taskType : [];
  let formSubmissionMasterTypes = [];
  taskType.map(type => {
    if(type.value == FORM_SUBMISSION_MASTER_TYPE.ADHOC) formSubmissionMasterTypes.push(FORM_SUBMISSION_MASTER_TYPE.ADHOC);
    if(type.value == FORM_SUBMISSION_MASTER_TYPE.SCHEDULED) formSubmissionMasterTypes.push(FORM_SUBMISSION_MASTER_TYPE.SCHEDULED);
    if(type.value == FORM_SUBMISSION_MASTER_TYPE.CORRECTIVE_ACTION) formSubmissionMasterTypes.push(FORM_SUBMISSION_MASTER_TYPE.CORRECTIVE_ACTION);
  });

  // if (!!formSubmissionMasterTypes.includes(FORM_SUBMISSION_MASTER_TYPE.ADHOC)) {
  //   filters.AdhocOnly = true;
  //   // filters.frequency = [-1];
  // } 
  if (formSubmissionMasterTypes.includes(FORM_SUBMISSION_MASTER_TYPE.SCHEDULED)) {
    // filters.ScheduledOnly = true;
    filters.occurrence = !!filterParams.occurrence
      ? _.map(filterParams.occurrence, "value")
      // ? _.map(filterParams.frequency, "value")
      : [];
  } 
  // if(formSubmissionMasterTypes.includes(FORM_SUBMISSION_MASTER_TYPE.CORRECTIVE_ACTION)){
  //   filters.formSubmissionMasterTypes = [FORM_SUBMISSION_MASTER_TYPE.CORRECTIVE_ACTION];
  // }
  filters.formSubmissionMasterTypes = formSubmissionMasterTypes;
  return filters;
}

/**
 * Parsing Filter Params for Due Tasks
 * @param {Object} filterParams
 */
export function parsedDueTasksFilters(filterParams) {
  let filters = {};
  filters.toDueDate = !!filterParams.endDate
    ? parseDateForServer(filterParams.endDate)
    : null;
  filters.fromDueDate = !!filterParams.startDate
    ? parseDateForServer(filterParams.startDate)
    : null;

  filters.workOrderIds = !!filterParams.workorder
    ? _.map(filterParams.workorder, "value")
    : [];
  filters.occurrence = !!filterParams.occurrence
    ? _.map(filterParams.occurrence, "value")
    : [];
  filters.locationIds = !!filterParams.location
    ? _.map(filterParams.location, "value")
    : [];
  filters.DepartmentIds = !!filterParams.program
    ? _.map(filterParams.program, "value")
    : [];

  let priority = filterParams.priority;
  priority = _.map(priority, "value");
  filters.priority = priority;
  filters.RequestPage = filterParams.RequestPage;
  filters.formSubmissionMasterTypes = !!filterParams.formSubmissionMasterTypes ? _.map(filterParams.formSubmissionMasterTypes) : [];
  return filters;
}

export async function uploadImages(model, uploadImagesAction) {
  const { TASK_DESIGNER_VALUE_TYPES } = GENERIC_CONSTANTS;
  const { formSubmissions } = model;
  const fileUploadPromises = [];

  const uploadImages = async (field) => {
    const { fileName, type, uri, path } = field[
      TASK_DESIGNER_VALUE_TYPES.MEDIA_ID
    ];
    const MediaUploadValidatorId = ["image/jpeg", "image/png"].includes(type)
      ? "2"
      : "3";
    // console.log("uploading", fileName, type, uri);
    // const formData = new FormData();
    // formData.append("MediaUploadValidatorId", MediaUploadValidatorId);
    // formData.append("FormFiles", {
    //   data: window.RNFetchBlob.wrap(path),
    //   // uri,
    //   type: type,
    //   name: fileName.toString(),
    //   filename: fileName.toString(),
    // });
    let formData = [
      { name: "MediaUploadValidatorId", data: MediaUploadValidatorId },

      {
        name: "FormFiles",
        filename: fileName,
        type: type,
        data: window.RNFetchBlob.wrap(uri),
      },
    ];

    const response = await uploadImagesAction(formData);
    const data = JSON.parse(response.data || {});
    field[TASK_DESIGNER_VALUE_TYPES.MEDIA_ID] =
      (response && data.result && data.result[0] && data.result[0].id) || null;
  };

  for (const field of formSubmissions) {
    if (
      !!field &&
      !!field[TASK_DESIGNER_VALUE_TYPES.MEDIA_ID] &&
      typeof field[TASK_DESIGNER_VALUE_TYPES.MEDIA_ID] === "object"
    ) {
      fileUploadPromises.push(uploadImages(field));
    }
  }

  return Promise.all(fileUploadPromises);
}
