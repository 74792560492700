import { REPORT_ACTIONS } from "../../constants/ActionConstants";

const initialState = {
  userSubmissions: [],
  dailySubmissions: [],
  taskSubmissions: [],
  taskNames: []
};

export function report(state = initialState, action) {
  switch (action.type) {
    case REPORT_ACTIONS.GET_USER_SUBMISSION_REPORT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case REPORT_ACTIONS.GET_USER_SUBMISSION_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        userSubmissions: !action.payload.res
          ? state.userSubmissions
          : action.payload.res.result,
        selectedUserPeriod: action.payload.params.type
      };
    case REPORT_ACTIONS.GET_USER_SUBMISSION_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        selectedUserPeriod: action.payload.params.type
      };

    case REPORT_ACTIONS.GET_TASK_SUBMISSION_REPORT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case REPORT_ACTIONS.GET_TASK_SUBMISSION_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        taskSubmissions: !action.payload.res
          ? state.taskSubmissions
          : action.payload.res.result,
        selectedTaskPeriod: action.payload.params.type
      };
    case REPORT_ACTIONS.GET_TASK_SUBMISSION_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        selectedTaskPeriod: action.payload.params.type
      };

    case REPORT_ACTIONS.GET_DAILY_SUBMISSION_REPORT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case REPORT_ACTIONS.GET_DAILY_SUBMISSION_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        dailySubmissions: !action.payload.res
          ? state.dailySubmissions
          : action.payload.res.result,
        selectedDailyPeriod: action.payload.params.type
      };
    case REPORT_ACTIONS.GET_DAILY_SUBMISSION_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        selectedDailyPeriod: action.payload.params.type
      };


    /* Get Report */
    case REPORT_ACTIONS.GET_REPORT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case REPORT_ACTIONS.GET_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        reports: !!action.payload.res? action.payload.res.result: [],
      };
    case REPORT_ACTIONS.GET_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        reports: []
      };
    /* Get Report */
    case REPORT_ACTIONS.GET_TASK_NAMES_REPORT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        taskNames: action?.payload?.res?.result || []
      };
    case REPORT_ACTIONS.GET_TASK_NAMES_REPORT_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        taskNames: []
      };
    default:
      return state;
  }
}
