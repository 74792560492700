import { GENERIC_CONSTANTS } from "../constants/GenericConstants";
import {
  parseDateForDatePicker,
  showFirstXCharacters,
  parseDateTime24H,
  parseTime24H,
  parseDate,
  parseDateForServer,
  parseDateTime24Hours,
} from "./GeneralHelper";
import _ from "lodash";
import React from "react";
import { parseLocationForSelect } from "./TaskHelper";

export function parseReviewHistoryListingData(reviewListingData, ENUM = {}) {
  let parsedReviewList = [];
  const { REVIEW_HISTORY_STATUS, OCCURRENCE_FREQUENCY } = ENUM;
  if (!!reviewListingData) {
    for (var i = 0; i < reviewListingData.length; i++) {
      let reviewObject = {};
      const data = reviewListingData[i];
      
      let statusObj = _.find(REVIEW_HISTORY_STATUS, (obj) => obj.value === data.reviewHistoryStatusId) || {};
      // let isActive = data.isActive;
      // let statusIcon = isActive ? "activeIcon" : "inactiveIcon";

      reviewObject.reviewNumber = ( <div>{/* <i className={statusIcon}></i> */}{data.taskNumber || "N/A"}</div>);
      reviewObject.taskTitle = showFirstXCharacters(data.taskTitle, 30) || "N/A";
      reviewObject.type = data.reviewType || "N/A";
      reviewObject.workOrder = data.workOrder || "N/A";
      reviewObject.location = data.location || "N/A";
      reviewObject.reviews = data.reviews || "N/A";
      reviewObject.reviewedBy = data.reviewedBy || "N/A";
      reviewObject.reviewedOn = parseDateTime24Hours(data.reviewedOn) || "N/A";
      reviewObject.id = data.id;
      reviewObject.status = <div className={statusObj.label? statusObj.className : "status-null"}>{statusObj.label? statusObj.label : "N/A"}</div>; // Accepted, Rejected, N/A

      let occurrenceObj = _.find(OCCURRENCE_FREQUENCY, (obj) => obj.value === data.reviewOccurenceEnumId) || {label:"Adhoc"};
      reviewObject.occurrence = !!occurrenceObj && !!occurrenceObj.label && occurrenceObj.label;

      parsedReviewList.push(reviewObject);
    }
  }

  return parsedReviewList;
}

export function parseReviewHistorySubmissionListingData(reviewSubmissionListingData, ENUM = {}) {
  let parsedReviewSubmissionList = [];
  if (!!reviewSubmissionListingData) {
    for (var i = 0; i < reviewSubmissionListingData.length; i++) {
      let reviewSubmissionObject = {};
      const data = reviewSubmissionListingData[i];
      
      reviewSubmissionObject.formSubmissionMasterId = data.formSubmissionMasterId;
      reviewSubmissionObject.submittedBy = data.submittedBy || "N/A";
      reviewSubmissionObject.submittedOn = parseDate(data.submittedOn);
      reviewSubmissionObject.occurrence = data.occurence;
      reviewSubmissionObject.violations = data.violations;
      reviewSubmissionObject.id = data.id;

      parsedReviewSubmissionList.push(reviewSubmissionObject);
    }
  }

  return parsedReviewSubmissionList;
}

export function parseReviewHistorySubmitData(formValues, reviewId){
  let parsedReviewSubmitData = {};

  parsedReviewSubmitData.reviewId = reviewId;
  parsedReviewSubmitData.formSubmissionMasterIds = formValues.formSubmissionMasterIds || [];
  parsedReviewSubmitData.reviewSubmissionStatusId = formValues.reviewStatus || 0;
  parsedReviewSubmitData.signature = formValues.signature || "";

  return parsedReviewSubmitData;
}

export function parseReviewHistoryData(formValues, status, isEdit = null) {
  let parsedResponse = {};
  let programManualDetails = {};

  programManualDetails.creationDateTime = formValues.creationDateTime || "";
  programManualDetails.status = status || "";
  programManualDetails.description = formValues.revisionNote && formValues.revisionNote.replace(/(<([^>]+)>)/gi, "") || "";
  programManualDetails.title = formValues.title || "";
  programManualDetails.type = formValues.type || "";
  programManualDetails.mediaIds = formValues.mediaIds || [];
  programManualDetails.locationIds =
    (formValues.locationIds &&
      formValues.locationIds.map((location) => location.value)) ||
    [];

  parsedResponse.programNumber = formValues.programNumber || "";
  parsedResponse.programManualDetails = [programManualDetails || {}];

  if (isEdit) parsedResponse.id = isEdit;

  return parsedResponse;
}

export function parsedReviewHistoryFilters(filterParams) {
  let filters = {};
  let reviewTypeIds = filterParams.type;
  let workOrderIds = filterParams.workorders;
  let locationIds = filterParams.location;
  let reviewStatusIds = filterParams.status;
  let includeHistory = filterParams.includeHistory;
  let occurrenceIds = filterParams.occurrence;
  let program = filterParams.program;

  filters.startDueDate = !!filterParams.startDate ? parseDateForServer(filterParams.startDate) : null;
  filters.endDueDate = !!filterParams.endDate ? parseDateForServer(filterParams.endDate) : null;
  reviewTypeIds = _.map(reviewTypeIds, "value");
  workOrderIds = _.map(workOrderIds, "value");
  locationIds = _.map(locationIds, "value");
  reviewStatusIds = _.map(reviewStatusIds, "value");
  occurrenceIds = _.map(occurrenceIds, "value");
  program = _.map(program, "value");

  filters.reviewTypeIds = reviewTypeIds;
  filters.workOrderIds = workOrderIds;
  filters.locationIds = locationIds;
  filters.includeHistory = includeHistory;
  if(includeHistory){
    filters.reviewHistoryStatusIds = reviewStatusIds;
  } else {
    filters.reviewStatusIds = reviewStatusIds;
  }
  filters.occurenceIds = occurrenceIds;
  filters.DepartmentIds = program;

  return filters;
}

export function parsedReviewHistorySubmissionsFilters(filterParams) {
  let filters = {};
  // let reviewTypeIds = filterParams.status;
  // let workOrderIds = filterParams.workOrder;
  // let locationIds = filterParams.location;
  // let reviewStatusIds = filterParams.reviewStatus;
  let reviewIds = filterParams.reviewIds;

  // filters.startDate = !!filterParams.startDate ? parseDateForServer(filterParams.startDate) : null;
  // filters.endDate = !!filterParams.endDate ? parseDateForServer(filterParams.endDate) : null;
  // reviewTypeIds = _.map(reviewTypeIds, "value");
  // workOrderIds = _.map(workOrderIds, "value");
  // locationIds = _.map(locationIds, "value");
  // reviewStatusIds = _.map(reviewStatusIds, "value");
  // taskStatusIds = _.map(taskStatusIds, "value");

  // filters.reviewTypeIds = reviewTypeIds;
  // filters.workOrderIds = workOrderIds;
  // filters.locationIds = locationIds;
  // filters.reviewStatusIds = reviewStatusIds;
  filters.reviewIds = reviewIds;

  return filters;
}

export function parseReviewHistoryEditFormData(programManualData) {
  const parsedData = {};
  const programManualObject = programManualData || {};
  const programManualDetails =
    (programManualData.programManualDetails &&
      programManualData.programManualDetails[0]) ||
    {};

  parsedData.creationDateTime = !!programManualDetails.revisionDateTime
    ? parseDateForDatePicker(programManualDetails.revisionDateTime)
    : parseDateForDatePicker(programManualDetails.creationDateTime);
  parsedData.revisionNote = programManualDetails.description;
  parsedData.formFields = [];
  if (
    !!programManualData.formFields &&
    programManualData.formFields.length > 0
  ) {
    parsedData.formFields = programManualData.formFields.map((obj) => {
      let fieldsObject = {};
      fieldsObject.specification = obj.specification || "";
      fieldsObject.title = obj.title;
      return fieldsObject;
    });
  }

  parsedData.programNumber = programManualData.programNumber;
  parsedData.program = !!programManualObject ? programManualObject.id : "";
  parsedData.title = programManualDetails.title;
  parsedData.type = programManualDetails.type;
  parsedData.locationIds =
    programManualDetails.locations &&
    parseLocationForSelect(programManualDetails.locations);
  parsedData.media = programManualDetails.media && programManualDetails.media;
  return parsedData;
}

export function parseReviewHistoryViewFormData(data) {
  const reviewObject = {};

  reviewObject.taskNumber = data.taskNumber || "N/A";
  reviewObject.title = showFirstXCharacters(data.taskTitle, 30) || "N/A";
  reviewObject.type = data.reviewType || "N/A";
  reviewObject.workOrder = data.workOrder || "N/A";
  reviewObject.location = data.location || "N/A";
  reviewObject.submissions = data.submissions || "N/A";
  reviewObject.recordReviewed = data.recordReviewed || "N/A";
  reviewObject.dueDate = parseDate(data.dueDate);
  reviewObject.id = data.id;
  reviewObject.status = data.reviewStatus;

  return reviewObject;
}

export function parseReviewHistoryForSelect(programManuals) {
  let parsedProgramManuals = [];
  if (!!programManuals && programManuals.length > 0) {
      for (var i = 0; i < programManuals.length; i++) {
          parsedProgramManuals.push({
              value: programManuals[i].id,
              label: programManuals[i].programNumber, color: '#00B8D9'
          });
      }
  }
  return parsedProgramManuals;
}

export function getReviewHistoryFilterCount(filters) {
  let dateRangePresent = false;
  let filterCount = 0;

  _.each(filters, (obj, key) => {
    if (!!obj) {
      if (
        key === GENERIC_CONSTANTS.FIELD_NAME.START_DATE ||
        key === GENERIC_CONSTANTS.FIELD_NAME.END_DATE
      ) {
        dateRangePresent = true;
        filterCount += 1;
      } else if (obj.length > 0) {
        filterCount += 1;
      }
    }
  });
  if (dateRangePresent) {
    //subtracting 1 because using start date and end date as single filter
    filterCount -= 1;
  }
  return filterCount;
}
