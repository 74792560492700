import { API_CONSTANTS } from "../../constants/ApiConstants";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { apiClient } from "./ApiClient";
import { LocationFilterModel } from "../../models/Location";

function submitLocationData(locationData) {
  return apiClient()
    .post(API_CONSTANTS.API_ROUTES.ADD_LOCATION, JSON.stringify(locationData))
    .then(handleResponse)
    .catch(handleError);
}

function getFilteredLocations(
  paginationData,
  searchTerms,
  filterObject = {},
  sortObject = {}
) {
  const url = API_CONSTANTS.API_ROUTES.GET_FILTERED_LOCATION;
  const params = new LocationFilterModel(
    filterObject,
    sortObject,
    paginationData,
    { searchTerms }
  );

  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function getAllLocationTypes() {
  const payload = JSON.stringify({ name: "LocationType" });
  return apiClient()
    .post(API_CONSTANTS.API_ROUTES.GET_METADATA_VALUES_ENUMS, payload)
    .then(handleResponse)
    .catch(handleError);
}

function getLocations() {
  let url = API_CONSTANTS.API_ROUTES.GET_ALL_LOCATIONS;
  return apiClient().get(url).then(handleResponse).catch(handleError);
}

function getLocationsByDivisionId(divisionId) {
  let url = API_CONSTANTS.API_ROUTES.GET_FILTERED_LOCATION;
  let params = {};
  params.divisions = !!divisionId ? [divisionId] : [];
  params.pageNumber = 0; // pageNumber 0 -> returns all locations assocaited with division

  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function validateLocationId(locationId) {
  return apiClient()
    .get(
      `${API_CONSTANTS.API_ROUTES.DOES_EXIST}?tableName=Location&value=${locationId}`
    )
    .then(handleResponse)
    .catch(handleError);
}

function associateDivisionToLocations(data) {
  return apiClient()
    .put(API_CONSTANTS.API_ROUTES.ASSOCIATE_DIVISION, JSON.stringify(data))
    .then(handleResponse)
    .catch(handleError);
}

function getLocationDetails(id) {
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.GET_LOCATION_BY_ID + id)
    .then(handleResponse)
    .catch(handleError);
}

function changeState(id, isActive) {
  return apiClient()
    .put(API_CONSTANTS.API_ROUTES.CHANGE_LOCATION_STATE + "/" + id, {
      isActive: isActive,
    })
    .then(handleResponse)
    .catch(handleError);
}

function getFilterLocations(params) {
  const url = API_CONSTANTS.API_ROUTES.GET_FILTERED_LOCATION;
  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function editLocation(locationsData, id) {
  return apiClient()
    .put(
      API_CONSTANTS.API_ROUTES.ADD_LOCATION + "/" + id,
      JSON.stringify(locationsData)
    )
    .then(handleResponse)
    .catch(handleError);
}

function getAllLocations() {
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.GET_ALL_LOCATION)
    .then(handleResponse)
    .catch(handleError);
}

export const locationService = {
  getAllLocationTypes,
  getLocationsByDivisionId,
  validateLocationId,
  getLocationDetails,
  submitLocationData,
  editLocation,
  getFilteredLocations,
  changeState,
  associateDivisionToLocations,
  getFilterLocations,
  getLocations,
  getAllLocations,
};
