import {
  TASK_SUBMISSION_ACTIONS,
  DASHBOARD_ACTIONS,
} from "../../constants/ActionConstants";
import { GENERIC_CONSTANTS, LOCAL_STORAGE_KEYS } from "../../constants/GenericConstants";
import { TaskSubmissionService, taskService } from "../../sevices/api";
import {
  setSyncingScheduledTasks,
  setSyncingTaskHistory,
  setSyncProgressScheduledTasks,
  setSyncingAutoSavedAdhocTasks,
  setSyncProgressAutoSavedAdhocTasks,
} from "./AppActions";
import { request, success, failure } from "./index";

// mobile offline support - data manager
import ScheduledTaskManager from "../../database/dataManagers/ScheduledTaskManager";

// storage service
import StorageService from "../../sevices/storageService";

// localization service
import LocalizationService from "@ecodocs/common/src/sevices/localizationService";
import TaskHistoryManager from "../../database/dataManagers/TaskHistoryManager";
//import LOCALIZATION_CONSTANTS from "../../constants/AppConstants";
import { getDetectedPlatformLocalization } from "../../helpers/GeneralHelper";
const LOCALIZATION_CONSTANTS = getDetectedPlatformLocalization();


export function getTaskSubmissions(
  paginationData = {},
  searchTerms = "",
  filters = {},
  sortObject,
  locationId
) {
  return function (dispatch) {
    dispatch(
      request(TASK_SUBMISSION_ACTIONS.GET_ALL_TASK_SUBMISSIONS_REQUEST, {
        paginationData,
        searchTerms,
        filters,
        sortObject,
        locationId,
      })
    );
    TaskSubmissionService.getTaskSubmissions(
      paginationData,
      searchTerms,
      filters,
      sortObject,
      locationId
    ).then(
      (data) => {
        dispatch(
          success(
            TASK_SUBMISSION_ACTIONS.GET_ALL_TASK_SUBMISSIONS_SUCCESS,
            data
          )
        );
        dispatch(
          success(
            TASK_SUBMISSION_ACTIONS.GET_ALL_TASK_SUBMISSIONS_SUCCESS_MOBILE,
            { ...paginationData, ...data }
          )
        );
      },
      (error) => {
        dispatch(
          failure(
            TASK_SUBMISSION_ACTIONS.GET_ALL_TASK_SUBMISSIONS_FAILURE,
            error.toString()
          )
        );
      }
    );
  };
}

export function getDashboardData() {
  return async (dispatch) => {
    dispatch(
      success(DASHBOARD_ACTIONS.GET_DUE_TASK_SUCCESS, {
        dueToday: true,
        data: { result: await ScheduledTaskManager.getDueTasks(true) },
      })
    );
    dispatch(
      success(DASHBOARD_ACTIONS.GET_DUE_TASK_SUCCESS, {
        dueToday: false,
        data: { result: await ScheduledTaskManager.getDueTasks(false) },
      })
    );
  };
}

export function getScheduledTasks(isFirstRequest = false) {
  return async (dispatch, getStore) => {
    isFirstRequest &&
      dispatch(
        request(
          TASK_SUBMISSION_ACTIONS.GET_ALL_TASK_SUBMISSIONS_OFFLINE_REQUEST,
        )
      );
    dispatch(
      success(
        TASK_SUBMISSION_ACTIONS.GET_ALL_TASK_SUBMISSIONS_OFFLINE_SUCCESS,
        await ScheduledTaskManager.getTasks(getStore().taskSubmission.filters)
      )
    );
  };
}

export function syncScheduledTaskById(id) {
  return async (dispatch, getStore) => {
    const store = getStore();

    if (store && store.app && !!store.app.isConnected) {
      const response = await taskService.getTaskSubmissionDetailsForOffline(id);

      if (
        response &&
        response.result &&
        response.result.elements &&
        !!response.result.elements.length
      ) {
        await ScheduledTaskManager.saveTasks(response.result.elements);
      }
    }
  };
}

export function syncAutoSavedAdhocTasks() {
  return async (dispatch, getStore) => {
    const store = getStore();

    // If app is offline OR sync has already started, return
    if (
      store &&
      store.app &&
      (!store.app.isConnected || store.app.isSyncingAutoSavedAdhocTasks)
    ) {
      return;
    }

    try {
      const pageSize = 20;
      let lastRecordTimestamp =
        (await StorageService.instance.getLastSync(
          LOCAL_STORAGE_KEYS.LAST_SYNC_AUTO_SAVE_ADHOC_TASK
        )) || new Date(0).toISOString();
      const departmentIds =
        parseInt(store.user.currentUserData.role) ===
        GENERIC_CONSTANTS.USER_ROLES.QC_TECH
          ? [store.user.department]
          : [];

      dispatch(setSyncingAutoSavedAdhocTasks(true));
      for (let pageNumber = 1; ; pageNumber++) {
        let responseSize = 0;
        const response =
          await TaskSubmissionService.getTaskSubmissionsForOffline(
            { pageNumber, pageSize },
            lastRecordTimestamp,
            departmentIds,
            true
          );

        // If there is data, save it in database and update store and last sync date time
        if (
          response &&
          response.result &&
          response.result.elements &&
          !!response.result.elements.length
        ) {
          responseSize = response.result.elements.length;
          ScheduledTaskManager.saveTasks(response.result.elements);
          await StorageService.instance.setLastSync(
            LOCAL_STORAGE_KEYS.LAST_SYNC_AUTO_SAVE_ADHOC_TASK,
            response.result.elements[response.result.elements.length - 1]
              .updatedOn
          );
          dispatch(
            setSyncProgressAutoSavedAdhocTasks({
              totalTasks: response.result.totalElements,
              syncedTasks:
                pageSize * (pageNumber - 1) + response.result.elements.length,
            })
          );
        }

        // If response records are less than page size, this means all the data has been synced
        if (responseSize < pageSize) {
          dispatch(setSyncProgressAutoSavedAdhocTasks({}));
          break;
        }
      }
    } catch (error) {
      console.log("syncAutoSavedAdhocTasks error", error);
      dispatch(
        failure(
          TASK_SUBMISSION_ACTIONS.GET_ALL_TASK_SUBMISSIONS_ADHOC_AUTOSAVE_OFFLINE_FAILURE,
          error
        )
      );
    } finally {
      dispatch(setSyncingAutoSavedAdhocTasks(false));
      dispatch(getScheduledTasks());
      return true;
    }
  };
}

export function syncScheduledTasks() {
  return async (dispatch, getStore) => {
    const store = getStore();

    // If app is offline OR sync has already started, return
    if (
      store &&
      store.app &&
      (!store.app.isConnected || store.app.isSyncingScheduledTasks)
    ) {
      return;
    }

    try {
      const pageSize = 20;
      let lastRecordTimestamp =
        (await StorageService.instance.getLastSync(
          LOCAL_STORAGE_KEYS.LAST_SYNC_SCHEDULED_TASK
        )) || new Date(0).toISOString();


      dispatch(setSyncingScheduledTasks(true));
      for (let pageNumber = 1; ; pageNumber++) {
        let responseSize = 0;
        const response =
          await TaskSubmissionService.getTaskSubmissionsForOffline(
            { pageNumber, pageSize },
            lastRecordTimestamp,
          );

        // If there is data, save it in database and update store and last sync date time
        if (
          response &&
          response.result &&
          response.result.elements &&
          !!response.result.elements.length
        ) {
          responseSize = response.result.elements.length;
          ScheduledTaskManager.saveTasks(response.result.elements);
          await StorageService.instance.setLastSync(
            LOCAL_STORAGE_KEYS.LAST_SYNC_SCHEDULED_TASK,
            response.result.elements[response.result.elements.length - 1]
              .updatedOn
          );
          dispatch(
            setSyncProgressScheduledTasks({
              totalTasks: response.result.totalElements,
              syncedTasks:
                pageSize * (pageNumber - 1) + response.result.elements.length,
            })
          );
        }

        // If response records are less than page size, this means all the data has been synced
        if (responseSize < pageSize) {
          dispatch(setSyncProgressScheduledTasks({}));
          dispatch(syncAutoSavedAdhocTasks());
          break;          
        }
      }
    } catch (error) {
      console.log("syncScheduledTasks error", error);
      dispatch(
        failure(
          TASK_SUBMISSION_ACTIONS.GET_ALL_TASK_SUBMISSIONS_OFFLINE_FAILURE,
          error
        )
      );
    } finally {
      dispatch(setSyncingScheduledTasks(false));
      return true;
    }
  };
}

export function getTaskHistory(
  paginationData = {},
  searchTerms = "",
  filters = {},
  sortObject,
  locationId
) {
  return async (dispatch, getStore) => {
    const store = getStore();

    if (store && store.app && !!store.app.isConnected) {
      getTaskDesignerSubmissions(
        paginationData,
        searchTerms,
        filters,
        sortObject,
        locationId
      )(dispatch);
    } else {
      // If offline , update the store with last three submissions records saved in local database.
      dispatch(
        request(TASK_SUBMISSION_ACTIONS.GET_TASK_HISTORY_OFFLINE_REQUEST)
      );
      dispatch(
        success(
          TASK_SUBMISSION_ACTIONS.GET_TASK_HISTORY_OFFLINE_SUCCESS,
          await TaskHistoryManager.getTasks()
        )
      );
    }
  };
}

export function syncTaskHistory() {
  return async (dispatch, getStore) => {
    const store = getStore();
    const pageSize = 10;
    try {
      let lastRecordTimestamp =
        (await StorageService.instance.getLastSync(
          LOCAL_STORAGE_KEYS.LAST_SYNC_TASK_HISTORY
        )) || new Date(0).toISOString();

      // If online get data from server and update the store
      if (store && store.app && !!store.app.isConnected) {
        // Only sync data when app is online and sync has not already started
        if (!store.app.isSyncingTaskHistory) {
          dispatch(setSyncingTaskHistory(true));
          for (let pageNumber = 1; ; pageNumber++) {
            let responseSize = 0;
            const response = await TaskSubmissionService.getTaskHistoryForOffline(
              { pageNumber, pageSize },
              lastRecordTimestamp
            );

            // If there is data, save it in database
            if (
              response &&
              response.result &&
              response.result.elements &&
              !!response.result.elements.length
            ) {
              responseSize = response.result.elements.length;
              // await window.RNFetchBlob.session(MOBILE_IMAGE_SESSION).dispose(); // empty all images from cache
              for (const task of response.result.elements) {
                const { formSubmission = [] } = task;
                for (field of formSubmission) {
                  for (formSubmissionMedia of field.formSubmissionMedias) {
                    if (!!formSubmissionMedia.media) {
                      try {
                        let url = formSubmissionMedia.media.relativePath;
                        if (url) {
                          url = url.split("\\").join("/");
                          const image = await taskService.downloadImagesMobile(
                            url,
                            task.submissionMasterId
                          );
                          formSubmissionMedia.media.relativePath = image.path();
                        }
                      } catch {
                        // This is deliberately left empty so that if one of the
                        // image download fails due to any reason, rest of them
                        // can continue download
                      }
                    }
                  }
                }
              }

              TaskHistoryManager.saveTasks(response.result.elements);
              await StorageService.instance.setLastSync(
                LOCAL_STORAGE_KEYS.LAST_SYNC_SCHEDULED_TASK,
                response.result.elements[response.result.elements.length - 1]
                  .updatedOn
              );
            }

            // If response records are less than page size, this means all the data has been synced
            if (responseSize < pageSize) {
              break;
            }
          }
        }
      }
    } catch (error) {
      console.log("syncTaskHistory error", error);
      dispatch(
        success(TASK_SUBMISSION_ACTIONS.GET_TASK_HISTORY_OFFLINE_FAILURE, error)
      );
    } finally {
      dispatch(setSyncingTaskHistory(false));
      return true;
    }
  };
}

export function handleUpsertTaskSubmissionMessages(messageObject) {
  return function (dispatch) {
    dispatch(
      request(
        TASK_SUBMISSION_ACTIONS.HANDLE_ADD_TASK_SUBMISSION_MESSAGE,
        messageObject
      )
    );
  };
}

export function upsertTaskSubmissonFilters(filterParams) {
  return function (dispatch) {
    dispatch(
      request(
        TASK_SUBMISSION_ACTIONS.UPSERT_TASK_SUBMISSION_LIST_FILTERS,
        filterParams
      )
    );
  };
}

export function updateTaskSubmissonFilters(filterParams) {
  return function (dispatch) {
    dispatch(
      request(
        TASK_SUBMISSION_ACTIONS.UPDATE_TASK_SUBMISSION_LIST_FILTERS,
        filterParams
      )
    );
  };
}

export function removeTaskSubmissionFilters() {
  return function (dispatch) {
    dispatch(
      request(TASK_SUBMISSION_ACTIONS.REMOVE_TASK_LIST_SUBMISSION_FILTERS)
    );
  };
}

export function getIsPendingSync() {
  return async function (dispatch) {
    dispatch(
      success(
        TASK_SUBMISSION_ACTIONS.GET_IS_PENDING_SYNC,
        !!(await TaskHistoryManager.getPendingSyncTasks()).length
      )
    );
  };
}

export function getTaskDesignerSubmissions(
  paginationData,
  searchTerms,
  filters,
  sortObject,
  locationId,
  taskId = null,
) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(
        request(TASK_SUBMISSION_ACTIONS.GET_ALL_TASK_SUBMISSIONS_REQUEST, {
          paginationData,
          searchTerms,
          filters,
          sortObject,
          locationId,
        }),
      );

      if (process.env.REACT_APP_PLATFORM !== "WEB") {
        TaskSubmissionService.getTaskHistoryForOfflineWithFilter(
          paginationData,
          searchTerms,
          filters,
          sortObject,
          locationId,
          taskId,
        ).then(
          async (data) => {
            if (paginationData.pageNumber === 1) {
              dispatch(
                success(
                  TASK_SUBMISSION_ACTIONS.GET_PENDING_SYNC_TASK,
                  await TaskHistoryManager.getPendingSyncTasks(),
                ),
              );
            }
            dispatch(
              success(
                TASK_SUBMISSION_ACTIONS.GET_ALL_TASK_SUBMISSIONS_SUCCESS_MOBILE,
                { ...paginationData, ...data },
              ),
            );
          },
          (error) => {
            dispatch(
              failure(
                TASK_SUBMISSION_ACTIONS.GET_ALL_TASK_SUBMISSIONS_FAILURE,
                error.toString(),
              ),
            );
          },
        );
      } else {
        TaskSubmissionService.getTaskDesignerSubmissions(
          paginationData,
          searchTerms,
          filters,
          sortObject,
          locationId,
          taskId,
        ).then(
          async (data) => {
            dispatch(
              success(
                TASK_SUBMISSION_ACTIONS.GET_ALL_TASK_SUBMISSIONS_SUCCESS,
                data,
              ),
            );
            resolve(data);
          },
          (error) => {
            dispatch(
              failure(
                TASK_SUBMISSION_ACTIONS.GET_ALL_TASK_SUBMISSIONS_FAILURE,
                error.toString(),
              ),
            );
            reject(error.toString())
          },
        );
      }
    });
  };
}

export function changeFilters(filters) {
  return { type: TASK_SUBMISSION_ACTIONS.CHANGE_FILTERS, payload: filters };
}

export function clearSubmissions() {
  return { type: TASK_SUBMISSION_ACTIONS.CLEAR_SUBMISSIONS };
}
