import { ENUM_ACTIONS } from "../../constants/ActionConstants";
import { API_CONSTANTS } from "../../constants/ApiConstants";

export const initialState = {
  ENUM: {
    DYNAMIC_FIELD_TYPES_ARRAY: [
      {
        text: "Short Text",
        label: "Short Text",
        value: 1
      },
      {
        text: "Single Select",
        label: "Single Select",
        value: 2
      },
      {
        text: "Formula",
        label: "Formula",
        value: 3
      },
      {
        text: "Long Text",
        label: "Long Text",
        value: 4
      },
      {
        text: "Time",
        label: "Time",
        value: 5
      },
      /*{
        text: "Temperature",
        label: "Temperature",
        value: 6
      },*/
      {
        text: "Yes/No",
        label: "Yes/No",
        value: 7
      },
      {
        text: "Number",
        label: "Number",
        value: 8
      },
      {
        text: "Multi Select",
        label: "Multi Select",
        value: 9
      },
      /*{
        text: "Decimal",
        label: "Decimal",
        value: 10
      },*/
      {
        text: "Date",
        label: "Date",
        value: 11
      },
      // {
      //   text: "Radio",
      //   label: "Radio",
      //   value: 12
      // },
      {
        text: "Image Upload",
        label: "Image Upload",
        value: 13
      }
    ],
    SKIP_TASK_REASONS:[
      {
        id: 1,
        name: "Breakdown"
      },
      {
        id: 2,
        name: "Department Not Operating"
      },
      {
        id: 3,
        name: "Finished Early"
      },
      {
        id: 4,
        name: "Late Start"
      },
      {
        id: 5,
        name: "Plant Closure"
      },
      {
        id: 6,
        name: "Other"
      },
    ],
    TASK_TYPES: [
      {
        text: "Adhoc",
        label: "Adhoc",
        value: 1
      },
      {
        text: "Scheduled",
        label: "Scheduled",
        value: 2
      },
      // {
      //   text: "Corrective Action",
      //   label: "Corrective Action",
      //   value: 3
      // }
    ],
    TASK_PRIORITY: [
      {
        text: "High",
        label: "High",
        value: 1
      },
      {
        text: "Medium",
        label: "Medium",
        value: 2
      },
      {
        text: "Low",
        label: "Low",
        value: 3
      },
    ],
    PROGRAM_TYPES: [
      {
        value: 1,
        label: "SOP",
        text: "SOP",
        color: "#00B8D9"
      },
      {
        value: 2,
        label: "SSOP",
        text: "SSOP",
        color: "#00B8D9"
      },
      {
        value: 3,
        label: "HACCP",
        text: "HACCP",
        color: "#00B8D9"
      },
    ],
    SCHEDULER_MONTHS: [
      {
        label: "January",
        value: 1
      },
      {
        label: "February",
        value: 2
      },
      {
        label: "March",
        value: 3
      },
      {
        label: "April",
        value: 4
      },
      {
        label: "May",
        value: 5
      },
      {
        label: "June",
        value: 6
      },
      {
        label: "July",
        value: 7
      },
      {
        label: "August",
        value: 8
      },
      {
        label: "September",
        value: 9
      },
      {
        label: "October",
        value: 10
      },
      {
        label: "November",
        value: 11
      },
      {
        label: "December",
        value: 12
      }
    ],
    SCHEDULER_WEEK: [
      {
        label: "1st",
        value: 1
      },
      {
        label: "2nd",
        value: 2
      },
      {
        label: "3rd",
        value: 3
      },
      {
        label: "4th",
        value: 4
      },
      {
        label: "5th",
        value: 5
      },
    ],
    SCHEDULER_DAY: [
      {
        label: "Monday",
        value: 1
      },
      {
        label: "Tuesday",
        value: 2
      },
      {
        label: "Wednesday",
        value: 3
      },
      {
        label: "Thursday",
        value: 4
      },
      {
        label: "Friday",
        value: 5
      },
      {
        label: "Saturday",
        value: 6
      },
      {
        label: "Sunday",
        value: 7
      },
    ],
    OCCURRENCE_FREQUENCY:[
      {
        label: "Adhoc",
        value: 1
      },
      {
        label: "Daily",
        value: 2
      },
    ],
    SCHEDULER_FREQUENCY: [
      {
        label: "Does Not Repeat",
        value: 1
      },
      {
        label: "Daily",
        value: 2
      },
      {
        label: "Weekly",
        value: 3
      },
      {
        label: "Monthly",
        value: 4
      },
      {
        label: "Yearly",
        value: 5
      },
    ],
    SCHEDULER_PRIORITY: [
      {
          value: "1",
          name: "High",
          icon: "ecoIcon-arrow-up",
          class: "option-high"
      },
      {
          value: "2",
          name: "Medium",
          icon: "ecoIcon-arrow-up",
          class: "option-medium"
      },
      {
          value: "3",
          name: "Low",
          icon: "ecoIcon-arrow-up",
          class: "option-low"
      }
    ],
    FREQUENCY_CALENDAR: [
      {
        text: "Adhoc Only",
        label: "Adhoc Only",
        value: -1
      },
      {
        text: "Daily",
        label: "Daily",
        value: 1
      },
      {
        text: "Weekly",
        label: "Weekly",
        value: 2
      },
      {
        text: "Monthly",
        label: "Monthly",
        value: 3
      },
      {
        text: "Quarterly",
        label: "Quarterly",
        value: 4
      },
      {
        text: "Yearly",
        label: "Yearly",
        value: 5
      }
    ],
    STATUS: [
      {
        value: 1,
        label: "Published",
        text: "Published",
        color: "#00B8D9"
      },
      {
        value: 2,
        label: "Draft",
        text: "Draft",
        color: "#00B8D9"
      }
    ],
    REPORTS_NAME: [
      // {
      //   value: 1,
      //   // label: "Daily Submission Summary",
      //   label: "Violation Report",
      //   id: API_CONSTANTS.API_ROUTES.GET_DAILY_SUBMISSION_REPORT
      // },
      // {
      //   value: 2,
      //   // label: "SI_TASK Submission Summary",
      //   label: "SI_TASK History Summary",
      //   id: API_CONSTANTS.API_ROUTES.GET_TASK_SUBMISSION_REPORT
      // },
      // {
      //   value: 3,
      //   // label: "User Submission Summary",
      //   label: "Workers' SI_TASK Completion Summary",
      //   id: API_CONSTANTS.API_ROUTES.GET_USER_SUBMISSION_REPORT
      // },
      // {
      //   value: 4,
      //   label: "SI_TASK Completion Report",
      //   id: API_CONSTANTS.API_ROUTES.GET_TASK_SUBMISSION_REPORT_BY_ID
      // },
      // {
      //   value: 5,
      //   label: "Worker Performance Report",
      //   id: API_CONSTANTS.API_ROUTES.WORKER_PERFORMANCE_REPORT
      // },
      // {
      //   value: 1,
      //   label: "Review Completion Report",
      //   id: API_CONSTANTS.API_ROUTES.GET_TASK_SUBMISSION_REPORT_BY_ID
      // },
      {
        value: 2,
        label: "SI_TASK Completion Report",
        id: API_CONSTANTS.API_ROUTES.GET_TASK_SUBMISSION_REPORT_BY_ID
      },
      {
        value: 3,
        label: "SI_TASK Completion Summary",
        id: API_CONSTANTS.API_ROUTES.GET_TASK_SUBMISSION_REPORT
      },
      // {
      //   value: 4,
      //   label: "SI_TASK Violation Report",
      //   id: API_CONSTANTS.API_ROUTES.GET_TASK_SUBMISSION_REPORT_BY_ID
      // },
      {
        value: 5,
        label: "User Performance Summary",
        id: API_CONSTANTS.API_ROUTES.WORKER_PERFORMANCE_REPORT
      },
    ],
    SUBMISSION_STATUS: [
      {
        value: 1,
        label: "Open",
        text: "Open",
      },
      {
        value: 2,
        label: "Completed",
        text: "Completed",
      },
      {
        value: 3,
        label: "Missed",
        text: "Missed",
      },
      {
        value: 4,
        label: "Pending Approval",
        text: "Pending Approval",
      },
      {
        value: 5,
        label: "Rejected",
        text: "Rejected",
      },
      {
        value: 6,
        label: "Accepted",
        text: "Accepted",
      },
      // {
      //   value: 7,
      //   label: "Not Performed",
      //   text: "Not Performed",
      // },
    ],
    STATE: [
      {
        value: true,
        label: "Active",
        text: "Active",
        color: "#00B8D9"
      },
      {
        value: false,
        label: "Inactive",
        text: "Inactive",
        color: "#00B8D9"
      }
    ],
    TASK_STATUS: [
      {
        value: 2,
        label: "Completed",
        text: "Completed",
        color: "#00B8D9"
      },
      {
        value: 4,
        label: "Pending",
        text: "Pending",
        color: "#00B8D9"
      },
    ],
    REVIEW_STATUS: [
      {
        value: 1,
        label: "Pending",
        text: "Pending",
        color: "#00B8D9"
      },
      {
        value: 2,
        label: "Approved",
        text: "Approved",
        color: "#00B8D9"
      },
      {
        value: 0,
        label: "N/A",
        text: "N/A",
        color: "#00B8D9"
      }
    ],
    REVIEW_VIOLATION:[
      {
        value: true,
        label: "Yes",
        text: "Yes",
        color: "#00B8D9"
      },
      {
        value: false,
        label: "No",
        text: "No",
        color: "#00B8D9"
      }
    ],
    DUE_STATUS: [
      {
        value: 1,
        label: "Due Today",
        text: "Due Today",
      },
      {
        value: 2,
        label: "Due Soon",
        text: "Due Soon",
      },
    ],
    APPROVAL_REQUIRED: [
      {
        value: true,
        label: "Yes",
        text: "Yes",
        color: "#00B8D9"
      },
      {
        value: false,
        label: "No",
        text: "No",
        color: "#00B8D9"
      }
    ],
    USER_STATUS: [
      {
        value: false,
        label: "Inactive",
        text: "Inactive",
        color: "#00B8D9"
      },
      {
        value: true,
        label: "Active",
        text: "Active",
        color: "#00B8D9"
      }
    ],
    USER_TYPE: [
      {
        value: 1,
        label: "Named User",
        text: "Named User",
        color: "#00B8D9"
      },
      {
        value: 2,
        label: "Active Directory",
        text: "Active Directory",
        color: "#00B8D9"
      }
    ],
    DASBOARD_FREQUENCY: [
      {
          value: 1,
          label: 'Today',
          text: 'Today',
      },
      {
          value: 2,
          label: 'Weekly',
          text: 'Weekly',
      },
      {
          value: 3,
          label: 'Monthly',
          text: 'Monthly',
      },
      {
          value: 4,
          label: 'Quarterly',
          text: 'Quarterly',
      },
      {
          value: 5,
          label: 'Half Yearly',
          text: 'Half Yearly',
      },
      {
          value: 6,
          label: 'Yearly',
          text: 'Yearly',
      },
      {
          value: 7,
          label: 'Date Range',
          text: 'Date Range',
      },
    ],
    REPORT_FREQUENCY: [
      {
          value: 1,
          label: 'Today',
          text: 'Today',
      },
      {
          value: 2,
          label: 'Weekly',
          text: 'Weekly',
      },
      {
          value: 3,
          label: 'Monthly',
          text: 'Monthly',
      },
      {
          value: 4,
          label: 'Yearly',
          text: 'Yearly',
      },
      {
          value: 5,
          label: 'Custom',
          text: 'Custom',
      }
    ],
    USER_ROLE: [
      {
        value: 1,
        label: "Manager",
        text: "Manager",
        color: "#00B8D9"
      },
      {
        value: 2,
        label: "Worker",
        text: "Worker",
        color: "#00B8D9"
      },
      // {
      //   value: 3,
      //   label: "View Only",
      //   text: "ViewOnly",
      //   color: "#00B8D9"
      // },
      // {
      //   value: 4,
      //   label: "Manager",
      //   text: "Manager",
      //   color: "#00B8D9"
      // },
      // {
      //   value: 5,
      //   label: "QC Tech",
      //   text: "QCTech",
      //   color: "#00B8D9"
      // },
      // {
      //   value: 6,
      //   label: "USDA Inspector",
      //   text: "USDAInspector",
      //   color: "#00B8D9"
      // },
      // {
      //   value: 7,
      //   label: "Super Admin",
      //   text: "SuperAdmin",
      //   color: "#00B8D9"
      // },
      // {
      //   value: 8,
      //   label: "Review Only",
      //   text: "ReviewOnly",
      //   color: "#00B8D9"
      // }
    ],
    FIELD_OPERATORS: {
      "1": [
        {
          value: 1,
          label: "Equals",
          text: "Equals",
          color: "#00B8D9"
        },
        {
          value: 2,
          label: "Not Equals",
          text: "Not Equals",
          color: "#00B8D9"
        }
      ]
    },
    SEARCH_OPERATORS: [
      {
        value: 1,
        label: "AND",
        text: "AND",
        color: "#00B8D9"
      },
      {
        value: 2,
        label: "OR",
        text: "OR",
        color: "#00B8D9"
      }
    ],
    OPERATOR_TYPE: [
      {
        text: "AND",
        value: 1
      },
      {
        text: "OR",
        value: 2
      }
    ],
    CONDITION_TYPE: [
      {
        text: "LessThan",
        value: 1
      },
      {
        text: "GreaterThan",
        value: 2
      },
      {
        text: "LessThanOrEqualTo",
        value: 3
      },
      {
        text: "GreaterThanOrEqualTo",
        value: 4
      },
      {
        text: "Equals",
        value: 5
      },
      {
        text: "NotEqualTo",
        value: 6
      },
      {
        text: "Contains",
        value: 7
      },
      {
        text: "StartsWith",
        value: 8
      },
      {
        text: "EndsWith",
        value: 9
      }
    ],
    PROGRAM_MANUAL_TYPES: [
      {
        label: "SOP",
        value: 1
      },
      {
        label: "SSOP",
        value: 2
      },
      {
        label: "HACCP",
        value: 3
      },
      {
        label: "SPS",
        value: 4
      },
      {
        label: "Other",
        value: 5
      },
    ],
    PROGRAM_MANUAL_STATUS: [
      {
        value: 1,
        label: "Published",
        text: "Published",
        color: "#00B8D9"
      },
      {
        value: 2,
        label: "Draft",
        text: "Draft",
        color: "#00B8D9"
      },
      {
        value: 3,
        label: "Active",
        text: "Active",
        color: "#00B8D9"
      },
      {
        value: 4,
        label: "Inactive",
        text: "Inactive",
        color: "#00B8D9"
      },
      {
        value: 5,
        label: "Archived",
        text: "Archived",
        color: "#00B8D9"
      },
    ],
    REVIEWS_STATUS: [
      {
        value: 1,
        className: "pending",
        label: "Pending",
        text: "Pending",
        color: "rgb(244, 182, 30)"
      },
      {
        value: 2,
        className: "partial-complete",
        label: "Partially Completed",
        text: "Partially Completed",
        color: "rgb(53, 127, 247)"
      },
      {
        value: 3,
        className: "status-accepted",
        label: "Completed",
        text: "Completed",
        color: "#000"
      },
    ],
    REVIEWS_TYPES: [
      {
        value: 1,
        label: "Record Review",
        text: "Record Review",
        color: "#00B8D9"
      },
      {
        value: 2,
        label: "Direct Observation",
        text: "Direct Observation",
        color: "#00B8D9"
      },
      {
        value: 3,
        label: "Pre-Shipment",
        text: "Pre-Shipment",
        color: "#00B8D9"
      },
    ],
    REVIEW_APPROVAL_STATUS: [
      {
        value: 1,
        label: "Accepted",
        text: "Accepted",
        color: "rgb(93, 193, 25)"
      },
      {
        value: 2,
        label: "Rejected",
        text: "Rejected",
        color: "rgb(229, 86, 86)"
      },
      {
        value: 3,
        label: "Not Applicable",
        text: "Not Applicable",
        color: "#000"
      },
    ],
    REVIEW_HISTORY_STATUS: [
      {
        value: 1,
        className: "status-accepted",
        label: "Accepted",
        text: "Accept",
        color: "#00B8D9"
      },
      {
        value: 2,
        className: "status-rejected",
        label: "Rejected",
        text: "Reject",
        color: "#00B8D9"
      },
      {
        value: 3,
        className: "status-null",
        label: "Not Applicable",
        text: "N/A",
        color: "#00B8D9"
      },
    ],
  }
};

export function enums(state = initialState, action) {
  switch (action.type) {
    case ENUM_ACTIONS.GET_ENUMS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case ENUM_ACTIONS.GET_ENUMS_SUCCESS:
      return {
        ...state,
        loading: false,
        ENUM: !!action.payload.result ? action.payload.result : {}
      };

    case ENUM_ACTIONS.GET_ENUMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case ENUM_ACTIONS.GET_METADATA_VALUES_REQUEST:
      return {
        ...state,
        loading: true
      };

    case ENUM_ACTIONS.GET_METADATA_VALUES_SUCCESS:
      const key = action.payload.meta;
      return {
        ...state,
        loading: false,
        [key]: !!action.payload.data.result ? action.payload.data.result : []
      };

    case ENUM_ACTIONS.GET_METADATA_VALUES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
}
