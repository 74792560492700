import { REVIEW_ACTIONS } from "../../constants/ActionConstants";
import { getReviewFilterCount } from "@ecodocs/common/src/helpers/ReviewHelper";

const initialState = {
  reviews: [],
  getReviewsLoading: false,
  showMessage: false,
};

export function review(state = initialState, action) {
  switch (action.type) {
    case REVIEW_ACTIONS.GET_REVIEWS_REQUEST:
      return {
        ...state,
        getReviewsLoading: true,
        getReviewsListingError: false,
      };

    case REVIEW_ACTIONS.GET_REVIEWS_SUCCESS:
      return {
        ...state,
        getReviewsLoading: false,
        reviews: !!action.payload.result ? action.payload.result.elements : [],
        //reviews: [],
        totalElements: !!action.payload.result
          ? action.payload.result.totalElements
          : 0,
        getReviewsListingError: false,
      };

    case REVIEW_ACTIONS.GET_REVIEWS_FAILURE:
      return {
        ...state,
        getReviewsLoading: false,
        getReviewsListingError: true,
        error: action.payload,
      };

    // Review Submissions
    case REVIEW_ACTIONS.GET_REVIEW_SUBMISSIONS_REQUEST:
      return {
        ...state,
        getReviewSubmissionsLoading: true,
        getReviewSubmissionsListingError: false,
      };

    case REVIEW_ACTIONS.GET_REVIEW_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        getReviewSubmissionsLoading: false,
        reviewSubmissions: !!action.payload.result
          ? action.payload.result.elements
          : [],
        submissionPending: !!action.payload.result
          ? action.payload.result.submissionPending
          : [],
        submissionMissed: !!action.payload.result
          ? action.payload.result.missedPending
          : [],
        submissionNotPerformed: !!action.payload.result
          ? action.payload.result.notPerformedPending
          : [],
        totalSubmissionElements: !!action.payload.result
          ? action.payload.result.totalElements
          : 0,
        getReviewSubmissionsListingError: false,
      };

    case REVIEW_ACTIONS.GET_REVIEW_SUBMISSIONS_FAILURE:
      return {
        ...state,
        getReviewSubmissionsLoading: false,
        getReviewSubmissionsListingError: true,
        error: action.payload,
      };

    case REVIEW_ACTIONS.EMPTY_REVIEWS_LIST:
      return {
        ...state,
        loading: false,
        reviews: [],
        totalElements: 0,
      };

    case REVIEW_ACTIONS.ENABLE_REVIEWS_SELECT_LIST:
      return {
        ...state,
        reviewEnabled: action.payload,
      };

    case REVIEW_ACTIONS.SUBMIT_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case REVIEW_ACTIONS.HANDLE_SUBMIT_REVIEW_SUCCESS:
      return {
        ...state,
        reviewMessageData: action.payload,
        showMessage: true,
        loading: false,
        checkedRows: [],
      };
    case REVIEW_ACTIONS.SUBMIT_REVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        reviewError: action.payload,
      };

    case REVIEW_ACTIONS.SUBMIT_REVIEW_SUCCESS:
      let success = false;
      let reviewError = null;
      if (
        action.payload.result &&
        action.payload.responseCode === 200 &&
        action.payload.message === "Success"
      ) {
        success = true;
      } else {
        success = false;
        // getting different response from server that is why handling it
        if (action.payload.result && !!action.payload.result.message) {
          reviewError = action.payload.result.message;
        }
      }
      return {
        ...state,
        loading: false,
        response: action.payload,
        success: success,
        //  showMessage: !!success ? true : false,
        reviewError: reviewError,
      };

    case REVIEW_ACTIONS.HANDLE_ADD_REVIEW_MESSAGE:
      return {
        ...state,
        reviewMessageData: action.payload,
        showMessage: action.payload.showMessage,
      };

    case REVIEW_ACTIONS.GET_REVIEW_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case REVIEW_ACTIONS.GET_REVIEW_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        reviewDetails: action.payload.result,
        recordNotFound: !action.payload.result,
      };

    case REVIEW_ACTIONS.GET_REVIEW_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case REVIEW_ACTIONS.GET_TASK_DETAILS_WITH_SUBMISSIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case REVIEW_ACTIONS.GET_TASK_DETAILS_WITH_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        taskList: action.payload.result,
        recordNotFound: !action.payload.result,
      };

    case REVIEW_ACTIONS.GET_TASK_DETAILS_WITH_SUBMISSIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case REVIEW_ACTIONS.UPSERT_REVIEW_LIST_FILTERS:
      return {
        ...state,
        reviewFilter: {
          filterCount: getReviewFilterCount(action.payload),
          reviewFilterValues: action.payload,
        },
      };

    case REVIEW_ACTIONS.REMOVE_REVIEW_LIST_FILTERS:
      return {
        ...state,
        reviewFilter: { filterCount: 0, reviewFilterValues: {} },
      };

    case REVIEW_ACTIONS.UPSERT_REVIEW_SUBMISSION_LIST_FILTERS:
      return {
        ...state,
        reviewSubmissionFilter: {
          filterCount: getReviewFilterCount(action.payload),
          reviewFilterValues: action.payload,
        },
      };

    case REVIEW_ACTIONS.REMOVE_REVIEW_SUBMISSION_LIST_FILTERS:
      return {
        ...state,
        reviewSubmissionFilter: { filterCount: 0, reviewFilterValues: {} },
      };

    case REVIEW_ACTIONS.CHANGE_REVIEW_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case REVIEW_ACTIONS.CHANGE_REVIEW_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        reviewMessageData: action.payload.message,
        showMessage: true,
      };

    case REVIEW_ACTIONS.CHANGE_REVIEW_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case REVIEW_ACTIONS.HANDLE_EDIT_REVIEW_MESSAGE:
      return {
        ...state,
        reviewMessageData: action.payload.message,
        showMessage: true,
      };

    case REVIEW_ACTIONS.ASSOCIATE_DIVISION_TO_REVIEWS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REVIEW_ACTIONS.HANDLE_ASSOCIATE_DIVISION_TO_REVIEWS_SUCCESS:
      return {
        ...state,
        reviewMessageData: action.payload,
        showMessage: true,
        loading: false,
        checkedRows: [],
      };
    case REVIEW_ACTIONS.ASSOCIATE_DIVISION_TO_REVIEWS_SUCCESS:
      success = false;
      if (
        action.payload.result &&
        action.payload.responseCode === 200 &&
        action.payload.message === "Success"
      ) {
        success = true;
      }
      return {
        ...state,
        loading: false,
        response: action.payload,
        success: success,
        checkedRows: [],
      };

    case REVIEW_ACTIONS.ASSOCIATE_DIVISION_TO_REVIEWS_FAILURE:
      return {
        ...state,
        loading: false,
        reviewError: action.payload,
      };

    /**
     * TABLE ACTIONS
     */

    case REVIEW_ACTIONS.ON_SORT_REVIEWS_COLUMN_REQUEST:
      return {
        ...state,
        getAllReviewsOnSortingLoading: true,
      };

    case REVIEW_ACTIONS.ON_SORT_REVIEWS_COLUMN_SUCCESS:
      return {
        ...state,
        getAllReviewsOnSortingLoading: false,
        reviews: !!action.payload.result ? action.payload.result.elements : [],
        totalElements: !!action.payload.result
          ? action.payload.result.totalElements
          : 0,
      };

    case REVIEW_ACTIONS.ON_SORT_REVIEWS_COLUMN_FAILURE:
      return {
        ...state,
        getAllReviewsOnSortingLoading: false,
        error: action.payload,
      };

    //EO | TABLE ACTIONS

    //Reset review detail from redux
    case REVIEW_ACTIONS.RESET_REVIEW_DETAILS:
      return {
        ...state,
        reviewDetails: null,
        reviewSubmissions: []
      };

    default:
      return state;
  }
}
