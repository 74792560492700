import { LOCAL_STORAGE_KEYS } from "../../constants/GenericConstants";

export default class ReactStorageService {
    constructor(storage) {
        this.localStorage = storage;
    }

    setAccessToken(accessToken) {
        this.localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, accessToken || null)
    }

    setUserData(user = {}) {
        if (user) {
            user = JSON.stringify(user);
            this.localStorage.setItem(LOCAL_STORAGE_KEYS.USER, user);
        }
    }
    getUserPermissions() {
        if (this.permissions) {
            return this.permissions;
        } else {
            try {
                const permissions = this.localStorage.getItem(
                  LOCAL_STORAGE_KEYS.PERMISSIONS
                );
                if (permissions) {
                  const localPermission = JSON.parse(permissions);
                  this.permissions = localPermission;
                  return this.permissions;
                }
                return [];
              } catch (error) {
                return [];
              }
        }
    }
    setUserPermissions(permissions = []) {
        if (permissions) {
            this.permissions = permissions;
            const localPermissions = JSON.stringify(permissions);
            this.localStorage.setItem(LOCAL_STORAGE_KEYS.PERMISSIONS, localPermissions);
        }
    }
    setItem(key, value) {
        if (key && value) {
            this.localStorage.setItem(key, JSON.stringify(value));
        }
    }
    getItem(key = '', jsonParse = true) {
        if (!key) { return; }
        try {
            const val = this.localStorage.getItem(
                key
            );
            if (val && jsonParse) {
                return JSON.parse(val);
            } else if (val) {
                return val;
            }
            return false;
        } catch (error) {
            return false;
        }
    }
    getDepartment() {
        try {
          const department = this.localStorage.getItem(
            LOCAL_STORAGE_KEYS.DEPARTMENT
          );
          if (department) {
            return JSON.parse(department);
          }
          return false;
        } catch (error) {
          return false;
        }
    }
    setDepartment(department) {
        this.localStorage.setItem(
          LOCAL_STORAGE_KEYS.DEPARTMENT,
          JSON.stringify(department) || ""
        );
    }
    setTaskOfflinePageSize(taskOfflinePageSize) {
        this.localStorage.setItem(
          LOCAL_STORAGE_KEYS.TASK_OFFLINE_PAGE_SIZE,
          JSON.stringify(taskOfflinePageSize) || ""
        );
    }
    setUserConfiguration(config = null) {
        if (!!config) {
            config = JSON.stringify(config);
            this.localStorage.setItem(LOCAL_STORAGE_KEYS.CONFIGURATION, config);
        }
    }

    getAccessToken() {
        try {
            const token = this.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
            return token;
        }
        catch (error) {
            return false;
        }
    }

    getUserData() {
        try {
            const data = this.localStorage.getItem(LOCAL_STORAGE_KEYS.USER);

            if (data) {
                return JSON.parse(data);
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    getUserConfiguration() {
        try {
            const data = this.localStorage.getItem(LOCAL_STORAGE_KEYS.CONFIGURATION);

            if (data) {
                return JSON.parse(data);
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    deleteLoginData() {
        this.localStorage.removeItem(LOCAL_STORAGE_KEYS.USER);
        this.localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
        this.localStorage.removeItem(LOCAL_STORAGE_KEYS.CONFIGURATION);
        this.localStorage.removeItem(LOCAL_STORAGE_KEYS.PERMISSIONS);
        this.localStorage.removeItem(LOCAL_STORAGE_KEYS.SELECTED_DEPARTMENT);
    }

    removeStorageItem(itemKey) {
        this.localStorage.removeItem(itemKey);
    }

}