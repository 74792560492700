import axios from "axios";
import axiosRetry from "axios-retry";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

export default class AccessToken {
  static token;
  static source = source;
}

export const apiClient = (contentType) => {
  const baseUrl = BarnManagerEnvironment.config().baseURL;
  const token = AccessToken.token;
  const defaultOptions = {
    cancelToken: AccessToken.source.token,
    headers: {
      Authorization: token ? `Bearer ${token || null}` : "",
      "Content-Type": contentType || "application/json",
    },
  };

  if (process.env.REACT_APP_PLATFORM !== "WEB") {
    axiosRetry(axios);
  }

  return {
    get: (url, options = {}) =>
      axios.get(`${baseUrl}${url}`, { ...defaultOptions, ...options }),
    post: (url, data, options = {}) =>
      axios.post(`${baseUrl}${url}`, data, { ...defaultOptions, ...options }),
    put: (url, data, options = {}) =>
      axios.put(`${baseUrl}${url}`, data, { ...defaultOptions, ...options }),
    delete: (url, options = {}) =>
      axios.delete(`${baseUrl}${url}`, { ...defaultOptions, ...options }),
  };
};

export const BuildMode = {
  BUILD_MODE_LOCAL: "local",
  BUILD_MODE_DEV: "dev",
  BUILD_MODE_QA: "qa",
  BUILD_MODE_STAGING: "staging",
  BUILD_MODE_DEMO: "demo",
  BUILD_MODE_TEMP: "temp",
  BUILD_MODE_CONSOLE: "console",
};

export const BarnManagerEnvironment = {
  // comment out one from following buildMode from which server you want to get data

  showEvironmentSelector: false,
  // buildMode: BuildMode.BUILD_MODE_DEV,
  // buildMode: BuildMode.BUILD_MODE_QA,
  // buildMode: BuildMode.BUILD_MODE_DEMO,
  buildMode: BuildMode.BUILD_MODE_CONSOLE,
  // buildMode: BuildMode.BUILD_MODE_STAGING,
  /* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

  allConfigurations: {
    local: {
      baseURL: "http://demo1.folio3.com:1434/dev_server/api",
      vendorKey: "",
      vendorName: "",
    },
    temp: {
      baseURL: "http://10.164.1.36:8098/ReleaseAPI/api",
      vendorKey: "",
      vendorName: "",
    },
    dev: {
      // baseURL: "http://demo7.folio3.com:8484/api",
      // baseURL: "http://demo9.folio3.com:8012/server/api",
      // baseURL: "http://demo9.folio3.com:8012/TaskDesignerServer/api",
      // baseURL: "http://demo9.folio3.com:8012/LiveStock/api",
      // baseURL: "http://demo9.folio3.com:8095/server/api", // Superior-Farms Dev URL
      // baseURL: "http://demo9.folio3.com:8012/LiveStock/api",
      // baseURL: "http://10.164.1.23:8091/server/api", // base Dev URL
      baseURL: "http://ecodocsbaseline.folio3.com:8091/server/api",
      vendorKey: "",
      vendorName: "",
    },
    qa: {
      baseURL: "http://ecodocsbaseline.folio3.com:8092/server/api", // base app QA URL
      vendorKey: "",
      vendorName: "",
    },
    staging: {
      baseURL: "https://folio3-ecodocs-backend-uat.azurewebsites.net/api",
      vendorKey: "",
      vendorName: "",
    },
    console: {
      baseURL: "https://console.ecodocspro.com:8080/api",
      vendorKey: "",
      vendorName: "",
    },
    demo: {
      baseURL: "http://demo9.folio3.com:8094/demoserver/api", // 36-Demo
      // baseURL: "http://demo1.folio3.com:1434/server/api",
      vendorKey: "",
      vendorName: "",
    },
  },

  config(buildMode) {
    const mode = buildMode || this.buildMode;
    return this.allConfigurations[mode];
  },
};
