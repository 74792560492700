import { PageData, SortData, SearchTerm } from "./General";

export const LivestockFilterModel = (filterData = {}, sortData = {}, pageData = {}, searchTerm = "") => {

    return ({
        ...new PageData(pageData),
        ...new SortData(sortData),
        ...new SearchTerm(searchTerm),
        categoryEnumIds: filterData.categories || [],
        statusEnumIds: filterData.status || [],
    })
    
} 
