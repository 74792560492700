class UserModel { }

UserModel.schema = {
  name: "User",
  primaryKey: "id",
  properties: {
    id: "string",
    email: "string?",
    firstName: "string?",
    fullName: "string?",
    lastName: "string?",
    role: "string?",
    type: "string?",
    username: "string?",
  },
};

export default UserModel;
