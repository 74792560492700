import { PROGRAM_ACTIONS } from "../../constants/ActionConstants";
import { getProgramFilterCount } from "@ecodocs/common/src/helpers/ProgramHelper";

const initialState = { programs: [], showMessage: false };

export function program(state = initialState, action) {
    switch (action.type) {
        case PROGRAM_ACTIONS.GET_PROGRAMS_REQUEST:
            return {
                ...state,
                getProgramsLoading: true,
                getProgramsListingError: false,
            };

        case PROGRAM_ACTIONS.GET_PROGRAMS_SUCCESS:
            return {
                ...state,
                getProgramsLoading: false,
                programs: !!action.payload.result ? action.payload.result.elements : [],
                //programs: [],
                totalElements: !!action.payload.result ? action.payload.result.totalElements : 0,
                getProgramsListingError: false,
            };

        case PROGRAM_ACTIONS.GET_PROGRAMS_FAILURE:
            return {
                ...state,
                getProgramsLoading: false,
                getProgramsListingError: true,
                error: action.payload
            };

        case PROGRAM_ACTIONS.EMPTY_PROGRAMS_LIST:
            return {
                ...state,
                loading: false,
                programs: [],
                totalElements: 0
            };

        case PROGRAM_ACTIONS.ENABLE_PROGRAMS_SELECT_LIST:
            return {
                ...state,
                programEnabled: action.payload
            };

        case PROGRAM_ACTIONS.SUBMIT_PROGRAM_REQUEST:
            return {
                ...state,
                loading: true
            };

        case PROGRAM_ACTIONS.HANDLE_SUBMIT_PROGRAM_SUCCESS:
            return {
                ...state,
                programMessageData: action.payload,
                showMessage: true,
                loading: false,
                checkedRows: []

            }
        case PROGRAM_ACTIONS.SUBMIT_PROGRAM_FAILURE:
            return {
                ...state,
                loading: false,
                programError: action.payload
            };

        case PROGRAM_ACTIONS.SUBMIT_PROGRAM_SUCCESS:
            let success = false;
            let programError = null;
            if (action.payload.result &&
                action.payload.responseCode === 200 &&
                action.payload.message === "Success") {
                success = true;
            } else {
                success = false;
                // getting different response from server that is why handling it
                if (action.payload.result && !!action.payload.result.message) {
                    programError = action.payload.result.message;
                }
            }
            return {
                ...state,
                loading: false,
                response: action.payload,
                success: success,
                //  showMessage: !!success ? true : false,
                programError: programError
            };

        case PROGRAM_ACTIONS.HANDLE_ADD_PROGRAM_MESSAGE:
            return {
                ...state,
                programMessageData: action.payload,
                showMessage: action.payload.showMessage
            };

        case PROGRAM_ACTIONS.GET_PROGRAM_BY_ID_REQUEST:
            return {
                ...state,
                loading: true
            };

        case PROGRAM_ACTIONS.GET_PROGRAM_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                programDetails: action.payload.result,
                recordNotFound: !action.payload.result
            };

        case PROGRAM_ACTIONS.GET_PROGRAM_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case PROGRAM_ACTIONS.UPSERT_PROGRAM_LIST_FILTERS:
            return {
                ...state,
                programFilter: { filterCount: getProgramFilterCount(action.payload), programFilterValues: action.payload }
            };

        case PROGRAM_ACTIONS.REMOVE_PROGRAM_LIST_FILTERS:
            return {
                ...state,
                programFilter: { filterCount: 0, programFilterValues: {} }
            };

        case PROGRAM_ACTIONS.CHANGE_PROGRAM_STATUS_REQUEST:
            return {
                ...state,
                loading: true
            };

        case PROGRAM_ACTIONS.CHANGE_PROGRAM_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                programMessageData: action.payload.message,
                showMessage: true
            };

        case PROGRAM_ACTIONS.CHANGE_PROGRAM_STATUS_FAILURE:
            return {
                ...state,
                loading: false
            };

        case PROGRAM_ACTIONS.HANDLE_EDIT_PROGRAM_MESSAGE:
            return {
                ...state,
                programMessageData: action.payload.message,
                showMessage: true
            }

        case PROGRAM_ACTIONS.ASSOCIATE_DIVISION_TO_PROGRAMS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case PROGRAM_ACTIONS.HANDLE_ASSOCIATE_DIVISION_TO_PROGRAMS_SUCCESS:
            return {
                ...state,
                programMessageData: action.payload,
                showMessage: true,
                loading: false,
                checkedRows: []
            };
        case PROGRAM_ACTIONS.ASSOCIATE_DIVISION_TO_PROGRAMS_SUCCESS:
            success = false;
            if (action.payload.result &&
                action.payload.responseCode === 200 &&
                action.payload.message === "Success") {
                success = true;
            }
            return {
                ...state,
                loading: false,
                response: action.payload,
                success: success,
                checkedRows: []
            };

        case PROGRAM_ACTIONS.ASSOCIATE_DIVISION_TO_PROGRAMS_FAILURE:
            return {
                ...state,
                loading: false,
                programError: action.payload
            };

        /**
         * TABLE ACTIONS
         */

        case PROGRAM_ACTIONS.ON_SORT_PROGRAM_COLUMN_REQUEST:
            return {
                ...state,
                getAllProgramsOnSortingLoading: true
            };

        case PROGRAM_ACTIONS.ON_SORT_PROGRAM_COLUMN_SUCCESS:
            return {
                ...state,
                getAllProgramsOnSortingLoading: false,
                programs: !!action.payload.result ? action.payload.result.elements : [],
                totalElements: !!action.payload.result ? action.payload.result.totalElements : 0
            };

        case PROGRAM_ACTIONS.ON_SORT_PROGRAM_COLUMN_FAILURE:
            return {
                ...state,
                getAllProgramsOnSortingLoading: false,
                error: action.payload
            };

        //EO | TABLE ACTIONS

        //Reset task detail from redux
        case PROGRAM_ACTIONS.RESET_PROGRAM_DETAIL_STATE:
            return {
                ...state,
                programDetails: null
            }
        
        //GET PROGRAM BY LOCATION ID
        case PROGRAM_ACTIONS.GET_PROGRAMS_BY_LOCATION_REQUEST:
            return {
                ...state,
                getProgramsLoading: true,
                getProgramsListingError: false,
            };

        case PROGRAM_ACTIONS.GET_PROGRAMS_BY_LOCATION_SUCCESS:
            return {
                ...state,
                getProgramsLoading: false,
                programs: !!action.payload.result ? action.payload.result : [],
                totalElements: !!action.payload.result ? action.payload.result.length : 0,
                getProgramsListingError: false,
            };

        case PROGRAM_ACTIONS.GET_PROGRAMS_BY_LOCATION_FAILURE:
            return {
                ...state,
                getProgramsLoading: false,
                getProgramsListingError: true,
                error: action.payload
            };

        default:
            return state;
    }
}
