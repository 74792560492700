import { PageData, SortData, SearchTerm } from "./General";

export const SchedulerFilterModel = (filterData = {}, sortData = {}, pageData = {}, searchTerm = "") => {

    sortData.sortColumn = sortData.sortColumn === "division" ? "divisionTitle" : sortData.sortColumn;

    return ({
        ...new PageData(pageData),
        ...new SortData(sortData),
        ...new SearchTerm(searchTerm),
        workOrderIds: filterData.workOrderIds || [],
        state: filterData.state || [],
        status: filterData.status || [],
        priority: filterData.priority || [],
        occurence: filterData.occurence || [],
        locations: filterData.locations || [],
        workers: filterData.workers || [],
        scheduledBy: filterData.scheduledBy || [],
    })
    
} 
