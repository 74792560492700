import axios from "axios";

import {
  USER_ACTIONS,
  BREAKCRUMB_ACTIONS,
  ADUSERS_ACTIONS,
} from "../../constants/ActionConstants";
import { userService } from "../../sevices/api";
import { request, success, failure } from "./index";
import {
  LOCAL_STORAGE_KEYS,
  GENERIC_CONSTANTS,
} from "../../constants/GenericConstants";
import { UserFilterModel } from "@ecodocs/common/src/models/User";
import { PageData, SearchTerm } from "@ecodocs/common/src/models/General";
import StorageService from "../../sevices/storageService";
import AccessToken from "../../sevices/api/ApiClient";

import LOCALIZATION_CONSTANTS from "../../constants/AppConstants";
import SubmissionManager from "../../database/dataManagers/SubmissionManager";
import { OfflineSubmissionRequestModel } from "../../models/Task";

import { syncData } from "./dataSync";
import { uploadImagesMobile } from "./TaskActions";
import { uploadImages } from "../../helpers/TaskSubmissionHelper";
import { saveAllAdhocTasks } from "../../helpers/TaskHelper";
import GeneralManager from "../../database/dataManagers/GeneralManager";

export function clearLocalStorageItems() {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  AccessToken.token = null;
  AccessToken.source &&
    AccessToken.source.cancel &&
    AccessToken.source.cancel();
  AccessToken.source = source;
  StorageService.instance.deleteLoginData();
  // localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  // localStorage.removeItem(LOCAL_STORAGE_KEYS.USER);
  clearMobileItems();
}

export async function clearMobileItems() {
  const keepAdhoc = await saveAllAdhocTasks();
  await StorageService.instance.resetLastSync(keepAdhoc);

  if (keepAdhoc) {
    GeneralManager.clearDBAndKeepAdhoc();
  } else {
    GeneralManager.clearDB();
  }
}

export function userAutoLoggedIn(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      const {
        token,
        user,
        permissions,
        applicationConfiguration,
        // department,
        taskOfflinePageSize,
      } = data;
      StorageService.instance.setAccessToken(token || "");
      StorageService.instance.setUserData(user || "");
      StorageService.instance.setUserPermissions(permissions || "");
      // StorageService.instance.setDepartment(department || "");
      StorageService.instance.setTaskOfflinePageSize(taskOfflinePageSize || "");
      StorageService.instance.setUserConfiguration(
        applicationConfiguration || "",
      );
      AccessToken.token = token;
      dispatch(success(USER_ACTIONS.AUTO_LOGGED_IN, data));
      resolve();
    });
  };
}

export function changeUserState(id, value) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(USER_ACTIONS.CHANGE_USER_STATUS_REQUEST, { id, value }));
      userService.changeState(id, value).then(
        (data) => {
          dispatch(success(USER_ACTIONS.CHANGE_USER_STATUS_SUCCESS, data));
          resolve(LOCALIZATION_CONSTANTS.SUCCESS_MESSAGES.USER_STATUS_UPDATED);
        },
        (error) => {
          dispatch(
            failure(USER_ACTIONS.CHANGE_USER_STATUS_FAILURE, error.toString()),
          );
          reject(error);
        },
      );
    });
  };
}

export function loginUser(model) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(
        request(USER_ACTIONS.LOGIN_REQUEST, { username: model.Username }),
      ); //store only username
      userService.login(model).then(
        async (data) => {
          const {
            accessToken,
            user,
            applicationConfiguration,
            permissions,
            taskOfflinePageSize,
          } = data.result;

          // if login is from mobile app and the currently logged in user is not
          // the same as previously logged in user, then clear all the data from
          // db and asyncStorage so that offline syncing starts from scratch
          if (process.env.REACT_APP_PLATFORM !== "WEB") {
            const previousUserData =
              await StorageService.instance.getUserData();
            console.log(previousUserData, user);
            console.log(
              "clearing",
              !previousUserData || previousUserData.id !== user.id,
            );
            if (!previousUserData || previousUserData.id !== user.id) {
              await clearMobileItems();
            }
          }

          StorageService.instance.setAccessToken(accessToken);
          StorageService.instance.setUserData(user);
          StorageService.instance.setUserPermissions(permissions);
          StorageService.instance.setTaskOfflinePageSize(taskOfflinePageSize);

          StorageService.instance.setUserConfiguration(
            applicationConfiguration,
          );
          AccessToken.token = accessToken;
          dispatch(
            success(USER_ACTIONS.LOGIN_SUCCESS, {
              ...data,
              currentUserData: user,
              applicationConfiguration,
            }),
          );
          resolve(user);
        },
        (error) => {
          dispatch(failure(USER_ACTIONS.LOGIN_FAILURE, error.toString()));
          reject(error.toString());
        },
      );
    });
  };
}

export function logout() {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(USER_ACTIONS.LOGOUT_SUCCESS));
      clearLocalStorageItems();
      resolve(); // TODO need to remove , once proper response come for logout api
      userService.logout().then(
        (data) => {
          clearLocalStorageItems();
          dispatch(success(USER_ACTIONS.LOGOUT_SUCCESS, data));
          resolve();
        },
        (error) => {
          dispatch(failure(USER_ACTIONS.LOGOUT_FAILURE), error.toString());
          reject(error.toString());
        },
      );
    });
  };
}

export function resetPassword(model, history) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(USER_ACTIONS.RESET_PASSWORD_REQUEST));
      userService.resetPassword(model).then(
        (data) => {
          dispatch(success(USER_ACTIONS.RESET_PASSWORD_SUCCESS, data));
          history && history.replace("/login");
          resolve();
        },
        (error) => {
          dispatch(
            failure(USER_ACTIONS.RESET_PASSWORD_FAILURE),
            error.toString(),
          );
          reject(error.toString());
        },
      );
    });
  };
}

export function forgotPassword(model, history) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(USER_ACTIONS.FORGOT_PASSWORD_REQUEST));
      userService.forgotPassword(model).then(
        (data) => {
          dispatch(success(USER_ACTIONS.FORGOT_PASSWORD_SUCCESS, data));
          resolve(data);
        },
        (error) => {
          dispatch(
            failure(USER_ACTIONS.FORGOT_PASSWORD_FAILURE),
            error.toString(),
          );
          reject(error);
        },
      );
    });
  };
}

export function setDepartment(department) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      StorageService.instance.setDepartment(department || "");
      dispatch(success(USER_ACTIONS.SET_DEPARTMENT, { department }));
      resolve();
    });
  };
}

export function getADUsersList() {
  return function (dispatch) {
    dispatch(request(ADUSERS_ACTIONS.GET_ADUSERLIST_REQUEST));
    userService
      .activeDirectoryUsers()
      .then((data) =>
        dispatch(success(ADUSERS_ACTIONS.GET_ADUSERLIST_SUCCESS, data)),
      )
      .catch((error) =>
        dispatch(
          failure(ADUSERS_ACTIONS.GET_ADUSERLIST_FAILURE, error.toString()),
        ),
      );
  };
}

export function getUsers(filterModel) {
  return function (dispatch) {
    dispatch(request(USER_ACTIONS.ON_SORT_USER_COLUMN_REQUEST, filterModel));
    userService.getFilterUsers(filterModel).then(
      (data) => {
        dispatch(success(USER_ACTIONS.ON_SORT_USER_COLUMN_SUCCESS, data));
      },
      (error) => {
        dispatch(
          failure(USER_ACTIONS.ON_SORT_USER_COLUMN_FAILURE, error.toString()),
        );
      },
    );
  };
}

export function getUserByLocationOrWorkOrder(filterModel) {
  return function (dispatch) {
    dispatch(
      request(USER_ACTIONS.GET_USER_BY_LOCATION_WORKER_REQUEST, filterModel),
    );
    userService.getUserByLocationOrWorkOrder(filterModel).then(
      (data) => {
        dispatch(
          success(USER_ACTIONS.GET_USER_BY_LOCATION_WORKER_SUCCESS, data),
        );
      },
      (error) => {
        dispatch(
          failure(
            USER_ACTIONS.GET_USER_BY_LOCATION_WORKER_FAILURE,
            error.toString(),
          ),
        );
      },
    );
  };
}

//TODO: will be removed later and replaced with getUsers
export function getFilteredUsers(
  paginationData,
  searchTerms,
  filters,
  sortObj,
) {
  return function (dispatch) {
    dispatch(
      request(USER_ACTIONS.GET_ALL_USERS_REQUEST, {
        paginationData,
        searchTerms,
        filters,
      }),
    );
    userService
      .getFilteredUsers(paginationData, searchTerms, filters, sortObj)
      .then(
        (data) => {
          dispatch(success(USER_ACTIONS.GET_ALL_USERS_SUCCESS, data));
        },
        (error) => {
          dispatch(
            failure(USER_ACTIONS.GET_ALL_USERS_FAILURE, error.toString()),
          );
        },
      );
  };
}

export function getAdminUsers(paginationData, searchTerms, filters, sortObj) {
  return function (dispatch) {
    dispatch(
      request(USER_ACTIONS.GET_ADMIN_USERS_REQUEST, {
        paginationData,
        searchTerms,
        filters,
      }),
    );
    userService
      .getFilteredUsers(paginationData, searchTerms, filters, sortObj)
      .then(
        (data) => {
          dispatch(success(USER_ACTIONS.GET_ADMIN_USERS_SUCCESS, data));
        },
        (error) => {
          dispatch(
            failure(USER_ACTIONS.GET_ADMIN_USERS_FAILURE, error.toString()),
          );
        },
      );
  };
}

export function getNonAdminUsers(
  paginationData,
  searchTerms,
  filters,
  sortObj,
) {
  return function (dispatch) {
    dispatch(
      request(USER_ACTIONS.GET_NON_ADMIN_USERS_REQUEST, {
        paginationData,
        searchTerms,
        filters,
      }),
    );
    userService
      .getFilteredUsers(paginationData, searchTerms, filters, sortObj)
      .then(
        (data) => {
          dispatch(success(USER_ACTIONS.GET_NON_ADMIN_USERS_SUCCESS, data));
        },
        (error) => {
          dispatch(
            failure(USER_ACTIONS.GET_NON_ADMIN_USERS_FAILURE, error.toString()),
          );
        },
      );
  };
}

export function getAllManagers() {
  return function (dispatch) {
    dispatch(request(USER_ACTIONS.GET_ALL_MANAGERS_REQUEST));

    let paginationData = {
      pageSize: GENERIC_CONSTANTS.TABLE_OPTIONS.MAX_PAGE_SIZE,
    };
    let searchTerms = "";
    let filters = { roles: [GENERIC_CONSTANTS.USER_ROLES.MANAGER] };
    let sortObj = {
      sortOrder: GENERIC_CONSTANTS.SORT_ORDER.DESC,
      sortColumn: GENERIC_CONSTANTS.DEFAULT_SORT_KEY,
    };

    userService
      .getFilteredUsers(paginationData, searchTerms, filters, sortObj)
      .then(
        (data) => {
          dispatch(success(USER_ACTIONS.GET_ALL_MANAGERS_SUCCESS, data));
        },
        (error) => {
          dispatch(
            failure(USER_ACTIONS.GET_ALL_MANAGERS_FAILURE, error.toString()),
          );
        },
      );
  };
}

export function getUserSettings(userId) {
  return function (dispatch) {
    dispatch(request(USER_ACTIONS.GET_USER_BY_ID_REQUEST, { userId }));
    userService.getUserSettings(userId).then(
      (data) => {
        dispatch(success(USER_ACTIONS.GET_USER_BY_ID_SUCCESS, data));
      },
      (error) => {
        dispatch(
          failure(USER_ACTIONS.GET_USER_BY_ID_FAILURE, error.toString()),
        );
      },
    );
  };
}

export function getUserDetails(id, breadcrumbArray = []) {
  return function (dispatch) {
    dispatch(request(USER_ACTIONS.GET_USER_BY_ID_REQUEST, { id }));
    userService.getUserDetails(id).then(
      (data) => {
        const { result } = data;
        breadcrumbArray.push({ label: result.username || "" });
        dispatch(
          request(BREAKCRUMB_ACTIONS.UPDATE_BREADCRUMB_ARRAY, breadcrumbArray),
        );
        dispatch(success(USER_ACTIONS.GET_USER_BY_ID_SUCCESS, data));
      },
      (error) => {
        dispatch(
          failure(USER_ACTIONS.GET_USER_BY_ID_FAILURE, error.toString()),
        );
      },
    );
  };
}

export function upsertUserFilters(userFilterParams) {
  return function (dispatch) {
    dispatch(request(USER_ACTIONS.UPSERT_USER_LIST_FILTERS, userFilterParams));
  };
}

export function removeuserFilters() {
  return function (dispatch) {
    dispatch(request(USER_ACTIONS.REMOVE_USER_LIST_FILTERS));
  };
}

export function handleUpsertUserMessages(messageObject) {
  return function (dispatch) {
    dispatch(request(USER_ACTIONS.HANDLE_ADD_USER_MESSAGE, messageObject));
  };
}

export function editUser(userData, history, userId) {
  return function (dispatch) {
    dispatch(request(USER_ACTIONS.SUBMIT_USER_REQUEST, { userData, userId }));
    userService.editUser(userData, userId).then(
      (data) => {
        const user = data && data.result;
        const currentUserData = JSON.parse(
          localStorage.getItem(LOCAL_STORAGE_KEYS.USER),
        );
        dispatch(
          request(USER_ACTIONS.HANDLE_EDIT_USER_MESSAGE, {
            message: "User updated successfully",
          }),
        );
        dispatch(success(USER_ACTIONS.SUBMIT_USER_SUCCESS, user));
        history.push(`/user/${userId}`);
        if (currentUserData.id == user.id) {
          !!user && StorageService.instance.setUserData(user);
          window.location.reload(false);
        }
      },
      (error) => {
        dispatch(failure(USER_ACTIONS.SUBMIT_USER_FAILURE, error.toString()));
      },
    );
  };
}

export function changeUserPassword(newPassword, history, userId) {
  return function (dispatch) {
    dispatch(
      request(USER_ACTIONS.CHANGE_PASSWORD_REQUEST, { newPassword, userId }),
    );
    userService.changePassword(newPassword, userId).then(
      (data) => {
        console.log("SUCCESS =>", data);
        dispatch(
          success(USER_ACTIONS.CHANGE_PASSWORD_SUCCESS, {
            message: "Password Changed Successfully!",
          }),
        );
        history != null ? history.push(`/user/${userId}`) : null;
      },
      (error) => {
        console.log("FAILUE =>", error);
        dispatch(
          failure(USER_ACTIONS.HANDLE_CHANGE_PASSWORD_MESSAGE, {
            message: error.toString(),
          }),
        );
      },
    );
  };
}

export function addUser(userData, history) {
  return function (dispatch) {
    dispatch(request(USER_ACTIONS.SUBMIT_USER_REQUEST, { userData }));
    userService.addUser(userData).then(
      (data) => {
        dispatch(
          request(USER_ACTIONS.HANDLE_SUBMIT_USER_SUCCESS, {
            message: "User Added Successfully",
          }),
        );
        dispatch(success(USER_ACTIONS.SUBMIT_USER_SUCCESS, data));
        history.push(`/user/all`);
      },
      (error) => {
        dispatch(failure(USER_ACTIONS.SUBMIT_USER_FAILURE, error.toString()));
      },
    );
  };
}

export function changeNotificationPreference(data, history, userId) {
  return function (dispatch) {
    dispatch(request(USER_ACTIONS.SUBMIT_USER_REQUEST, { data, userId }));
    userService.changeNotificationPreference(data, userId).then(
      (data) => {
        dispatch(success(USER_ACTIONS.SUBMIT_USER_SUCCESS, data));
      },
      (error) => {
        dispatch(failure(USER_ACTIONS.SUBMIT_USER_FAILURE, error.toString()));
      },
    );
  };
}

export function associateUserToDivision(model) {
  return function (dispatch) {
    dispatch(request(USER_ACTIONS.ASSOCIATE_USER_TO_DIVISION_REQUEST));
    userService.associateUserToDivision(model).then(
      (data) => {
        const paginationData = new PageData();
        const searchTerms = new SearchTerm().SearchTerm;
        const filters = new UserFilterModel();
        const sortObj = {
          sortColumn: "fullName",
          sortOrder: GENERIC_CONSTANTS.SORT_ORDER.ASC,
        };

        dispatch(
          request(USER_ACTIONS.ASSOCIATE_USER_TO_DIVISION_SUCCESS, {
            message: "User associated successfully",
          }),
        );

        //reset user table data
        dispatch(
          request(USER_ACTIONS.GET_ALL_USERS_REQUEST, {
            paginationData,
            searchTerms,
            filters,
          }),
        );
        userService
          .getFilteredUsers(paginationData, searchTerms, filters, sortObj)
          .then(
            (data) => {
              dispatch(success(USER_ACTIONS.GET_ALL_USERS_SUCCESS, data));
            },
            (error) => {
              dispatch(
                failure(USER_ACTIONS.GET_ALL_USERS_FAILURE, error.toString()),
              );
            },
          );
      },
      (error) => {
        dispatch(
          failure(USER_ACTIONS.ASSOCIATE_USER_TO_DIVISION_FAILURE, {
            showMessage: true,
            message: error || LOCALIZATION_CONSTANTS.SOMETHING_WENT_WRONG,
            class: "error",
            error: true,
          }),
        );
      },
    );
  };
}

export function onQATaskDesignerFormSubmission(
  model,
  isEdit = false,
  showToast = true,
  editMode = false,
) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(USER_ACTIONS.QA_FORM_SUBMSSION_REQUEST));
      userService.qaTaskDesignerFormSubmission(model, isEdit).then(
        (data) => {
          // if(data.message.toLowerCase().indexOf("success") >= 0 && data.result != null) {
          const message = editMode
            ? "Task Edited Successfully"
            : "Form Submitted Successfully";
          dispatch(
            request(
              USER_ACTIONS.QA_FORM_SUBMSSION_SUCCESS,
              showToast && {
                success: !(!!data && !!data.isViolated),
                message,
              },
            ),
          );
          resolve(data);
          // }else{
          //   const message = data.message;
          //   dispatch(
          //     request(USER_ACTIONS.QA_FORM_SUBMSSION_FAILURE, showToast && {
          //       error: true,
          //       message,
          //     })
          //   );
          //   reject(message);
          // }

          //console.log(parentSubmissionId, subTaskId);
          /*if (history) {
              if(!!workorderId){
                history.replace(`/today-tasks/${workorderId}`);
              }else if(!!parentSubmissionId && !!subTaskId){
                history.replace(`/today-sub-tasks/${parentSubmissionId}/${subTaskId}`);
              }else{
                history.replace("/workorder/all");
              }
              
            }*/
        },
        (error) => {
          const errorMessage = error || "Something Went Wrong";
          dispatch(
            failure(
              USER_ACTIONS.QA_FORM_SUBMSSION_FAILURE,
              showToast && {
                error: true,
                message: errorMessage,
              },
            ),
          );

          reject(errorMessage);
        },
      );
    });
  };
}

export function onQAFormSubmission(model, isEdit = false, showToast = true) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(USER_ACTIONS.QA_FORM_SUBMSSION_REQUEST));
      userService.qaFormSubmission(model, isEdit).then(
        (data) => {
          const message = "Task Submitted Successfully";
          dispatch(
            request(USER_ACTIONS.QA_FORM_SUBMSSION_SUCCESS, {
              success: !(!!data && !!data.isViolated),
              message,
            }),
          );
          resolve(data);
          console.log("pending task submitted", data);
          //console.log(parentSubmissionId, subTaskId);
          /*if (history) {
              if(!!workorderId){
                history.replace(`/today-tasks/${workorderId}`);
              }else if(!!parentSubmissionId && !!subTaskId){
                history.replace(`/today-sub-tasks/${parentSubmissionId}/${subTaskId}`);
              }else{
                history.replace("/workorder/all");
              }
              
            }*/
        },
        (error) => {
          const errorMessage = error || "Something Went Wrong";
          dispatch(
            failure(
              USER_ACTIONS.QA_FORM_SUBMSSION_FAILURE,
              showToast && {
                error: true,
                message: errorMessage,
              },
            ),
          );
          reject(errorMessage);
        },
      );
    });
  };
}

export function onQAFormSubmissionMobile(
  model,
  isEdit = false,
  showToast = true,
) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(USER_ACTIONS.QA_FORM_SUBMSSION_REQUEST));
      userService.qaTaskDesignerFormSubmission(model, isEdit).then(
        (data) => {
          const message = "Task Submitted Successfully";
          dispatch(
            request(USER_ACTIONS.QA_FORM_SUBMSSION_SUCCESS, {
              success: !(!!data && !!data.isViolated),
              message,
            }),
          );
          resolve({ data, message });
        },
        (error) => {
          const errorMessage = error || "Something Went Wrong";
          dispatch(
            failure(
              USER_ACTIONS.QA_FORM_SUBMSSION_FAILURE,
              showToast && {
                error: true,
                message: errorMessage,
              },
            ),
          );
          reject(errorMessage);
        },
      );
    });
  };
}

/**
 *
 * @param {Object} model => request model for form submission API
 * @param {String} taskType => task type , either ADHOC or SCHEDULED
 * @param {Boolean} isNewSubmission => Will be true, if its a new submission of a subtask other then the required submisison count
 */
export function submitFormForOffline(
  model,
  taskType,
  isEditing = false,
  isAutoSaving = false,
  isNewSubmission = false,
  shouldSyncData = true,
) {
  return async function (dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        let message =
          "Task submitted successfully and will be synced on connectivity.";
        const userData = await StorageService.instance.getUserData();

        if (isEditing) {
          message = "Task edited successfully.";
          const uploadImagesAction = (images) =>
            uploadImagesMobile(images)(dispatch);
          await uploadImages(model, uploadImagesAction);
          await onQAFormSubmissionMobile(model)(dispatch);
          dispatch(syncData());
          resolve({ message });
        } else {
          dispatch(request(USER_ACTIONS.QA_FORM_SUBMSSION_REQUEST));
          const offlineSubmissionPayload = OfflineSubmissionRequestModel(
            model,
            taskType,
          );

          const result = await SubmissionManager.saveSubmission(
            offlineSubmissionPayload,
            taskType,
            userData.fullName,
            isNewSubmission,
            isAutoSaving,
          );
          dispatch(syncData());
          dispatch(
            request(USER_ACTIONS.QA_FORM_SUBMSSION_SUCCESS, {
              success: true,
              message,
            }),
          );
          resolve({ id: result, message });
        }
      } catch (error) {
        dispatch(
          failure(USER_ACTIONS.QA_FORM_SUBMSSION_FAILURE, {
            error: true,
            message: error.toString(),
          }),
        );

        reject(error.toString());
      }
    });
  };
}

export function updateCurrentUserData(data) {
  return function (dispatch) {
    dispatch(request(USER_ACTIONS.UPDATE_USER_DETAIL_REQUEST, data));
  };
}

export function resetUserDetails() {
  return function (dispatch) {
    dispatch(request(USER_ACTIONS.RESET_USER_DETAIL_STATE));
  };
}

export function emptyAllUsers() {
  return function (dispatch) {
    dispatch(request(USER_ACTIONS.EMPTY_ALL_USERS));
  };
}

export function signupUser(model) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(USER_ACTIONS.USER_SIGNUP_REQUEST));
      userService.signup(model).then(
        (data) => {
          const { accessToken, user, applicationConfiguration } = data.result;
          StorageService.instance.setAccessToken(accessToken);
          StorageService.instance.setUserData(user);
          StorageService.instance.setUserConfiguration(
            applicationConfiguration,
          );
          AccessToken.token = accessToken;
          dispatch(
            success(USER_ACTIONS.USER_SIGNUP_SUCCESS, {
              ...data,
              currentUserData: user,
            }),
          );
          resolve();
        },
        (error) => {
          dispatch(failure(USER_ACTIONS.USER_SIGNUP_FAILURE, error.toString()));
          reject(error.toString());
        },
      );
    });
  };
}

export function toggeleNotifications() {
  return function (dispatch) {
    dispatch(request(USER_ACTIONS.TOGGLE_NOTIFICATIONS_REQUEST));
    userService.toggleNotifcations().then(
      (data) => {
        dispatch(request(USER_ACTIONS.TOGGLE_NOTIFICATIONS_SUCCESS, data));
        let userData = StorageService.instance.getUserData();
        userData.notificationEnabled = data.result;
        StorageService.instance.setUserData(userData);
      },
      (error) => {
        dispatch(
          failure(USER_ACTIONS.TOGGLE_NOTIFICATIONS_FAILURE, error.toString()),
        );
      },
    );
  };
}
