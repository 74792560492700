import { API_CONSTANTS } from "../../constants/ApiConstants";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { apiClient } from "./ApiClient";

export const reportService = {
  getReport,
  getUserSubmissionReport,
  getTaskSubmissionReport,
  getDailySubmissionReport,
  getTaskNames
};
function getTaskNames(body) {
  let url = API_CONSTANTS.API_ROUTES.GET_TASK_NAMES;
  return apiClient()
    .post(url, body)
    .then(handleResponse)
    .catch(handleError);
}
function getReport(params, url) {
  if (params.GetExcel == true) {
    return apiClient().post(url, params, { responseType: "arraybuffer" });
  } else {
    return apiClient()
      .post(url, params)
      .then(handleResponse)
      .catch(handleError);
  }
}

function getDailySubmissionReport(params) {
  let url = API_CONSTANTS.API_ROUTES.GET_DAILY_SUBMISSION_REPORT;
  if (params.GetExcel == true) {
    return apiClient().post(url, params, { responseType: "arraybuffer" });
  } else {
    return apiClient()
      .post(url, params)
      .then(handleResponse)
      .catch(handleError);
  }
}

function getTaskSubmissionReport(params) {
  let url = API_CONSTANTS.API_ROUTES.GET_TASK_SUBMISSION_REPORT;

  if (params.GetExcel == true) {
    return apiClient().post(url, params, { responseType: "arraybuffer" });
  } else {
    return apiClient()
      .post(url, params)
      .then(handleResponse)
      .catch(handleError);
  }
}

function getUserSubmissionReport(params) {
  let url = API_CONSTANTS.API_ROUTES.GET_USER_SUBMISSION_REPORT;

  if (params.GetExcel == true) {
    return apiClient().post(url, params, { responseType: "arraybuffer" });
  } else {
    return apiClient()
      .post(url, params)
      .then(handleResponse)
      .catch(handleError);
  }
}
