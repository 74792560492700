const MapAPIToDB = (workorder) => ({
  ...workorder,
  locations:
    Object.keys((workorder && workorder.locations) || {}).length > 0
      ? workorder.locations
      : [],
});

const MapDBToState = (workorder) => ({
  ...workorder,
  id: workorder.id,
  title: workorder.title,
  workorderNumber: workorder.workorderNumber,
  locations:
    workorder.locations && workorder.locations.length
      ? Array.from(workorder.locations)
      : [],
});

export { MapAPIToDB, MapDBToState };
