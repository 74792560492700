import {
  LOCATION_ACTIONS,
  BREAKCRUMB_ACTIONS,
} from "../../constants/ActionConstants";
import { locationService } from "../../sevices/api";
import StorageService from "../../sevices/storageService";
import { request, success, failure } from "./index";
import {
  LocationFilterModel,
  LocationFilterModelForOffline,
} from "@ecodocs/common/src/models/Location";
import { SearchTerm, PageData } from "@ecodocs/common/src/models/General";
import {
  GENERIC_CONSTANTS,
  LOCAL_STORAGE_KEYS,
} from "../../constants/GenericConstants";
import { setSyncingLocation } from "../../store/actions/AppActions";
import LocationManager from "../../database/dataManagers/LocationManager";
// localization service
import { getDetectedPlatformLocalization } from "../../helpers/GeneralHelper";
const LOCALIZATION_CONSTANTS = getDetectedPlatformLocalization();

export function submitLocation(locationData) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
    dispatch(
      request(LOCATION_ACTIONS.SUBMIT_LOCATION_REQUEST, { locationData })
    );
    locationService.submitLocationData(locationData).then(
      (data) => {
        // dispatch(
        //   request(LOCATION_ACTIONS.HANDLE_SUBMIT_LOCATION_SUCCESS)
        // );
        resolve(data)
        dispatch(success(LOCATION_ACTIONS.SUBMIT_LOCATION_SUCCESS, data));
        // history.push(`/location/all`);
      },
      (error) => {
        dispatch(
          failure(LOCATION_ACTIONS.SUBMIT_LOCATION_FAILURE, error.toString())
        );
        reject(error)

      }
    );
    })
  };
}

export function getLocationTypes() {
  return function (dispatch) {
    dispatch(request(LOCATION_ACTIONS.GET_ALL_LOCATION_TYPES_REQUEST));
    locationService.getAllLocationTypes().then(
      (data) => {
        dispatch(
          success(LOCATION_ACTIONS.GET_ALL_LOCATION_TYPES_SUCCESS, data)
        );
      },
      (error) => {
        dispatch(
          failure(
            LOCATION_ACTIONS.GET_ALL_LOCATION_TYPES_FAILURE,
            error.toString()
          )
        );
      }
    );
  };
}

export function handleUpsertLocationMessages(messageObject) {
  return function (dispatch) {
    dispatch(
      request(LOCATION_ACTIONS.HANDLE_ADD_LOCATION_MESSAGE, messageObject)
    );
  };
}

export function resetLocationDetails() {
  return function (dispatch) {
    dispatch(request(LOCATION_ACTIONS.RESET_LOCATION_DETAIL_STATE));
  };
}

export function changeLocationState(id, value) {
  return function (dispatch) {
    dispatch(
      request(LOCATION_ACTIONS.CHANGE_LOCATION_STATUS_REQUEST, { id, value })
    );
    locationService.changeState(id, value).then(
      (data) => {
        dispatch(
          success(LOCATION_ACTIONS.CHANGE_LOCATION_STATUS_SUCCESS, {
            message:
              LOCALIZATION_CONSTANTS.SUCCESS_MESSAGES.LOCATION_STATUS_UPDATED,
          })
        );
      },
      (error) => {
        dispatch(
          failure(
            LOCATION_ACTIONS.CHANGE_LOCATION_STATUS_FAILURE,
            error.toString()
          )
        );
      }
    );
  };
}

export function getLocationDetails(id, breadcrumbArray = [], locationTypes = true) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(LOCATION_ACTIONS.GET_LOCATION_BY_ID_REQUEST, { id }));
      locationService.getLocationDetails(id).then(
        (data) => {
          const { result } = data;
          const locationObject = !!result ? result.location : {};
          const locationTypeId = !!locationObject
            ? locationObject.locationTypeId
            : null;
          // const divisionId = !!divisionObject ? divisionObject.id : null;
          // const locationId = !!locationObject ? locationObject.id : null;

          breadcrumbArray.push({ label: result.id || "" });
          dispatch(
            request(BREAKCRUMB_ACTIONS.UPDATE_BREADCRUMB_ARRAY, breadcrumbArray)
          );

          //nesting for location types
          dispatch(request(LOCATION_ACTIONS.GET_ALL_LOCATION_TYPES_REQUEST));
          locationService.getAllLocationTypes().then(
            (data) => {
              dispatch(
                success(LOCATION_ACTIONS.GET_ALL_LOCATION_TYPES_SUCCESS, data)
              );
            },
            (error) => {
              dispatch(
                failure(
                  LOCATION_ACTIONS.GET_ALL_LOCATION_TYPES_FAILURE,
                  error.toString()
                )
              );
            }
          );

          dispatch(success(LOCATION_ACTIONS.GET_LOCATION_BY_ID_SUCCESS, data));
          resolve(result);
        },
        (error) => {
          dispatch(
            failure(LOCATION_ACTIONS.GET_LOCATION_BY_ID_FAILURE, error.toString())
          );
          reject(error.toString());
        }
      );
    });
  };
}

export function getFilterLocations(filterModel) {
  return function (dispatch) {
    dispatch(
      request(LOCATION_ACTIONS.ON_SORT_LOCATION_COLUMN_REQUEST, filterModel)
    );
    locationService.getFilterLocations(filterModel).then(
      (data) => {
        dispatch(
          success(LOCATION_ACTIONS.ON_SORT_LOCATION_COLUMN_SUCCESS, data)
        );
      },
      (error) => {
        dispatch(
          failure(
            LOCATION_ACTIONS.ON_SORT_LOCATION_COLUMN_FAILURE,
            error.toString()
          )
        );
      }
    );
  };
}

export function getFilteredLocations(
  paginationData,
  searchTerm,
  filters,
  sortObject
) {
  return function (dispatch) {
    dispatch(
      request(LOCATION_ACTIONS.GET_LOCATION_REQUEST, {
        paginationData,
        searchTerm,
        filters,
        sortObject,
      })
    );
    const filterObject = new LocationFilterModel(filters);
    locationService
      .getFilteredLocations(
        paginationData,
        searchTerm,
        filterObject,
        sortObject
      )
      .then(
        (data) => {
          dispatch(success(LOCATION_ACTIONS.GET_LOCATION_SUCCESS, data));
        },
        (error) => {
          dispatch(
            failure(LOCATION_ACTIONS.GET_LOCATION_FAILURE, error.toString())
          );
        }
      );
  };
}

export function getAllLocations() {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(LOCATION_ACTIONS.GET_ALL_LOCATION_REQUEST, {}));
      locationService.getAllLocations().then(
        (data) => {
          dispatch(success(LOCATION_ACTIONS.GET_ALL_LOCATION_SUCCESS, data));
          resolve(data);
        },
        (error) => {
          dispatch(
            failure(LOCATION_ACTIONS.GET_ALL_LOCATION_FAILURE, error.toString())
          );
          reject(error.toString());
        }
      );
    });
  };
}

export function syncLocations() {
  return async function (dispatch, getStore) {
    try {
      const store = getStore();
      let lastRecordTimestamp =
        (await StorageService.instance.getLastSync(
          LOCAL_STORAGE_KEYS.LAST_SYNC_LOCATION
        )) || new Date(0).toISOString();

      // Only sync data when app is online and sync has not already started
      if (
        store &&
        store.app &&
        !!store.app.isConnected &&
        !store.app.isSyncingLocation
      ) {
        dispatch(setSyncingLocation(true));

        const response = await locationService.getFilterLocations(
          new LocationFilterModelForOffline(lastRecordTimestamp)
        );

        if (
          response &&
          response.result &&
          response.result.elements &&
          !!response.result.elements.length
        ) {
          LocationManager.saveLocations(response.result.elements);

          await StorageService.instance.setLastSync(
            LOCAL_STORAGE_KEYS.LAST_SYNC_LOCATION,
            response.result.elements[response.result.elements.length - 1]
              .updatedOn
          );
        }
      }
    } catch (error) {
      console.log("syncLocations error", error);
      dispatch(failure(LOCATION_ACTIONS.GET_LOCATION_FAILURE, error));
    } finally {
      dispatch(
        success(
          LOCATION_ACTIONS.GET_LOCATION_SUCCESS,
          await LocationManager.getLocations()
        )
      );
      dispatch(setSyncingLocation(false));
      return true;
    }
  };
}

export function getLocationsByDivisionId(params) {
  return function (dispatch) {
    dispatch(request(LOCATION_ACTIONS.GET_LOCATIONS_REQUEST, params));

    locationService.getLocationsByDivisionId(params).then(
      (data) => {
        dispatch(success(LOCATION_ACTIONS.GET_LOCATIONS_SUCCESS, data));
        //dispatch(request(LOCATION_ACTIONS.ENABLE_LOCATIONS_SELECT_LIST, true));
      },
      (error) => {
        dispatch(
          failure(LOCATION_ACTIONS.GET_LOCATIONS_FAILURE, error.toString())
        );
      }
    );
  };
}

export function editLocation(locationsData, id) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(
        request(LOCATION_ACTIONS.SUBMIT_LOCATION_REQUEST, { locationsData })
      );
      locationService.editLocation(locationsData, id).then(
        (data) => {
          var newTaskId = !!data.result ? data.result.id : null;
          dispatch(
            request(LOCATION_ACTIONS.HANDLE_EDIT_LOCATION_MESSAGE, {
              message: LOCALIZATION_CONSTANTS.SUCCESS_MESSAGES.LCOATION_UPDATED,
            })
          );
          //dispatch(success(LOCATION_ACTIONS.SUBMIT_LOCATION_SUCCESS, data));
          resolve(data);
        },
        (error) => {
          dispatch(
            failure(LOCATION_ACTIONS.SUBMIT_LOCATION_FAILURE, error.toString())
          );
          reject(error.toString());
        }
      );
    });
  };
}

export function removeLocationFilters() {
  return function (dispatch) {
    dispatch(request(LOCATION_ACTIONS.REMOVE_LOCATION_LIST_FILTERS));
  };
}

export function upsertLocationFilters(filterParams) {
  return function (dispatch) {
    dispatch(
      request(LOCATION_ACTIONS.UPSERT_LOCATION_LIST_FILTERS, filterParams)
    );
  };
}

export function emptyLocationsList(value) {
  return function (dispatch) {
    dispatch(request(LOCATION_ACTIONS.EMPTY_LOCATIONS_LIST));
  };
}
