import {
  MapApiToDB as MapTaskDetailApiToDB,
  MapDBToState as MapTaskDetailDBToState,
} from "./taskDetail";

const MapApiToDB = (task) => {
  return {
    id: task.id,
    parentTaskId: task.parentTaskId,
    taskNumber: task.taskNumber,
    isDeleted: task.isDeleted,
    isActive: task.isActive,
    workorderId: task.workorderId,
    workorderNumber: task.workorderNumber,
    workorderTitle: task.workorderTitle,
    // workorder: task.workorder,
    updatedOn: task.updatedOn,
    taskTypeEnumId: task.taskTypeEnumId,
    createdBy: task.createdBy,
    taskCreatedBy: task.createdBy,
    locations: task.locations || [],
    taskDetails:
      task.taskDetails && task.taskDetails.length > 0
        ? task.taskDetails.map((td) => MapTaskDetailApiToDB(td))
        : [],
    subTasks: task.subTasks
      ? task.subTasks.map((subtask) => MapSubTaskToDB(subtask))
      : [],
    isSubtask: false,
    departmentName: task.departmentName || "",
    departmentId: task.departmentId || null,
    departmentNumber: task.departmentNumber || "",
  };
};

const MapSubTaskToDB = (task) => ({
  id: task.id,
  parentTaskId: task.parentTaskId,
  taskNumber: task.taskNumber,
  isDeleted: task.isDeleted,
  isActive: task.isActive,
  workorderId: task.workorderId,
  workorderNumber: task.workorderNumber,
  workorderTitle: task.workorderTitle,
  // workorder: task.workorder,
  updatedOn: task.updatedOn,
  createdBy: task.createdBy,
  taskCreatedBy: task.createdBy,
  locations: task.locations || [],
  taskDetails:
    task.taskDetails && task.taskDetails.length > 0
      ? task.taskDetails.map((td) => MapTaskDetailApiToDB(td))
      : [],
  subTasks: task.subTasks
    ? task.subTasks.map((subtask) => MapSubTaskToDB(subtask))
    : [],
  isSubtask: true,
  departmentName: task.departmentName || "",
  departmentId: task.departmentId || null,
  departmentNumber: task.departmentNumber || "",
});

const MapDBToState = (task) => {
  return {
    id: task.id,
    parentTaskId: task.parentTaskId,
    taskNumber: task.taskNumber,
    isDeleted: task.isDeleted,
    isActive: task.isActive,
    workorderId: task.workorderId,
    workorderNumber: task.workorderNumber,
    workorderTitle: task.workorderTitle,
    // workorder: task.workorder,
    updatedOn: task.updatedOn,
    taskTypeEnumId: task.taskTypeEnumId,
    createdBy: task.createdBy,
    taskCreatedBy: task.createdBy,
    locations: task.locations || [],
    taskDetails:
      task.taskDetails && task.taskDetails.length > 0
        ? task.taskDetails.map((td) => MapTaskDetailDBToState(td))
        : [],
    subTasks: task.subTasks
      ? task.subTasks.map((subtask) => MapDBToState(subtask))
      : [],
    isSubtask: task.isSubtask,
    departmentName: task.departmentName || "",
    departmentId: task.departmentId || null,
    departmentNumber: task.departmentNumber || "",
  };
};

const MapDBToStateForList = (task) => {
  task.taskDetails = task.taskDetails.filter(
    (td) => td.isActive && td.taskActivityStatusEnumId == 1
  );

  return {
    id: task.id,
    taskNumber: task.taskNumber,
    taskName:
      (task.taskDetails && task.taskDetails[0] && task.taskDetails[0].title) ||
      "",
    workorder: {
      id: task.workorderId,
      workorderNumber: task.workorderNumber,
      title: task.workorderTitle,
    },
    updatedOn: task.updatedOn,
    departmentName: task.departmentName || "",
    departmentNumber: task.departmentNumber || "",
  };
};

export { MapApiToDB, MapDBToState, MapDBToStateForList };
