import {
  PROGRAM_ACTIONS,
  BREAKCRUMB_ACTIONS,
} from "../../constants/ActionConstants";
import { programService } from "../../sevices/api";
import StorageService from "../../sevices/storageService";
import { request, success, failure } from "./index";
import {
  ProgramFilterModel,
  ProgramFilterModelForOffline,
} from "@ecodocs/common/src/models/Program";
import { SearchTerm, PageData } from "@ecodocs/common/src/models/General";
import {
  GENERIC_CONSTANTS,
  LOCAL_STORAGE_KEYS,
} from "../../constants/GenericConstants";
import { setSyncingProgram } from "./AppActions";
import ProgramManager from "../../database/dataManagers/ProgramManager";

// localization service
import LocalizationService from "@ecodocs/common/src/sevices/localizationService";

//import LOCALIZATION_CONSTANTS from "../../constants/AppConstants";
import { getDetectedPlatformLocalization } from "../../helpers/GeneralHelper";
const LOCALIZATION_CONSTANTS = getDetectedPlatformLocalization();


export function getProgramsByDivisionId(params) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {enableDisableProgramsSelectList
      programService.getProgramsByDivisionId(params).then(
        (data) => {
          dispatch(success(PROGRAM_ACTIONS.GET_PROGRAMS_SUCCESS, data));
          //dispatch(request(PROGRAM_ACTIONS.ENABLE_PROGRAMS_SELECT_LIST, true));
          resolve(data);
        },
        (error) => {
          dispatch(
            failure(PROGRAM_ACTIONS.GET_PROGRAMS_FAILURE, error.toString())
          );
          reject(error);
        }
      );
    });
  };
}

export function getProgramsByLocationId(params) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
    dispatch(request(PROGRAM_ACTIONS.GET_PROGRAMS_BY_LOCATION_REQUEST, params));
    programService.getProgramsByLocationId(params).then(
      (data) => {
        dispatch(success(PROGRAM_ACTIONS.GET_PROGRAMS_BY_LOCATION_SUCCESS, data));
        resolve(data)
      },
      (error) => {
        dispatch(
          failure(PROGRAM_ACTIONS.GET_PROGRAMS_BY_LOCATION_FAILURE, error.toString())
        );
        reject(error)
      }
    );
    })
  };
}

export function associateDivisionToPrograms(divisionId, checkedRows) {
  return function (dispatch) {
    dispatch(request(PROGRAM_ACTIONS.ASSOCIATE_DIVISION_TO_PROGRAMS_REQUEST));

    programService
      .associateDivisionToPrograms({
        programs: checkedRows,
        divisionId: divisionId,
      })
      .then(
        (data) => {
          dispatch(
            request(
              PROGRAM_ACTIONS.HANDLE_ASSOCIATE_DIVISION_TO_PROGRAMS_SUCCESS,
              {
                message:
                  LOCALIZATION_CONSTANTS.SUCCESS_MESSAGES
                    .DIVISION_ASSOCIATED_SUCCESSFULLY,
              }
            )
          );

          //////reset table with new data page data
          const paginationData = new PageData();
          const searchTerm = new SearchTerm().SearchTerm;
          const filters = new ProgramFilterModel();
          const sortObject = {
            sortColumn: GENERIC_CONSTANTS.DEFAULT_SORT_KEY,
            sortOrder: GENERIC_CONSTANTS.SORT_ORDER.DESC,
          };
          dispatch(
            request(PROGRAM_ACTIONS.GET_PROGRAMS_REQUEST, {
              paginationData,
              searchTerm,
              filters,
              sortObject,
            })
          );
          programService
            .getFilteredPrograms(
              paginationData,
              searchTerm,
              filters,
              sortObject
            )
            .then(
              (data) => {
                dispatch(success(PROGRAM_ACTIONS.GET_PROGRAMS_SUCCESS, data));
              },
              (error) => {
                dispatch(
                  failure(
                    PROGRAM_ACTIONS.GET_PROGRAMS_FAILURE,
                    error.toString()
                  )
                );
              }
            );
        },
        (error) => {
          dispatch(
            failure(
              PROGRAM_ACTIONS.ASSOCIATE_DIVISION_TO_PROGRAMS_FAILURE,
              error.toString()
            )
          );
        }
      );
  };
}

export function enableDisableProgramsSelectList(flag) {
  return function (dispatch) {
    dispatch(request(PROGRAM_ACTIONS.ENABLE_PROGRAMS_SELECT_LIST, flag));
  };
}

export function getPrograms() {
  return function (dispatch) {
    dispatch(request(PROGRAM_ACTIONS.GET_PROGRAMS_REQUEST));
    programService.getPrograms().then(
      (data) => {
        dispatch(success(PROGRAM_ACTIONS.GET_PROGRAMS_SUCCESS, data));
      },
      (error) => {
        dispatch(
          failure(PROGRAM_ACTIONS.GET_PROGRAMS_FAILURE, error.toString())
        );
      }
    );
  };
}

export function syncPrograms() {
  return async function (dispatch, getStore) {
    try {
      const store = getStore();
      let lastRecordTimestamp =
        (await StorageService.instance.getLastSync(
          LOCAL_STORAGE_KEYS.LAST_SYNC_PROGRAM
        )) || new Date(0).toISOString();

      // Only sync data when app is online and sync has not already started
      if (
        store &&
        store.app &&
        !!store.app.isConnected &&
        !store.app.isSyncingProgram
      ) {
        dispatch(setSyncingProgram(true));

        const response = await programService.getProgramsForOffline(
          new ProgramFilterModelForOffline(lastRecordTimestamp)
        );

        if (response && response.result && response.result.length) {
          ProgramManager.savePrograms(response.result);

          await StorageService.instance.setLastSync(
            LOCAL_STORAGE_KEYS.LAST_SYNC_PROGRAM,
            response.result[response.result.length - 1].updatedOn
          );
        }
      }
    } catch (error) {
      console.log("syncPrograms error", error);
      dispatch(failure(PROGRAM_ACTIONS.GET_PROGRAMS_FAILURE, error));
    } finally {
      dispatch(
        success(
          PROGRAM_ACTIONS.GET_PROGRAMS_SUCCESS,
          await ProgramManager.getPrograms()
        )
      );
      dispatch(setSyncingProgram(false));
      return true;
    }
  };
}

export function getFilterPrograms(filterModel) {
  return function (dispatch) {
    dispatch(
      request(PROGRAM_ACTIONS.ON_SORT_PROGRAM_COLUMN_REQUEST, filterModel)
    );
    programService.getFilterPrograms(filterModel).then(
      (data) => {
        dispatch(success(PROGRAM_ACTIONS.ON_SORT_PROGRAM_COLUMN_SUCCESS, data));
      },
      (error) => {
        dispatch(
          failure(
            PROGRAM_ACTIONS.ON_SORT_PROGRAM_COLUMN_FAILURE,
            error.toString()
          )
        );
      }
    );
  };
}

export function getFilteredPrograms(
  paginationData,
  searchTerm,
  filters,
  sortObject
) {
  return function (dispatch) {
    dispatch(
      request(PROGRAM_ACTIONS.GET_PROGRAMS_REQUEST, {
        paginationData,
        searchTerm,
        filters,
        sortObject,
      })
    );
    const filterObject = new ProgramFilterModel(filters);
    programService
      .getFilteredPrograms(paginationData, searchTerm, filterObject, sortObject)
      .then(
        (data) => {
          dispatch(success(PROGRAM_ACTIONS.GET_PROGRAMS_SUCCESS, data));
        },
        (error) => {
          dispatch(
            failure(PROGRAM_ACTIONS.GET_PROGRAMS_FAILURE, error.toString())
          );
        }
      );
  };
}

export function removeProgramFilters() {
  return function (dispatch) {
    dispatch(request(PROGRAM_ACTIONS.REMOVE_PROGRAM_LIST_FILTERS));
  };
}

export function upsertProgramFilters(filterParams) {
  return function (dispatch) {
    dispatch(
      request(PROGRAM_ACTIONS.UPSERT_PROGRAM_LIST_FILTERS, filterParams)
    );
  };
}

export function emptyProgramsList(value) {
  return function (dispatch) {
    dispatch(request(PROGRAM_ACTIONS.EMPTY_PROGRAMS_LIST));
  };
}

export function handleUpsertProgramMessages(messageObject) {
  return function (dispatch) {
    dispatch(
      request(PROGRAM_ACTIONS.HANDLE_ADD_PROGRAM_MESSAGE, messageObject)
    );
  };
}

export function submitProgram(programsData) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(
        request(PROGRAM_ACTIONS.SUBMIT_PROGRAM_REQUEST, { programsData })
      );
      programService.submitProgramData(programsData).then(
        (data) => {
          dispatch(request(PROGRAM_ACTIONS.HANDLE_SUBMIT_PROGRAM_SUCCESS));
          resolve(data);
          dispatch(success(PROGRAM_ACTIONS.SUBMIT_PROGRAM_SUCCESS, data));
          // history.push(`/program/all`);
        },
        (error) => {
          dispatch(
            failure(PROGRAM_ACTIONS.SUBMIT_PROGRAM_FAILURE, error.toString())
          );
          reject(error);
        }
      );
    });
  };
}

export function getProgramDetails(id, breadcrumbArray = []) {
  return function (dispatch) {
    dispatch(request(PROGRAM_ACTIONS.GET_PROGRAM_BY_ID_REQUEST, { id }));
    programService.getProgramDetails(id).then(
      (data) => {
        const { result } = data;
        const programObject = !!result ? result.program : {};
        const divisionObject = !!programObject ? programObject.division : {};
        const divisionId = !!divisionObject ? divisionObject.id : null;
        const programId = !!programObject ? programObject.id : null;

        breadcrumbArray.push({ label: result.programNumber || "" });
        dispatch(
          request(BREAKCRUMB_ACTIONS.UPDATE_BREADCRUMB_ARRAY, breadcrumbArray)
        );

        //nesting for programs
        dispatch(request(PROGRAM_ACTIONS.GET_PROGRAMS_REQUEST, divisionId));
        programService
          .getProgramsByDivisionId(divisionId)
          .then(
            (programs) => {
              dispatch(success(PROGRAM_ACTIONS.GET_PROGRAMS_SUCCESS, programs));
              //nesting for work orders
              dispatch(
                request(PROGRAM_ACTIONS.GET_PROGRAMS_REQUEST, programId)
              );
            },
            (error) => {
              dispatch(
                failure(PROGRAM_ACTIONS.GET_PROGRAMS_FAILURE, error.toString())
              );
            }
          )
          .catch((error) => error);
        dispatch(success(PROGRAM_ACTIONS.GET_PROGRAM_BY_ID_SUCCESS, data));
      },
      (error) => {
        dispatch(
          failure(PROGRAM_ACTIONS.GET_PROGRAM_BY_ID_FAILURE, error.toString())
        );
      }
    );
  };
}

export function editProgram(programsData, id) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(
        request(PROGRAM_ACTIONS.SUBMIT_PROGRAM_REQUEST, { programsData })
      );
      programService.editProgram(programsData, id).then(
        (data) => {
          var newTaskId = !!data.result ? data.result.id : null;
          dispatch(
            request(PROGRAM_ACTIONS.HANDLE_EDIT_PROGRAM_MESSAGE, {
              message: LOCALIZATION_CONSTANTS.PROGRAM_UPDATED_SUCCESFULLY,
            })
          );
          dispatch(success(PROGRAM_ACTIONS.SUBMIT_PROGRAM_SUCCESS, data));
          resolve(data);
        },
        (error) => {
          dispatch(
            failure(PROGRAM_ACTIONS.SUBMIT_PROGRAM_FAILURE, error.toString())
          );
          reject(error.toString());
        }
      );
    });
  };
}

export function changeProgramState(id, value) {
  return function (dispatch) {
    dispatch(
      request(PROGRAM_ACTIONS.CHANGE_PROGRAM_STATUS_REQUEST, { id, value })
    );
    programService.changeState(id, value).then(
      (data) => {
        dispatch(
          success(PROGRAM_ACTIONS.CHANGE_PROGRAM_STATUS_SUCCESS, {
            message:
              LOCALIZATION_CONSTANTS.SUCCESS_MESSAGES.PROGRAM_STATUS_UPDATED,
          })
        );
      },
      (error) => {
        dispatch(
          failure(
            PROGRAM_ACTIONS.CHANGE_PROGRAM_STATUS_FAILURE,
            error.toString()
          )
        );
      }
    );
  };
}

export function resetProgramDetails() {
  return function (dispatch) {
    dispatch(request(PROGRAM_ACTIONS.RESET_PROGRAM_DETAIL_STATE));
  };
}
