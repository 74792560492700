import { NOTIFICATION_ACTIONS } from "../../constants/ActionConstants";
const initialState = { loading: false, notifications: [], totalElements: 0 };

export function notification(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_ACTIONS.GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        notifications: !!action.payload ? [] : state.notifications,
        totalElements: !!action.payload ? 0 : state.totalElements,
      };

    case NOTIFICATION_ACTIONS.GET_NOTIFICATIONS_SUCCESS:
      if (process.env.REACT_APP_PLATFORM !== "WEB") {
        return {
          ...state,
          loading: false,
          notifications:
            !!action.payload.result && action.payload.result.notifications
              ? [...action.payload.result.notifications]
              : state.notifications,
          totalElements:
            !!action.payload.result && action.payload.result.totalElements
              ? action.payload.result.totalElements
              : 0,
        };
      }

      return {
        ...state,
        loading: false,
        notifications:
          !!action.payload.result && action.payload.result.notifications
            ? [...state.notifications, ...action.payload.result.notifications]
            : state.notifications,
        totalElements:
          !!action.payload.result && action.payload.result.totalElements
            ? action.payload.result.totalElements
            : 0,
      };

    case NOTIFICATION_ACTIONS.GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case NOTIFICATION_ACTIONS.GET_HEADER_NOTIFICATIONS_REQUEST:
      return {
        ...state,
      };

    case NOTIFICATION_ACTIONS.GET_HEADER_NOTIFICATIONS_SUCCESS:
      let notifications = null;
      let totalUnReadCount = null;
      if(action.payload.result){
          notifications = action.payload.result.notifications;
          totalUnReadCount = action.payload.result.totalUnReadCount;
      }
      return {
          ...state,
          headerNotifications: !!notifications? notifications: [],
          headerNotificationsLength: !!notifications? notifications.length: 0,
          totalUnReadElement: totalUnReadCount || 0
      };

    case NOTIFICATION_ACTIONS.GET_HEADER_NOTIFICATIONS_FAILURE:
      return {
        ...state,
      };

    case NOTIFICATION_ACTIONS.MARK_ALL_NOTIFICATIONS_REQUEST:
      return {
        ...state,
      };

    case NOTIFICATION_ACTIONS.MARK_ALL_NOTIFICATIONS_SUCCESS:
      let oldNotifications = state.headerNotifications;

      if (oldNotifications) {
        for (let i = 0; i < oldNotifications.length; i++) {
          oldNotifications[i].isRead = true;
        }
      }

      return {
        ...state,
        headerNotifications: oldNotifications,
        totalUnReadElement: 0,
      };

    case NOTIFICATION_ACTIONS.MARK_ALL_NOTIFICATIONS_FAILURE:
      return {
        ...state,
      };

    default:
      return state;
  }
}
