import moment from "moment";
import { FILES_ICONS } from "./FilesIcons";

export const GENERIC_CONSTANTS = {
  TABLE_OPTIONS: {
    PAGE_SIZE: 20,
    PAGE_NUMBER: 1,
    SEARCH_TERM: "",
    MAX_PAGE_SIZE: 9999,
    UI_MAX_PAGES: 10,
  },
  SCHEDULER: {
    MAIN_BODY: {
      LOCATION: {
        NAME: "location",
      },
      LOCATION_LIST: {
        NAME: "locationList",
      },
      WORKER: {
        NAME: "worker",
      },
      WORKER_LIST: {
        NAME: "workerList",
      },
      TASK: {
        NAME: "task",
      },
      TASK_LIST: {
        NAME: "taskList",
      },
      START_DATE: {
        NAME: "startDate",
      },
      END_DATE: {
        NAME: "endDate",
      },
      START_TIME: {
        NAME: "startTime",
      },
      END_TIME: {
        NAME: "endTime",
      },
      ALL_DAY: {
        NAME: "openForAll",
      },
      INCLUDE_WEEKENDS: {
        NAME: "includeWeekends",
      },
      SUBMISSION_COUNT: {
        NAME: "submissionCount",
      },
      OCCURENCE: {
        NAME: "frequency",
      },
      PRIORITY: {
        NAME: "priority",
      },
      MONTH: {
        NAME: "month",
      },
      WEEK: {
        NAME: "week",
      },
      DAY: {
        NAME: "day",
      },
      FIELD_LIST: {
        NAME: "fieldList",
      },
    },
  },
  REQUEST_PAGE: {
    CORRECTIVE_ACTIONS: {
      NAME: 'CorrectiveActions',
      VALUE: 1
    },
    DASHBOARD: {
      NAME: 'Dashboard',
      VALUE: 2
    },
    SCHEDULED_TASKS: {
      NAME: 'ScheduledTasks',
      VALUE: 3
    },
    DASHBOARD_PENDING_APPROVAL: {
      NAME: 'Dashboard',
      VALUE: 5
    },
    TASK_HISTORY: {
      NAME: 'TaskHistory',
      VALUE: 6
    },
  },
  SKIP_TAK:{
    FORM: "skipTaskForm",
    FIELDS:{
      REASON: {
        NAME: 'reason'
      },
      COMMENT: {
        NAME: 'comment'
      }
    }
  },
  REVIEW:{
    TYPES:{
      RECORD_REVIEW:{
        TEXT: 'Record Review',
        NAME: 'RecordReview',
        VALUE: 1
      },
      DIRECT_OBSERVATION:{
        TEXT: 'Direct Observation',
        NAME: 'DirectObservation',
        VALUE: 2,
      },
      PRE_SHIPMENT: {
        TEXT: 'Pre-Shipment',
        NAME: 'PreShipment',
        VALUE: 3,
      },
    },
    FIELDS:{
      SIGNATURE:{
        NAME: 'signature'
      },
      REVIEW_STATUS:{
        NAME: 'reviewStatus'
      },
      TASK_STATUS:{
        NAME: 'taskStatus'
      },
      STATUS:{
        NAME: 'status'
      },
      OCCURRENCE:{
        NAME: 'occurrence'
      }
    }
  },
  TASK: {
    MAIN_BODY: {
      TASK_TYPE: {
        NAME: "taskType",
      },
      SIGNATURE:{
        NAME: "signature",
      },
      EDIT_FORM: {
        NAME: "editForm",
      },
      TASK_NO: {
        NAME: "taskNumber",
      },
      REVISION_DATE: {
        LABEL: "Revision Date",
        NAME: "revisionDateTime",
      },
      REVISION_NOTE: {
        NAME: "revisionNote",
      },
      DIVISION: {
        NAME: "division",
      },
      PROGRAM: {
        NAME: "program",
      },
      PROGRAM_MANUAL: {
        NAME: "programManual",
      },
      PROGRAM_MANUAL_LIST: {
        NAME: "programManualList",
      },
      WORKORDER: {
        NAME: "workorder",
      },
      TASK: {
        NAME: "task",
      },
      FREQUENCY_CALENDER: {
        NAME: "frequency",
      },
      TITLE: {
        NAME: "title",
      },
      SUBMISSION_COUNT: {
        NAME: "submissionCount",
      },
      PRIORITY: {
        NAME: "priority",
      },
      DAILY_APPROVAL_REQUIRED: {
        NAME: "dailyApprovalRequired",
      },
      DAILY_APPROVAL: {
        NAME: "dailyApproval",
      },
      DAILY_APPROVAL_LIST: {
        NAME: "dailyApprovalList",
      },
      WEEKLY_APPROVAL_REQUIRED: {
        NAME: "weeklyApprovalRequired",
      },
      WEEKLY_APPROVAL: {
        NAME: "weeklyApproval",
      },
      WEEKLY_APPROVAL_LIST: {
        NAME: "weeklyApprovalList",
      },
      PRE_SHIPMENT_APPROVAL_REQUIRED: {
        NAME: "preShipmentApprovalRequired",
      },
      PRE_SHIPMENT_APPROVAL: {
        NAME: "preShipmentApproval",
      },
      PRE_SHIPMENT_APPROVAL_LIST: {
        NAME: "preShipmentApprovalList",
      },
      APPROVAL_REQUIRED: {
        NAME: "approvalRequired",
      },
      CORRECTIVE_ACTION_REQUIRED: {
        NAME: "correctiveActionRequired"
      },
      TASK_REVIEWER: {
        NAME: "taskReviewer",
      },
      TASK_REVIEWER_LIST: {
        NAME: "taskReviewerList"
      },
      CORRECTIVE_ACTION_REVIEWER: {
        NAME: "correctiveActionReviewer"
      },
      CORRECTIVE_ACTION_REVIEWER_LIST: {
        NAME: "correctiveActionReviewerList"
      },
      CORRECTIVE_ACTION_FILLER_LIST:{
        NAME: "correctiveActionFillerList"
      },
      CORRECTIVE_ACTION_FILLER: {
        NAME: "correctiveActionFiller"
      },
      COMMENTS: {
        NAME: "comments",
      },
      DUE_DATE: {
        NAME: "DueDate",
      },
      SUBMISSION_DAY: {
        NAME: "SubmissionDay",
      },
      START_TIME: {
        NAME: "SubmissionStartTime",
      },
      END_TIME: {
        NAME: "SubmissionEndTime",
      },
      OPEN_FOR_ALL: {
        NAME: "IsOpenForAllDay",
      },
      ELEMENTS: {
        NAME: "elementIds",
      },
      APPROVED: {
        NAME: "approved"
      }, 
      REASON_FOR_EDITING: {
        NAME: 'reasonForEditing'
      },
      TASK_DETAIL_MEDIA: {
        NAME: 'taskDetailMedias'
      },
    },
    STATUS: {
      PUBLISH: "1",
      DRAFT: "2",
    },
    EDITOR_FIELDS: {
      INSTRUCTIONS: "instruction",
      REQUIREMENTS: "requirement",
    },
  },
  ASSET: {
    MAIN_BODY: {
      ASSET_ID: {
        NAME: "assetId",
      },
      NAME: {
        NAME: "name",
      },
      CATEGORY: {
        NAME: "category",
      },
      LOCATION: {
        NAME: "location",
      },
      STATUS: {
        NAME: "status",
      },
      BARCODE: {
        NAME: "barcode",
      },
      OWNERSHIP: {
        NAME: "ownership",
      },
      DATE_OF_ACQUISITION: {
        NAME: "acquisitionDate",
      },
      SUPPLIER: {
        NAME: "supplier",
      },
      PURCHASE_ORDER_NO: {
        NAME: "purchaseOrderNo",
      },
      WARRANTY_NO: {
        NAME: "warrantyNo",
      },
      WARRANTY_EXPIRE: {
        NAME: "warrantyExpireDate",
      },
      DESCRIPTION: {
        NAME: "description",
      },
    },
  },
  LIVESTOCK: {
    CATEGORY: "category",
    EAR_TAG: "ear_tag",
    STATUS: "status",
    DESCRIPTION: "description",
    BREED: "breed",
    GENDER: "gender",
    DOCILITY: "Docility",
    OWNERSHIP: "ownership",
    OWNER_NAME: "owner_name",
    SUPPLIER: "supplier",
    PURCHASE_PRICE: "purchase_price",
    PURCHASE_ORDER: "purchase_order_no",
  },
  WORKORDER: {
    MAIN_BODY: {
      WORKORDER_NO: {
        NAME: "workorderNumber",
      },
      REVISION_DATE: {
        NAME: "revisionDateTime",
      },
      REVISION_NOTE: {
        NAME: "revisionNote",
      },
      DIVISION: {
        NAME: "division",
      },
      PROGRAM: {
        NAME: "program",
      },
      TITLE: {
        NAME: "title",
      },
      LOCATION: {
        NAME: "locationIds",
      },
      ASSIGN: {
        NAME: "userIDs",
      },
      APPROVAL_REQUIRED: {
        NAME: "approvalRequired",
      },
    },
    STATUS: {
      PUBLISH: "1",
      DRAFT: "2",
    },
    EDITOR_FIELDS: {
      INSTRUCTIONS: "instruction",
      REQUIREMENTS: "requirement",
    },
  },
  USER: {
    MAIN_BODY: {
      USER_NAME: {
        NAME: "username",
      },
      FIRST_NAME: {
        NAME: "firstName",
      },
      LAST_NAME: {
        NAME: "lastName",
      },
      EMAIL: {
        NAME: "email",
      },
      ROLE: {
        NAME: "role",
      },
      TYPE: {
        NAME: "type",
      },
      PASSWORD: {
        NAME: "password",
      },
      CONFIRM_PASSWORD: {
        NAME: "confirmPassword",
      },
      OLD_PASSWORD: {
        NAME: "oldPassword",
      },
      CURRENT_PASSWORD: {
        NAME: "currentPassword",
      },
      NEW_PASSWORD: {
        NAME: "newPassword",
      },
      STATUS: {
        NAME: "status",
      },
    },
  },
  PROGRAM: {
    MAIN_BODY: {
      PROGRAM_NO: {
        LABEL: "Program #",
        NAME: "programNumber",
      },
      REVISION_DATE: {
        LABEL: "Revision Date",
        NAME: "revisionDateTime",
      },
      REVISION_NOTE: {
        LABEL: "Revision Note",
        NAME: "revisionNote",
      },
      DIVISION: {
        LABEL: "Division",
        NAME: "division",
      },
      WORKORDER: {
        LABEL: "Work Order",
        NAME: "workorder",
      },
      TITLE: {
        LABEL: "Program Title",
        NAME: "title",
      },
      ASSIGN: {
        LABEL: "Assign",
        NAME: "assign",
      },
    },
    STATUS: {
      PUBLISH: "1",
      DRAFT: "2",
    },
    EDITOR_FIELDS: {
      INSTRUCTIONS: "instruction",
      REQUIREMENTS: "requirement",
    },
  },
  PROGRAM_MANUAL: {
    MAIN_BODY: {
      PROGRAM_MANUAL_NO: {
        LABEL: "Program #",
        NAME: "programNumber",
      },
      PROGRAM_MANUAL_TYPE: {
        LABEL: "Program Type",
        NAME: "type",
      },
      LOCATION: {
        LABEL: "Location",
        NAME: "locationIds",
      },
      CREATION_DATE_TIME: {
        LABEL: "Creation Date & Time",
        NAME: "creationDateTime",
      },
      REVISION_DATE: {
        LABEL: "Revision Date",
        NAME: "revisionDateTime",
      },
      REVISION_NOTE: {
        LABEL: "Revision Note",
        NAME: "revisionNote",
      },
      TITLE: {
        LABEL: "Program Title",
        NAME: "title",
      },
    },
    STATUS: {
      PUBLISH: "1",
      DRAFT: "2",
      INACTIVE: "3",
    },
    EDITOR_FIELDS: {
      INSTRUCTIONS: "instruction",
      REQUIREMENTS: "requirement",
    },
  },
  REPORT: {
    REPORT_TYPE: "reportType",
    FREQUENCY: "frequency",
    START_DATE: "startDate",
    END_DATE: "endDate",
    WORKER_IDS: "workerIds",
    TASK_TYPE: "taskType",
    REPORT_TYPES_OLD: {
      DAILY_SUBMISSION_SUMMARY: 1,
      TASK_SUBMISSION_SUMMARY: 2,
      USER_SUBMISSION_SUMMARY: 3,
      TASK_SUBMISSION_REPORT: 4,
      WORKER_PERFORMANCE_REPORT: 5,
    },
    REPORT_TYPES: {
      REVIEW_COMPLETION_REPORT: 1,
      TASK_COMPLETION_REPORT: 2,
      TASK_COMPLETION_SUMMARY: 3,
      TASK_VIOLATION_REPORT: 4,
      USER_PERFORMANCE_SUMMARY: 5,
    },
  },
  LOCATION: {
    MAIN_BODY: {
      LOCATION_ID: {
        LABEL: "Location ID",
        NAME: "locationId",
      },
      LOCATION_NAME: {
        LABEL: "Location Name",
        NAME: "locationName",
      },
      LOCATION_TYPE: {
        LABEL: "Location Type",
        NAME: "locationType",
      },
      ADDRESS: {
        LABEL: "Address",
        NAME: "address",
      },
      STATUS: {
        LABEL: "Status",
        NAME: "status",
      },
      MANAGER_ID: {
        LABEL: "Manager's Id",
        NAME: "managerId",
      },
      MANAGER_NAME: {
        LABEL: "Manager's Name",
        NAME: "managerName",
      },
      WORKERS: {
        LABEL: "Workers",
        NAME: "workers",
      },
    },
  },
  RECORD_TYPES: {
    TASK_SUBMISSION: "taskSubmission",
    TASK: "task",
    ADHOC_TASKS: "adhocTasks",
    WORKORDER: "workorder",
    PROGRAM: "program",
    LOCATION: "location",
    LIVESTOCK: "livestock",
    DIVISION: "division",
    USER: "User",
    CLEANING: "Cleaning",
  },
  FIELD_NAME: {
    ROLE: "role",
    TYPE: "type",
    STATUS: "status",
    DIVISION: "divisions",
    SEARCHTEXT: "SearchText",
    WORKORDER: "workorders",
    STATE: "state",
    PRIORITY: "priority",
    START_DATE: "startDate",
    END_DATE: "endDate",
    PROGRAM: "programs",
    REVIEW_STATUS: "reviewStatus",
    DUPLICATE: "isDuplicate",
    VIOLATION: 'violation',
    //DIVISION: "Divisions"
  },
  SUBMISSIONS: {
    WORKORDER: "workorder",
    LOCATION: "location",
    COMPLETED_BY: "completedBy",
    REVIEWER: "reviewerId",
    TASK_TYPE: "taskType",
    // FREQUENCY: "frequency",
    PROGRAM: "program",
    OCCURRENCE: "occurrence",
    STATUS: "status",
    SHOW_VIOLATION: "showViolation",
    SHOW_SUBTASKS: "showSubtask",
    DUE_STATUS: "dueStatus",
    SUBMISSION_ACTION: {
      CREATE: 1,
      UPDATE: 2,
    }
  },
  DATE_TIME_24_HOUR_FORMAT: "MMM DD[,] YYYY | HH:mm",
  DATE_FORMAT_CLIENT: "MM/DD/YYYY",
  DATE_FORMAT_SERVER: "YYYY-MM-DD hh:mm:ss",
  DATE_FORMAT_DD_MM_YYYY: "DD/MM/YYYY",
  DATE_TIME_FORMAT_SERVER: "YYYY-MM-DD LT",
  DATE_FORMAT_SERVER_DASHBOARD: "YYYY-MM-DD",
  DATE_TIME_FORMAT: "MM/DD/YYYY hh:mm A",
  MMM_D_YYYY_LT: "MMM D, YYYY LT",
  DATE_FORMAT: "MMMM DD[,] YYYY",
  DATE_FORMAT_YEAR: "MMM DD[,] YYYY",
  DATE_FORMAT_COMPLETE: "MMM DD[,] YYYY hh:mm A",
  DATE_FORMAT_NO_YEAR: "MMM DD",
  TIME_FORMATE: "HH:mm",
  // TIME_FORMATE: "hh:mm A", //Disbaling due to 24hours format of time
  TIME_FORMATE_24: "HH:mm",
  DATE_TIME_24_FORMAT: "MMM DD[,] YYYY | HH:mm",
  MONTH_DAY_FORMAT: "MMMM DD",
  PRINT_VIEW_DATE_TIME_FORMAT: "MMMM DD[,] YYYY | hh:mm A",
  KEYBOARD_KEYS: {
    ENTER: 13,
  },
  UPLOAD: {
    TYPES: {
      ASSET: 1,
      IMAGE: 2,
      FILE: 3,
    },
    ALLOWED_EXTENSIONS: {
      ASSET: ".pdf, .doc",
      IMAGE: ".jpg, .png",
      FILE: ".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .txt, .csv, .zip, .rar, .odt, .rtf, .otf",
    },
    FILE_ICONS: {
      pdf: FILES_ICONS.PDF,
      doc: FILES_ICONS.DOC,
      docx: FILES_ICONS.DOC,
      ppt: FILES_ICONS.PPT,
      pptx: FILES_ICONS.PPT,
      xls: FILES_ICONS.XLS,
      xlsx: FILES_ICONS.XLS,
      txt: FILES_ICONS.TXT,
      csv: FILES_ICONS.CSV,
      zip: FILES_ICONS.ZIP,
      rar: FILES_ICONS.RAR,
      odt: FILES_ICONS.ODT,
      rtf: FILES_ICONS.RTF,
      otf: FILES_ICONS.OTF,
      file: FILES_ICONS.FILE,
    },
  },
  TIME_MODE: {
    TWELVE_HOURS: "12 Hours",
    TWENTY_FOUR_HOURS: "24 Hours",
  },
  ADDRESS_FIELD_LABEL_US: {
    StreetAddress1: "Address Line 1",
    StreetAddress2: "Address Line 2",
    City: "City",
    StateProvinceRegion: "State",
    PostalZipCode: "ZIP Code",
  },
  ADDRESS_FIELD_LABEL_NON_US: {
    StreetAddress1: "Address Line 1",
    StreetAddress2: "Address Line 2",
    City: "City",
    StateProvinceRegion: "Province/Region",
    PostalZipCode: "Postal Code",
  },
  FORMULA_OPERATOR: [
    { value: "4", icon: "ecoIcon-minus", sign: "-" },
    { value: "3", icon: "ecoIcon-add", sign: "+" },
    { value: "5", icon: "ecoIcon-add is-cross", sign: "*" },
    { value: "6", icon: "ecoIcon-divide", sign: "/" },
  ],
  ADDRESS_FIELD_LENGTH: {
    StreetAddress1: 250,
    StreetAddress2: 150,
    City: 100,
    StateProvinceRegion: 100,
    PostalZipCode: 15,
  },
  TASK_ELEMENT_FIELD_KEYS: {
    CUSTOM: 'CUSTOM',
    REQUIRED: "Required",
    LABEL: "FieldLabel",
    PLACEHOLDER: "Placeholder",
    HELPTEXT: "HelpText",
    MAX_CHARACTERS: "MaxCharacters",
    WIDTH_COLUMN: "WidthColumn",
    METRICS: "Metric",
    DEFAULT_STATE: "DefaultState",
    DEFAULT_VALUE: "DefaultValue",
    VALUES: "Values",
    INITIAL: "Initial",
    MINIMUM: "Minimum",
    MAXIMUM: "Maximum",
    STEPS: "Steps",
    USE_1000_SEPARATOR: "Use1000separator",
    DECIMAL_PLACES: "DecimalPlacesAllowed",
    FORMAT: "Format",
    OPTIONS: "options",
    ADDRESS1: "StreetAddress1", // when changing the value, also update the keys in ADDRESS_FIELD_LABEL enum above
    ADDRESS2: "StreetAddress2", // when changing the value, also update the keys in ADDRESS_FIELD_LABEL enum above
    CITY: "City", // when changing the value, also update the keys in ADDRESS_FIELD_LABEL enum above
    PROVINCE: "StateProvinceRegion", // when changing the value, also update the keys in ADDRESS_FIELD_LABEL enum above
    POSTAL: "PostalZipCode", // when changing the value, also update the keys in ADDRESS_FIELD_LABEL enum above
    FORMULA: "Formula",
    US_BASED_ADDRESS: "USBasedAddress",
    FILE_TYPE: "FileType",
    SECTION_TITLE: "SectionTitle",
  },
  TASK_DESIGNER_VALUE_TYPES: {
    FIELDS: "fields",
    STRING: "valueString",
    BOOL: "valueBool",
    INT: "valueInt",
    DOUBLE: "valueDouble",
    DATE_TIME: "valueDateTime",
    MEDIA_ID: "MediaId",
    CHILD_CONDITIONS: "childRuleConditions",
  },
  SUBMISSION_ACTIONS: {
    CREATE: 1,
    UPDATE: 2,
  },
  TASK_ELEMENT_META_ENUMS: {
    Short_Text: 1,
    Long_Text: 2,
    Number: 3,
    Counter: 4,
    Toggle: 5,
    Temperature: 6,
    Image: 7,
    List: 9,
    Checkbox: 10,
    Single_Select: 11,
    Multi_Select: 12,
    Date: 13,
    Time: 14,
    DateTime: 15,
    Slider: 16,
    Yes_No_NA: 17,
    Address: 18,
    Formula: 19,
    File_Upload: 20,
    Signature: 21,
    Divider: 22,
    Note: 23,
    Section: 24,
    Title: 25,
  },
  FORM_FIELD_TYPES: {
    SHORT_TEXT: 1,
    FORMULA: 3,
    SINGLE_SELECT: 2,
    LONG_TEXT: 4,
    TIME: 5,
    TEMPERATURE: 6,
    TOGGLE: 7,
    NUMBER: 8,
    MULTI_SELECT: 9,
    DECIMAL: 10,
    DATE: 11,
    IMAGE_UPLOAD: 13,
    CHECKBOX: 14,
    SLIDER: 15,
    TITLE: 16,
    COUNTER: 19,
    LIST: 20,
    DATE_TIME: 21,
    YES_NO_NA: 22,
    ADDRESS: 23,
    SECTION: 24,
    RADIO: 25,
    MESSAGE: 27,
    OPTIONS: 29,
    FILE_UPLOAD: 30,
    MULTI_CHECKBOX: 31,
    SIGNATURE: 32,
    DIVIDER: 33,
    NOTE: 34,
    FORMULA_BODY: 35,
  },
  FORM_FIELD_TEXT: {
    1: "Short Text",
    2: "Single Select",
    3: "Formula",
    4: "Long Text",
    5: "Time",
    6: "Temperature",
    7: "Toggle",
    8: "Number",
    9: "Multi Select",
    10: "Decimal",
    11: "Date",
    14: "Check Box",
    15: "Slider",
    19: "Counter",
    20: "List",
    25: "Radio",
    27: "Message",
  },
  FIELD_TYPES: {
    TEXT: 1,
    FORMULA: 3,
    SINGLE_SELECT: 2,
    TEXT_AREA: 4,
    TIME: 5,
    TEMPERATURE: 6,
    BOOLEAN: 7,
    INTEGER: 8,
    MULTI_SELECT: 9,
    DECIMAL: 10,
    DATE: 11,
    RADIO: 12,
    IMAGE_UPLOAD: 13,
  },
  FIELD_TEXT: {
    1: "Short Text",
    2: "Single Select",
    3: "Formula",
    4: "Long Text",
    5: "Time",
    6: "Temperature",
    7: "Yes/No",
    8: "Number",
    9: "Multi Select",
    10: "Decimal",
    11: "Date",
  },
  CONDITION_TYPE: {
    1: "LessThan",
    2: "GreaterThan",
    3: "LessThanOrEqualTo",
    4: "GreaterThanOrEqualTo",
    5: "Equals",
    6: "NotEqualTo",
    7: "Contains",
    8: "StartsWith",
    9: "EndsWith",
  },
  OPERATOR_TYPE: {
    1: "AND",
    2: "OR",
  },
  SORT_ORDER: {
    ASC: 1,
    DESC: 2,
  },
  DEFAULT_SORT_KEY: "createdOn",
  USER_ROLES: {
    MANAGER: 1,
    WORKER: 2
    // QA_TECHNICIAN: 2,
    // VIEW_ONLY: 3,
    // MANAGER: 4,
    // QC_TECH: 5,
    // USDA_INSPECTOR: 6,
    // SUPER_ADMIN: 7,
    // REVIEW_ONLY: 8
  },
  USER_ROLE_TYPE_ID: {
    // ADMINS: 1,
    MANAGERS: 1,
    WORKERS: 2,
  },
  USER_ROLES_TYPE: {
    1: "Manager",
    2: "Worker",
    // 3: "View Only",
    // 4: "Manager",
    // 5: "QC Tech",
    // 6: "USDA Inspector",
    // 7: "Super Admin",
    // 8: "Review Only",
  },
  USER_STATUS: {
    ACTIVE: 1,
    INACTIVE: 2,
  },
  PERMISSIONS: {
    DASHBOARD: "DASHBOARD",
    PROGRAM: "PROGRAM",
    WORKORDER: "WORKORDER",
    TASK_TEMPLATE: "TASK_TEMPLATE",
    TASK_SCHEDULER: "TASK_SCHEDULER",
    TASK_SCHEDULES: "TASK_SCHEDULES",
    TASK_ADHOC: "TASK_ADHOC",
    TASK_HISTORY: "TASK_HISTORY",
    LOCATION: "LOCATION",
    REPORT: "REPORT",
    LIVESTOCK: "LIVESTOCK",
    USER: "USER",
    ASSET: "ASSET",
    HOME: "HOME",
    DASHBOARD_ADMIN: "DASHBOARD_ADMIN",
    DASHBOARD_QA: "DASHBOARD_QA",
    NOTIFICATION: "NOTIFICATION",
    
    USER_ACTIVE_HANDLER: 'USER_ACTIVE_HANDLER', 
    USER_EDIT: 'USER_EDIT',
    SHOW_OLD_PASSWORD_FIELD: 'SHOW_OLD_PASSWORD_FIELD',
    USER_CREATE: 'USER_CREATE'
  },
  MAX_IMAGE_ATTACHMENT_SIZE: 20,
  TASK_ELEMENT_META_ENUM: "TaskElementMetaEnum",
  PROGRAMS_META_ENUM: "Program",
};

export const LOCAL_STORAGE_KEYS = {
  USER_FILTERS: 'user_filters',
  USER: "user",
  ACCESS_TOKEN: "accessToken",
  DEPARTMENT: "department",
  TASK_OFFLINE_PAGE_SIZE: "taskOfflinePageSize",
  CONFIGURATION: "configuration",
  PERMISSIONS: "permissions",
  SELECTED_DEPARTMENT: "selected_department",
  LAST_SYNC: "last_sync",
  LAST_SYNC_UI: "last_sync_ui",
  LAST_SYNC_SCHEDULED_TASK: "last_sync_scheduled_task",
  LAST_SYNC_AUTO_SAVE_ADHOC_TASK: "last_sync_auto_save_adhoc_task",
  LAST_SYNC_CORRECTIVE_ACTION: "last_sync_corrective_action",
  LAST_SYNC_ADHOC_TASK: "last_sync_adhoc_task",
  LAST_SYNC_ADHOC_TASK_FOR_CA: "last_sync_adhoc_task_for_ca",
  LAST_SYNC_TASK_HISTORY: "last_sync_task_history",
  LAST_SYNC_LOCATION: "last_sync_location",
  LAST_SYNC_WORKORDER: "last_sync_workorder",
  LAST_SYNC_PROGRAM: "last_sync_program",
};

export const ADMIN_CONFIGURATION_KEYS = {
  COMPANY: {
    NAME: "CompanyName",
    LOGO: "CompanyLogoPath",
  },
  DEFAULT_IDS: {
    DIVISION: "DefaultDivisionId",
    PROGRAM: "DefaultProgramId",
    WORKORDER: "DefaultWorkOrderId",
  },
  PROGRAM: {
    VISIBILITY: "IsProgramVisible",
    TEXT: "ProgramText",
    DEFAULT_TEXT: "Program",
    PL_TEXT: "ProgramsText",
    DEFAULT_PL_TEXT: "Programs",
  },
  SOP_MANUAL: {
    VISIBILITY: "IsProgramManualVisible",
    TEXT: "ProgramManualText",
    DEFAULT_TEXT: "Program",
    PL_TEXT: "ProgramManualsText",
    DEFAULT_PL_TEXT: "Programs",
  },
  LOCATION: {
    VISIBILITY: "IsLocationVisible",
    TEXT: "LocationText",
    DEFAULT_TEXT: "Location",
    PL_TEXT: "LocationsText",
  },
  WORKORDER: {
    VISIBILITY: "IsWorkOrderVisible",
    TEXT: "WorkOrderText",
    DEFAULT_TEXT: "Workorder",
    PL_TEXT: "WorkOrdersText",
    DEFAULT_PL_TEXT: "Work Orders",
    ABBR: "WOText",
    DEFAULT_ABBR: "WO",
  },
  TASK: {
    VISIBILITY: "IsTaskVisible",
    TEXT: "TaskText",
    DEFAULT_TEXT: "Task",
    PL_TEXT: "TasksText",
    DEFAULT_PL_TEXT: "Tasks",
  },
  ADHOC_TASK: {
    VISIBILITY: "IsAdhocTaskVisible",
    TEXT: "AdhocTaskText",
    DEFAULT_TEXT: "AdhocTasks",
  },
  LIVESTOCK: {
    VISIBILITY: "IsLivestockVisible",
    TEXT: "LivestockText",
    DEFAULT_TEXT: "Livestock",
    PL_TEXT: "LivestocksText",
    DEFAULT_PL_TEXT: "Livestocks",
  },
  INCLUDE_ALL_PUBLISHED_TASKS_OFFLINE: {
    VISIBILITY: "IncludeAllPublishedTasksOffline",
  },
};

export const FORM_CONSTANTS = {
  TASK_SUBMISSION_FORM: {
    FORM_NAME: "qaTaskSubmissionForm",
  },
  ADHOC_TASKS_SUBMISSION_FORM: {
    FORM_NAME: "adhocTasksSubmissionForm",
  },
  LIVE_STOCK_FORM: {
    FORM_NAME: "qaLiveStockForm",
  },
  RESET_PASSWORD_FORM: {
    FORM_NAME: "resetPasswordForm",
  },
  FORGOT_PASSWORD_FORM: {
    FORM_NAME: "forgotPasswordForm",
  },
  TASK_FORM: {
    FORM_FIELD_LIST_NAME: "formFieldList",
    FORM_SUBLIST_NAME: "formFields",
    CONDITIONS_SUBLIST_NAME: "conditions",
    ADD_TASK: "addTaskForm",
    EDIT_TASK: "editTaskForm",
    VIEW_TASK: "viewTaskForm",
  },
  USER_FORM: {
    ADD_USER: "addUserForm",
    EDIT_USER: "editUserForm",
    VIEW_USER: "viewUserForm",
  },
  WORKORDER_FORM: {
    FORM_SUBLIST_NAME: "formFields",
    ADD_WORKORDER: "addWorkorderForm",
    EDIT_WORKORDER: "editWorkorderForm",
    VIEW_WORKORDER: "viewWorkorderForm",
    QA_WORKORDER_VIEW: "qaWorkorderViewForm",
  },
  PROGRAM_FORM: {
    FORM_SUBLIST_NAME: "formFields",
    ADD_PROGRAM: "addProgramForm",
    EDIT_PROGRAM: "editProgramForm",
    VIEW_PROGRAM: "viewProgramForm",
  },
  PROGRAM_MANUAL_FORM: {
    FORM_SUBLIST_NAME: "formFields",
    ADD_PROGRAM_MANUAL: "addProgramManualForm",
    EDIT_PROGRAM_MANUAL: "editProgramManualForm",
    VIEW_PROGRAM_MANUAL: "viewProgramManualForm",
  },
  REVIEW_FORM: {
    FORM_SUBLIST_NAME: "formFields",
    ADD_REVIEW: "addReviewForm",
    EDIT_REVIEW: "editReviewForm",
    VIEW_REVIEW: "viewReviewForm",
  },
  ASSET_FORM: {
    ADD_ASSET: "addAssetForm",
  },
  SKIP_TASK_FORM: {
    SKIP_FORM: "skipTaskForm",
  },
  LOCATION_FORM: {
    FORM_SUBLIST_NAME: "formFields",
    LOCATION_FORM: "locationForm",
    ADD_LOCATION: "addLocationForm",
    EDIT_LOCATION: "editLocationForm",
    VIEW_LOCATION: "viewLocationForm",
    LOCATION_TYPE: "locationTypeIds",
    LOCATION_MANAGER: "managerIds",
    STATUS: "status",
  },
  SETTINGS_FORM: {
    ACCOUNT_DETAILS_SETTING: "detailsSettingsForm",
    CHANGE_PASSWORD_SETTING: "changePasswordSettingsForm",
  },
  TASK_RULES: {
    TASK_RULES: "taskRules",
  },
  REPORT_FORM: {
    GENERATE_REPROT: "generateReport",
  },
};

export const TASK_LIST_COLUMNS = [
  {
    label: "SI_TASK #",
    id: "taskNumber",
    sortable: false,
  },
  {
    label: "Task Id",
    id: "id",
    type: "hidden",
    sortable: false,
  },
  {
    label: "Title",
    id: "title",
    sortable: false,
  },
  {
    label: "SI_WORKORDER",
    id: "workorder",
    sortable: true,
  },
  {
    label: "Location Name",
    id: "locationName",
    sortable: false,
  },
  {
    label: "Frequency",
    id: "frequency",
    sortable: true,
  },
  {
    label: "Created By",
    id: "createdBy",
    sortable: false,
  },
  {
    label: "State",
    id: "status",
    sortable: true,
  },
  // {
  //     label: "Revision Date",
  //     id: "revisionDateTime",
  //     sortable: true
  // },
  {
    label: "Last Updated",
    id: "updatedOn",
    sortable: true,
  },
];

export const TASK_DESIGNER_LIST_COLUMNS = [
  {
    label: "SI_TASK #",
    id: "taskNumber",
    sortable: true,
  },
  {
    label: "Title",
    id: "taskDetailTitle",
    sortable: true,
  },
  {
    label: "SI_WORKORDER #",
    id: "workorderNumber",
    sortable: true,
  },
  {
    label: "Location(s)",
    id: "location",
    sortable: false,
  },
  // {
  //   label: "Frequency",
  //   id: "frequency",
  //   sortable: true,
  // },
  {
    label: "Created By",
    id: "createdBy",
    sortable: false,
  },
  {
    label: "State",
    id: "taskActivityStatus",
    sortable: true,
  },
  {
    label: "Last Updated",
    id: "updatedOn",
    sortable: true,
  },
  // {
  //   label: "Action",
  //   id: "action",
  //   sortable: false,
  // },
];

export const TASK_SUBMISSION_LIST_COLUMNS = [
  {
    label: "Title",
    id: "taskName",
    sortable: true,
  },
  {
    label: "SI_WORKORDER #",
    id: "workorderNumber",
    sortable: true,
  },
  {
    label: "Location",
    id: "locationName",
    sortable: false,
  },
  {
    label: "SI_TASK Type",
    id: "taskType",
    sortable: true,
  },
  {
    label: "Occurrence",
    id: "OccurenceEnumId",
    sortable: true,
  },
  {
    label: "Status",
    id: "status",
    sortable: true,
  },
  {
    label: "Completed By",
    id: "submittedBy",
    sortable: false,
  },
  {
    label: "Completed On",
    id: "submittedOn",
    sortable: true,
  },
];

export const TASK_SUBMISSION_LIST_COLUMNS_SINGLE_TASK = [
  {
    label: "Location",
    id: "locationName",
    sortable: false,
  },
  {
    label: "Status",
    id: "status",
    sortable: true,
  },
  {
    label: "Completed By",
    id: "submittedBy",
    sortable: false,
  },
  {
    label: "Completed On",
    id: "submittedOn",
    sortable: true,
  },
];

export const TASK_SUBMISSION_LIST_COLUMNS_LOCATIONS = [
  {
    label: "SI_TASK Name",
    id: "taskName",
    sortable: true,
  },
  {
    label: "SI_WORKORDER #",
    id: "workorderNumber",
    sortable: true,
  },
  {
    label: "SI_TASK Type",
    id: "taskType",
    sortable: true,
  },
  {
    label: "Frequency",
    id: "frequency",
    sortable: true,
  },
  {
    label: "Status",
    id: "status",
    sortable: true,
  },
  {
    label: "Completed By",
    id: "submittedBy",
    sortable: false,
  },
  {
    label: "Completed On",
    id: "submittedOn",
    sortable: true,
  },
];

export const TODAY_TASKS_LIST_COLUMNS = [
  {
    label: "Due Date",
    id: "dueDate",
    sortable: true,
  },
  {
    label: "SI_TASK #",
    id: "taskNumber",
    sortable: false,
  },
  {
    label: "Title",
    id: "title",
    sortable: false,
  },
  {
    label: "Frequency",
    id: "frequency",
    sortable: true,
  },
  {
    label: "Status",
    id: "status",
    sortable: true,
  },
  {
    label: "Last Updated",
    id: "updatedOn",
    sortable: true,
  },
];

export const ADHOC_TASK_LIST_COLUMNS = [
  {
    label: "Task #",
    id: "taskNumber",
    sortable: false,
  },
  {
    label: "Task Title",
    id: "title",
    sortable: false,
  },
  {
    label: "WorkOrder #",
    id: "workorder",
    sortable: true,
  },
  {
    label: "Frequency",
    id: "frequency",
    sortable: true,
  },
  // {
  //   label: "Location Name",
  //   id: "locationName",
  //   sortable: false,
  // },
  {
    label: "Created By",
    id: "createdBy",
    sortable: false,
  },
];

export const ADHOC_TASK_DESIGNER_LIST_COLUMNS = [
  {
    label: "SI_TASK #",
    id: "taskNumber",
    sortable: true,
  },
  {
    label: "SI_TASK Title",
    id: "taskDetailTitle",
    sortable: true,
  },
  {
    label: "SI_WORKORDER #",
    id: "workorderNumber",
    sortable: true,
  },
  {
    label: "SI_PROGRAM #",
    id: "programNumber",
    sortable: false,
  },
  // {
  //   label: "Frequency",
  //   id: "frequency",
  //   sortable: true,
  // },
  // {
  //   label: "Priority",
  //   id: "priority",
  //   sortable: true,
  // },
  // {
  //   label: "Created By",
  //   id: "createdBy",
  //   sortable: false,
  // },
];

export const TODAY_SUB_TASKS_LIST_COLUMNS = [
  /*{
        label: "Due Date",
        id: "dueDate",
        sortable: true
    },*/
  {
    label: "SubSI_TASK #",
    id: "taskNumber",
    sortable: false,
  },
  {
    label: "Title",
    id: "title",
    sortable: false,
  },
  {
    label: "Status",
    id: "status",
    sortable: true,
  },
  {
    label: "Completed On",
    id: "updatedOn",
    sortable: true,
  },
];

export const SUB_TASK_LIST_COLUMNS = [
  {
    label: "SubSI_TASK #",
    id: "taskNumber",
    sortable: true,
  },
  {
    label: "Task Id",
    id: "id",
    type: "hidden",
    sortable: true,
  },
  {
    label: "Title",
    id: "title",
    sortable: true,
  },
  {
    label: "Last Revision Date",
    id: "revisionDateTime",
    sortable: true,
  },
  {
    label: "Status",
    id: "status",
    sortable: true,
  },
];

export const SUB_TASK_DESIGNER_LIST_COLUMNS = [
  {
    label: "SubSI_TASK #",
    id: "taskNumber",
    sortable: true,
  },
  {
    label: "Title",
    id: "taskDetailTitle",
    sortable: true,
  },
  {
    label: "Created By",
    id: "createdBy",
    sortable: false,
  },
  {
    label: "State",
    id: "taskActivityStatus",
    sortable: true,
  },
  {
    label: "Last Updated",
    id: "updatedOn",
    sortable: true,
  },
];

export const TASK_SUBMIT_HISTORY_LIST_COLUMNS = [
  {
    label: "Location",
    id: "locationName",
    sortable: true,
  },
  {
    label: "Status",
    id: "status",
    sortable: true,
  },
  {
    label: "Completed By",
    id: "completedBy",
    sortable: true,
  },
  {
    label: "Completed On",
    id: "completedOn",
    sortable: true,
  },
];

export const WORKORDER_LIST_COLUMNS = [
  {
    label: "SI_WORKORDER #",
    id: "workorderNumber",
    sortable: true,
  },
  {
    label: "workorder Id",
    id: "id",
    type: "hidden",
    sortable: true,
  },
  {
    label: "Title",
    id: "title",
    sortable: true,
  },
  {
    label: "SI_PROGRAM",
    id: "program",
    sortable: true,
  },
  // {
  //   label: "Last Revision Date",
  //   id: "revisionDateTime",
  //   sortable: true,
  // },
  // {
  //   label: "Review",
  //   id: "reviewStatus",
  //   sortable: true,
  // },
  {
    label: "State",
    id: "status",
    sortable: true,
  },
  {
    label: "Created By",
    id: "createdBy",
    sortable: false,
  },
  {
    label: "Last Updated",
    id: "updatedOn",
    sortable: true,
  },
];

export const QA_WORKORDER_LIST_COLUMNS = [
  {
    label: "SI_WORKORDER #",
    id: "workorderNumber",
    sortable: true,
  },
  {
    label: "workorder Id",
    id: "id",
    type: "hidden",
    sortable: true,
  },
  {
    label: "Title",
    id: "title",
    sortable: true,
  },
  {
    label: "SI_PROGRAM",
    id: "program",
    sortable: true,
  },
  {
    label: "Last Revision Date",
    id: "revisionDateTime",
    sortable: true,
  },
  /*{
        label: "Review",
        id: "reviewStatus",
        sortable: true
    }, {
        label: "Status",
        id: "status",
        sortable: true
    }*/
];

export const USER_LIST_COLUMNS = [
  {
    label: "User Id",
    id: "id",
    type: "hidden",
    sortable: true,
  },
  {
    label: "Name",
    id: "fullName",
    sortable: true,
  },
  /*{
        label: "Division",
        id: "division",
        sortable: true
    },*/
  {
    label: "Last Active",
    id: "lastActive",
    sortable: true,
  },
  {
    label: "Role",
    id: "role",
    sortable: true,
  },
  {
    label: "Type",
    id: "type",
    sortable: true,
  },
];

export const MODAL_CLASS = {
  SMALL: "modal-sm",
  MEDIUM: "",
  LARGE: "modal-lg",
};

export const LOCATION_LIST_COLUMNS = [
  {
    label: "Name",
    id: "locationName",
    sortable: true,
  },
  {
    label: "Location Type",
    id: "locationType",
    sortable: true,
  },
  {
    label: "Manager",
    id: "managerName",
    sortable: true,
  },
  {
    label: "Worker(s) Count",
    id: "workerCount",
    sortable: true,
  },
  {
    label: "Status",
    id: "status",
    sortable: true,
  },
  {
    label: "Created On",
    id: "createdOn",
    sortable: true,
  },
];

export const SCHEDULER_LIST_COLUMNS = [
  {
    label: "SI_TASK #",
    id: "taskNumber",
    sortable: true,
  },
  {
    label: "Title",
    id: "title",
    sortable: true,
  },
  {
    label: "SI_WORKORDER",
    id: "workOrder",
    sortable: true,
  },
  {
    label: "Priority",
    id: "priorityId",
    sortable: true,
  },
  {
    label: "Occurrence",
    id: "occurrence",
    sortable: false,
  },
  {
    label: "Location(s)",
    id: "locationName",
    sortable: false,
  },
  {
    label: "Worker(s)",
    id: "workerName",
    sortable: false,
  },
  {
    label: "Scheduled By",
    id: "scheduledBy",
    sortable: true,
  },
];

export const PROGRAM_MANUAL_LIST_COLUMNS = [
  {
    label: "SI_SOP_MANUAL #",
    id: "programNumber",
    sortable: true,
  },
  {
    label: "Title",
    id: "title",
    sortable: false,
  },
  {
    label: "Type",
    id: "type",
    sortable: false,
  },
  {
    label: "Location(s)",
    id: "location",
    sortable: false,
  },
  {
    label: "Revisions",
    id: "revisionNumber",
    sortable: false,
  },
  {
    label: "Last Revision",
    id: "revisionDateTime",
    sortable: true,
  },
  {
    label: "Status",
    id: "status",
    sortable: false,
  },
];

export const PROGRAM_MANUAL_LIST_COLUMNS_WORKER = [
  {
    label: "SI_SOP_MANUAL #",
    id: "programNumber",
    sortable: true,
  },
  {
    label: "Title",
    id: "title",
    sortable: false,
  },
  {
    label: "Type",
    id: "type",
    sortable: false,
  },
  {
    label: "Location(s)",
    id: "location",
    sortable: false,
  },
  {
    label: "Revisions",
    id: "revisionNumber",
    sortable: false,
  },
  {
    label: "Last Revision",
    id: "revisionDateTime",
    sortable: true,
  },
];

export const REVIEWS_LIST_COLUMNS = [
  {
    label: "SI_TASK #",
    id: "taskNumber",
    sortable: false,
  },
  {
    label: "Title",
    id: "taskTitle",
    sortable: true,
  },
  {
    label: "Type",
    id: "type",
    sortable: false,
  },
  {
    label: "SI_WORKORDER",
    id: "workOrder",
    sortable: false,
  },
  {
    label: "Occurrence",
    id:"occurrence",
    sortable: false
  },
  {
    label: "Location",
    id: "location",
    sortable: false,
  },
  {
    label: "Completed",
    id: "submissions",
    sortable: false,
  },
  {
    label: "Reviewed",
    id: "reviews",
    sortable: false,
  },
  {
    label: "Due Date",
    id: "dueDate",
    sortable: true,
  },
  {
    label: "Status",
    id: "status",
    sortable: false,
  },
];

export const REVIEWS_LIST_COLUMNS_WORKER = [
  {
    label: "SI_TASK #",
    id: "taskNumber",
    sortable: false,
  },
  {
    label: "Title",
    id: "taskTitle",
    sortable: true,
  },
  {
    label: "Type",
    id: "type",
    sortable: false,
  },
  {
    label: "SI_WORKORDER",
    id: "workOrder",
    sortable: false,
  },
  {
    label: "Location",
    id: "location",
    sortable: false,
  },
  {
    label: "Completed",
    id: "submissions",
    sortable: false,
  },
  {
    label: "Reviewed",
    id: "reviews",
    sortable: false,
  },
  {
    label: "Due Date",
    id: "dueDate",
    sortable: true,
  },
  {
    label: "Status",
    id: "status",
    sortable: false,
  },
];

export const REVIEW_BASIC_INFO = [
  { label: "Task Number", value: "taskNumber" },
  { label: "Review Type", value: "taskNumber" },
];

export const REVIEW_SUBMISSION_COLUMNS = [
  {
    label: "Completed By",
    id: "submittedBy",
    sortable: false,
  },
  {
    label: "Completed On",
    id: "submittedOn",
    sortable: false,
  },
  {
    label: "Occurrence",
    id: "occurrence",
    sortable: false,
  },
  {
    label: "Violations",
    id: "violations",
    sortable: false,
  },
];

export const REVIEW_HISTORY_LIST_COLUMNS = [
  {
    label: "SI_TASK #",
    id: "reviewNumber",
    sortable: false,
  },
  {
    label: "Title",
    id: "taskTitle",
    sortable: true,
  },
  {
    label: "Review Type",
    id: "type",
    sortable: false,
  },
  {
    label: "SI_WORKORDER",
    id: "workOrder",
    sortable: false,
  },
  {
    label: "Occurrence",
    id:"occurrence",
    sortable: false
  },
  {
    label: "Location",
    id: "location",
    sortable: false,
  },
  {
    label: "Reviewed",
    id: "reviews",
    sortable: false,
  },
  {
    label: "Reviewed By",
    id: "reviewedBy",
    sortable: false,
  },
  {
    label: "Reviewed On",
    id: "reviewedOn",
    sortable: true,
  },
  {
    label: "Status",
    id: "status",
    sortable: false,
  },
];

export const PROGRAM_LIST_COLUMNS = [
  {
    label: "SI_PROGRAM #",
    id: "programNumber",
    sortable: true,
  },
  {
    label: "Title",
    id: "title",
    sortable: true,
  },
  {
    label: "Division",
    id: "division",
    sortable: true,
  },
  // {
  //   label: "Last Revision Date",
  //   id: "revisionDateTime",
  //   sortable: true,
  // },
  {
    label: "State",
    id: "status",
    sortable: true,
  },
  {
    label: "Created By",
    id: "createdBy",
    sortable: false,
  },
  // {
  //   label: "Created On",
  //   id: "createdOn",
  //   sortable: true,
  // },
  {
    label: "Last Updated",
    id: "updatedOn",
    sortable: true,
  },
];

export const LIVESTOCK_LIST_COLUMNS = [
  {
    label: "Name/Ear Tag",
    id: "earTag",
    remainSameLabel: true,
    sortable: true,
  },
  {
    label: "Category",
    id: "category",
    sortable: true,
  },
  {
    label: "Status",
    id: "status",
    sortable: true,
  },
  {
    label: "Created By",
    id: "createdBy",
    sortable: true,
  },
  {
    label: "Created On",
    id: "createdOn",
    sortable: true,
  },
  {
    label: "Last Updated",
    id: "updatedOn",
    sortable: true,
  },
];

export const TASK_RULES = {
  ACTIONS: {
    ADD: "ADD",
    DELETE: "DELETE",
    EDIT: "EDIT",
  },
};

/**
 * DASHBOARD CONSTANTS
 */

export const DUE_TASK_TODAY_LIST_COLUMNS = [
  {
    label: "Task Name",
    id: "taskName",
    sortable: true,
  },
  {
    label: "Task Number",
    id: "taskNumber",
    sortable: true,
  },
  {
    label: "Work Order #",
    id: "workorderNumber",
    sortable: true,
  },
  {
    label: "Location",
    id: "locationName",
    sortable: true,
  },
];

export const DUE_TASK_SOON_LIST_COLUMNS = [
  {
    label: "SI_TASK #",
    id: "taskNumber",
    sortable: true,
  },
  {
    label: "SI_TASK Title",
    id: "taskName",
    sortable: true,
  },
  {
    label: "SI_WORKORDER #",
    id: "workorderNumber",
    sortable: true,
  },
  {
    label: "Location",
    id: "locationName",
    sortable: true,
  },
  {
    label: "Occurrence",
    id: "OccurenceEnumId",
    sortable: true,
  },
  {
    label: "Priority",
    id: "PriorityEnumID",
    sortable: true,
  },
  {
    label: "Start Time",
    id: "submissionStartTime",
    sortable: true,
  },
  {
    label: "End Time",
    id: "submissionEndTime",
    sortable: true,
  },
  // {
  //   label: "Due Date",
  //   id: "dueDate",
  //   sortable: true,
  // },
];

export const DASHBOARD_WORKERS_ACTIVE_TODAY = [
  {
    label: "Name",
    transalationLabel: "NAME",
    id: "fullName",
  },
  {
    label: "Division",
    transalationLabel: "DIVISION",
    id: "division.title",
  },
  {
    label: "Role",
    transalationLabel: "ROLE",
    id: "role",
    enum: "USER_ROLE",
  },
  {
    label: "Type",
    transalationLabel: "TYPE",
    id: "type",
    enum: "USER_TYPE",
  },
  {
    label: "User Id",
    id: "id",
    type: "hidden",
  },
  // {
  //     label: "Last Active",
  //     transalationLabel: "LAST_ACTIVE",
  //     id: "lastLoginDate"
  // }
];

export const DASHBOARD_RECENT_SUBMISSIONS_COLUMNS = [
  {
    label: "Task",
    transalationLabel: "TASK",
    id: "title",
  },
  {
    label: "Work Order",
    transalationLabel: "WORKORDER",
    id: "workorder.title",
  },
  {
    label: "Division",
    transalationLabel: "DIVISION",
    id: "workorder.program.division.title",
  },
  {
    label: "Created At",
    transalationLabel: "CREATED_AT",
    id: "createdOn",
    filterCallback(value) {
      let formatttedDate = null;
      formatttedDate = !!value ? moment(value).format(DATE_FORMAT_YEAR) : "";
      return formatttedDate;
    },
  },
  {
    label: "Task Id",
    id: "id",
    type: "hidden",
  },
];

export const NOTIFICATIONS = {
  UPDATE_TIME: 60000,
  HEADER_NOTIFICATION_COUNT: 10,
  TYPES: {
    REJECTED_TASK_ASSIGNMENT: 1,
    PENDING_APPROVAL: 2,
    TASK_ACCEPTED: 3,
    TASK_REJECTED: 4,
    CORRECTIVE_ACTION_ASSIGNMENT: 5,
    EDITED_SUBMISSION: 6,
    SKIPPED_SUBMISSION: 7,
  }
};

export const DASHBAORD_COLLAPSABLE_TYPES = {
  WORKRODER_STATISTICS: 0,
  RECENT_SUBMISSION: 1,
  LIMIT_RULE_VIOLTION: 2,
  WORKERS_ACTIVE_TODAY: 3,
  PENDING_WORKORDERS: 4,
  MISSED_WORKORDERS: 5,
};

export const BM_LIST_ITEM_TYPES = {
  LIMIT_RULE_VIOLTION: 2,
  PENDING_WORKORDERS: 4,
  MISSED_WORKORDERS: 5,
};

export const WORKORDER_PERIOD_TYPES = {
  TODAY: 1,
  WEEK: 2,
  MONTH: 3,
  YEARLY: 4,

  1: "WORKORDER_PERIOD_TODAY",
  2: "WORKORDER_PERIOD_WEEK",
  3: "WORKORDER_PERIOD_MONTH",
  4: "WORKORDER_PERIOD_YEARLY",
};

export const PROGRAM_MANUAL = {
  TYPE: {
    HACCP: 1,
    SOP: 2,
    SSOP: 3,
    SPS: 4,
    Others: 5,
  },
  LABEL: {
    ALL: "ALL",
    HACCP: "HACCP",
    SOP: "SOP",
    SSOP: "SSOP",
    SPS: "SPS",
    Others: "Others",
  },
  STATUS: {
    DRAFT: 1,
    PUBLISH: 2,
    INACTIVE: 3
  },
  STATUSES:{
    PUBLISH: 1,
    DRAFT: 2,
    ACTIVE: 3,
    INACTIVE: 4,
    ARCHIVED: 5,
  }
}

export const SCHEDULER = {
  FREQUENCY: {
    DOES_NOT_REPEAT: 1,
    DAILY: 2,
    WEEKLY: 3,
    MONTHLY: 4,
    YEARLY: 5,
  },
  PRIORITY: {
    HIGH: "1",
    MEDIUM: "2",
    LOW: "3",
  },
};

// EO | DASHBOARD CONSTANTS

/**
 * DATE PICKER CONSTANTS
 */

export const DATE_PICKER_TYPES = {
  FROM: "from",
  TO: "to",
};

// EO | DATE PICKER CONSTANTS

/**
 * CSS Constants
 */

export const STYLE_CONSTANTS = {
  SORT_CLASS: {
    1: "asc",
    2: "desc",
  },
};

//EO | CSS Constants

/**
 * Platform constants
 */
export const PLATFORMS = {
  MOBILE: "mobile",
  WEB: "web",
};

// EO | Platform constants

export const REPORTS_PERIOD_TYPES = {
  TODAY: 1,
  WEEK: 2,
  MONTH: 3,
  YEARLY: 4,

  1: "WORKORDER_PERIOD_TODAY",
  2: "WORKORDER_PERIOD_WEEK",
  3: "WORKORDER_PERIOD_MONTH",
  4: "WORKORDER_PERIOD_YEARLY",
};

export const TaskSubmissionStatus = {
  PENDING_SYNC: -1,
  PENDING: 1,
  COMPLETED: 2,
  MISSED: 3,
  ALL: 4,
};

export const FormSubmissionStatusEnums = {
  PENDING_SYNC: -1,
  PENDING: 1,
  COMPLETED: 2,
  INCOMPLETE: 3,
  PENDING_APPROVAL: 4,
  REJECTED: 5,
  ACCEPTED: 6,
  NOT_PERFORMED: 7,
  SUBMITTED: 8,
};

export const STATS_KEY = {
  COMPLETED: "completed",
  TOTAL: "total",
  INCOMPLETE: "incomplete",
  PENDING: "pending",
};

export const TASK_VIEW_TYPE = {
  ADHOC: "ADHOC",
  SCHEDULED: "SCHEDULED",
};

export const FORM_SUBMISSION_MASTER_TYPE = {
  ADHOC: 1,
  SCHEDULED: 2,
  CORRECTIVE_ACTION: 3,
};

export const TASK_TYPE_ENUM_ID = {
  TEMPLATE: 1,
  CORRECTIVE_ACTION: 2,
};

export const DATE_FORMAT = {
  DAY_MONTH_YEAR: {
    month: "long",
    day: "numeric",
    year: "numeric",
  },
  DATE_MONTH_TIME: {
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    // second: "numeric",
  },
};

export const FREQUENCY_CALENDAR = {
  Daily: 1,
  Weekly: 2,
  Monthly: 3,
  Quarterly: 4,
  Yearly: 5,
  Adhoc: -1,
};

export const OCCURRENCE = {
  DOES_NOT_REPEAT: 1,
  DAILY: 2,
  WEEKLY: 3,
  MONTHLY: 4,
  YEARLY: 5,
};

export const SCHEDULER_FREQUENCY_MOBILE = [
  {
    label: "Does Not Repeat",
    value: 1,
  },
  {
    label: "Daily",
    value: 2,
  },
  {
    label: "Weekly",
    value: 3,
  },
  {
    label: "Monthly",
    value: 4,
  },
  {
    label: "Yearly",
    value: 5,
  },
];

export const PRIORITY = {
  HIGH: 1,
  MEDIUM: 2,
  LOW: 3,
};

export const RECENT_SUBMISSION_COUNT = 3; // most recent submission to show in offline mode in task history

// This definition of MAX_DATE is taken from JavaScript's official standards (http://www.ecma-international.org/ecma-262/5.1/#sec-15.9.1.1).
// A value greater than 8640000000000000 returns invalid date.
export const MAX_DATE = new Date(8640000000000000);

export const MOBILE_IMAGE_SESSION = "MOBILE_IMAGE_SESSION";

export const UI_SYNC_TIME_INTERVAL = 2000; // Sync UI with the state after every X secs.

export const SUBMISSION_TYPE = {
  SUBMIT: "SUBMIT",
  DELETE: "DELETE",
  SUBMITTED: "SUBMITTED",
};

export const REVIEW_STATUS = {
  ACCEPT: "Accept",
  REJECT: "Reject",
  NOT_APPLICABLE: "Not Applicable",
};

export const REVIEW_STATUS_ID = {
  ACCEPT: 1,
  REJECT: 2,
  NOT_APPLICABLE: 3,
};

export const STANDARD_TABLET_WIDTH = 750;

export const REQUEST_PAGE_ENUM = {
  DEFAULT: 0,
  CORRECTIVE_ACTION: 1,
  SCHEDULED_TASKS: 3,
  REVIEW: 4,
  TASK_HISTORY: 6,
  NOTIFICATION: 7,
};

export const PRIORITY_FILTER = [
  { id: 1, name: "High" },
  { id: 2, name: "Medium" },
  { id: 3, name: "Low" },
];

export const NOT_PERFORMED_REASONS_ENUM = [
  { value: 1, label: "Breakdown" },
  { value: 2, label: "Department Not Operating" },
  { value: 3, label: "Finished Early" },
  { value: 4, label: "Late Start" },
  { value: 5, label: "Plant Closure" },
  { value: 6, label: "Other" },
];
export const PLATFORM_KEYS = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop'
}