import { ENUM_ACTIONS } from "../../constants/ActionConstants";
import { enumServices } from "../../sevices/api";
import { request, success, failure } from "./index";
import MetadataManager from "../../database/dataManagers/MetadataManager";

export function getAllEnumns() {
  return function (dispatch) {
    dispatch(request(ENUM_ACTIONS.GET_ENUMS_REQUEST));
    enumServices.getAllEnumns().then(
      (data) => {
        dispatch(success(ENUM_ACTIONS.GET_ENUMS_SUCCESS, data));
      },
      (error) => {
        dispatch(failure(ENUM_ACTIONS.GET_ENUMS_FAILURE, error.toString()));
      },
    );
  };
}

export function getMetaDataValues(meta, status) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(ENUM_ACTIONS.GET_METADATA_VALUES_REQUEST));
      enumServices.getMetaDataValues(meta, status).then(
        (data) => {
          dispatch(
            success(ENUM_ACTIONS.GET_METADATA_VALUES_SUCCESS, { data, meta }),
          );
          resolve(data);
        },
        (error) => {
          dispatch(
            failure(ENUM_ACTIONS.GET_METADATA_VALUES_FAILURE, error.toString()),
          );
          reject(error.toString());
        },
      );
    });
  };
}

export function syncMetadata(meta) {
  return async function (dispatch) {
    try {
      const data = await getMetaDataValues(meta)(dispatch);
      MetadataManager.saveMetadata(meta, data.result);
    } catch (error) {
      console.log(
        "syncMetadata errorrrr",
        JSON.stringify(error),
        JSON.parse(error),
      );
    }
  };
}
