import axios from "axios";
import StorageService from "../storageService";
import NavigationService from "../navigationService";
import AccessToken, { BarnManagerEnvironment } from "./ApiClient";
import { API_CONSTANTS } from "../../constants/ApiConstants";

/**
 * Response interceptor
 */
axios.interceptors.response.use(
  (response) => response,
  async function (error) {
    // console.log('interceptors error', error);
    const originalRequest = error.config;
    try {
      if (error.response &&
        error.response.status === 401 &&
        originalRequest.url.includes("authenticate")
      ) {
        return Promise.reject(error);
      } else if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const headers = { token: AccessToken.token };
        const res = await axios.get(
          `${BarnManagerEnvironment.config().baseURL}${
            API_CONSTANTS.API_ROUTES.REFRESH_TOKEN
          }`,
          { headers }
        );
        if (res && res.status === 200) {
          StorageService.instance.setAccessToken(res.data.result.accessToken);
          AccessToken.token = res.data.result.accessToken;
          originalRequest.headers[
            "Authorization"
          ] = `Bearer ${res.data.result.accessToken}`;
          return axios(originalRequest);
        }
        return Promise.reject(error);
      }
      return Promise.reject(error);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export function handleResponse(response) {
  // console.log("api response", response);
  if (
    response.data.responseCode === 200 ||
    response.data.httpResponseCode === 200 ||
    response.status === 200
  ) {
    //for new Apis
    return response.data;
  } else {
    handleError(response);
  }
}

export function handleError(error) {
  // console.log('handleError', error);
  let errorMessage = "Network Error";
  if (
    (error && error.status === 401) ||
    (!!error.response && error.response.status === 401)
  ) {
    if (!error.config.url.includes("authenticate")) {
      NavigationService.instance.redirectToLogin();
      errorMessage = "Un Authorized";
    }
  }
  if (!!error.response && error.response.data) {
    if (error.response.data.status === 400) {
      errorMessage = error.response.data.errors || {};
    } else {
      errorMessage = error.response.data.message || "";
    }
  }

  throw errorMessage;
}
