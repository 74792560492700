import { updateLocallizationConstants } from "../../helpers/GeneralHelper";

export default class ReactNativeLocalizationService {
    constructor(localization) {
        this.localization = localization;

    }

    getLocalization() {
        try {
            const local = updateLocallizationConstants(this.localization);
            return local;
        }
        catch (error) {
            return false;
        }

    }

}