class WorkorderModel {}

WorkorderModel.schema = {
  name: "Workorder",
  primaryKey: "id",
  properties: {
    id: "int",
    locations: { type: "Location[]", default: [] },
    title: "string?",
    workorderNumber: "string?",
  },
};

export default WorkorderModel;
