import EcoDocsDB, { PROGRAM_SCHEMA_NAME } from "..";
import { GENERAL_MAPPER_DB_TO_STATE } from "../../models/databaseModels/general";
import { MapAPIToDB, MapDBToState } from "../../models/databaseModels/program";

class ProgramManager {
  async savePrograms(programs) {
    const db = await EcoDocsDB.getConnection();
    db.write(() => {
      programs.forEach((program) => {
        db.create(PROGRAM_SCHEMA_NAME, MapAPIToDB(program), true);
      });
      console.log("programs saved: ", programs && programs.length);
    });
    // EcoDocsDB.close(db);
  }

  async getPrograms() {
    const db = await EcoDocsDB.getConnection();
    const results = db.objects(PROGRAM_SCHEMA_NAME).sorted("name");
    const programs = results.map((program) =>
      Object.assign({}, MapDBToState(program))
    );
    // EcoDocsDB.close(db);

    return new GENERAL_MAPPER_DB_TO_STATE(programs);
  }
}

export default new ProgramManager();
