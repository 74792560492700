import { API_CONSTANTS } from "../../constants/ApiConstants";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { apiClient } from "./ApiClient";

export const divisionService = {
  getDivisions,
};

function getDivisions() {
  return apiClient()
    .get(`${API_CONSTANTS.API_ROUTES.GET_ALL_DIVISIONS}`)
    .then(handleResponse)
    .catch(handleError);
}
