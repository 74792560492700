import moment from "moment";
import { PageData, SortData, SearchTerm } from "./General";
import { BMMultiSelect } from "./Form";
import {
  FORM_SUBMISSION_MASTER_TYPE,
  GENERIC_CONSTANTS,
  TASK_VIEW_TYPE,
  FormSubmissionStatusEnums,
  RECENT_SUBMISSION_COUNT,
  OCCURRENCE,
  PRIORITY,
  REQUEST_PAGE_ENUM,
  TASK_TYPE_ENUM_ID,
} from "../constants/GenericConstants";
import { parseDateForServer } from "../helpers/GeneralHelper";
import { getDueDate } from "../helpers/TaskHelper";

export const QAFormRequest = (props) => ({
  listFormSubmission: props.length
    ? props.map((formField) => new FormFieldRequest(formField))
    : [],
});

export const FormFieldRequest = (props) => ({
  formFieldID: props.id,
  id: props.recordId,
  value: (() => {
    if (props.value !== undefined) {
      if (Array.isArray(props.value) && props.value.length) {
        //multi select handling
        return props.value.map((el) => el.value).toString();
      } else if (props.value instanceof moment) {
        //for date field
        return props.value.format("YYYY-MM-DD HH:mm:ss");
      } else {
        if (Array.isArray(props.value) && !props.value.length) {
          return "";
        }
        return props.value;
      }
    } else {
      return null;
    }
  })(),
  FormSubmissionMedias: (() => {
    if (props.value !== undefined) {
      if (props.fieldType === 13) {
        return FormSubmissionMedias(props.value);
      }
    } else {
      return [];
    }
  })(),
});

const FormSubmissionMedias = (value) => {
  let media = [];
  for (let i = 0; i < value.length; i++) {
    media.push({ MediaId: value[i].id });
  }

  return media;
};

export const QAFormRequestModel = (
  fieldValues,
  isAdhocEntry,
  formSubmissionMasterTypeId,
  location,
  id,
  formSubmissionMasterVersion,
  taskDetailId,
  correctivePreventiveMeasure,
  submitWithViolations,
  reasonsForEditing,
  isEditing,
  showViolations,
  violatedFields,
  isConnected,
  subTaskId,
  isNewSubmission
) => {
  const formSubmissions = [];
  var isEmpty = true;

  fieldValues &&
    Array.isArray(fieldValues) &&
    fieldValues.forEach((field) => {
      const { value, taskElementId } = field;
      const key = field.taskElementMetaEnum.valueColumn;
      const taskFieldTypeEnumId =
        (field &&
          field.taskElementMetaEnum &&
          field.taskElementMetaEnum.taskFieldTypeEnumId) ||
        null;

      if (
        (value !== null && value !== undefined && value !== "" && !!key) ||
        [
          GENERIC_CONSTANTS.FORM_FIELD_TYPES.COUNTER,
          GENERIC_CONSTANTS.FORM_FIELD_TYPES.SLIDER,
          GENERIC_CONSTANTS.FORM_FIELD_TYPES.YES_NO_NA,
        ].includes(taskFieldTypeEnumId)
      ) {
        isEmpty = false;
      }

      if (
        [
          GENERIC_CONSTANTS.FORM_FIELD_TYPES.DATE,
          GENERIC_CONSTANTS.FORM_FIELD_TYPES.TIME,
          GENERIC_CONSTANTS.FORM_FIELD_TYPES.DATE_TIME,
        ].includes(taskFieldTypeEnumId)
      ) {
        formSubmissions.push({
          taskElementId,
          [field.taskElementMetaEnum.valueColumn]:
            moment(field.value).isValid() && !!field.value
              ? moment(field.value).format("YYYY-MM-DD HH:mm:ss")
              : null,
        });
      } else if (
        [
          GENERIC_CONSTANTS.FORM_FIELD_TYPES.IMAGE_UPLOAD,
          GENERIC_CONSTANTS.FORM_FIELD_TYPES.FILE_UPLOAD,
        ].includes(taskFieldTypeEnumId)
      ) {
        value &&
          value.forEach((val) => {
            const mediaId = (val && val.id) || null;
            formSubmissions.push({
              taskElementId,
              [key]: mediaId || val,
            });
          });
      } else if (
        taskFieldTypeEnumId == GENERIC_CONSTANTS.FORM_FIELD_TYPES.ADDRESS
      ) {
        value &&
          Object.keys(value).forEach((key) => {
            formSubmissions.push({
              taskElementId,
              key,
              [GENERIC_CONSTANTS.TASK_DESIGNER_VALUE_TYPES.STRING]: value[key],
            });
          });
      } else if (Array.isArray(value)) {
        value &&
          value.forEach((val) => {
            formSubmissions.push({
              taskElementId,
              [key]: val,
            });
          });
      } else {
        formSubmissions.push({
          taskElementId,
          [key]: value,
        });
      }
    });

  return {
    formSubmissions,
    formSubmissionMasterId: isNewSubmission ? null : id,
    ParentFormSubmissionMasterId: isNewSubmission ? id : null,
    taskDetailId: taskDetailId,
    version: formSubmissionMasterVersion,
    locationId: Number(location) ? location : (location && location.id) || 0,
    correctivePreventiveMeasure,
    submitWithViolations,
    reasonsForEditing,
    submissionAction: isEditing
      ? GENERIC_CONSTANTS.SUBMISSION_ACTIONS.UPDATE
      : GENERIC_CONSTANTS.SUBMISSION_ACTIONS.CREATE,
    showViolations,
    violatedFields,
    isAdhocEntry,
    formSubmissionMasterTypeId,
    subTaskId,
    submittedOn: moment().format("YYYY-MM-DD HH:mm:ss"),
    formSubmissionStatusEnumId: FormSubmissionStatusEnums.COMPLETED,
    isEmpty,
  };
};

export const QAFormRequestModelAutoSave = (
  fieldValues,
  isAdhocEntry,
  location,
  id,
  formSubmissionMasterVersion,
  taskDetailId,
  correctivePreventiveMeasure,
  submitWithViolations,
  isEditing,
  showViolations,
  violatedFields
) => {
  const formSubmissions = [];
  var isEmpty = true;

  fieldValues &&
    Array.isArray(fieldValues) &&
    fieldValues.forEach((field) => {
      const { value, taskElementId } = field;
      const key = field.taskElementMetaEnum.valueColumn;
      const taskFieldTypeEnumId =
        (field &&
          field.taskElementMetaEnum &&
          field.taskElementMetaEnum.taskFieldTypeEnumId) ||
        null;

      if (
        (value !== null && value !== undefined && value !== "" && !!key) ||
        [
          GENERIC_CONSTANTS.FORM_FIELD_TYPES.COUNTER,
          GENERIC_CONSTANTS.FORM_FIELD_TYPES.SLIDER,
          GENERIC_CONSTANTS.FORM_FIELD_TYPES.YES_NO_NA,
        ].includes(taskFieldTypeEnumId)
      ) {
        isEmpty = false;
      }

      if (
        [
          GENERIC_CONSTANTS.FORM_FIELD_TYPES.DATE,
          GENERIC_CONSTANTS.FORM_FIELD_TYPES.TIME,
          GENERIC_CONSTANTS.FORM_FIELD_TYPES.DATE_TIME,
        ].includes(taskFieldTypeEnumId)
      ) {
        formSubmissions.push({
          taskElementId,
          [field.taskElementMetaEnum.valueColumn]:
            moment(field.value).isValid() && !!field.value
              ? moment(field.value).format("YYYY-MM-DD HH:mm:ss")
              : null,
        });
      } else if (
        [
          GENERIC_CONSTANTS.FORM_FIELD_TYPES.IMAGE_UPLOAD,
          GENERIC_CONSTANTS.FORM_FIELD_TYPES.FILE_UPLOAD,
        ].includes(taskFieldTypeEnumId)
      ) {
        value &&
          value.forEach((val) => {
            const mediaId = (val && val.id) || null;
            formSubmissions.push({
              taskElementId,
              [key]: mediaId || val,
            });
          });
      } else if (
        taskFieldTypeEnumId == GENERIC_CONSTANTS.FORM_FIELD_TYPES.ADDRESS
      ) {
        value &&
          Object.keys(value).forEach((key) => {
            formSubmissions.push({
              taskElementId,
              key,
              [GENERIC_CONSTANTS.TASK_DESIGNER_VALUE_TYPES.STRING]: value[key],
            });
          });
      } else if (Array.isArray(value)) {
        value &&
          value.forEach((val) => {
            formSubmissions.push({
              taskElementId,
              [key]: val,
            });
          });
      } else {
        formSubmissions.push({
          taskElementId,
          [key]: value,
        });
      }
    });

  return {
    formSubmissions,
    formSubmissionMasterId: isAdhocEntry ? null : id,
    taskDetailId: taskDetailId,
    version: formSubmissionMasterVersion,
    locationId: Number(location) ? location : (location && location.id) || 0,
    correctivePreventiveMeasure,
    submitWithViolations,
    submissionAction: isEditing
      ? GENERIC_CONSTANTS.SUBMISSION_ACTIONS.UPDATE
      : GENERIC_CONSTANTS.SUBMISSION_ACTIONS.CREATE,
    showViolations,
    violatedFields,
    isAdhocEntry,
    formSubmissionStatusEnumId: FormSubmissionStatusEnums.PENDING,
    isEmpty,
  };
};

export const FormFieldRequestModel = (props) => ({
  taskElementId: props.id,
  value: (() => {
    if (Array.isArray(props.value) && props.value.length) {
      //multi select handling
      return props.value.toString();
    } else if (moment(props.value).isValid()) {
      return moment(props.value).format(GENERIC_CONSTANTS.DATE_FORMAT_SERVER);
    } else {
      return props.value;
    }
  })(),
  FormSubmissionMedias: (() => {
    if (props.value !== undefined) {
      if (props.fieldType === 13) {
        return FormSubmissionMedias(props.value);
      }
    } else {
      return [];
    }
  })(),
});

export const TaskFilterModel = (
  filterData = {},
  sortData = {},
  pageData = {},
  searchTerm = "",
  workorderId,
  taskId,
  parentTaskOnly = true
) => {
  sortData.sortColumn =
    sortData.sortColumn === "workorder"
      ? "workorderTitle"
      : sortData.sortColumn;
  return {
    ...new PageData(pageData),
    ...new SortData(sortData),
    ...new SearchTerm(searchTerm),
    workOrders: filterData.workorders || (workorderId ? [workorderId] : []),
    status: filterData.status || (workorderId ? [1] : []), // 1 will return only task that are published => for QA view
    endDate: filterData.endDate || null,
    state: filterData.state || [],
    priority: filterData.priority || [],
    isDuplicate:
      filterData.isDuplicate === true
        ? true
        : filterData.isDuplicate === false
        ? false
        : null,
    startDate: filterData.startDate || null,
    parentTasks: !!taskId ? [taskId] : [],
    parentTasksOnly: parentTaskOnly,
    formSubmissionMasterTypes: filterData.formSubmissionMasterTypes || [],
    DepartmentIds: filterData.DepartmentIds || [],
  };
};

export const TaskSubmisionFilterModel = (
  filterData = {},
  sortData = {},
  pageData = {},
  searchTerm = "",
  workorderId
) => {
  return {
    ...new PageData(pageData),
    ...new SortData(sortData),
    ...new SearchTerm(searchTerm),
    workOrderIds: workorderId ? [workorderId] : [],
    status:
      filterData.status && filterData.status.length
        ? filterData.status
        : [1, 2],
    //status: filterData.status || [],
    endDate: filterData.endDate || null,
    startDate: filterData.startDate || null,
    TodaysRecordsOnly: true,
  };
};

export const SubmisionHistoryFilterModel = (
  pageData = {},
  searchTerm = "",
  filterData = {},
  sortData = {},
  locationId,
  taskId
) => {
  return {
    ...new PageData(pageData),
    ...new SortData(sortData),
    ...new SearchTerm(searchTerm),
    SubmissionEndTime: filterData.endDate
      ? moment(filterData.endDate).endOf("day").format()
      : "",
    SubmissionStartTime: filterData.startDate
      ? moment(filterData.startDate).startOf("day").format()
      : "",
    status: filterData.status || [],
    departmentIds: filterData.departmentIds || [],
    workOrderIds: filterData.workOrderIds || [],
    priority: filterData.priority || [],
    locationIds: !!locationId
      ? [parseInt(locationId)]
      : filterData.locationIds || [],
    submittedBy: filterData.submittedBy || [],
    reviewerId: filterData.reviewerId || "",
    // frequency: filterData.frequency || [],
    TaskScheduleType: filterData.occurrence || [],
    // AdhocOnly: filterData.AdhocOnly || false,
    // ScheduledOnly:
    //   filterData.ScheduledOnly ||
    //   (filterData.occurrence && !!filterData.occurrence.length),
    TaskID: taskId || "",
    isSubTask:
      filterData.isSubTask || (filterData.isSubTask === false ? false : true),
    showViolations:
      filterData.showViolation ||
      (filterData.showViolation === false ? false : ""),
    LatestByDate: "",
    formSubmissionMasterTypes: filterData.formSubmissionMasterTypes,
    RequestPage: REQUEST_PAGE_ENUM.TASK_HISTORY,
  };
};

export const SubmisionHistoryFilterModelMobile = (
  pageData = {},
  searchTerm = "",
  filterData = {},
  sortData = {},
  locationId,
  taskId
) => {
  return {
    ...new PageData(pageData),
    ...new SortData({ sortOrder: 2, sortColumn: "UpdatedOn" }),
    ...new SearchTerm(searchTerm),
    SubmissionEndTime: filterData.endDate
      ? moment(filterData.endDate).endOf("day").format()
      : "",
    SubmissionStartTime: filterData.startDate
      ? moment(filterData.startDate).startOf("day").format()
      : "",
    status: filterData.status || [],
    workOrderIds: filterData.workOrderIds || [],
    priority: filterData.priority || [],
    locationIds: !!locationId
      ? [parseInt(locationId)]
      : filterData.locationIds || [],
    submittedBy: filterData.submittedBy || [],
    TaskScheduleType: filterData.occurrence || null,
    AdhocOnly: filterData.AdhocOnly || false,
    TaskID: taskId || "",
    isSubTask:
      filterData.isSubTask || (filterData.isSubTask === false ? false : true),
    showViolations:
      filterData.showViolation ||
      (filterData.showViolation === false ? false : ""),
    LatestByDate: "",
    formSubmissionMasterTypes: filterData.formSubmissionMasterTypes,
    RequestPage: REQUEST_PAGE_ENUM.TASK_HISTORY,
    scheduledOnly: filterData.occurrence.length > 0 ? true : false
  };
};

export const TaskSubmissionsAdhocAutoSaveForOfflineModel = (
  pageData = {},
  timestamp,
  departmentIds
) => {
  return {
    ...new PageData(pageData),
    ...new SortData({ sortOrder: 1, sortColumn: "UpdatedOn" }),
    LatestByDate: timestamp,
    formSubmissionMasterTypes: [
      FORM_SUBMISSION_MASTER_TYPE.ADHOC,
    ],
    status: [
      FormSubmissionStatusEnums.PENDING,
      FormSubmissionStatusEnums.INCOMPLETE,
    ],
    departmentIds: departmentIds || [],
    RequestPage: REQUEST_PAGE_ENUM.SCHEDULED_TASKS,
  };
};

export const TaskSubmissionsForOfflineModel = (
  pageData = {},
  timestamp,
  departmentIds
) => {
  return {
    ...new PageData(pageData),
    ...new SortData({ sortOrder: 1, sortColumn: "UpdatedOn" }),
    LatestByDate: timestamp,
    // IncludeAdhoc: true,
    formSubmissionMasterTypes: [
      FORM_SUBMISSION_MASTER_TYPE.ADHOC,
      FORM_SUBMISSION_MASTER_TYPE.SCHEDULED,
    ],
    status: [
      FormSubmissionStatusEnums.PENDING,
      // FormSubmissionStatusEnums.ACCEPTED,
      // FormSubmissionStatusEnums.PENDING_APPROVAL,
      // FormSubmissionStatusEnums.REJECTED,
      // FormSubmissionStatusEnums.COMPLETED,
      // FormSubmissionStatusEnums.INCOMPLETE,
    ],
    departmentIds: departmentIds || [],
    fromDueDate: moment().startOf("day").format("YYYY-MM-DD"),
    toDueDate: moment().add(7, "day").format("YYYY-MM-DD"),
    RequestPage: REQUEST_PAGE_ENUM.SCHEDULED_TASKS,
    // RequestPage: !!filterData.RequestPage ? filterData.RequestPage : 0,
    // formSubmissionMasterTypes: !!filterData.formSubmissionMasterTypes ? _.map(filterData.formSubmissionMasterTypes) : [],
    // DepartmentIds: filterData.DepartmentIds || [],
  };
};

export const TaskHistoryForOfflineModel = (paginationData, timestamp) => {
  return {
    ...new PageData(paginationData),
    ...new SortData({ sortOrder: 1, sortColumn: "UpdatedOn" }),
    LatestByDate: timestamp,
    formSubmissionMasterTypes: [
      FORM_SUBMISSION_MASTER_TYPE.ADHOC,
      FORM_SUBMISSION_MASTER_TYPE.SCHEDULED,
    ],
    status: [
      // FormSubmissionStatusEnums.ACCEPTED,
      // FormSubmissionStatusEnums.PENDING_APPROVAL,
      // FormSubmissionStatusEnums.REJECTED,
      // FormSubmissionStatusEnums.COMPLETED,
    ],
    startDate: moment().subtract(5, "day").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    isSubTask: true,
    RequestPage: REQUEST_PAGE_ENUM.TASK_HISTORY,
  };
};

export const AssignedTaskForOfflineModel = (
  pageData = {},
  timestamp,
  taskTypeEnumId = TASK_TYPE_ENUM_ID.TEMPLATE // taskTypeEnumId: Template = 1, CorrectiveAction = 2
) => {
  return {
    ...new PageData(pageData),
    ...new SortData({ sortOrder: 1, sortColumn: "UpdatedOn" }),
    LatestByDate: timestamp,
    TaskTypeEnumId: taskTypeEnumId,
    TaskActivityStatusEnumId: 1, // taskActivityStatusEnumId: Published = 1
    useCache: false,
  };
};

export const SubTaskSubmisionFilterModel = (
  filterData = {},
  sortData = {},
  pageData = {},
  searchTerm = "",
  todaySubTaskParams
) => {
  const defaultStatus = !!todaySubTaskParams.isHistory ? [1, 2, 3] : [1, 2];

  return {
    ...new PageData(pageData),
    ...new SortData(sortData),
    ...new SearchTerm(searchTerm),
    status: filterData.status || defaultStatus,
    endDate: filterData.endDate || null,
    startDate: filterData.startDate || null,
    TodaysRecordsOnly: !todaySubTaskParams.isHistory,
    ParentSubmissionIds: !!todaySubTaskParams.parentSubmissionId
      ? [todaySubTaskParams.parentSubmissionId]
      : [],
    taskID: todaySubTaskParams.subTaskId || [],
  };
};

export const QATaskHistoryModel = (
  filterData = {},
  sortData = {},
  pageData = {},
  searchTerm = "",
  TaskId
) => {
  //sortData.sortColumn = sortData.sortColumn === "workorder" ? "workorderTitle" : sortData.sortColumn;

  return {
    ...new PageData(pageData),
    ...new SortData(sortData),
    ...new SearchTerm(searchTerm),
    TaskID: TaskId || null,
    endDate: filterData.endDate || null,
    startDate: filterData.startDate || null,
    //todaysRecordsOnly: true,
    //status: [1, 2, 3]
  };
};

export const TaskSumbissionDetailsModel = (props) => ({
  result: formatSubmissionResponse(props.result) || {},
});

function formatSubmissionResponse(result) {
  let _result = JSON.parse(JSON.stringify(result));
  let arr1 = result.formFields;
  let arr2 = result.formSubmission;
  let merged = [];

  let disabled = true; //result.formSubmission.length ? true:false;
  for (let i = 0; i < arr1.length; i++) {
    merged.push({
      ...arr1[i],
      ...arr2.find((itmInner) => itmInner.formFieldID === arr1[i].id),
      disabled,
    });
  }

  merged
    .filter((x) => x.fieldType === GENERIC_CONSTANTS.FIELD_TYPES.TIME)
    .forEach(function (timeValue) {
      // let time=[]
      // if(timeValue.value!==null)

      // time.push(timeValue.value.substr(11, 2))
      console.log("merged timeValue", new Date(timeValue.value).getTime());
      //     var moonLanding = new Date(timeValue);
      //     timeValue.value=timeValue.value.substr(11, 2)
    });

  merged
    .filter((x) => x.fieldType === GENERIC_CONSTANTS.FIELD_TYPES.MULTI_SELECT)
    .forEach(function (multiselectobj) {
      let multival = [];
      if (multiselectobj !== null)
        multiselectobj.value.split(",").forEach(function (csv) {
          multival.push(
            new BMMultiSelect({
              value: multiselectobj.id,
              text: multiselectobj.title,
              label: csv,
            })
          );
        });
      multiselectobj.value = multival;
    });

  _result.formFields = merged;

  return _result;
}

export const GetFrequencyLabel = (
  frequency,
  taskType,
  isAdhocEntry,
  formSubmissionMasterTypeId
) => ({
  frequencyLabel: getFormattedText(
    frequency,
    taskType ? taskType.toUpperCase() : '',
    isAdhocEntry,
    formSubmissionMasterTypeId
  ),
});

function getFormattedText(
  frequency,
  taskType,
  isAdhocEntry,
  formSubmissionMasterTypeId
) {
  if (
    parseInt(formSubmissionMasterTypeId) === FORM_SUBMISSION_MASTER_TYPE.ADHOC
  ) {
    return "Adhoc";
  }

  // if (!!isAdhocEntry) {
  //   return "Adhoc";
  // }

  switch (frequency) {
    case OCCURRENCE.DOES_NOT_REPEAT:
      return "Does Not Repeat";
    case OCCURRENCE.DAILY:
      return "Daily";
    case OCCURRENCE.WEEKLY:
      return "Weekly";
    case OCCURRENCE.MONTHLY:
      return "Monthly";
    case OCCURRENCE.YEARLY:
      return "Yearly";
  }
}

function getLabelStyle(
  frequency,
  taskType,
  isAdhocEntry,
  formSubmissionMasterTypeId
) {
  if (
    parseInt(formSubmissionMasterTypeId) === FORM_SUBMISSION_MASTER_TYPE.ADHOC
  ) {
    return {
      backgroundColor: "rgb(205, 131, 1)",
    };
  }
  // if (!!isAdhocEntry) {
  //   return {
  //     backgroundColor: "rgb(205, 131, 1)",
  //   };
  // }

  switch (frequency) {
    case OCCURRENCE.DAILY:
      return {
        backgroundColor: "rgb(81, 136, 218)",
      };
    case OCCURRENCE.WEEKLY:
      return {
        backgroundColor: "rgb(229,86,86)",
      };
    case OCCURRENCE.MONTHLY:
      return {
        backgroundColor: "rgb(68,130,26)",
      };
    case OCCURRENCE.YEARLY:
      return {
        backgroundColor: "purple",
      };
  }
}

export const GetPriorityIconDetail = (priority) => {
  switch (priority) {
    case PRIORITY.HIGH:
      return {
        color: "rgb(229, 86, 86)",
        icon: "arrow-up",
        label: "High",
      };
    case PRIORITY.MEDIUM:
      return {
        color: "rgb(248,154,50)",
        icon: "arrow-up",
        label: "Medium",
      };
    case PRIORITY.LOW:
      return {
        color: "rgb(68,130,26)",
        icon: "arrow-down",
        label: "Low",
      };
  }
};

export const TaskDetailCardModel = (props) => ({
  title:
    props.taskName ||
    (props &&
      props.taskDetails &&
      props.taskDetails[0] &&
      props.taskDetails[0].title),
  taskNumber: props && props.taskNumber,
  dueDate:
    moment(props.submissionStartTime || null).isValid() &&
    moment(props.submissionEndTime || null).isValid()
      ? getDueDate(props.submissionStartTime, props.submissionEndTime)
      : "N/A",
  frequency: props && props.frequency,
  locationName: props && props.location && props.location.locationName,
  departmentName: props && props.departmentName,
  workorderNumber: props && props.workorder && props.workorder.workorderNumber,
  isAdhocEntry: props && props.isAdhocEntry,
  formSubmissionMasterTypeId: props && props.formSubmissionMasterTypeId,
  priority: props && props.priorityEnumId,
  isActive: props && props.isFormFillable,
  departmentName: (props && props.departmentName) || "N/A",
  departmentNumber: (props && props.departmentNumber) || "N/A",
});

export const WorkorderDetailCardModel = (props) => ({
  woTitle: props.title,
  woNumber: props.workorderNumber,
  totalTasks: "0",
  pendingTasks: "0",
  submittedTasks: "0",
});

export const QATaskSubmissionFilterModel = (props) => ({
  endDate: parseDateForServer(props.date.endDate),
  // frequency: props.frequency && props.frequency.length ? props.frequency : [],
  occurrence: props.frequency && props.frequency.length ? props.frequency : [],
  isSubTask: true,
  startDate: parseDateForServer(props.date.startDate),
  status: props.status
    ? typeof props.status == "object"
      ? props.status
      : props.status
    : [], // as for dashboard view all cards list of status were needed
  submittedBy: props.submittedBy ? props.submittedBy : [],
  departmentIds: props.department ? props.department : [],
  workOrderIds: props.workorder ? props.workorder : [],
  showViolation: props.showViolation,
  locationIds: props.locationId ? props.locationId : [],
  AdhocOnly: props.taskType === "AdhocOnly" ? true : false,
  ScheduledOnly: props.taskType === "Scheduled" ? true : false,
  formSubmissionMasterTypes: props.formSubmissionMasterTypesIds || [],
  priority: props.priority || [],
  reviewerIds: props.reviewedBy || [],
});

export const LocationOptionModel = (props) => ({
  key: props.id,
  value: props.id,
  label: props.locationName,
});

export const OfflineSubmissionRequestModel = (props, taskType) => ({
  id:
    taskType === TASK_VIEW_TYPE.SCHEDULED
      ? Number(
          props.subTaskId ||
            props.formSubmissionMasterId ||
            props.parentSubTaskId
        )
      : Number(props.subTaskId || props.TaskID || props.id),
  payload: JSON.stringify(props),
  taskType,
});
