import { TASK_ACTIONS, USER_ACTIONS } from "../../constants/ActionConstants";
import {
  getTaskFilterCount,
  getTaskSubmissionsFilterCount,
} from "@ecodocs/common/src/helpers/TaskHelper";

export const initialState = { taskSubmitHistory: [], tasks: [], checkedRows: [] };

export function task(state = initialState, action) {
  switch (action.type) {

    case TASK_ACTIONS.GET_ALL_ADHOC_TASKS_REQUEST:
      return {
        ...state,
        getAdhocTasksLoading: true,
        adhocTasks: [],
        totalElements: 0,
      };

    case TASK_ACTIONS.GET_ALL_ADHOC_TASKS_SUCCESS:
      return {
        ...state,
        getAdhocTasksLoading: false,
        adhocTasks:
          !!action.payload.result && action.payload.result.elements
            ? action.payload.result.elements
            : [],
        totalElements:
          !!action.payload.result && !!action.payload.result.totalElements
            ? action.payload.result.totalElements
            : 0,
      };

    case TASK_ACTIONS.GET_ALL_ADHOC_TASKS_FAILURE:
      return {
        ...state,
        getAdhocTasksLoading: false,
        adhocTasks: [],
        totalElements: 0,
        error: action.payload,
      };

    case TASK_ACTIONS.GET_ALL_TASKS_REQUEST:
    case TASK_ACTIONS.GET_ALL_TASKS_OFFLINE_REQUEST:
      return {
        ...state,
        getAllTaskLoading: true,
        getAllTaskListingError: false,
        tasks: [],
        totalElements: 0,
      };

    case TASK_ACTIONS.GET_ALL_TASKS_SUCCESS:
      return {
        ...state,
        getAllTaskLoading: false,
        tasks:
          !!action.payload.result && action.payload.result.elements
            ? action.payload.result.elements
            : [],
        totalElements:
          !!action.payload.result && !!action.payload.result.totalElements
            ? action.payload.result.totalElements
            : 0,
      };

    case TASK_ACTIONS.GET_ALL_TASKS_SUCCESS_MOBILE:
    case TASK_ACTIONS.GET_ALL_TASKS_OFFLINE_SUCCESS:
      return {
        ...state,
        getAllTaskLoading: false,
        tasks: action.payload,
        totalElements: (action.payload && action.payload.length) || 0,
      };

    case TASK_ACTIONS.GET_ALL_TASKS_FAILURE:
    case TASK_ACTIONS.GET_ALL_TASKS_OFFLINE_FAILURE:
      return {
        ...state,
        getAllTaskLoading: false,
        getAllTaskListingError: true,
        tasks: [],
        totalElements: 0,
        error: action.payload,
      };

    case TASK_ACTIONS.DUPLICATE_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TASK_ACTIONS.DUPLICATE_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        showMessage: true,
        taskMessageData: action.payload.message
      };

    case TASK_ACTIONS.DUPLICATE_TASK_FAILURE:
      return {
        ...state,
        loading: false,
        showErrorMessage: true,
        taskMessageData: action.payload.message
      };

    case TASK_ACTIONS.GET_ALL_TASK_NAMES_REQUEST:
      return {
        ...state,
      };

    case TASK_ACTIONS.GET_ALL_TASK_NAMES_SUCCESS:
      return {
        ...state,
        allTaskNames:
          !!action.payload && !!action.payload.result
            ? action.payload.result
            : [],
      };

    case TASK_ACTIONS.GET_ALL_TASK_NAMES_FAILURE:
      return {
        ...state,
        allTaskNames: [],
      };

    case TASK_ACTIONS.ENABLE_TASKS_SELECT_LIST:
      return {
        ...state,
        taskEnabled: action.payload,
      };

    case TASK_ACTIONS.ON_TASK_SUBMISSION_HISTORY_REQUEST:
      return {
        ...state,
        getTaskSubmissionHistoryLoading: true,
      };

    case TASK_ACTIONS.ON_TASK_SUBMISSION_HISTORY_SUCCESS:
      return {
        ...state,
        getTaskSubmissionHistoryLoading: false,
        taskSubmitHistory: !!action.payload.result
          ? action.payload.result.elements
          : [],
        totalElements: !!action.payload.result
          ? action.payload.result.totalElements
          : 0,
      };

    case TASK_ACTIONS.ON_TASK_SUBMISSION_HISTORY_FAILURE:
      return {
        ...state,
        getTaskSubmissionHistoryLoading: false,
        error: action.payload,
      };

    case TASK_ACTIONS.GET_TASK_DETAIL_WITH_SUBMISSION_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TASK_ACTIONS.GET_TASK_DETAIL_WITH_SUBMISSION_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        taskWithFormSubmissionDetail: action.payload.result,
        recordNotFound: !action.payload.result,
      };

    case TASK_ACTIONS.GET_TASK_DETAIL_WITH_SUBMISSION_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TASK_ACTIONS.UPSERT_TASK_SUBMISSIONS_LIST_FILTERS:
      return {
        ...state,
        taskSubmissionsFilter: {
          filterCount: getTaskSubmissionsFilterCount(action.payload),
          taskSubmissionsFilterValues: action.payload,
        },
      };

    case TASK_ACTIONS.REMOVE_TASK_SUBMISSIONS_LIST_FILTERS:
      return {
        ...state,
        taskSubmissionsFilter: {
          filterCount: 0,
          taskSubmissionsFilterValues: {},
        },
      };

    case TASK_ACTIONS.SUBMIT_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TASK_ACTIONS.SUBMIT_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        taskMessageData: action.payload.message,
        showMessage: true,
      };

    case TASK_ACTIONS.SUBMIT_TASK_FAILURE:
      return {
        ...state,
        loading: false,
        taskError: action.payload,
        taskMessageData: action.payload,
        showErrorMessage: true,
      };

    case TASK_ACTIONS.TASK_EXISTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TASK_ACTIONS.TASK_EXISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        taskExists: !!action.payload.result,
        taskValidityResponse: action.payload.result,
      };

    case TASK_ACTIONS.TASK_EXISTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TASK_ACTIONS.GET_TASK_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        taskDetails: null,
        formSubmission: null
      };

    case TASK_ACTIONS.GET_TASK_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        taskDetails: action.payload.result,
        recordNotFound: !action.payload.result,
        formSubmission: null,
      };

    case TASK_ACTIONS.GET_TASK_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TASK_ACTIONS.GET_PARENT_TASK_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TASK_ACTIONS.GET_PARENT_TASK_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case TASK_ACTIONS.GET_PARENT_TASK_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case TASK_ACTIONS.GET_TASK_DESIGNER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        taskDetails: null,
          formSubmission: null
      };

    case TASK_ACTIONS.GET_TASK_DESIGNER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        taskDetails: action.payload.result.task,
        formSubmission: action.payload.result.formSubmission,
          recordNotFound: !action.payload.result
      };

    case TASK_ACTIONS.GET_TASK_DESIGNER_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
          error: action.payload
      };

    case TASK_ACTIONS.GET_TASK_RULES_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TASK_ACTIONS.GET_TASK_RULE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        taskRuleDetails: action.payload.result,
        recordNotFound: !action.payload.result,
      };

    case TASK_ACTIONS.GET_TASK_RULES_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TASK_ACTIONS.HANDLE_ADD_TASK_MESSAGE:
      return {
        ...state,
        showMessage: action.payload.showMessage,
        showErrorMessage: action.payload.showMessage
      };

    case TASK_ACTIONS.UPSERT_TASK_LIST_FILTERS:
      return {
        ...state,
        taskFilter: {
          filterCount: getTaskFilterCount(action.payload),
          taskFilterValues: action.payload,
        },
      };

    case TASK_ACTIONS.REMOVE_TASK_LIST_FILTERS:
      return {
        ...state,
        taskFilter: { filterCount: 0, taskFilterValues: {} },
      };

    case TASK_ACTIONS.SAVE_TASK_ID:
      let lstChecked = [...state.checkedRows];
      let index = lstChecked.indexOf(action.payload.taskid);

      if (action.payload.checked) {
        if (index < 0) lstChecked.push(action.payload.taskid);
      } else {
        if (action.payload.checked) {
          lstChecked.push(action.payload.taskid);
        } else {
          let index = lstChecked.indexOf(action.payload.taskid);
          let copyList = lstChecked.slice();
          copyList.splice(index, 1);
          lstChecked = copyList;
        }
      }
      return {
        ...state,
        checkedRows: lstChecked,
      };
    case TASK_ACTIONS.ASSOCIATE_WORKORDER_TO_TASKS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    // case TASK_ACTIONS.HANDLE_ASSOCIATE_WORKORDER_TO_TASKS_SUCCESS:
    //   return {
    //     ...state,
    //     taskMessageData: action.payload.message,
    //     checkedRows: [],
    //     showMessage: true,
    //     loading: false
    //   };
    case TASK_ACTIONS.ASSOCIATE_WORKORDER_TO_TASKS_SUCCESS:
      return {
        ...state,
        loading: false,
        // response: action.payload,
        // taskError: taskError,
        taskMessageData: action.payload.message,
        showMessage: true,
      };
    case TASK_ACTIONS.ASSOCIATE_WORKORDER_TO_TASKS_FAILURE:
      return {
        ...state,
        loading: false,
        taskError: action.payload,
      };
    case TASK_ACTIONS.CHANGE_TASK_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TASK_ACTIONS.CHANGE_TASK_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        taskMessageData: action.payload.message,
        showMessage: true,
        taskDetails: action.payload.taskDetails
      };

    case TASK_ACTIONS.CHANGE_TASK_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    /**
     * TASK RULE ACTIONS CREATE
     */

    case TASK_ACTIONS.ADD_RULE_FOR_FORM_FIELD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TASK_ACTIONS.ADD_RULE_FOR_FORM_FIELD_SUCCESS:
      return {
        ...state,
        loading: false,
        taskRuleDetails: action.payload.result,
      };

    case TASK_ACTIONS.ADD_RULE_FOR_FORM_FIELD_FAILURE:
      return {
        ...state,
        loading: false,
      };

    /**
     * TASK RULE ACTIONS UPDATE
     */
    case TASK_ACTIONS.UPDATE_RULE_FOR_FORM_FIELD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TASK_ACTIONS.UPDATE_RULE_FOR_FORM_FIELD_SUCCESS:
      let taskRuleDetails = JSON.parse(JSON.stringify(state.taskRuleDetails));
      const { result } = action.payload;

      for (let i = 0; i < taskRuleDetails.elements.length; i++) {
        if (taskRuleDetails.elements[i].formFieldID === result.formFieldID) {
          result.formField = taskRuleDetails.elements[i].formField;
          taskRuleDetails.elements[i] = result;
        }
      }

      return {
        ...state,
        loading: false,
        taskRuleDetails: taskRuleDetails,
      };

    case TASK_ACTIONS.UPDATE_RULE_FOR_FORM_FIELD_FAILURE:
      return {
        ...state,
        loading: false,
      };

    /**
     * TASK RULE ACTIONS DELETE
     */
    case TASK_ACTIONS.DELETE_RULE_FOR_FORM_FIELD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TASK_ACTIONS.DELETE_RULE_FOR_FORM_FIELD_SUCCESS:
      let taskRules = JSON.parse(JSON.stringify(state.taskRuleDetails));
      let taskRuleId = action.payload;

      for (let i = 0; i < taskRules.elements.length; i++) {
        if (taskRules.elements[i].id === taskRuleId) {
          taskRules.elements.splice(i, 1);
        }
      }

      taskRules.totalElements = taskRules.elements.length;
      return {
        ...state,
        loading: false,
        taskRuleDetails: taskRules,
      };

    case TASK_ACTIONS.DELETE_RULE_FOR_FORM_FIELD_FAILURE:
      return {
        ...state,
        loading: false,
      };

    /**
     * TASK ACTIONS
     */

    case TASK_ACTIONS.ON_SORT_TASK_COLUMN_REQUEST:
      return {
        ...state,
        getAllTaskOnSortingLoading: true,
      };

    case TASK_ACTIONS.ON_SORT_TASK_COLUMN_SUCCESS:
      return {
        ...state,
        getAllTaskOnSortingLoading: false,
        tasks: !!action.payload.result ? action.payload.result.elements : [],
        totalElements: !!action.payload.result
          ? action.payload.result.totalElements
          : 0,
      };

    case TASK_ACTIONS.ON_SORT_TASK_COLUMN_FAILURE:
      return {
        ...state,
        getAllTaskOnSortingLoading: false,
        error: action.payload,
      };

    //EO | TASK ACTIONS

    case TASK_ACTIONS.HANDLE_EDIT_TASK_MESSAGE:
      return {
        ...state,
        taskMessageData: action.payload.message,
        showMessage: true,
      };

    case TASK_ACTIONS.GET_TASK_SUBMISSION_BY_ID_SUCCESS:
      return {
        ...state,
        FormSubmissionDetail: action.payload.result,
      };
    //Reset task detail from redux
    case TASK_ACTIONS.RESET_TASK_DETAIL_STATE:
      return {
        ...state,
        taskDetails: null,
        formSubmission: null
      };

    /**
     * IMAGE UPLOAD QA TASK FORM
     */

    case TASK_ACTIONS.UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        imageLoading: true,
      };

    case TASK_ACTIONS.UPLOAD_IMAGE_SUCCESS:
      const mediaIds = action.payload && action.payload.result.map((id, index) => id.id) || [];
      return {
        ...state,
        imageLoading: false,
        mediaIds: [...state.mediaIds || [], ...mediaIds]
      };

    case TASK_ACTIONS.UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        imageLoading: false,
      };

    // Reset program media ids
    case TASK_ACTIONS.RESET_MEDIA_IDS: {
      const {mediaIds, ...newState} = state;
      return {
          ...newState,
          mediaIds: [],
      }
    }

    /**
     * IMAGE DELETE QA TASK FORM
     */

    case TASK_ACTIONS.DELETE_IMAGE_REQUEST:
      return {
        ...state,
        imageLoading: true,
      };

    case TASK_ACTIONS.DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        imageLoading: false,
      };

    case TASK_ACTIONS.DELETE_IMAGE_FAILURE:
      return {
        ...state,
        imageLoading: false,
      };

    /**
     * APPROVE SUBMISSION
     */

    case TASK_ACTIONS.APPROVE_SUBMISSON_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TASK_ACTIONS.APPROVE_SUBMISSON_SUCCESS:
      return {
        ...state,
        taskDetails: action.payload.result.task,
        formSubmission: action.payload.result.formSubmission,
        loading: false,
      };

    case TASK_ACTIONS.APPROVE_SUBMISSON_FAILURE:
      return {
        ...state,
        loading: false,
      };

    // Delete Is Adhoc Entry
    case TASK_ACTIONS.DELETE_IS_ADHOC_ENTRY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TASK_ACTIONS.DELETE_IS_ADHOC_ENTRY_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case TASK_ACTIONS.DELETE_IS_ADHOC_ENTRY_FAILURE:
      return {
        ...state,
        loading: false,
      };

    // Get all tasks with locations
    case TASK_ACTIONS.GET_ALL_TASKS_WITH_LOCATIONS_REQUEST:
      return {
        ...state,
        getAllTaskLoading: true,
        taskWithLocations: [],
      };

    case TASK_ACTIONS.GET_ALL_TASKS_WITH_LOCATIONS_SUCCESS:
      return {
        ...state,
        getAllTaskLoading: false,
        taskWithLocations:
          !!action.payload.result
            ? action.payload.result
            : [],
      };

    case TASK_ACTIONS.GET_ALL_TASKS_WITH_LOCATIONS_FAILURE:
      return {
        ...state,
        getAllTaskLoading: false,
        taskWithLocations: [],
      };

    case TASK_ACTIONS.CLEAR_TASKS:
      return {
        ...state,
        tasks: [],
      };

    // submit task(s) as not performed
    case TASK_ACTIONS.MARK_TASK_AS_NOT_PERFORMED_REQUEST:
      return {
        ...state,
        markAsNotPerformedLoading: true,
        markAsNotPerformedSuccessMessage: '',
        markAsNotPerformedError: ''
      };

    case TASK_ACTIONS.MARK_TASK_AS_NOT_PERFORMED_SUCCESS:
      return {
        ...state,
        markAsNotPerformedLoading: false,
        markAsNotPerformedSuccessMessage: action.payload.successMessage || ''
      };

    case TASK_ACTIONS.MARK_TASK_AS_NOT_PERFORMED_FAILURE:
      return {
        ...state,
        markAsNotPerformedLoading: false,
        markAsNotPerformedError: action.payload
      };

    // Skip Tasks
    case TASK_ACTIONS.SKIP_TASK_REQUEST:
      return {
        ...state,
        skipTaskLoading: true,
      };

    case TASK_ACTIONS.SKIP_TASK_SUCCESS:
      return {
        ...state,
        skipTaskLoading: false,
      };

    case TASK_ACTIONS.SKIP_TASK_FAILURE:
      return {
        ...state,
        skipTaskLoading: false,
      };

    // Get Task Elements by Task Group Id
    case TASK_ACTIONS.GET_ELEMENT_BY_GROUP_ID_REQUEST:
      return {
        ...state,
        taskElementsLoading: true,
      };

    case TASK_ACTIONS.GET_ELEMENT_BY_GROUP_ID_SUCCESS:
      let taskDetails = state.taskDetails;
      let isStillLoading = false;
      if(action.payload.qaTaskForm){
        let elements = action.payload.data.result.elements;
        let taskGroupId = !!elements.length && elements[0].taskGroupId;
        taskDetails.taskDetails[0].taskGroups.map((taskGroup => {
          if(taskGroup.id==taskGroupId){
            taskGroup.taskElements = elements;
          }
        }));
        isStillLoading = taskDetails.taskDetails[0].taskGroups.every(group => group.taskElements.length === 0);
      }
      return {
        ...state,
        taskDetails: taskDetails,
        taskElementsLoading: isStillLoading,
      };

    case TASK_ACTIONS.GET_ELEMENT_BY_GROUP_ID_FAILURE:
      return {
        ...state,
        taskElementsLoading: false,
      };

    case TASK_ACTIONS.GET_TASK_DESIGNER_BY_PROGRAM_ID_REQUEST:
      return {
        ...state,
        getAllTaskLoading: true,
        getAllTaskListingError: false,
        tasks: [],
        totalElements: 0,
      };

    case TASK_ACTIONS.GET_TASK_DESIGNER_BY_PROGRAM_ID_SUCCESS:
      return {
        ...state,
        getAllTaskLoading: false,
        tasks: !!action.payload.result ? action.payload.result : [],
        totalElements: !!action.payload.result
          ? action.payload.result.length
          : 0,
      };

    case TASK_ACTIONS.GET_TASK_DESIGNER_BY_PROGRAM_ID_FAILURE:
      return {
        ...state,
        getAllTaskLoading: false,
        getAllTaskListingError: true,
        tasks: [],
        totalElements: 0,
        error: action.payload,
      };

    case TASK_ACTIONS.EMPTY_TASKS_LIST:
      return {
          ...state,
          getAllTaskLoading: false,
          tasks: [],
          totalElements: 0,
      };

    default:
      return state;
  }
}
