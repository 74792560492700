import { LIVESTOCK_ACTIONS } from '../../constants/ActionConstants';
import { getProgramFilterCount } from '@ecodocs/common/src/helpers/ProgramHelper';

const initialState = { livestocks: [], totalElements: 0, livestockMessage: "", showMessage: false, showErrorMessage: false };

export function livestock(state = initialState, action) {
    switch (action.type) {
        case LIVESTOCK_ACTIONS.GET_LIVESTOCKS_REQUEST:
            return {
                ...state,
                loading: true,
                livestockListingError: false
            };

        case LIVESTOCK_ACTIONS.GET_LIVESTOCKS_SUCCESS:
            return {
                ...state,
                loading: false,
                livestockListingError: false,
                livestocks: !!action.payload.result ? action.payload.result.elements : [],
                totalElements: !!action.payload.result ? action.payload.result.totalElements : 0
            };

        case LIVESTOCK_ACTIONS.GET_LIVESTOCKS_FAILURE:
            return {
                ...state,
                loading: false,
                livestockListingError: true,
                error: action.payload
            };

        case LIVESTOCK_ACTIONS.SAVE_LIVESTOCK_REQUEST:
            return {
                ...state,
                loading: true
            };

        case LIVESTOCK_ACTIONS.SAVE_LIVESTOCK_SUCCESS:
            return {
                ...state,
                loading: false,
                showMessage: true,
                livestockMessage: action.payload.message || ""
            };

        case LIVESTOCK_ACTIONS.SAVE_LIVESTOCK_FAILURE:
            return {
                ...state,
                loading: false,
                showErrorMessage: true,
                livestockMessage: action.payload.message || ""
            };

        case LIVESTOCK_ACTIONS.HANDLE_MESSAGE:
            return {
                ...state,
                showMessage: false,
                showErrorMessage: false,
            };

          /**Live Stock Detail */
        case LIVESTOCK_ACTIONS.GET_LIVESTOCK_DETAILS_BY_ID_REQUEST:
        return {
          ...state,
          loading: true,
          liveStockDetails: null,
        };
  
      case LIVESTOCK_ACTIONS.GET_LIVESTOCK_DETAILS_BY_ID_SUCCESS:
        return {
          ...state,
          loading: true,
          liveStockDetails: action.payload.result,
        };
  
      case LIVESTOCK_ACTIONS.GET_LIVESTOCK_DETAILS_BY_ID_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
  
      /**Live Stock Ear Tags */
      case LIVESTOCK_ACTIONS.GET_ALL_FILTERED_EAR_TAGS_REQUEST:
        return {
          ...state,
          loading: true,
          tags: null,
        };
  
      case LIVESTOCK_ACTIONS.GET_ALL_FILTERED_EAR_TAGS_SUCCESS:
        return {
          ...state,
          loading: true,
          tags: action.payload.result,
        };
  
      case LIVESTOCK_ACTIONS.GET_ALL_FILTERED_EAR_TAGS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload
        };

        default:
            return state;
    }
}