import { LOCATION_ACTIONS } from "../../constants/ActionConstants";
import { getLocationFilterCount } from "@ecodocs/common/src/helpers/Locationhelper";

const initialState = { locations: [], showMessage: false };

export function location(state = initialState, action) {
  switch (action.type) {
    case LOCATION_ACTIONS.GET_LOCATION_REQUEST:
      return {
        ...state,
        getLocationsLoading: true,
        getLocationsListingError: false
      };

    case LOCATION_ACTIONS.GET_LOCATION_SUCCESS:
      return {
        ...state,
        getLocationsLoading: false,
        getLocationsListingError: false,
        locations: !!action.payload.result
          ? action.payload.result.elements
          : [],
        totalElements: !!action.payload.result
          ? action.payload.result.totalElements
          : 0,
      };

    case LOCATION_ACTIONS.GET_LOCATION_FAILURE:
      return {
        ...state,
        getLocationsLoading: false,
        getLocationsListingError: true,
        error: action.payload,
      };

    case LOCATION_ACTIONS.GET_ALL_LOCATION_REQUEST:
      return {
        ...state,
        locationNames: [],
      };

    case LOCATION_ACTIONS.GET_ALL_LOCATION_SUCCESS:
      return {
        ...state,
        locationNames: !!action.payload.result
          ? action.payload.result
          : []
      };

    case LOCATION_ACTIONS.GET_ALL_LOCATION_FAILURE:
      return {
        ...state,
        locationNames: []
      };

    case LOCATION_ACTIONS.SUBMIT_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case LOCATION_ACTIONS.HANDLE_SUBMIT_LOCATION_SUCCESS:
      return {
        ...state,
        locationMessageData: action.payload,
        showMessage: true,
        loading: false,
      };

    case LOCATION_ACTIONS.SUBMIT_LOCATION_FAILURE:
      return {
        ...state,
        loading: false,
        locationError: !!action.payload && action.payload,
      };

    case LOCATION_ACTIONS.GET_ALL_LOCATION_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case LOCATION_ACTIONS.GET_ALL_LOCATION_TYPES_SUCCESS:
      return {
        ...state,
        locationTypes: !!action.payload && !!action.payload.result? action.payload.result: [],
        loading: false,
      };
    case LOCATION_ACTIONS.GET_ALL_LOCATION_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        locationTypesError: action.payload,
      };
    case LOCATION_ACTIONS.GET_LOCATION_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };

    case LOCATION_ACTIONS.GET_LOCATION_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        locationDetails: action.payload.result,
        recordNotFound: !action.payload.result
      };

    case LOCATION_ACTIONS.GET_LOCATION_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case LOCATION_ACTIONS.HANDLE_ADD_LOCATION_MESSAGE:
      return {
        ...state,
        locationMessageData: action.payload,
        showMessage: action.payload.showMessage
      };

    case LOCATION_ACTIONS.GET_LOCATIONS_REQUEST:
      return {
        ...state,
        getLocationsLoading: true
      };

    case LOCATION_ACTIONS.GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        getLocationsLoading: false,
        locations: !!action.payload.result ? action.payload.result.elements : [],
        totalElements: !!action.payload.result ? action.payload.result.totalElements : 0
      };

    case LOCATION_ACTIONS.GET_LOCATIONS_FAILURE:
      return {
        ...state,
        getLocationsLoading: false,
        error: action.payload
      };

    case LOCATION_ACTIONS.EMPTY_LOCATIONS_LIST:
      return {
        ...state,
        loading: false,
        locations: [],
        totalElements: 0
      };

    case LOCATION_ACTIONS.ENABLE_LOCATIONS_SELECT_LIST:
      return {
        ...state,
        locationEnabled: action.payload
      };

    case LOCATION_ACTIONS.SUBMIT_LOCATION_SUCCESS:
      let success = false;
      let locationError = null;
      if (action.payload.result &&
        action.payload.responseCode === 200 &&
        action.payload.message === "Success") {
        success = true;
      } else {
        success = false;
        // getting different response from server that is why handling it
        if (action.payload.result && !!action.payload.result.message) {
          locationError = action.payload.result.message;
        }
      }
      return {
        ...state,
        loading: false,
        response: action.payload,
        success: success,
        //  showMessage: !!success ? true : false,
        locationError: locationError
      };

    case LOCATION_ACTIONS.GET_LOCATION_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };

    case LOCATION_ACTIONS.GET_LOCATION_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        locationDetails: action.payload.result,
        recordNotFound: !action.payload.result
      };

    case LOCATION_ACTIONS.GET_LOCATION_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case LOCATION_ACTIONS.UPSERT_LOCATION_LIST_FILTERS:
      return {
        ...state,
        locationFilter: { filterCount: getLocationFilterCount(action.payload), locationFilterValues: action.payload }
      };

    case LOCATION_ACTIONS.REMOVE_LOCATION_LIST_FILTERS:
      return {
        ...state,
        locationFilter: { filterCount: 0, locationFilterValues: {} }
      };

    case LOCATION_ACTIONS.CHANGE_LOCATION_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case LOCATION_ACTIONS.CHANGE_LOCATION_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        locationMessageData: action.payload.message,
        showMessage: true
      };

    case LOCATION_ACTIONS.CHANGE_LOCATION_STATUS_FAILURE:
      return {
        ...state,
        loading: false
      };

    case LOCATION_ACTIONS.HANDLE_EDIT_LOCATION_MESSAGE:
      return {
        ...state,
        locationMessageData: action.payload,
        showMessage: true
      }

    case LOCATION_ACTIONS.ASSOCIATE_DIVISION_TO_LOCATIONS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case LOCATION_ACTIONS.HANDLE_ASSOCIATE_DIVISION_TO_LOCATIONS_SUCCESS:
      return {
        ...state,
        locationMessageData: action.payload,
        showMessage: true,
        loading: false,
        checkedRows: []
      };
    case LOCATION_ACTIONS.ASSOCIATE_DIVISION_TO_LOCATIONS_SUCCESS:
      success = false;
      if (action.payload.result &&
        action.payload.responseCode === 200 &&
        action.payload.message === "Success") {
        success = true;
      }
      return {
        ...state,
        loading: false,
        response: action.payload,
        success: success,
        checkedRows: []
      };

    case LOCATION_ACTIONS.ASSOCIATE_DIVISION_TO_LOCATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        locationError: action.payload
      };

    /**
     * TABLE ACTIONS
     */

    case LOCATION_ACTIONS.ON_SORT_LOCATION_COLUMN_REQUEST:
      return {
        ...state,
        getAllLocationsOnSortingLoading: true
      };

    case LOCATION_ACTIONS.ON_SORT_LOCATION_COLUMN_SUCCESS:
      return {
        ...state,
        getAllLocationsOnSortingLoading: false,
        locations: !!action.payload.result ? action.payload.result.elements : [],
        totalElements: !!action.payload.result ? action.payload.result.totalElements : 0
      };

    case LOCATION_ACTIONS.ON_SORT_LOCATION_COLUMN_FAILURE:
      return {
        ...state,
        getAllLocationsOnSortingLoading: false,
        error: action.payload
      };

    //EO | TABLE ACTIONS

    //Reset task detail from redux
    case LOCATION_ACTIONS.RESET_LOCATION_DETAIL_STATE:
      return {
        ...state,
        locationDetails: null
      }


    default:
      return state;
  }
}
