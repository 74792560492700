import { TASK_VIEW_TYPE } from "../../constants/GenericConstants";

class AdhocTaskModel {}

AdhocTaskModel.schema = {
  name: "AdhocTask",
  primaryKey: "id",
  properties: {
    id: "int",
    parentTaskId: "int?",
    taskNumber: "string?",
    isDeleted: "bool?",
    isActive: "bool?",
    departmentId: "int?",
    departmentName: "string?",
    departmentNumber: "string?",
    workorderId: "int?",
    workorderNumber: "string?",
    workorderTitle: "string?",
    // workorder: "TaskWorkorder?", //TODO: remove this if not required
    updatedOn: "string?",
    taskTypeEnumId: "int?",
    createdBy: "string?",
    taskCreatedBy: "string?",
    locations: {
      type: "Location[]",
      default: [],
    },
    taskDetails: { type: "TaskDetail[]", default: [] },
    taskType: { type: "string?", default: TASK_VIEW_TYPE.ADHOC },
    isSubtask: "bool?",
  },
};

export default AdhocTaskModel;
