import { API_CONSTANTS } from "../../constants/ApiConstants";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { apiClient } from "./ApiClient";

export const enumServices = {
  getAllEnumns,
  getMetaDataValues,
};

function getAllEnumns() {
  return apiClient()
    .get(`${API_CONSTANTS.API_ROUTES.GET_ALL_ENUMS}`)
    .then(handleResponse)
    .catch(handleError);
}

function getMetaDataValues(meta, status = null) {
  const payload = JSON.stringify({
    name: meta,
    status: status
  });
  return apiClient()
    .post(`${API_CONSTANTS.API_ROUTES.GET_METADATA_VALUES_ENUMS}`, payload)
    .then(handleResponse)
    .catch(handleError);
}
