import EcoDocsDB, {
  SUBMISSION_SCHEMA_NAME,
  ADHOC_TASK_SCHEMA_NAME,
  TASK_SUBMISSION_SCHEMA_NAME,
} from "..";
import {
  FormSubmissionStatusEnums,
  FORM_SUBMISSION_MASTER_TYPE,
  GENERIC_CONSTANTS,
  SUBMISSION_TYPE,
} from "../../constants/GenericConstants";
import moment from "moment";

// Database formatting Model
import {
  MapApiToDB,
  MapDBToState,
  MapDBToStateForList,
} from "../../models/databaseModels/taskSubmission";

class ScheduledTaskManager {
  async saveTasks(tasks) {
    const db = await EcoDocsDB.getConnection();
    db.write(() => {
      tasks.forEach((task) => {
        const mappedTask = MapApiToDB(task);

        const submission =
          Number(mappedTask.formSubmissionMasterTypeId) ===
          FORM_SUBMISSION_MASTER_TYPE.ADHOC
            ? db
                .objects(SUBMISSION_SCHEMA_NAME)
                .filtered(`id == ${Number(mappedTask.taskId)}`)[0]
            : db
                .objects(SUBMISSION_SCHEMA_NAME)
                .filtered(`parentId == ${Number(mappedTask.id)}`)[0];

        if (
          submission &&
          submission.submissionType == SUBMISSION_TYPE.SUBMITTED
        ) {
          const task = db
            .objects(TASK_SUBMISSION_SCHEMA_NAME)
            .filtered(`id == ${Number(submission.parentId)}`)[0];

          task &&
            Number(mappedTask.formSubmissionMasterTypeId) ===
              FORM_SUBMISSION_MASTER_TYPE.ADHOC &&
            db.delete(task);
          console.log("deleting", submission);
          db.delete(submission);
        }

        db.create(TASK_SUBMISSION_SCHEMA_NAME, mappedTask, true);
      });
      console.log("Scheduled tasks saved: ", tasks && tasks.length);
    });
    // EcoDocsDB.close(db);
  }

  async getTasks(filters = {}) {
    const {
      date = {},
      locationId,
      search,
      department,
      workorder,
      frequency,
      formSubmissionMasterTypeId,
      userId,
      priority,
    } = filters;
    if (formSubmissionMasterTypeId === null) return [];
    const { startDate, endDate } = date;
    const args = [];
    const query = [];
    const db = await EcoDocsDB.getConnection();

    query.push(`status == $${args.length}`);
    args.push(FormSubmissionStatusEnums.PENDING);

    query.push(`isSubtask = $${args.length}`);
    args.push(false);

    query.push(`isDeleted = $${args.length}`);
    args.push(false);

    query.push(`isActive = $${args.length}`);
    args.push(true);

    // for tasks, parentTaskId will be null
    // for subtasks, parentTaskId will not be null
    // when subtasks will be handled, then this condition will need to be removed/changed
    query.push(`parentTaskId = null`);

    query.push(
      `(formSubmissionMasterTypeId = $${
        args.length
      } OR formSubmissionMasterTypeId = $${args.length + 1})`,
    );
    args.push(FORM_SUBMISSION_MASTER_TYPE.ADHOC);
    args.push(FORM_SUBMISSION_MASTER_TYPE.SCHEDULED);
    query.push(
      `(submissionEndTime >= $${args.length} || submissionEndTime = null)`,
    );
    args.push(moment().startOf("day").toDate());

    if (
      !!startDate &&
      !!endDate &&
      moment(startDate).isValid() &&
      moment(endDate).isValid()
    ) {
      query.push(
        `(submissionStartTime >= $${args.length} AND submissionEndTime <= $${
          args.length + 1
        })`,
      );
      args.push(moment(startDate).startOf("day").toDate());
      args.push(moment(endDate).endOf("day").toDate());
    }

    if (search) {
      query.push(
        `(taskName CONTAINS[c] $${args.length} OR taskNumber CONTAINS[c] $${args.length})`,
      );
      args.push(search);
    }

    if (department && department.length > 0) {
      query.push(
        `(${department
          .map((fq, index) => "departmentId = $" + (args.length + index))
          .join(" OR ")})`,
      );
      args.push(...department);
    }

    if (workorder && workorder.length > 0) {
      query.push(
        `(${workorder
          .map((fq, index) => "workorder.id = $" + (args.length + index))
          .join(" OR ")})`,
      );
      args.push(...workorder);
    }

    if (priority && priority.length > 0) {
      query.push(
        `(${priority
          .map((fq, index) => "priorityEnumId = $" + (args.length + index))
          .join(" OR ")})`,
      );
      args.push(...priority);
    }

    if (locationId && locationId.length > 0) {
      query.push(
        `(${locationId
          .map((fq, index) => "location.id = $" + (args.length + index))
          .join(" OR ")})`,
      );
      args.push(...locationId);
    }

    if (frequency && !!frequency.length) {
      query.push(
        `(${frequency
          .map((fq, index) => "occurenceEnumId = $" + (args.length + index))
          .join(" OR ")})`,
      );
      args.push(...frequency);
    }

    let results = db.objects(TASK_SUBMISSION_SCHEMA_NAME);

    results = results.filtered(
      `${query.join(" AND ")} SORT(submissionStartTime ASC)`,
      ...args,
    );

    results = results.toJSON();

    // if (userId) {
    //   results = results.filter((r) =>
    //     JSON.parse(r.taskDesignerFormSubmissionUsers).some(
    //       (user) => userId === user.userId
    //     )
    //   );
    // }

    // if (
    //   formSubmissionMasterTypeId ===
    //   FORM_SUBMISSION_MASTER_TYPE.CORRECTIVE_ACTION
    // ) {
    //   results = results.filter((r) => {
    //     const [task] = db
    //       .objects(ADHOC_TASK_SCHEMA_NAME)
    //       .filtered(`id = ${r.taskId}`)
    //       .toJSON();

    //     const taskDetail = task.taskDetails.filter(
    //       (td) => td.id === r.taskDetailId
    //     );

    //     return (
    //       taskDetail[0].taskDetailCorrectionFilers &&
    //       JSON.parse(taskDetail[0].taskDetailCorrectionFilers).some(
    //         (cf) => userId === cf.approverId
    //       )
    //     );
    //   });
    // }

    return results.map((result) => MapDBToStateForList(result));
  }

  async getDueTasks(dueToday = true) {
    const db = await EcoDocsDB.getConnection();
    const query = [];
    const args = [];

    query.push(`isSubtask = $${args.length}`);
    args.push(false);

    query.push(`isDeleted = $${args.length}`);
    args.push(false);

    query.push(`isActive = $${args.length}`);
    args.push(true);

    // for tasks, parentTaskId will be null
    // for subtasks, parentTaskId will not be null
    // when subtasks will be handled, then this condition will need to be removed/changed
    query.push(`parentTaskId = null`);

    query.push(`formSubmissionMasterTypeId = $${args.length}`);
    args.push(FORM_SUBMISSION_MASTER_TYPE.SCHEDULED);

    if (!dueToday) {
      query.push(
        `submissionStartTime >= $${args.length} AND submissionEndTime >= $${args.length}`,
      );
      args.push(moment().endOf("day").toDate());
    } else {
      query.push(
        `submissionStartTime <= $${args.length} AND submissionEndTime >= $${
          args.length + 1
        }`,
      );
      args.push(moment().endOf("day").toDate());
      args.push(new Date());
    }

    let allTasks = db
      .objects(TASK_SUBMISSION_SCHEMA_NAME)
      .filtered(
        `parentTaskId = null AND (status = $0 OR status = $1 OR status = $2 OR status = $3 OR status = $4 OR status = $5 OR status = $6)`,
        FormSubmissionStatusEnums.PENDING,
        FormSubmissionStatusEnums.COMPLETED,
        FormSubmissionStatusEnums.PENDING_APPROVAL,
        FormSubmissionStatusEnums.ACCEPTED,
        FormSubmissionStatusEnums.REJECTED,
        FormSubmissionStatusEnums.PENDING_SYNC,
        FormSubmissionStatusEnums.SUBMITTED,
      )
      .filtered(query.join(" AND "), ...args);
    // allTasks = allTasks.filter((task) =>
    //   JSON.parse(task.taskDesignerFormSubmissionUsers).some(
    //     (user) => userId === user.userId
    //   )
    // );

    let pending = db
      .objects(TASK_SUBMISSION_SCHEMA_NAME)
      .filtered(
        `parentTaskId = null AND status = $0 SORT(submissionEndTime ASC)`,
        FormSubmissionStatusEnums.PENDING,
      )
      .filtered(query.join(" AND "), ...args);

    pending = pending.toJSON();
    // pending = pending.filter((p) =>
    //   JSON.parse(p.taskDesignerFormSubmissionUsers).some(
    //     (user) => userId === user.userId
    //   )
    // );

    let completed = db
      .objects(TASK_SUBMISSION_SCHEMA_NAME)
      .filtered(
        `parentTaskId = null AND (status = $0 OR status = $1 OR status = $2 OR status = $3 OR status = $4 OR status = $5)`,
        FormSubmissionStatusEnums.COMPLETED,
        FormSubmissionStatusEnums.PENDING_APPROVAL,
        FormSubmissionStatusEnums.ACCEPTED,
        FormSubmissionStatusEnums.REJECTED,
        FormSubmissionStatusEnums.PENDING_SYNC,
        FormSubmissionStatusEnums.SUBMITTED,
      )
      .filtered(query.join(" AND "), ...args);
    // completed = completed.filter((task) =>
    //   JSON.parse(task.taskDesignerFormSubmissionUsers).some(
    //     (user) => userId === user.userId
    //   )
    // );

    return {
      totalTask: allTasks.length,
      completed: completed.length,
      remaining: pending.length,
      totalElements: allTasks.length,
      elements: pending
        .slice(0, 20)
        .map((result) => MapDBToStateForList(result)),
    };
  }

  async getTaskById(id) {
    const db = await EcoDocsDB.getConnection();

    let [taskSubmission] = db
      .objects(TASK_SUBMISSION_SCHEMA_NAME)
      .filtered(`id = ${id}`)
      .toJSON();

    let [task] = db
      .objects(ADHOC_TASK_SCHEMA_NAME)
      .filtered(`id = ${taskSubmission.taskId}`)
      .toJSON();

    if (!task || !task.taskDetails) {
      return MapDBToState(taskSubmission);
    }

    taskSubmission.taskDetails = task.taskDetails.filter(
      (td) => td.id === taskSubmission.taskDetailId,
    );

    return MapDBToState(taskSubmission);
  }

  async deleteTask(id) {
    const db = await EcoDocsDB.getConnection();
    const query = `id == $0`;
    const task = db.objects(TASK_SUBMISSION_SCHEMA_NAME).filtered(query, id);
    db.write(() => {
      db.delete(task);
    });
  }
}

export default new ScheduledTaskManager();
