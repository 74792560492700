import { API_CONSTANTS } from '../../constants/ApiConstants';
import { handleResponse, handleError } from './GenericResponseHandler';
import { apiClient } from './ApiClient';
import { LivestockFilterModel } from '../../models/Livestock';

export const LivestockService = {
    validateLivestock,
    saveLivestock,
    getFilteredLivestocks,
    getEarTagsByLocationId,
    getLiveStockDetails
}

function validateLivestock(value) {
    return apiClient().get(API_CONSTANTS.API_ROUTES.LIVESTOCK_EXISTS + value)
        .then(handleResponse)
        .catch(handleError);
}

function saveLivestock(params) {
    let url = API_CONSTANTS.API_ROUTES.SAVE_LIVESTOCK;
    return apiClient().post(url, params)
        .then(handleResponse)
        .catch(handleError);
}

function getFilteredLivestocks(paginationData, searchTerms, filterObject = {}, sortObject = {}) {

    const url = API_CONSTANTS.API_ROUTES.GET_FILTERED_LIVESTOCK;
    const params = new LivestockFilterModel(filterObject, sortObject, paginationData, { searchTerms });

    return apiClient().post(url, params)
        .then(handleResponse)
        .catch(handleError);
}

function getEarTagsByLocationId(id) {
    return apiClient().get(API_CONSTANTS.API_ROUTES.GET_LIVESTOCK_EAR_TAG_ID + id)
        .then(handleResponse)
        .catch(handleError);
}

function getLiveStockDetails(id) {
    return apiClient().get(API_CONSTANTS.API_ROUTES.GET_LIVESTOCK_DETAILS + id)
        .then(handleResponse)
        .catch(handleError);
}