import { USER_ACTIONS, ADUSERS_ACTIONS } from "../../constants/ActionConstants";
import { LOCAL_STORAGE_KEYS } from "../../constants/GenericConstants";
import { getUserFilterCount } from "@ecodocs/common/src/helpers/UserHelper";
import { getDetectedPlatformStorage } from "../../helpers/GeneralHelper";

// let currentUserData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));
// let token = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
const storage = getDetectedPlatformStorage();

const state = {
  loggedIn: true,
  token: null,
  currentUserData: {},
  loading: false,
  showToast: false,
  loginError: false,
  department: null,
  toggleNotificationChecked: false,
  userFilter: storage?.getItem(LOCAL_STORAGE_KEYS.USER_FILTERS) || []
};
console.log(state);
const initialState = user ? state : {};

export function user(state = initialState, action) {
  switch (action.type) {
    /**
     * USER LOGIN
     */
    case USER_ACTIONS.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case USER_ACTIONS.AUTO_LOGGED_IN:
      const {
        token,
        user,
        userPermissions,
        taskOfflinePageSize,
        applicationConfiguration,
        department,
      } = action.payload;
      return {
        ...state,
        loggedIn: true,
        loading: false,
        token: token,
        permissions: userPermissions,
        currentUserData: user,
        applicationConfiguration,
        department: department,
        taskOfflinePageSize: taskOfflinePageSize,
      };

    case USER_ACTIONS.LOGIN_SUCCESS:
      const { currentUserData } = action.payload;
      const {
        accessToken,
        permissions,
        taskOfflinePageSize: pageSize,
      } = action.payload.result;
      return {
        ...state,
        loggedIn: true,
        loading: false,
        user: action.user,
        currentUserData: currentUserData,
        loginError: false,
        token: accessToken,
        permissions: permissions,
        taskOfflinePageSize: pageSize,
        applicationConfiguration: action.payload.applicationConfiguration,
      };

    case USER_ACTIONS.SET_DEPARTMENT:
      return {
        ...state,
        department: action.payload.department,
      };

    case USER_ACTIONS.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        loginError: true,
      };

    //EO | USER LOGIN

    /**
     * USER LOGOUT
     */

    case USER_ACTIONS.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case USER_ACTIONS.LOGOUT_SUCCESS:
      return {
        ...state,
        loggedIn: false,
        token: null,
        department: null,
        loading: false,
      };

    case USER_ACTIONS.LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        token: null,
        department: null,
      };

    //EO | USER LOGOUT

    /**
     * USER FORGOT PASSWORD
     */

    case USER_ACTIONS.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case USER_ACTIONS.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        showToast: true,
        forgotPasswordError: false,
      };

    case USER_ACTIONS.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        forgotPasswordError: true
      };

    //EO | USER FORGOT PASSWORD

    /**
     * USER RESET PASSWORD
     */

    case USER_ACTIONS.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case USER_ACTIONS.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case USER_ACTIONS.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
      };

    //EO | USER RESET PASSWORD

    /**
     * ASSOCIATE USER TO DIVISION
     */

    case USER_ACTIONS.ASSOCIATE_USER_TO_DIVISION_SUCCESS:
      return {
        ...state,
        userMessageData: action.payload.message,
        showMessage: true,
      };

    case USER_ACTIONS.ASSOCIATE_USER_TO_DIVISION_REQUEST:
      return {
        ...state,
      };

    case USER_ACTIONS.ASSOCIATE_USER_TO_DIVISION_FAILURE:
      return {
        ...state,
        userMessageData: action.payload,
      };

    //EO | ASSOCIATE USER TO DIVISION

    case USER_ACTIONS.GET_ALL_USERS_REQUEST:
      return {
        ...state,
        getAllUsersLoading: true,
        getAllUsersListingError: false,
      };

    case USER_ACTIONS.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        getAllUsersLoading: false,
        getAllUsersListingError: false,
        users: !!action.payload.result ? action.payload.result.elements : [],
        totalElements: !!action.payload.result
          ? action.payload.result.totalElements
          : 0,
      };

    case USER_ACTIONS.GET_ALL_USERS_FAILURE:
      return {
        ...state,
        getAllUsersLoading: false,
        getAllUsersListingError: true,
        error: action.payload,
      };

    case USER_ACTIONS.GET_NON_ADMIN_USERS_REQUEST:
      return {
        ...state,
        getNonAdminUsersLoading: true,
        getNonAdminUsersListingError: false,
      };

    case USER_ACTIONS.GET_NON_ADMIN_USERS_SUCCESS:
      return {
        ...state,
        getNonAdminUsersLoading: false,
        getNonAdminUsersListingError: false,
        nonAdminUsers: !!action.payload.result ? action.payload.result.elements : [],
        totalElements: !!action.payload.result ? action.payload.result.totalElements : 0,
      };

    case USER_ACTIONS.GET_NON_ADMIN_USERS_FAILURE:
      return {
        ...state,
        getNonAdminUsersLoading: false,
        getNonAdminUsersListingError: true,
        error: action.payload,
      };

    case USER_ACTIONS.GET_ADMIN_USERS_REQUEST:
      return {
        ...state,
        getAdminUsersLoading: true,
        getAdminUsersListingError: false,
      };

    case USER_ACTIONS.GET_ADMIN_USERS_SUCCESS:
      return {
        ...state,
        getAdminUsersLoading: false,
        getAdminUsersListingError: false,
        adminUsers: !!action.payload.result ? action.payload.result.elements : [],
        totalElements: !!action.payload.result ? action.payload.result.totalElements : 0,
      };

    case USER_ACTIONS.GET_ADMIN_USERS_FAILURE:
      return {
        ...state,
        getAdminUsersLoading: false,
        getAdminUsersListingError: true,
        error: action.payload,
      };

    // All Managers
    case USER_ACTIONS.GET_ALL_MANAGERS_REQUEST:
      return {
        ...state,
        getAllUsersLoading: true,
      };

    case USER_ACTIONS.GET_ALL_MANAGERS_SUCCESS:
      return {
        ...state,
        getAllUsersLoading: false,
        managers: !!action.payload.result ? action.payload.result.elements : [],
      };

    case USER_ACTIONS.GET_ALL_MANAGERS_FAILURE:
      return {
        ...state,
        getAllUsersLoading: false,
        error: action.payload,
        managers: [],
      };

    // All Managers - End

    case USER_ACTIONS.HANDLE_EDIT_USER_MESSAGE:
      return {
        ...state,
        showMessage: true,
        userMessageData: action.payload.message,
      };

    case USER_ACTIONS.HANDLE_ADD_USER_MESSAGE:
      return {
        ...state,
        showMessage: action.payload.showMessage,
      };

    case USER_ACTIONS.UPSERT_USER_LIST_FILTERS:
      storage.setItem(LOCAL_STORAGE_KEYS.USER_FILTERS, {
        filterCount: getUserFilterCount(action.payload),
        userFilterValues: action.payload,
      });
      return {
        ...state,
        userFilter: {
          filterCount: getUserFilterCount(action.payload),
          userFilterValues: action.payload,
        },
      };

    case USER_ACTIONS.REMOVE_USER_LIST_FILTERS:
      storage.removeItem(LOCAL_STORAGE_KEYS.USER_FILTERS);
      return {
        ...state,
        userFilter: { filterCount: 0, userFilterValues: {} },
      };

    case USER_ACTIONS.GET_USER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case USER_ACTIONS.GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: action.payload.result,
        recordNotFound: !action.payload.result,
      };

    case USER_ACTIONS.GET_USER_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case USER_ACTIONS.SUBMIT_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case USER_ACTIONS.HANDLE_SUBMIT_USER_SUCCESS:
      return {
        ...state,
        showMessage: true,
        loading: false,
        userMessageData: action.payload.message,
      };

    case USER_ACTIONS.SUBMIT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: action.payload.result,
      };

    case USER_ACTIONS.SUBMIT_USER_FAILURE:
      return {
        ...state,
        loading: false,
        addUserError: action.payload,
      };

    case USER_ACTIONS.HANDLE_CHANGE_PASSWORD_MESSAGE:
      return {
        ...state,
        loading: false,
        showMessage: true,
        userMessageData: action.payload.message,
      };

    case USER_ACTIONS.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case USER_ACTIONS.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        showMessage: true,
        loading: false,
        userMessageData: action.payload.message,
      };

    case ADUSERS_ACTIONS.GET_ADUSERLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ADUSERS_ACTIONS.GET_ADUSERLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        ADUserList: !!action.payload.result
          ? action.payload.result.elements
          : [],
      };

    case ADUSERS_ACTIONS.GET_ADUSERLIST_FAILURE:
      return {
        ...state,
        loading: false,
        ADUserList: !!action.payload.result
          ? action.payload.result.elements
          : [],
      };

    /**
     * QA Tech Actions
     */

    case USER_ACTIONS.QA_FORM_SUBMSSION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case USER_ACTIONS.QA_FORM_SUBMSSION_SUCCESS:
      return {
        ...state,
        qaFormSubmssionResponse: action.payload,
        showMessage: true,
        loading: false,
      };

    case USER_ACTIONS.QA_FORM_SUBMSSION_FAILURE:
      return {
        ...state,
        qaFormSubmssionResponse: action.payload,
        showMessage: true,
        loading: false,
      };

    //EO | QA Tech Actions

    /**
     * TABLE ACTIONS
     */

    case USER_ACTIONS.ON_SORT_USER_COLUMN_REQUEST:
      return {
        ...state,
        getAllUsersOnSortingLoading: true,
        users: [],
      };

    case USER_ACTIONS.ON_SORT_USER_COLUMN_SUCCESS:
      return {
        ...state,
        getAllUsersOnSortingLoading: false,
        users: !!action.payload.result ? action.payload.result.elements : [],
        totalElements: !!action.payload.result
          ? action.payload.result.totalElements
          : 0,
      };

    case USER_ACTIONS.ON_SORT_USER_COLUMN_FAILURE:
      return {
        ...state,
        getAllUsersOnSortingLoading: false,
        users: [],
        error: action.payload,
      };

    case USER_ACTIONS.GET_USER_BY_LOCATION_WORKER_REQUEST:
      return {
        ...state,
        getAllUsersOnSortingLoading: true,
        usersByLocationWorkorder: [],
      };

    case USER_ACTIONS.GET_USER_BY_LOCATION_WORKER_SUCCESS:
      return {
        ...state,
        getAllUsersOnSortingLoading: false,
        usersByLocationWorkorder: !!action.payload ? action.payload.result.elements : [],
        totalElements: !!action.payload
          ? action.payload.totalElements
          : 0,
      };

    case USER_ACTIONS.GET_USER_BY_LOCATION_WORKER_FAILURE:
      return {
        ...state,
        getAllUsersOnSortingLoading: false,
        usersByLocationWorkorder: [],
        error: action.payload,
      };

    //EO | TABLE ACTIONS

    //Reset user detail from redux
    case USER_ACTIONS.RESET_USER_DETAIL_STATE:
      return {
        ...state,
        userDetails: null,
      };

    //Update current user data from redux
    case USER_ACTIONS.UPDATE_USER_DETAIL_REQUEST:
      return {
        ...state,
        currentUserData: action.payload,
      };

    // empty all users list
    case USER_ACTIONS.EMPTY_ALL_USERS:
      return {
        ...state,
        users: [],
        totalElements: 0,
      };

    // User Signup - Checklist Journey
    case USER_ACTIONS.USER_SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_ACTIONS.USER_SIGNUP_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loading: false,
        user: action.user || {},
        currentUserData: action.payload.currentUserData || {},
        loginError: false,
        token: action.payload.result.accessToken || "",
      };
    case USER_ACTIONS.USER_SIGNUP_FAILURE:
      return {
        ...state,
        signupError: true,
        error: action.payload,
        loading: false,
      };

    case USER_ACTIONS.CHANGE_USER_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case USER_ACTIONS.CHANGE_USER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: action.payload.result
      };

    case USER_ACTIONS.CHANGE_USER_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case USER_ACTIONS.TOGGLE_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case USER_ACTIONS.TOGGLE_NOTIFICATIONS_SUCCESS:
      let wow = state.currentUserData;
      wow.notificationEnabled = action.payload.result;
      return {
        ...state,
        loading: false,
        toggleNotificationChecked: action.payload.result,
        currentUserData: wow,
      };

    case USER_ACTIONS.TOGGLE_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
