import React from "react";

import { GENERIC_CONSTANTS } from "../constants/GenericConstants";
import { BMMultiSelect } from "../models/Form";
import {
  parseDateForServer,
  parseDateForDatePicker,
  showFormulaField,
  showFirstXCharacters,
} from "./GeneralHelper";

export function parseLocationData(formValues) {
  let parsedResponse = {
    status: true,
    locationWorkerIds: _.map(formValues.workers, "value"),
    // managerId:
    //   !!formValues.managerName && formValues.managerName.value
    //     ? formValues.managerName.value
    //     : null,
    // locationManagerIds: _.map(formValues.managerName, "value"),
    managerId: !!formValues.managerName && formValues.managerName.value? formValues.managerName.value: null,
    locationId: formValues.locationId.trim(),
    locationName: formValues.locationName.trim(),
    locationTypeId: parseInt(formValues.locationType),
    address: formValues.address,
  };
  return parsedResponse;
}

export function parsedLocationFilters(filterParams) {
  let filters = {};

  let status = filterParams.status;
  let managerIds = filterParams.managerIds;
  let locationTypeIds = filterParams.locationTypeIds;

  filters.status =
    !!status && !!status.value
      ? status.value
      : !!status && status.value === false
      ? false
      : "";
  filters.managerIds =
    !!managerIds && !!managerIds.value ? [managerIds.value] : [];
  filters.locationTypeIds =
    !!locationTypeIds && !!locationTypeIds.value ? [locationTypeIds.value] : [];

  return filters;
}

export function parseLocationListingData(locationListingData, ENUM = {}) {
  let parsedLocationList = [];
  if (!!locationListingData) {
    for (var i = 0; i < locationListingData.length; i++) {
      const data = locationListingData[i];

      let locationObject = {
        id: data.id,
        locationName: showFirstXCharacters(data.locationName, 15),
        locationType: data.locationType,
        managerName: data.manager || '',
        workerCount: data.workerCount || 0,
        status: data.status ? "Active" : "Inactive",
        createdOn: !!data.createdOn
          ? parseDateForDatePicker(data.createdOn)
          : "",
      };
      parsedLocationList.push(locationObject);
    }
  }

  return parsedLocationList;
}

export function parseLocationViewFormData(locationData) {
  const { locationType, locationId, locationName, address, status, manager, locationWorkers, locationManagers, formFields } = locationData;

  const managerId = !!manager && !!manager.id ? manager.id : "";
  const managerName = !!manager && !!manager.name ? manager.name : "";

  const parsedData = {
    locationId: locationId,
    locationName: locationName,
    address: address,
    status: status,

    locationType: locationData.locationTypeId,
    managerName: new BMMultiSelect({ value: managerId, label: managerName }),
    // managerName: !!locationManagers && !!locationManagers.length? locationManagers.map((data) => new BMMultiSelect({ value: data.id, label: data.name })): [],
    workers: !!locationWorkers && !!locationWorkers.length? locationWorkers.map((data) => new BMMultiSelect({ value: data.id, label: data.name })): []
  };

  return parsedData;
}

export function parseLocationEditFormData(locationData) {
  const {
    locationType,
    locationId,
    locationName,
    address,
    status,
    manager,
    locationWorkers,
    formFields,
  } = locationData;

  const parsedData = {
    locationId: locationId,
    locationName: locationName,
    address: address,
    status: status,
    locationTypes: locationType,
    managers: manager,
    locationWorkers: locationWorkers,
  };

  parsedData.formFields = [];
  if (!!formFields && formFields.length > 0) {
    parsedData.formFields = formFields.map((obj) => {
      let fieldsObject = {};
      fieldsObject.specification = obj.specification || "";
      fieldsObject.title = obj.title;
      return fieldsObject;
    });
  }

  return parsedData;
}

export function parseLocationTypeForSelect(values) {
  let parsedData = [];
  if (!!values && values.length > 0) {
    for (var i = 0; i < values.length; i++) {
      parsedData.push({
        value: values[i].id,
        label: values[i].name,
        color: "#00B8D9",
      });
    }
  }
  return parsedData;
}

/**
 * Getting Filter Count For
 * Task List Page
 * @param {Object} filters
 */
export function getLocationFilterCount(filters) {
  let filterCount = 0;

  _.each(filters, (obj, key) => {
    if (!!obj) {
      filterCount += 1;
    }
  });
  return filterCount;
}
