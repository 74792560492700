import EcoDocsDB, {
  SUBMISSION_SCHEMA_NAME,
  TASK_SUBMISSION_SCHEMA_NAME,
  ADHOC_TASK_SCHEMA_NAME,
  LOCATION_SCHEMA_NAME,
} from "..";
import { parseSubmissionPayload } from "../../models/databaseModels/pendingSubmission";
import {
  TASK_VIEW_TYPE,
  FORM_SUBMISSION_MASTER_TYPE,
  FormSubmissionStatusEnums,
  SUBMISSION_TYPE,
  GENERIC_CONSTANTS,
  MAX_DATE,
} from "../../constants/GenericConstants";
import { getSubFormSubmissionMasterTasks } from "../../helpers/TaskHelper";
import { MapApiToDB } from "../../models/databaseModels/taskSubmission";
import { MapDBToState as AdhocTaskMapper } from "../../models/databaseModels/adhocTask";
import {
  MapDBToState as ScheduledTaskMapper,
  MapSubTaskToDB,
} from "../../models/databaseModels/taskSubmission";

class SubmissionManager {
  async saveSubmission(
    submission,
    taskType,
    submittedBy,
    isNewSubmission = false,
    isAutoSaving = false
  ) {
    try {
      const db = await EcoDocsDB.getConnection();

      submission.taskType = taskType;
      submission.submissionType = SUBMISSION_TYPE.SUBMIT;

      const task =
        taskType === TASK_VIEW_TYPE.SCHEDULED
          ? db
              .objects(TASK_SUBMISSION_SCHEMA_NAME)
              .filtered(`id = ${Number(submission.id)}`)[0]
          : db
              .objects(ADHOC_TASK_SCHEMA_NAME)
              .filtered(`id = ${Number(submission.id)}`)[0];

      const fieldValues = JSON.parse(submission.payload);
      const {
        correctivePreventiveMeasure,
        showViolations,
        violatedFields,
        locationId,
        formSubmissions,
      } = fieldValues;

      if (taskType === TASK_VIEW_TYPE.ADHOC) {
        // TODO: Subtasks are not implemented in either listing or submission
        // in new APIs
        if (task.isSubtask) {
          // const uniqueId = Date.now();
          // const parentTask = db
          //   .objects(ADHOC_TASK_SCHEMA_NAME)
          //   .filtered(`id == ${Number(task.parentTaskId)}`)[0];
          // let parentTaskSubmission = AdhocTaskMapper(parentTask);
          // parentTaskSubmission.submissionMasterId = uniqueId;
          // const newSubtaskId = Date.now();
          // parentTaskSubmission.subTasks = parentTask.subTasks.map((subtask) => {
          //   const newSubtask = AdhocTaskMapper(subtask);
          //   if (newSubtask.id === task.id) {
          //     newSubtask.status = FormSubmissionStatusEnums.PENDING_SYNC;
          //     newSubtask.correctivePreventiveMeasure =
          //       correctivePreventiveMeasure;
          //     newSubtask.showViolations = showViolations;
          //     newSubtask.submittedOn = new Date();
          //     newSubtask.submittedBy = submittedBy;
          //     newSubtask.formSubmissionsByParentMasterId = [
          //       {
          //         ...newSubtask,
          //         submissionMasterId: newSubtaskId,
          //         formSubmission: JSON.stringify(formSubmission),
          //       },
          //     ];
          //   }
          //   newSubtask.parentTaskId = uniqueId;
          //   return newSubtask;
          // });
          // parentTaskSubmission = MapApiToDB(
          //   Object.assign({}, parentTaskSubmission)
          // );
          // parentTaskSubmission.submissionStartTime = MAX_DATE;
          // parentTaskSubmission.submissionEndTime = MAX_DATE;
          // parentTaskSubmission.isAdhocEntry = true;
          // parentTaskSubmission.formSubmissionMasterTypeId =
          //   FORM_SUBMISSION_MASTER_TYPE.ADHOC;
          // parentTaskSubmission.submissionCount = null;
          // parentTaskSubmission.taskType = taskType;
          // parentTaskSubmission.location = db
          //   .objects(LOCATION_SCHEMA_NAME)
          //   .filtered(`id == ${Number(locationId)}`)[0];
          // submission.id = newSubtaskId;
          // submission.parentId = parentTaskSubmission.submissionMasterId;
          // submission.isSubtask = fieldValues.isSubtask;
          // db.create(TASK_SUBMISSION_SCHEMA_NAME, parentTaskSubmission, true);
          // db.create(SUBMISSION_SCHEMA_NAME, submission, true);
        } else {
          db.write(() => {
            const uniqueId = Math.random() * Date.now();
            const location = db
              .objects(LOCATION_SCHEMA_NAME)
              .filtered(`id == ${Number(locationId)}`)[0];
            const taskDetail =
              task.taskDetails && task.taskDetails.filter((td) => td.isActive);

            const newEntry = {
              id: uniqueId,
              taskId: task.id,
              taskDetailId: taskDetail && taskDetail[0] && taskDetail[0].id,
              taskName:
                (taskDetail && taskDetail[0] && taskDetail[0].title) || "",
              taskNumber: task.taskNumber,
              createdBy: task.createdBy,
              taskCreatedBy: task.taskCreatedBy,
              workorderId: task.workorderId,
              workorderNumber: task.workorderNumber,
              workorder: task.workorder,
              location,
              locationId: location.id,
              locationName: location.locationName,
              taskType,
              parentTaskId: task.parentTaskId,
              isActive: true,
              status: isAutoSaving
                ? FormSubmissionStatusEnums.PENDING
                : FormSubmissionStatusEnums.PENDING_SYNC,
              submittedOn: new Date(),
              isSubtask: false,
              showViolations,
              correctivePreventiveMeasure,
              isDeleted: false,
              submittedBy: submittedBy,
              formSubmissionMasterTypeId: FORM_SUBMISSION_MASTER_TYPE.ADHOC,
              formSubmissions: JSON.stringify(formSubmissions),
            };
            submission.parentId = newEntry.id;

            db.create(TASK_SUBMISSION_SCHEMA_NAME, newEntry, true);
            db.create(SUBMISSION_SCHEMA_NAME, submission, true);
          });
        }
      } else {
        if (isNewSubmission) {
          // TODO: Subtasks are not implemented in either listing or submission
          // in new APIs
          // const uniqueId = Date.now();
          // const parentSubTask = new ScheduledTaskMapper(task); // Creating copy of parent sub task for which the new submission is generating
          // parentSubTask.submissionMasterId = uniqueId;
          // const mappedTask = new MapSubTaskToDB(parentSubTask);
          // db.create(TASK_SUBMISSION_SCHEMA_NAME, mappedTask, true);
          // const newEntry = db
          //   .objects(TASK_SUBMISSION_SCHEMA_NAME)
          //   .filtered(`submissionMasterId == ${Number(uniqueId)}`)[0];
          // newEntry.status = FormSubmissionStatusEnums.PENDING_SYNC;
          // newEntry.formSubmission = JSON.stringify(formSubmission);
          // newEntry.submittedOn = new Date();
          // newEntry.submittedBy = submittedBy;
          // newEntry.correctivePreventiveMeasure = correctivePreventiveMeasure;
          // newEntry.showViolations = showViolations;
          // newEntry.workorderNumber =
          //   mappedTask.workorder && mappedTask.workorder.workorderNumber;
          // task.formSubmissionsByParentMasterId.push(newEntry);
          // submission.id = uniqueId;
          // submission.parentId = task.parentTaskId;
          // submission.isSubtask = fieldValues.isSubtask;
          // db.create(SUBMISSION_SCHEMA_NAME, submission, true);
        } else {
          db.write(() => {
            task.status = isAutoSaving
              ? FormSubmissionStatusEnums.PENDING
              : FormSubmissionStatusEnums.PENDING_SYNC;
            task.correctivePreventiveMeasure = correctivePreventiveMeasure;
            task.showViolations = showViolations;
            task.formSubmissions = JSON.stringify(formSubmissions);
            task.submittedOn = new Date();
            task.submittedBy = submittedBy;
            submission.isSubtask = fieldValues.isSubtask;
            submission.parentId = submission.id;
            db.create(SUBMISSION_SCHEMA_NAME, submission, true);
          });
        }
      }

      return submission.parentId;
    } catch (e) {
      console.log("saveSubmission fail", e);
    }
  }

  async saveDeleteSubmission(id) {
    const db = await EcoDocsDB.getConnection();
    const submission = {
      id,
      submissionType: SUBMISSION_TYPE.DELETE,
    };

    db.write(() => {
      db.create(SUBMISSION_SCHEMA_NAME, submission);
    });
  }

  async getSubmissions() {
    const db = await EcoDocsDB.getConnection();
    const results = db
      .objects(SUBMISSION_SCHEMA_NAME)
      .sorted("isSubtask", true);
    // EcoDocsDB.close(db);
    return results;
  }
}

export default new SubmissionManager();
