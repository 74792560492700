import { DIVISION_ACTIONS } from '../../constants/ActionConstants';

const initialState = { divisions: [] };

export function division(state = initialState, action) {

    switch (action.type) {
        case DIVISION_ACTIONS.GET_DIVISIONS_REQUEST:
            return {
                ...state,
                loading: true
            };

        case DIVISION_ACTIONS.GET_DIVISIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                divisions: !!action.payload ? action.payload.result.elements : [],
                totalElements: !!action.payload ? action.payload.result.totalElements : 0
            };

        case DIVISION_ACTIONS.GET_DIVISIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        default:
            return state;
    }
}