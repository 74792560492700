import { API_CONSTANTS } from "../../constants/ApiConstants";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { apiClient } from "./ApiClient";
import { WorkorderFilterModel } from "../../models/Workorder";

export const workorderService = {
  getWorkOrdersByProgramId,
  getFilterWorkorders,
  getFilteredWorkorders,
  submitWorkorderData,
  validateWorkorderNo,
  getWorkorderDetails,
  editWorkorder,
  associateProgramToWorkorders,
  getWorkorderStatistics,
  changeState,
  getFilteredLocation,
};

function getWorkOrdersByProgramId(programId) {
  let url = "";

  if (programId) {
    url = API_CONSTANTS.API_ROUTES.GET_FILTERED_WORKORDER;
    return apiClient()
      .post(url, { programs: [programId], pageNumber: 0, state: [true], status:[1] }) // pageNumber 0 -> returns all workorders assocaited with program
      .then(handleResponse)
      .catch(handleError);
  } else {
    url = API_CONSTANTS.API_ROUTES.GET_ALL_WORKORDERS;
    return apiClient().get(url).then(handleResponse).catch(handleError);
  }
}

function getFilteredWorkorders(
  paginationData,
  searchTerms,
  filterObject = {},
  sortObject = {},
  isQA
) {
  const url = API_CONSTANTS.API_ROUTES.GET_FILTERED_WORKORDER;
  const params = new WorkorderFilterModel(
    filterObject,
    sortObject,
    paginationData,
    { searchTerms },
    isQA
  );

  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function submitWorkorderData(workordersData) {
  return apiClient()
    .post(
      API_CONSTANTS.API_ROUTES.ADD_WORKORDER,
      JSON.stringify(workordersData)
    )
    .then(handleResponse)
    .catch(handleError);
}

function validateWorkorderNo(workorderNo) {
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.WORKORDER_EXISTS + "/" + workorderNo)
    .then(handleResponse)
    .catch(handleError);
}

function getWorkorderDetails(id) {
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.GET_WORKORDER_BY_ID + id)
    .then(handleResponse)
    .catch(handleError);
}

function editWorkorder(workordersData, id) {
  return apiClient()
    .put(
      API_CONSTANTS.API_ROUTES.Edit_WORKORDER + "/" + id,
      JSON.stringify(workordersData)
    )
    .then(handleResponse)
    .catch(handleError);
}

function associateProgramToWorkorders(data) {
  return apiClient()
    .put(API_CONSTANTS.API_ROUTES.ASSOCIATE_PROGRAM, JSON.stringify(data))
    .then(handleResponse)
    .catch(handleError);
}

function getWorkorderStatistics(model) {
  return apiClient()
    .post(API_CONSTANTS.API_ROUTES.GET_WORKORDER_STATISTICS, model)
    .then(handleResponse)
    .catch(handleError);
}

function changeState(id, isActive) {
  return apiClient()
    .put(API_CONSTANTS.API_ROUTES.CHANGE_WORKORDER_STATE + "/" + id, {
      isActive: isActive,
    })
    .then(handleResponse)
    .catch(handleError);
}

function getFilterWorkorders(model) {
  const url = API_CONSTANTS.API_ROUTES.GET_FILTERED_WORKORDER;
  return apiClient().post(url, model).then(handleResponse).catch(handleError);
}

function getFilteredLocation(params) {
  const url = API_CONSTANTS.API_ROUTES.GET_FILTERED_LOCATIONS;
  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}
