import { GENERIC_CONSTANTS } from "../constants/GenericConstants";
import {
  parseDateForDatePicker,
  showFirstXCharacters,
  parseDateTime24H,
  parseTime24H,
  parseDate,
  parseDateForServer,
  parseDateTime24Hours,
} from "./GeneralHelper";
import _ from "lodash";
import React from "react";
import { parseLocationForSelect } from "./TaskHelper";

export function parseReviewListingData(reviewListingData, ENUM = {}) {
  let parsedReviewList = [];
  const { REVIEWS_STATUS, OCCURRENCE_FREQUENCY } = ENUM;
  if (!!reviewListingData) {
    for (var i = 0; i < reviewListingData.length; i++) {
      let reviewObject = {};
      const data = reviewListingData[i];

      let statusObj =
        _.find(REVIEWS_STATUS, (obj) => obj.value === data.reviewStatusId) ||
        {};
      // let isActive = data.isActive;
      // let statusIcon = isActive ? "activeIcon" : "inactiveIcon";

      reviewObject.taskNumber = (
        <div>
          {/* <i className={statusIcon}></i> */}
          {data.taskNumber || "N/A"}
        </div>
      );
      reviewObject.taskTitle = showFirstXCharacters(data.taskTitle, 30) || "N/A";
      reviewObject.type = data.reviewType || "N/A";
      reviewObject.workOrder = data.workOrder || "N/A";
      reviewObject.location = data.location || "N/A";
      reviewObject.submissions = data.submissions || "N/A";
      reviewObject.reviews = data.reviews || "N/A";
      reviewObject.dueDate = parseDate(data.dueDate);
      reviewObject.id = data.id;
      reviewObject.status = (
        <div className={statusObj.className || "status-null"}>
          {statusObj.label || "N/A"}
        </div>
      ); // pending, partial-completed, completed

      let occurrenceObj = _.find(OCCURRENCE_FREQUENCY, (obj) => obj.value === data.reviewOccurenceEnumId) || {label:"Adhoc"};
      reviewObject.occurrence = !!occurrenceObj && !!occurrenceObj.label && occurrenceObj.label;

      parsedReviewList.push(reviewObject);
    }
  }

  return parsedReviewList;
}

export function parseReviewSubmissionListingData(
  reviewSubmissionListingData,
  ENUM = {}
) {
  let parsedReviewSubmissionList = [];
  if (!!reviewSubmissionListingData) {
    for (var i = 0; i < reviewSubmissionListingData.length; i++) {
      let reviewSubmissionObject = {};
      const data = reviewSubmissionListingData[i];

      reviewSubmissionObject.formSubmissionMasterId =
        data.formSubmissionMasterId;
      reviewSubmissionObject.submittedBy = data.submittedBy || "N/A";
      reviewSubmissionObject.submittedById = data.submittedById;
      reviewSubmissionObject.submittedOn = data.submittedOn? parseDateTime24Hours(data.submittedOn) : "N/A";
      reviewSubmissionObject.occurrence = data.occurence;
      reviewSubmissionObject.violations = data.violations;
      reviewSubmissionObject.id = data.formSubmissionMasterId;
      reviewSubmissionObject.recordClass =
        data.violations == "Yes" ? "rowViolation" : "";

      parsedReviewSubmissionList.push(reviewSubmissionObject);
    }
  }

  return parsedReviewSubmissionList;
}

export function parseReviewSubmitData(formValues) {
  let parsedReviewSubmitData = {};

  parsedReviewSubmitData.reviewId = parseInt(formValues.reviewId);
  parsedReviewSubmitData.reviewFormSubmissionMaster = formValues.reviewFormSubmissionMaster || [];
  parsedReviewSubmitData.reviewSubmissionStatusId = parseInt(formValues.reviewStatus) || 0;
  parsedReviewSubmitData.signature = formValues.signature || "";

  return parsedReviewSubmitData;
}

export function parseReviewData(formValues, status, isEdit = null) {
  let parsedResponse = {};
  let programManualDetails = {};

  programManualDetails.creationDateTime = formValues.creationDateTime || "";
  programManualDetails.status = status || "";
  programManualDetails.description =
    (formValues.revisionNote &&
      formValues.revisionNote.replace(/(<([^>]+)>)/gi, "")) ||
    "";
  programManualDetails.title = formValues.title || "";
  programManualDetails.type = formValues.type || "";
  programManualDetails.mediaIds = formValues.mediaIds || [];
  programManualDetails.locationIds =
    (formValues.locationIds &&
      formValues.locationIds.map((location) => location.value)) ||
    [];

  parsedResponse.programNumber = formValues.programNumber || "";
  parsedResponse.programManualDetails = [programManualDetails || {}];

  if (isEdit) parsedResponse.id = isEdit;

  return parsedResponse;
}

export function parsedReviewFilters(filterParams) {
  let filters = !!filterParams.ReviewDepartmentId? {ReviewDepartmentId: filterParams.ReviewDepartmentId} : {};
  let reviewTypeIds = filterParams.type;
  let workOrderIds = filterParams.workorders;
  let locationIds = filterParams.locations;
  let reviewStatusIds = filterParams.reviewStatus;
  let taskStatusIds = filterParams.taskStatus;
  let includeHistory = filterParams.includeHistory;
  let occurrenceIds = filterParams.occurrence;
  let program = filterParams.program;

  filters.startDueDate = !!filterParams.startDate ? parseDateForServer(filterParams.startDate) : null;
  filters.endDueDate = !!filterParams.endDate ? parseDateForServer(filterParams.endDate) : null;
  reviewTypeIds = _.map(reviewTypeIds, "value");
  workOrderIds = _.map(workOrderIds, "value");
  locationIds = _.map(locationIds, "value");
  reviewStatusIds = _.map(reviewStatusIds, "value");
  taskStatusIds = _.map(taskStatusIds, "value");
  occurrenceIds = _.map(occurrenceIds, "value");
  program = _.map(program, "value");

  filters.reviewTypeIds = reviewTypeIds;
  filters.workOrderIds = workOrderIds;
  filters.locationIds = locationIds;
  filters.reviewStatusIds = reviewStatusIds;
  filters.taskStatusIds = taskStatusIds;
  filters.includeHistory = includeHistory;
  filters.occurenceIds = occurrenceIds;
  filters.DepartmentIds = program;
  // filters.ShowReview = filterParams.ShowReview;

  return filters;
}

export function parsedReviewSubmissionsFilters(filterParams, ENUM = {}) {
  const { TASK_TYPES } = ENUM;
  let filters = {};
  let taskTypes = filterParams.taskType;
  let violation = filterParams.violation;
  let reviewIds = filterParams.reviewIds;
  let completedBy = filterParams.completedBy;
  let formSubmissionMasterTypeIds = [];

  taskTypes = _.map(taskTypes, "value");
  violation = violation && violation.value;
  completedBy = completedBy && completedBy.value;

  taskTypes.map((type) =>{
    type == TASK_TYPES[0].value && formSubmissionMasterTypeIds.push(1);
    type == TASK_TYPES[1].value && formSubmissionMasterTypeIds.push(2);
  });

  filters.formSubmissionMasterTypeIds = formSubmissionMasterTypeIds;
  filters.includeViolated = violation;
  filters.completedBy = completedBy;
  filters.reviewIds = reviewIds;
  filters.includePendingReviews = filterParams.includePendingReviews;
  filters.ShowReview = filterParams.ShowReview;
  return filters;
}

export function parseReviewersForSelect(users) {
  let parsedData = [];
  users = _.uniqBy(users, "submittedById");
  if (!!users && users.length > 0) {
    for (var i = 0; i < users.length; i++) {
      parsedData.push({
        value: users[i].submittedById,
        label: users[i].submittedBy,
        color: "#00B8D9",
      });
    }
  }
  return parsedData;
}

export function parseReviewEditFormData(programManualData) {
  const parsedData = {};
  const programManualObject = programManualData || {};
  const programManualDetails =
    (programManualData.programManualDetails &&
      programManualData.programManualDetails[0]) ||
    {};

  parsedData.creationDateTime = !!programManualDetails.revisionDateTime
    ? parseDateForDatePicker(programManualDetails.revisionDateTime)
    : parseDateForDatePicker(programManualDetails.creationDateTime);
  parsedData.revisionNote = programManualDetails.description;
  parsedData.formFields = [];
  if (
    !!programManualData.formFields &&
    programManualData.formFields.length > 0
  ) {
    parsedData.formFields = programManualData.formFields.map((obj) => {
      let fieldsObject = {};
      fieldsObject.specification = obj.specification || "";
      fieldsObject.title = obj.title;
      return fieldsObject;
    });
  }

  parsedData.programNumber = programManualData.programNumber;
  parsedData.program = !!programManualObject ? programManualObject.id : "";
  parsedData.title = programManualDetails.title;
  parsedData.type = programManualDetails.type;
  parsedData.locationIds =
    programManualDetails.locations &&
    parseLocationForSelect(programManualDetails.locations);
  parsedData.media = programManualDetails.media && programManualDetails.media;
  return parsedData;
}

export function parseReviewViewFormData(data, ENUM) {
  const reviewObject = {};

  reviewObject.taskNumber = data.taskNumber || "N/A";
  reviewObject.title = showFirstXCharacters(data.taskTitle, 30) || "N/A";
  reviewObject.type = data.reviewType || "N/A";
  reviewObject.workOrder = data.workOrder || "N/A";
  reviewObject.location = data.location || "N/A";
  reviewObject.submissions = data.submissions || "N/A";
  reviewObject.recordReviewed = data.recordReviewed || "N/A";
  reviewObject.dueDate = parseDate(data.dueDate);
  reviewObject.id = data.id;
  reviewObject.reviewedOn = parseDateTime24Hours(data.reviewedOn);
  reviewObject.reviewedBy = data.reviewedBy;
  reviewObject.signature = data.signature;
  if(data.reviewHistoryStatus){
    const { REVIEW_HISTORY_STATUS } = ENUM;
    let statusObj = _.find(REVIEW_HISTORY_STATUS, (obj) => obj.value === data.reviewHistoryStatusId) || {};
    reviewObject.status = statusObj.label || "N/A";
  } else
    reviewObject.status = data.reviewStatus;
    
  const { OCCURRENCE_FREQUENCY} = ENUM;
  let occurrenceObj = _.find(OCCURRENCE_FREQUENCY, (obj) => obj.value === data.reviewOccurenceEnumId) || {label:"Adhoc"};
  reviewObject.occurrence = !!occurrenceObj && !!occurrenceObj.label && occurrenceObj.label;
  reviewObject.occurrenceEnumId = data.reviewOccurenceEnumId;
  return reviewObject;
}

export function parseReviewsForSelect(programManuals) {
  let parsedProgramManuals = [];
  if (!!programManuals && programManuals.length > 0) {
    for (var i = 0; i < programManuals.length; i++) {
      parsedProgramManuals.push({
        value: programManuals[i].id,
        label: programManuals[i].programNumber,
        color: "#00B8D9",
      });
    }
  }
  return parsedProgramManuals;
}

export function getReviewFilterCount(filters) {
  let dateRangePresent = false;
  let filterCount = 0;
  if(!!filters.violation && !Array.isArray(filters.violation)) filters.violation = [filters.violation];
  if(!!filters.completedBy && !Array.isArray(filters.completedBy)) filters.completedBy = [filters.completedBy];
  _.each(filters, (obj, key) => {
    if (!!obj) {
      if (
        key === GENERIC_CONSTANTS.FIELD_NAME.START_DATE ||
        key === GENERIC_CONSTANTS.FIELD_NAME.END_DATE
      ) {
        dateRangePresent = true;
        filterCount += 1;
      } else if (obj.length > 0) {
        filterCount += 1;
      }
    }
  });
  if (dateRangePresent) {
    //subtracting 1 because using start date and end date as single filter
    filterCount -= 1;
  }
  if(!!filters.violation && Array.isArray(filters.violation)) filters.violation = filters.violation[0];
  if(!!filters.completedBy && Array.isArray(filters.completedBy)) filters.completedBy = filters.completedBy[0];
  return filterCount;
}
