import { SCHEDULER_ACTIONS } from '../../constants/ActionConstants';
import { getProgramFilterCount } from '@ecodocs/common/src/helpers/ProgramHelper';

const initialState = { schedulers: [], showMessage: false };

export function scheduler(state = initialState, action) {
    switch (action.type) {

        /* 
            Scheduler Listing
        */
        case SCHEDULER_ACTIONS.GET_SCHEDULER_REQUEST:
            return {
                ...state,
                getSchedulersLoading: true,
                getSchedulersListingError: false,
            };

        case SCHEDULER_ACTIONS.GET_SCHEDULER_SUCCESS:
            return {
                ...state,
                getSchedulersLoading: false,
                schedulers: !!action.payload.result ? action.payload.result.elements : [],
                totalElements: !!action.payload.result ? action.payload.result.totalElements : 0,
                getSchedulersListingError: false,
            };

        case SCHEDULER_ACTIONS.GET_SCHEDULER_FAILURE:
            return {
                ...state,
                getSchedulersLoading: false,
                getSchedulersListingError: true,
                error: action.payload
            };

        /*
            Handle Scheuler Messages
        */
        case SCHEDULER_ACTIONS.HANDLE_ADD_SCHEDULER_MESSAGE:
            return {
                ...state,
                schedulerMessageData: action.payload,
                showMessage: action.payload.showMessage
            };

        /*
            Scheuler List Filters
        */
        case SCHEDULER_ACTIONS.UPSERT_SCHEDULER_LIST_FILTERS:
            return {
                ...state,
                schedulerFilter: { filterCount: getProgramFilterCount(action.payload), schedulerFilterValues: action.payload }
            };

        /*
          Remove Scheuler List Filters  
        */
        case SCHEDULER_ACTIONS.REMOVE_SCHEDULER_LIST_FILTERS:
            return {
                ...state,
                schedulerFilter: { filterCount: 0, schedulerFilterValues: {} }
            };

        /*
            Add Scheduler
         */
        case SCHEDULER_ACTIONS.ADD_SCHEDULER_REQUEST:
            return {
                ...state,
                addSchedulersLoading: true,
            };

        case SCHEDULER_ACTIONS.ADD_SCHEDULER_SUCCESS:
            return {
                ...state,
                addSchedulersLoading: false,
            };

        case SCHEDULER_ACTIONS.ADD_SCHEDULER_FAILURE:
            return {
                ...state,
                addSchedulersLoading: false,
            };


        /*
          Edit Scheduler
        */
        case SCHEDULER_ACTIONS.EDIT_SCHEDULER_REQUEST:
            return {
                ...state,
                addSchedulersLoading: true,
            };

        case SCHEDULER_ACTIONS.EDIT_SCHEDULER_SUCCESS:
            return {
                ...state,
                addSchedulersLoading: false,
            };

        case SCHEDULER_ACTIONS.EDIT_SCHEDULER_FAILURE:
            return {
                ...state,
                addSchedulersLoading: false,
            };

        /* Get Scheduler by id */
        case SCHEDULER_ACTIONS.GET_SCHEDULER_BY_ID_REQUEST:
            return {
                ...state,
                loading: true,
                scheduleLoading: true
            };

        case SCHEDULER_ACTIONS.GET_SCHEDULER_BY_ID_SUCCESS:
            let num = action.payload.id;
            let a = { ...state.schedulerDetails };
            a[num] = action.payload.data.result;
            return {
                ...state,
                loading: false,
                scheduleLoading: false,
                schedulerDetails: a,
                // schedulerDetails: action.payload.data.result,
                recordNotFound: !action.payload.data.result
            };

        case SCHEDULER_ACTIONS.GET_SCHEDULER_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                scheduleLoading: false,
                error: action.payload
            };

        /* Get Schedulers by Task id */
        case SCHEDULER_ACTIONS.GET_TASK_SCHEDULERS_REQUEST:
            return {
                ...state,
                loading: true
            };

        case SCHEDULER_ACTIONS.GET_TASK_SCHEDULERS_SUCCESS:
            return {
                ...state,
                loading: false,
                taskSchedulers: action.payload.result,
                recordNotFound: !action.payload.result
            };

        case SCHEDULER_ACTIONS.GET_TASK_SCHEDULERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case SCHEDULER_ACTIONS.REMOVE_SCHEDULER_REQUEST:
            return {
                ...state,
                loading: false,
                taskSchedulers: null,
                recordNotFound: false,
                schedulerDetails: null
            };
        default:
            return state;
    }
}

function dumyScheulerListingData() {
    return [
        {
            taskNumber: "123",
            title: "Bran Cleaning",
            workorderTitle: "Sun Farms WO",
            occurenceEnumId: 2,
            priorityEnumId: 2,
            createdBy: "Muhammad S.",
            isActive: true
        },
        {
            taskNumber: "456",
            title: "Fence Painting",
            workorderTitle: "Sun Farms WO",
            occurenceEnumId: 3,
            priorityEnumId: 1,
            createdBy: "Rashid R.",
            isActive: true
        },
        {
            taskNumber: "789",
            title: "Sheep Feeding",
            workorderTitle: "Sun Farms WO",
            occurenceEnumId: 1,
            priorityEnumId: 2,
            createdBy: "Mubashra Y.",
            isActive: true
        },
        {
            taskNumber: "0123",
            title: "My Task",
            workorderTitle: "Sun Farms WO",
            occurenceEnumId: 4,
            priorityEnumId: 3,
            createdBy: "Shahbaz A.",
            isActive: true
        }
    ];
}