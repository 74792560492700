import moment from "moment";
import React from "react";
// import { convertFromRaw } from 'draft-js';
import {
  GENERIC_CONSTANTS,
  ADMIN_CONFIGURATION_KEYS,
  LOCAL_STORAGE_KEYS,
  PLATFORMS,
} from "../constants/GenericConstants";
import StorageService from "../sevices/storageService";
import _ from "lodash";
import LocalizationService from "../sevices/localizationService";
/**
 * Validating if String is Stringified json
 * @param {String} string
 */
export function validateJSON(string) {
  let validJSON = false;
  if (
    /^[\],:{}\s]*$/.test(
      string
        .replace(/\\["\\\/bfnrtu]/g, "@")
        .replace(
          /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
          "]"
        )
        .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
    )
  ) {
    validJSON = true;
  } else {
    validJSON = false;
  }
  return validJSON;
}

export function parseDateForDatePicker(date) {
  let formatttedDate = null;
  formatttedDate = !!date
    ? moment(date).format(GENERIC_CONSTANTS.DATE_FORMAT_YEAR)
    : "";
  return formatttedDate;
}

export function parseDateTime24Hours(date) {
  let formatttedDate = null;
  formatttedDate = !!date
    ? moment(date).format(GENERIC_CONSTANTS.DATE_TIME_24_FORMAT)
    : "";
  return formatttedDate;
}

export function parseDateForServer(date) {
  let formatttedDate = null;
  formatttedDate = !!date
    ? moment(date).format(GENERIC_CONSTANTS.DATE_FORMAT_SERVER_DASHBOARD)
    : "";
  return formatttedDate;
}

export function parseDateForDatePickerWithTimePart(date){
  let formattedDate = null;
  formattedDate = !!date ? moment(date).format(GENERIC_CONSTANTS.DATE_FORMAT_COMPLETE)
  : "";
  return formattedDate;
}

export function parseDateForServerForDashboard(date) {
  let formatttedDate = null;
  formatttedDate = !!date
    ? moment(date).format(GENERIC_CONSTANTS.DATE_FORMAT_SERVER_DASHBOARD)
    : "";
  return formatttedDate;
}

export function parseDateTimeForServerForDashboard(date) {
  let formatttedDate = null;
  formatttedDate = !!date
    ? moment(date).format(GENERIC_CONSTANTS.DATE_TIME_FORMAT_SERVER)
    : "";
  return formatttedDate;
}

export function parseDate(date) {
  let formatttedDate = null;
  formatttedDate = !!date
    ? moment(date).format(GENERIC_CONSTANTS.DATE_FORMAT)
    : "";
  return formatttedDate;
}

export function parseDateWithYear(date) {
  let formatttedDate = null;
  formatttedDate = !!date
    ? moment(date).format(GENERIC_CONSTANTS.DATE_FORMAT_YEAR)
    : "";
  return formatttedDate;
}

export function parseDateWithoutYear(date) {
  let formatttedDate = null;
  formatttedDate = !!date
    ? moment(date).format(GENERIC_CONSTANTS.DATE_FORMAT_NO_YEAR)
    : "";
  return formatttedDate;
}

export function parseDateTime(date) {
  let formatttedDate = null;
  formatttedDate = !!date
    ? moment(date).format(GENERIC_CONSTANTS.MMM_D_YYYY_LT)
    : "";
  return formatttedDate;
}

export function parseDateTime24H(date) {
  let formatttedDate = null;
  formatttedDate = !!date
    ? moment(date).format(GENERIC_CONSTANTS.DATE_FORMAT_SERVER)
    : "N/A";
  return formatttedDate;
}

export function parseDateTime24H_DD_MM_YYYY(date) {
  let formatttedDate = null;
  formatttedDate = !!date
    ? moment(date).format(GENERIC_CONSTANTS.DATE_FORMAT_DD_MM_YYYY+" - "+GENERIC_CONSTANTS.TIME_FORMATE_24)
    : "N/A";
  return formatttedDate;
}

export function parseDateTimeIn24HourFormat(date) {
  let formatttedDate = null;
  formatttedDate = !!date
    ? moment(date).format(GENERIC_CONSTANTS.DATE_TIME_24_HOUR_FORMAT)
    : "N/A";
  return formatttedDate;
}

export function parseDate_DD_MM_YYYY(date) {
  let formatttedDate = null;
  formatttedDate = !!date
    ? moment(date).format(GENERIC_CONSTANTS.DATE_FORMAT_DD_MM_YYYY)
    : "N/A";
  return formatttedDate;
}

export function parseTime12H(date){
  let formatttedDate = null;
  formatttedDate = !!date
    ? moment(date).format(GENERIC_CONSTANTS.TIME_FORMATE)
    : "";
  return formatttedDate;
}

export function parseTime24H(date){
  let formatttedDate = null;
  formatttedDate = !!date
    ? moment(date).format(GENERIC_CONSTANTS.TIME_FORMATE_24)
    : "";
  return formatttedDate;
}

export function parseDashboardRecentSubmissionTime(inputDate) {
  let date = inputDate || new Date();

  let formatttedDate = null;

  let todayDate = moment(new Date()).format(
    GENERIC_CONSTANTS.DATE_FORMAT_CLIENT
  );
  let submissionDate = moment(date).format(
    GENERIC_CONSTANTS.DATE_FORMAT_CLIENT
  );

  if (todayDate === submissionDate) {
    formatttedDate = moment(date).format(GENERIC_CONSTANTS.TIME_FORMATE);
  } else {
    formatttedDate = moment(date).format(GENERIC_CONSTANTS.MONTH_DAY_FORMAT);
  }

  return formatttedDate;
}

/**
 * Checking if props passed are valid or not
 * @param {EDITOR State Object} editorState
 */
// export const isValidEditorState = (editorState) => {
//     let isValid = false;
//     try {
//         convertFromRaw(editorState);
//         isValid = true;
//     } catch (exp) {
//     }
//     return isValid;
// }
/**
 * show specification field or not  Condition
 */
export function showFormulaField(fieldType) {
  let showField = false;
  fieldType = parseInt(fieldType);
  if (
    fieldType === GENERIC_CONSTANTS.FIELD_TYPES.FORMULA ||
    fieldType === GENERIC_CONSTANTS.FIELD_TYPES.SINGLE_SELECT ||
    fieldType === GENERIC_CONSTANTS.FIELD_TYPES.MULTI_SELECT
  ) {
    showField = true;
  }
  return showField;
}

export function focusOnErrorField(errors) {
  let errorKey = _.keys(errors);
  let firstErrorKey = !!errorKey ? errorKey[0] : "";
  if (firstErrorKey === "formFields") {
    // handling for sublist fields error
    let sublistErrorArray = errors["formFields"];
    let errorFieldsArray =
      !!sublistErrorArray && sublistErrorArray.length > 0
        ? _.keys(sublistErrorArray)
        : [];
    firstErrorKey =
      !!errorFieldsArray && errorFieldsArray.length > 0
        ? "formFields[0]" + errorFieldsArray[0]
        : "";
  }
  let elem = document.querySelectorAll('[name="' + firstErrorKey + '"]');
  if (!!elem && elem.length > 0 && elem[0].name !== "revisionDateTime") {
    elem[0].focus();
  }
}

export function validateEmail(email) {
  let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
}

export function validatePasswordStrength(password) {
  let regex = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
  return regex.test(password);
}

export function showFirstXCharacters(str, number) {
  if (!!str && str.length > number) {
    return str.substring(0, number) + "...";
  }
  return str;
}

// giving user menus on the base of given config
export function getUserMenus(nav) {
  let upatedNav = [];

  nav.forEach((obj, index) => {
    // check for visibility of menu?
    let visibility = getConfigValue(obj.visibility) || "true";
    if (!!visibility && visibility.toLowerCase() === "true") {
      let name = replaceConfigText(obj.name) || "Menu";
      // push data in updated nav list
      upatedNav.push({
        name: name,
        url: obj.url || "",
        subUrl: obj.subUrl || "",
        editUrl: obj.editUrl || "",
        icon: obj.icon || "",
      });
    }
  });

  return {
    items: upatedNav,
  };
}

// get user routes on the base of given config
export function getUserRoutes(routes) {
  let upatedRoutes = [];

  routes.forEach((obj, index) => {
    // check for visibility of menu?
    let visibility = getConfigValue(obj.visibility) || "true";
    if (!!visibility && visibility.toLowerCase() === "true") {
      // push data in updated nav list
      upatedRoutes.push({
        path: obj.path || "",
        exact: obj.exact || "",
        name: obj.name || "",
        component: obj.component || "",
      });
    }
  });

  return upatedRoutes;
}

// get config value by given key
export function getConfigValue(key) {
  if (!key) {
    return "";
  }
  const config = getDetectedPlatformStorage()?.getUserConfiguration();
  for (let i = 0; i < config.length; i++) {
    let obj = config[i];
    if (!!obj.key && obj.key === key) {
      return obj.value;
    }
  }

  return "";
}

// get config value from store by given key
export function getConfigValueFromStore(config, key) {
  if (!key || !config) {
    return "";
  }

  for (let i = 0; i < config.length; i++) {
    let obj = config[i];
    if (!!obj.key && obj.key === key) {
      return obj.value;
    }
  }

  return "";
}

// get localization constants for web
/*export function getLocallizationConstants(){
    return updateLocallizationConstants(window.LOCALIZATION_CONSTANTS);
}*/

// update localization constants on the base of given config
export function updateLocallizationConstants(jsonObject = {}) {
  let parsedJsonObject = {};
  for (const property in jsonObject) {
    if (typeof jsonObject[property] !== "object") {
      parsedJsonObject[property] = replaceConfigText(jsonObject[property]);
    } else {
      parsedJsonObject[property] = updateLocallizationConstants(
        jsonObject[property]
      );
    }
  }
  return parsedJsonObject;
}

// replace program/workorder/task text by given configuration text
export function replaceConfigText(str) {
  var mapObj = {
    SI_PROGRAM:
      getConfigValue(ADMIN_CONFIGURATION_KEYS.PROGRAM.TEXT) ||
      ADMIN_CONFIGURATION_KEYS.PROGRAM.DEFAULT_TEXT,
    PL_PROGRAM:
      getConfigValue(ADMIN_CONFIGURATION_KEYS.PROGRAM.PL_TEXT) ||
      ADMIN_CONFIGURATION_KEYS.PROGRAM.DEFAULT_PL_TEXT,

    SI_SOP_MANUAL:
      getConfigValue(ADMIN_CONFIGURATION_KEYS.SOP_MANUAL.TEXT) ||
      ADMIN_CONFIGURATION_KEYS.SOP_MANUAL.DEFAULT_TEXT,
    PL_SOP_MANUAL:
      getConfigValue(ADMIN_CONFIGURATION_KEYS.SOP_MANUAL.PL_TEXT) ||
      ADMIN_CONFIGURATION_KEYS.SOP_MANUAL.DEFAULT_PL_TEXT,

    SI_WORKORDER:
      getConfigValue(ADMIN_CONFIGURATION_KEYS.WORKORDER.TEXT) ||
      ADMIN_CONFIGURATION_KEYS.WORKORDER.DEFAULT_TEXT,
    PL_WORKORDER:
      getConfigValue(ADMIN_CONFIGURATION_KEYS.WORKORDER.PL_TEXT) ||
      ADMIN_CONFIGURATION_KEYS.WORKORDER.DEFAULT_PL_TEXT,
    ABBR_WORKORDER:
      getConfigValue(ADMIN_CONFIGURATION_KEYS.WORKORDER.ABBR) ||
      ADMIN_CONFIGURATION_KEYS.WORKORDER.DEFAULT_ABBR,

    SI_TASK:
      getConfigValue(ADMIN_CONFIGURATION_KEYS.TASK.TEXT) ||
      ADMIN_CONFIGURATION_KEYS.TASK.DEFAULT_TEXT,
    PL_TASK:
      getConfigValue(ADMIN_CONFIGURATION_KEYS.TASK.PL_TEXT) ||
      ADMIN_CONFIGURATION_KEYS.TASK.DEFAULT_PL_TEXT,

    SI_LIVESTOCK:
      getConfigValue(ADMIN_CONFIGURATION_KEYS.LIVESTOCK.TEXT) ||
      ADMIN_CONFIGURATION_KEYS.LIVESTOCK.DEFAULT_TEXT,
    PL_LIVESTOCK:
      getConfigValue(ADMIN_CONFIGURATION_KEYS.LIVESTOCK.PL_TEXT) ||
      ADMIN_CONFIGURATION_KEYS.LIVESTOCK.DEFAULT_PL_TEXT,
  };

  var re = new RegExp(Object.keys(mapObj).join("|"), "gi");
  return str.replace(re, function (matched) {
    return mapObj[matched];
  });
}

// update columns labels according to configuration
export function configGridColumnsLabels(columns) {
  let parsedData = [];
  for (let i = 0; i < columns.length; i++) {
    let obj = JSON.parse(JSON.stringify(columns[i]));
    obj.label = replaceConfigText(obj.label);
    parsedData.push(obj);
  }
  return parsedData;
}

export function getApplicationLogo() {
  let logo = getConfigValue(ADMIN_CONFIGURATION_KEYS.COMPANY.LOGO);
  return logo || "";
}

// remove html tags from string
export function stripHtml(html) {
  var tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function capitalLetter(str) {
  str = str.split(" ");
  for (var i = 0, x = str.length; i < x; i++) {
    str[i] = str[i][0].toUpperCase() + str[i].substr(1).toLowerCase();
  }
  return str.join(" ");
}

export function capitalizeParagraph(str) {
  if (!!str) {
    return str[0].toUpperCase() + str.substr(1).toLowerCase();
  }
  return str;
}

export const shrinkFontSizeOngreaterLength = (className, classQuery) => {
  const elements = document.getElementsByClassName(className);
  var elementsCSS = document.querySelectorAll(classQuery);

  if (elementsCSS.length && elements.length) {
    for (let i = 0; i < elements.length; i++) {
      const FontSize = window.getComputedStyle(elementsCSS[i]).fontSize;
      let fontSize = parseFloat(FontSize.split("px")[0]);
      let currentLength = elements[i].innerText.length;

      if (currentLength > 9) {
        fontSize = fontSize - 20 + "px";
        elements[i].style.fontSize = fontSize;
      } else if (currentLength > 5) {
        fontSize = fontSize - 15 + "px";
        elements[i].style.fontSize = fontSize;
      }
    }
  }
};

export function fillArray(start, end, step) {
  let number = start;
  let arr = [];
  for (let i = 0; number <= end; i++) {
    arr.push(number);
    number += step;
  }

  return arr;
}

export const getFrequencyDateRange = (
  frequency,
  customStartDate,
  customEndDate
) => {
  let date = "";
  let endDate = moment();
  let endDateYear = endDate.format("YYYY");

  switch (frequency) {
    case 1:
      date = parseDateWithoutYear(new Date());
      break;
    case 2: {
      let startDate = moment().subtract(6, "d");

      date =
        parseDateWithoutYear(new Date(startDate)) +
        " - " +
        parseDateWithoutYear(endDate);

      break;
    }
    case 3: {
      let startDate = moment().subtract(1, "month");
      let startDateYear = startDate.format("YYYY");

      date = showDateRangesWithOrWithoutYear(
        endDateYear,
        startDateYear,
        startDate,
        endDate
      );
      break;
    }
    case 4: {
      let startDate = moment().subtract(3, "month");
      let startDateYear = startDate.format("YYYY");

      date = showDateRangesWithOrWithoutYear(
        endDateYear,
        startDateYear,
        startDate,
        endDate
      );
      break;
    }
    case 5: {
      let startDate = moment().subtract(6, "month");
      let startDateYear = startDate.format("YYYY");

      date = showDateRangesWithOrWithoutYear(
        endDateYear,
        startDateYear,
        startDate,
        endDate
      );

      break;
    }
    case 6: {
      let startDate = moment().subtract(12, "month");
      let startDateYear = startDate.format("YYYY");

      date = showDateRangesWithOrWithoutYear(
        endDateYear,
        startDateYear,
        startDate,
        endDate
      );

      break;
    }
    case 7: {
      endDate = moment(customEndDate);
      let startDate = moment(customStartDate);
      let startDateYear = startDate.format("YYYY");

      date = customEndDate
        ? showDateRangesWithOrWithoutYear(
            endDateYear,
            startDateYear,
            startDate,
            endDate
          )
        : "";
      break;
    }
    default:
      date = "";
  }

  return !!date ? ` (${date})` : "";
};

export const showDateRangesWithOrWithoutYear = (
  endDateYear,
  startDateYear,
  startDate,
  endDate
) => {
  if (endDateYear === startDateYear) {
    return (
      parseDateWithoutYear(new Date(startDate)) +
      " - " +
      parseDateWithoutYear(endDate)
    );
  } else {
    return (
      parseDateWithYear(new Date(startDate)) +
      " - " +
      parseDateWithYear(endDate)
    );
  }
};

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function getCapital1stChar(str) {
  if (!!str) {
    return str.substring(0, 1).toUpperCase() + ".";
  }
  return "";
}

export const getRandomColor = (num) => {
  let colors = [
    "selected-task-item-shade1",
    "selected-task-item",
    "selected-task-item-shade2",
    "selected-task-item-shade3",
    "selected-task-item-shade4",
  ];
  return colors[num % colors.length];
};

export const getRandomColorForUserTags = (num) => {
  let colors = [
    "selected-task-item-tags-shade-1", 
    "selected-task-item-tags-shade-2", 
    "selected-task-item-tags-shade-3", 
    "selected-task-item-tags-shade-4", 
  ];
  return colors[num % colors.length];
}

export function parseErrors(errors) {
  let parsedString = null;
  const TYPE = { OBJECT: 'object', STRING: 'string' };
  switch(typeof errors){
    case TYPE.OBJECT:
      let parsedErrors = Object.keys(errors).map((key, index) => errors[key][0]);
      parsedString = (
        <p>
          {parsedErrors.map((err) => (
            <p>
              {err}
              <br></br>
            </p>
          ))}
        </p>
      );
      break;
    case TYPE.STRING:
      parsedString = (<p>{errors}</p>);
      break;
  }
  return parsedString;
}

export function getUserRole(){
  let userDataString = localStorage.getItem(LOCAL_STORAGE_KEYS.USER);
  let userData = JSON.parse(userDataString) || {};
  const userRole = !!userData && userData.role ? parseInt(userData.role) : null;
  return userRole;
}

export function getUserRoleTypeId(){
  let roleTypeId = GENERIC_CONSTANTS.USER_ROLE_TYPE_ID.MANAGERS;
  switch(getUserRole()){
    case GENERIC_CONSTANTS.USER_ROLES.MANAGER:
      roleTypeId = GENERIC_CONSTANTS.USER_ROLE_TYPE_ID.MANAGERS;
      break;
    case GENERIC_CONSTANTS.USER_ROLES.WORKER:
      roleTypeId = GENERIC_CONSTANTS.USER_ROLE_TYPE_ID.WORKERS;
      break;
  }
  return roleTypeId
}

export function checkWhiteSpaces(str){
  return /^\s+|\s+$/gm.test(str || "")
}

export function truncateLeadingZeroes(val) {
  if (typeof val == "string") {
    val = parseInt(val);
    val = val.toString();
  }
  return val;
}
export function getDetectedPlatformStorage () {
  let Storage = StorageService?.instance;
  if (Storage) {
    return Storage;
  }
  if (process.env.REACT_APP_PLATFORM !== "WEB") {
    if (typeof localStorage === undefined)
      Storage = StorageService.instance ? StorageService.instance : {};
  } else {
    if (Storage?.instance) {
      Storage = StorageService.instance;
    } else {
      StorageService.instance = new StorageService(PLATFORMS.WEB, localStorage);
      Storage = StorageService.instance;
    }
  }
  return Storage;
}
export function getDetectedPlatformLocalization () {
  let LOCALIZATION_CONSTANTS = LocalizationService?.instance?.getLocalization();
  if (LOCALIZATION_CONSTANTS) {
    return LOCALIZATION_CONSTANTS;
  }
  if (typeof window === undefined && process.env.REACT_APP_PLATFORM !== "WEB") {
    LOCALIZATION_CONSTANTS = LocalizationService.instance
      ? LocalizationService.instance.getLocalization()
      : {};
  } else {
      if (LocalizationService.instance) {
        LOCALIZATION_CONSTANTS = LocalizationService.instance.getLocalization();
      } else {
        LocalizationService.instance = new LocalizationService(
          PLATFORMS.WEB,
          window.LOCALIZATION_CONSTANTS
        );
        return LocalizationService.instance.getLocalization();
      }
  }
  return LOCALIZATION_CONSTANTS;
}

/*
  @function:
    checkPermission
  @params:
    permission: permission can be string or array
    matchAnyOne: match if any of the array element exists in the permission array default value false
  @return: (boolean)
    true || false
*/
export function checkPermission(permission, matchAnyOne = false) {
  return true;
  const permissions = (getDetectedPlatformStorage()?.getUserPermissions() || []);
  if (!Array.isArray(permission)) {
    return permissions?.includes(permission);
  } else {
    const found = permissions.filter(function(permission){ return permission.includes(permission) });
    if (matchAnyOne) {
      return found.length > 0;
    } 
    return found.length === permission.length;
  }
}

export function getParameterByName(name, url = '') {
  try {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  } catch (e) {
    console.log(e);
    return '';
  }
}