import moment from "moment";
import { TASK_VIEW_TYPE } from "../../constants/GenericConstants";
import {
  MapApiToDB as MapTaskDetailApiToDB,
  MapDBToState as MapTaskDetailDBToState,
} from "./taskDetail";

const MapApiToDB = (task) => {
  return {
    id: task.id,
    taskDetailId: task.taskDetailId,
    taskId: task.taskId,
    taskName: task.taskName,
    taskNumber: task.taskNumber,
    updatedOn: moment(task.updatedOn).isValid()
      ? moment(task.updatedOn).toDate()
      : null,
    createdBy: task.taskCreatedBy,
    taskCreatedBy: task.taskCreatedBy,
    departmentId: task.departmentId,
    departmentName: task.departmentName,
    departmentNumber: task.departmentNumber,
    workorderId: task.workorderId,
    workorderNumber: task.workorderNumber,
    workorder: task.workorder,
    location: task.location,
    locationId: task.locationId,
    locationName: task.locationName,
    frequency: task.frequency,
    taskType: task.taskType ? task.taskType : TASK_VIEW_TYPE.SCHEDULED,
    formSubmissionMasterTypeId: task.formSubmissionMasterTypeId,
    signature: task.signature,
    violatedFormSubmissionMasterId: task.violatedFormSubmissionMasterId,
    violatedFormSubmissionTitle: task.violatedFormSubmissionTitle,
    violatedFormSubmissionTaskNumber: task.violatedFormSubmissionTaskNumber,
    parentTaskId: task.parentTaskId,
    isActive: task.isActive,
    status: task.status,
    submittedOn: moment(task.submittedOn).isValid()
      ? moment(task.submittedOn).toDate()
      : null,
    submissionStartTime: moment(task.submissionStartTime).isValid()
      ? moment(task.submissionStartTime).toDate()
      : null,
    submissionEndTime: moment(task.submissionEndTime).isValid()
      ? moment(task.submissionEndTime).toDate()
      : null,
    parentFormSubmissionMasterId: task.parentFormSubmissionMasterId,
    isSubtask: false,
    showViolations: task.showViolations,
    formSubmissionMasterVersion: task.formSubmissionMasterVersion,
    correctivePreventiveMeasure: task.correctivePreventiveMeasure,
    comments: task.comments,
    isDeleted: task.isDeleted,
    isEditable: task.isEditable,
    reasonsForEditing: task.reasonsForEditing,
    lastEditedBy: task.lastEditedBy,
    lastEditedOn: task.lastEditedOn,
    occurenceEnumId: task.occurenceEnumId,
    priorityEnumId: task.priorityEnumId,
    isFormFillable: task.isFormFillable,
    submittedBy: task.submittedBy,
    formSubmissions: JSON.stringify(task.formSubmissions || []),
    taskDesignerFormSubmissionUsers: JSON.stringify(
      task.taskDesignerFormSubmissionUsers || []
    ),
    taskDetails:
      task.taskDetails && task.taskDetails.length > 0
        ? task.taskDetails.map((td) => MapTaskDetailApiToDB(td))
        : [],
    notPerformedComment: task.notPerformedComment,
    notPerformedMarkedBy: task.notPerformedMarkedBy,
    notPerformedMarkedOn: task.notPerformedMarkedOn,
    notPerformedReasonEnumId: task.notPerformedReasonEnumId,
  };
};

const MapSubTaskToDB = (task, parentTask = null) => ({
  id: task.id,
  taskDetailId: task.taskDetailId,
  taskId: task.taskId,
  taskName: task.taskName,
  taskNumber: task.taskNumber,
  updatedOn: moment(task.updatedOn).isValid()
    ? moment(task.updatedOn).toDate()
    : null,
  createdBy: task.taskCreatedBy,
  workorderId: task.workorderId,
  workorderNumber: task.workorderNumber,
  workorder: task.workorder,
  location: task.location,
  locationId: task.locationId,
  locationName: task.locationName,
  frequency: task.frequency,
  taskType: task.taskType ? task.taskType : TASK_VIEW_TYPE.SCHEDULED,
  formSubmissionMasterTypeId: task.formSubmissionMasterTypeId,
  signature: task.signature,
  violatedFormSubmissionMasterId: task.violatedFormSubmissionMasterId,
  violatedFormSubmissionTitle: task.violatedFormSubmissionTitle,
  violatedFormSubmissionTaskNumber: task.violatedFormSubmissionTaskNumber,
  parentTaskId: task.parentTaskId,
  isActive: task.isActive,
  status: task.status,
  submittedOn: moment(task.submittedOn).isValid()
    ? moment(task.submittedOn).toDate()
    : null,
  submissionStartTime: moment(task.submissionStartTime).isValid()
    ? moment(task.submissionStartTime).toDate()
    : null,
  submissionEndTime: moment(task.submissionEndTime).isValid()
    ? moment(task.submissionEndTime).toDate()
    : null,
  parentFormSubmissionMasterId: task.parentFormSubmissionMasterId,
  isSubtask: true,
  showViolations: task.showViolations,
  formSubmissionMasterVersion: task.formSubmissionMasterVersion,
  correctivePreventiveMeasure: task.correctivePreventiveMeasure,
  comments: task.comments,
  isDeleted: task.isDeleted,
  occurenceEnumId: task.occurenceEnumId,
  priorityEnumId: task.priorityEnumId,
  isFormFillable: task.isFormFillable,
  submittedBy: task.submittedBy,
  formSubmissions: JSON.stringify(task.formSubmissions || []),
  taskDetails:
    task.taskDetails && task.taskDetails.length > 0
      ? task.taskDetails.map((td) => MapTaskDetailApiToDB(td))
      : [],
});

const MapDBToState = (task) => {
  return {
    id: task.id,
    taskDetailId: task.taskDetailId,
    taskId: task.taskId,
    taskName: task.taskName,
    taskNumber: task.taskNumber,
    updatedOn: moment(task.updatedOn).isValid()
      ? moment(task.updatedOn).toDate()
      : null,
    createdBy: task.createdBy,
    taskCreatedBy: task.taskCreatedBy,
    departmentId: task.departmentId,
    departmentName: task.departmentName,
    departmentNumber: task.departmentNumber,
    workorderId: task.workorderId,
    workorderNumber: task.workorderNumber,
    workorder: task.workorder,
    location: task.location,
    locationId: task.locationId,
    locationName: task.locationName,
    frequency: task.frequency,
    taskType: task.taskType ? task.taskType : TASK_VIEW_TYPE.SCHEDULED,
    formSubmissionMasterTypeId: task.formSubmissionMasterTypeId,
    signature: task.signature,
    violatedFormSubmissionMasterId: task.violatedFormSubmissionMasterId,
    violatedFormSubmissionTitle: task.violatedFormSubmissionTitle,
    violatedFormSubmissionTaskNumber: task.violatedFormSubmissionTaskNumber,
    parentTaskId: task.parentTaskId,
    isActive: task.isActive,
    status: task.status,
    submittedOn: moment(task.submittedOn).isValid()
      ? moment(task.submittedOn).toDate()
      : null,
    submissionStartTime: moment(task.submissionStartTime).isValid()
      ? moment(task.submissionStartTime).toDate()
      : null,
    submissionEndTime: moment(task.submissionEndTime).isValid()
      ? moment(task.submissionEndTime).toDate()
      : null,
    parentFormSubmissionMasterId: task.parentFormSubmissionMasterId,
    isSubtask: task.isSubtask,
    showViolations: task.showViolations,
    formSubmissionMasterVersion: task.formSubmissionMasterVersion,
    correctivePreventiveMeasure: task.correctivePreventiveMeasure,
    comments: task.comments,
    isDeleted: task.isDeleted,
    isEditable: task.isEditable,
    reasonsForEditing: task.reasonsForEditing,
    lastEditedBy: task.lastEditedBy,
    lastEditedOn: task.lastEditedOn,
    occurenceEnumId: task.occurenceEnumId,
    priorityEnumId: task.priorityEnumId,
    isFormFillable: task.isFormFillable,
    submittedBy: task.submittedBy,
    formSubmissions: JSON.parse(task.formSubmissions || "[]"),
    taskDetails:
      task.taskDetails && task.taskDetails.length > 0
        ? task.taskDetails.map((td) => MapTaskDetailDBToState(td))
        : [],
    notPerformedComment: task.notPerformedComment,
    notPerformedMarkedBy: task.notPerformedMarkedBy,
    notPerformedMarkedOn: task.notPerformedMarkedOn,
    notPerformedReasonEnumId: task.notPerformedReasonEnumId,
  };
};

const MapDBToStateForList = (task) => {
  return {
    id: task.id,
    taskDetailId: task.taskDetailId,
    taskId: task.taskId,
    taskName: task.taskName,
    taskNumber: task.taskNumber,
    updatedOn: moment(task.updatedOn).isValid()
      ? moment(task.updatedOn).toDate()
      : null,
    departmentName: task.departmentName,
    departmentNumber: task.departmentNumber,
    workorder: task.workorder,
    location: task.location,
    taskType: task.taskType ? task.taskType : TASK_VIEW_TYPE.SCHEDULED,
    formSubmissionMasterTypeId: task.formSubmissionMasterTypeId,
    status: task.status,
    submissionStartTime: moment(task.submissionStartTime).isValid()
      ? moment(task.submissionStartTime).toDate()
      : null,
    submissionEndTime: moment(task.submissionEndTime).isValid()
      ? moment(task.submissionEndTime).toDate()
      : null,
    parentFormSubmissionMasterId: task.parentFormSubmissionMasterId,
    occurenceEnumId: task.occurenceEnumId,
    priorityEnumId: task.priorityEnumId,
    isFormFillable: task.isFormFillable,
    notPerformedMarkedBy: task.notPerformedMarkedBy,
    notPerformedMarkedOn: task.notPerformedMarkedOn,
  };
};

export { MapApiToDB, MapDBToState, MapDBToStateForList, MapSubTaskToDB };
