import { API_CONSTANTS } from "../../constants/ApiConstants";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { apiClient } from "./ApiClient";
import { SchedulerFilterModel } from "../../models/Scheduler";

export const schedulerService = {
  getFilteredSchedules,
  addSchedulerData,
  editSchedulerData,
  getSchedulerDetails,
  getTaskSchedulers,
};

function getFilteredSchedules(
  paginationData,
  searchTerms,
  filterObject = {},
  sortObject = {}
) {
  const url = API_CONSTANTS.API_ROUTES.GET_FILTERED_SCHEDULER;
  const params = new SchedulerFilterModel(
    filterObject,
    sortObject,
    paginationData,
    { searchTerms }
  );

  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function addSchedulerData(schedulerData) {
  return apiClient()
    .post(API_CONSTANTS.API_ROUTES.ADD_SCHEDULER, JSON.stringify(schedulerData))
    .then(handleResponse)
    .catch(handleError);
}

function editSchedulerData(id, schedulerData) {
  return apiClient()
    .put(
      API_CONSTANTS.API_ROUTES.ADD_SCHEDULER + "/" + id,
      JSON.stringify(schedulerData)
    )
    .then(handleResponse)
    .catch(handleError);
}

function getSchedulerDetails(id) {
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.GET_SCHEDULER_DETAILS + "/" + id)
    .then(handleResponse)
    .catch(handleError);
}

function getTaskSchedulers(id) {
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.GET_TASK_SCHEDULERS + "/" + id)
    .then(handleResponse)
    .catch(handleError);
}
