class MetadataModel { }

MetadataModel.schema = {
  name: "Metadata",
  primaryKey: "meta",
  properties: {
    meta: "string",
    value: "string?",
  },
};

export default MetadataModel;
