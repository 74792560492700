import EcoDocsDB, { WORKORDER_SCHEMA_NAME } from "..";
import { GENERAL_MAPPER_DB_TO_STATE } from "../../models/databaseModels/general";
import {
  MapAPIToDB,
  MapDBToState,
} from "../../models/databaseModels/workorder";

class WorkorderManager {
  async saveWorkorders(workorders) {
    const db = await EcoDocsDB.getConnection();
    db.write(() => {
      workorders.forEach((workorder) => {
        db.create(WORKORDER_SCHEMA_NAME, MapAPIToDB(workorder), true);
      });
      "Workorders saved: ", workorders && workorders.length;
    });
    // EcoDocsDB.close(db);
  }

  async getWorkorders() {
    const db = await EcoDocsDB.getConnection();
    const results = db.objects(WORKORDER_SCHEMA_NAME);
    const workorders = results.map((wo) => Object.assign({}, MapDBToState(wo)));
    // EcoDocsDB.close(db);

    return new GENERAL_MAPPER_DB_TO_STATE(workorders);
  }
}

export default new WorkorderManager();
