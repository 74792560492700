import { GENERIC_CONSTANTS } from "../../constants/GenericConstants";
import MetadataManager from "../../database/dataManagers/MetadataManager";

export async function addMetaEnumToTasks(tasks) {
  const taskElementMetaEnum = await MetadataManager.getMetadata(
    GENERIC_CONSTANTS.TASK_ELEMENT_META_ENUM
  );

  if (taskElementMetaEnum && tasks) {
    tasks.forEach((task) => {
      task &&
        task.taskDetails &&
        fillTask(task.taskDetails, taskElementMetaEnum);
      // task.subTasks && task.subTasks.forEach(subtask => {
      //   subtask.taskDetails && fillTask(subtask.taskDetails, taskElementMetaEnum);
      //   subtask.formSubmissionsByParentMasterId && subtask.formSubmissionsByParentMasterId.forEach(submission => {
      //     submission && submission.task && submission.task.taskDetails && fillTask(submission.task.taskDetails, taskElementMetaEnum);
      //   });
      // });
    });
  }
}

const fillTask = (taskDetails, taskElementMetaEnum) => {
  taskDetails &&
    taskDetails.length > 0 &&
    taskDetails.forEach((td) => {
      td.taskGroups &&
        td.taskGroups.length > 0 &&
        td.taskGroups.forEach((group) => {
          group &&
            group.taskElements &&
            group.taskElements.forEach((field) => {
              field.taskElementMetaEnum = taskElementMetaEnum.find(
                (metaenum) => metaenum.id === field.taskElementMetaEnumId
              );
            });
        });
    });
};
