import { PROGRAM_MANUAL_ACTIONS } from '../../constants/ActionConstants';
// import { getProgramManualFilterCount } from '@ecodocs/common/src/helpers/ProgramManualHelper';

const initialState = { programManuals: [], getProgramManualsLoading: false, showMessage: false };

export function programManual(state = initialState, action) {
    switch (action.type) {
        case PROGRAM_MANUAL_ACTIONS.GET_PROGRAM_MANUALS_REQUEST:
            return {
                ...state,
                getProgramManualsLoading: true,
                getProgramManualsListingError: false,
            };

        case PROGRAM_MANUAL_ACTIONS.GET_PROGRAM_MANUALS_SUCCESS:
            return {
                ...state,
                getProgramManualsLoading: false,
                programManuals: !!action.payload.result ? action.payload.result.elements : [],
                totalElements: !!action.payload.result ? action.payload.result.totalElements : 0,
                getProgramManualsListingError: false,
            };

        case PROGRAM_MANUAL_ACTIONS.GET_PROGRAM_MANUALS_FAILURE:
            return {
                ...state,
                getProgramManualsLoading: false,
                getProgramManualsListingError: true,
                error: action.payload
            };

        case PROGRAM_MANUAL_ACTIONS.EMPTY_PROGRAMS_LIST:
            return {
                ...state,
                loading: false,
                programManuals: [],
                totalElements: 0
            };

        case PROGRAM_MANUAL_ACTIONS.ENABLE_PROGRAMS_SELECT_LIST:
            return {
                ...state,
                programManualEnabled: action.payload
            };

        case PROGRAM_MANUAL_ACTIONS.SUBMIT_PROGRAM_MANUAL_REQUEST:
            return {
                ...state,
                loading: true
            };

        case PROGRAM_MANUAL_ACTIONS.HANDLE_SUBMIT_PROGRAM_MANUAL_SUCCESS:
            return {
                ...state,
                programManualMessageData: action.payload,
                showMessage: true,
                loading: false,
                checkedRows: []

            }
        case PROGRAM_MANUAL_ACTIONS.SUBMIT_PROGRAM_MANUAL_FAILURE:
            return {
                ...state,
                loading: false,
                programManualError: action.payload
            };

        case PROGRAM_MANUAL_ACTIONS.SUBMIT_PROGRAM_MANUAL_SUCCESS:
            let success = false;
            let programManualError = null;
            if (action.payload.result &&
                action.payload.responseCode === 200 &&
                action.payload.message === "Success") {
                success = true;
            } else {
                success = false;
                // getting different response from server that is why handling it
                if (action.payload.result && !!action.payload.result.message) {
                    programManualError = action.payload.result.message;
                }
            }
            return {
                ...state,
                loading: false,
                response: action.payload,
                success: success,
                //  showMessage: !!success ? true : false,
                programManualError: programManualError
            };

        case PROGRAM_MANUAL_ACTIONS.HANDLE_ADD_PROGRAM_MANUAL_MESSAGE:
            return {
                ...state,
                programManualMessageData: action.payload,
                showMessage: action.payload.showMessage
            };

        case PROGRAM_MANUAL_ACTIONS.GET_PROGRAM_MANUAL_BY_ID_REQUEST:
            return {
                ...state,
                loading: true
            };

        case PROGRAM_MANUAL_ACTIONS.GET_PROGRAM_MANUAL_BY_ID_SUCCESS:
            let programMediaIds = action.payload.result && action.payload.result.programManualDetails &&
                                    action.payload.result.programManualDetails.length > 0 && action.payload.result.programManualDetails[0].media ?
                                    action.payload.result.programManualDetails[0].media.map( s => s.id) : [];
            return {
                ...state,
                loading: false,
                mediaIds: programMediaIds,
                programManualDetails: action.payload.result,
                recordNotFound: !action.payload.result
            };

        // Reset program media ids
        case PROGRAM_MANUAL_ACTIONS.RESET_MEDIA_IDS: {
            const {mediaIds, ...newState} = state;
            return {
                ...newState,
                mediaIds: []
            }
        }
        case PROGRAM_MANUAL_ACTIONS.SET_MEDIA_IDS:{
            return {
                ...state,
                mediaIds: action.payload
            }
        }
        case PROGRAM_MANUAL_ACTIONS.UPLOAD_PROGRAM_MANUAL_IMAGE_REQUEST:
            return {
                ...state,
                imageLoading: true,
            };
        case PROGRAM_MANUAL_ACTIONS.UPLOAD_PROGRAM_MANUAL_IMAGE_SUCCESS:
            const mediaIds = action.payload && action.payload.result.map((id, index) => id.id) || [];
            return {
                ...state,
                imageLoading: false,
                mediaIds: [...state.mediaIds || [], ...mediaIds]
            };
        case PROGRAM_MANUAL_ACTIONS.REMOVE_PROGRAM_MANUAL_IMAGE:
            state.mediaIds.splice(action.payload, 1);
            return{
                ...state,
                imageLoading: false,
                mediaIds: [...state.mediaIds || []]
            };
        case PROGRAM_MANUAL_ACTIONS.UPLOAD_PROGRAM_MANUAL_IMAGE_FAILURE:
            return {
                ...state,
                imageLoading: false,
            };
        case PROGRAM_MANUAL_ACTIONS.GET_PROGRAM_MANUAL_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case PROGRAM_MANUAL_ACTIONS.UPSERT_PROGRAM_MANUAL_LIST_FILTERS:
            return {
                ...state,
                // programManualFilter: { filterCount: getProgramManualFilterCount(action.payload), programManualFilterValues: action.payload }
            };

        case PROGRAM_MANUAL_ACTIONS.REMOVE_PROGRAM_MANUAL_LIST_FILTERS:
            return {
                ...state,
                programManualFilter: { filterCount: 0, programManualFilterValues: {} }
            };

        case PROGRAM_MANUAL_ACTIONS.CHANGE_PROGRAM_MANUAL_STATUS_REQUEST:
            return {
                ...state,
                loading: true
            };

        case PROGRAM_MANUAL_ACTIONS.CHANGE_PROGRAM_MANUAL_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                programManualMessageData: action.payload.message,
                showMessage: true
            };

        case PROGRAM_MANUAL_ACTIONS.CHANGE_PROGRAM_MANUAL_STATUS_FAILURE:
            return {
                ...state,
                loading: false
            };

        case PROGRAM_MANUAL_ACTIONS.HANDLE_EDIT_PROGRAM_MESSAGE:
            return {
                ...state,
                programManualMessageData: action.payload.message,
                showMessage: true
            }

        case PROGRAM_MANUAL_ACTIONS.ASSOCIATE_DIVISION_TO_PROGRAMS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case PROGRAM_MANUAL_ACTIONS.HANDLE_ASSOCIATE_DIVISION_TO_PROGRAMS_SUCCESS:
            return {
                ...state,
                programManualMessageData: action.payload,
                showMessage: true,
                loading: false,
                checkedRows: []
            };
        case PROGRAM_MANUAL_ACTIONS.ASSOCIATE_DIVISION_TO_PROGRAMS_SUCCESS:
            success = false;
            if (action.payload.result &&
                action.payload.responseCode === 200 &&
                action.payload.message === "Success") {
                success = true;
            }
            return {
                ...state,
                loading: false,
                response: action.payload,
                success: success,
                checkedRows: []
            };

        case PROGRAM_MANUAL_ACTIONS.ASSOCIATE_DIVISION_TO_PROGRAMS_FAILURE:
            return {
                ...state,
                loading: false,
                programManualError: action.payload
            };

        /**
         * TABLE ACTIONS
         */

        case PROGRAM_MANUAL_ACTIONS.ON_SORT_PROGRAM_MANUAL_COLUMN_REQUEST:
            return {
                ...state,
                getAllProgramManualsOnSortingLoading: true
            };

        case PROGRAM_MANUAL_ACTIONS.ON_SORT_PROGRAM_MANUAL_COLUMN_SUCCESS:
            return {
                ...state,
                getAllProgramManualsOnSortingLoading: false,
                programManuals: !!action.payload.result ? action.payload.result.elements : [],
                totalElements: !!action.payload.result ? action.payload.result.totalElements : 0
            };

        case PROGRAM_MANUAL_ACTIONS.ON_SORT_PROGRAM_MANUAL_COLUMN_FAILURE:
            return {
                ...state,
                getAllProgramManualsOnSortingLoading: false,
                error: action.payload
            };

        //EO | TABLE ACTIONS

        //Reset task detail from redux
        case PROGRAM_MANUAL_ACTIONS.RESET_PROGRAM_DETAIL_STATE:
            return {
                ...state,
                programDetails: null
            }


        default:
            return state;
    }
}