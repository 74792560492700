import { TASK_VIEW_TYPE } from "../../constants/GenericConstants";

class TaskSubmissionModel {}

TaskSubmissionModel.schema = {
  name: "TaskSubmission",
  primaryKey: "id",
  properties: {
    id: "int?",
    taskDetailId: "int?",
    taskId: "int?",
    taskName: "string?",
    taskNumber: "string?",
    updatedOn: "date?",
    createdBy: "string?",
    taskCreatedBy: "string?",
    departmentId: "int?",
    departmentName: "string?",
    departmentNumber: "string?",
    workorderId: "int?",
    workorderNumber: "string?",
    workorder: "Workorder?",
    location: "Location?",
    locationId: "int?",
    locationName: "string?",
    frequency: "int?",
    taskType: { type: "string?", default: TASK_VIEW_TYPE.SCHEDULED },
    formSubmissionMasterTypeId: "int?",
    signature: "string?",
    violatedFormSubmissionMasterId: "int?",
    violatedFormSubmissionTitle: "string?",
    violatedFormSubmissionTaskNumber: "string?",
    parentTaskId: "int?",
    isActive: "bool?",
    status: "int?",
    submittedOn: "date?",
    parentFormSubmissionMasterId: "int?",
    isSubtask: "bool?",
    showViolations: "bool?",
    formSubmissionMasterVersion: "string?",
    correctivePreventiveMeasure: "string?",
    comments: "string?",
    submissionStartTime: "date?",
    submissionEndTime: "date?",
    isDeleted: "bool?",
    isEditable: "bool?",
    reasonsForEditing: "string?",
    lastEditedBy: "string?",
    lastEditedOn: "date?",
    occurenceEnumId: "int?",
    priorityEnumId: "int?",
    isFormFillable: "bool?",
    submittedBy: "string?",
    formSubmissions: "string?",
    taskDesignerFormSubmissionUsers: "string?",
    taskDetails: { type: "TaskDetail[]", default: [] },
    notPerformedComment: "string?",
    notPerformedMarkedBy: "string?",
    notPerformedMarkedOn: "date?",
    notPerformedReasonEnumId: "int?",
  },
};

export default TaskSubmissionModel;
