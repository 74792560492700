import { API_CONSTANTS } from "../../constants/ApiConstants";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { apiClient } from "./ApiClient";

export const notifiationService = {
  getNotifications,
  markNotifications,
};

function getNotifications(pageNumber = 1, pageSize = 20) {
  let url = API_CONSTANTS.API_ROUTES.GET_NOTIFICATIONS;
  let params = {
    pageSize: pageSize,
    pageNumber: pageNumber,
  };
  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function markNotifications() {
  let url = API_CONSTANTS.API_ROUTES.MARK_READ_NOTIFICATIONS;
  return apiClient().put(url).then(handleResponse).catch(handleError);
}
