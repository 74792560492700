import { LOCAL_STORAGE_KEYS } from "../../constants/GenericConstants";

export default class ReactNativeStorageService {
  constructor(storage) {
    this.asyncStorage = storage;
  }

  async setAccessToken(accessToken) {
    await this.asyncStorage.setItem(
      LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
      accessToken || "",
    );
  }

  async setDepartment(department) {
    await this.asyncStorage.setItem(
      LOCAL_STORAGE_KEYS.DEPARTMENT,
      JSON.stringify(department) || "",
    );
  }

  async setUserData(user = {}) {
    if (user) {
      user = JSON.stringify(user);
      await this.asyncStorage.setItem(LOCAL_STORAGE_KEYS.USER, user);
    }
  }

  async setUserPermissions(permissions = []) {
    if (permissions) {
      permissions = JSON.stringify(permissions);
      await this.asyncStorage.setItem(
        LOCAL_STORAGE_KEYS.PERMISSIONS,
        permissions,
      );
    }
  }

  async setTaskOfflinePageSize(taskOfflinePageSize) {
    await this.asyncStorage.setItem(
      LOCAL_STORAGE_KEYS.TASK_OFFLINE_PAGE_SIZE,
      JSON.stringify(taskOfflinePageSize) || "",
    );
  }

  async setUserConfiguration(config = null) {
    if (!!config) {
      config = JSON.stringify(config);
      await this.asyncStorage.setItem(LOCAL_STORAGE_KEYS.CONFIGURATION, config);
    }
  }

  async setLastSync(key, value) {
    if (!!value) {
      value = JSON.stringify(value);
      await this.asyncStorage.setItem(key, value);
    }
  }

  async getAccessToken() {
    try {
      const token = await this.asyncStorage.getItem(
        LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
      );

      return token;
    } catch (error) {
      return false;
    }
  }

  async getUserData() {
    try {
      const data = await this.asyncStorage.getItem(LOCAL_STORAGE_KEYS.USER);
      if (data) {
        return JSON.parse(data);
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  async getDepartment() {
    try {
      const department = await this.asyncStorage.getItem(
        LOCAL_STORAGE_KEYS.DEPARTMENT,
      );
      if (department) {
        return JSON.parse(department);
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  async getTaskOfflinePageSize() {
    try {
      const taskOfflinePageSize = await this.asyncStorage.getItem(
        LOCAL_STORAGE_KEYS.TASK_OFFLINE_PAGE_SIZE,
      );
      if (taskOfflinePageSize) {
        return JSON.parse(taskOfflinePageSize);
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  async getUserPermissions() {
    try {
      const data = await this.asyncStorage.getItem(
        LOCAL_STORAGE_KEYS.PERMISSIONS,
      );
      if (data) {
        return JSON.parse(data);
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  async getUserConfiguration() {
    try {
      const data = await this.asyncStorage.getItem(
        LOCAL_STORAGE_KEYS.CONFIGURATION,
      );
      if (data) {
        return JSON.parse(data);
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  async getLastSync(key) {
    let data = "";
    try {
      data = await this.asyncStorage.getItem(key);
      data = data ? JSON.parse(data) : new Date(0).toISOString();
    } catch (error) {
      data = "";
    }

    return data;
  }

  async deleteLoginData() {
    await this.asyncStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    await this.asyncStorage.removeItem(LOCAL_STORAGE_KEYS.CONFIGURATION);
    await this.asyncStorage.removeItem(LOCAL_STORAGE_KEYS.PERMISSIONS);
    await this.asyncStorage.removeItem(LOCAL_STORAGE_KEYS.DEPARTMENT);
    await this.asyncStorage.removeItem(
      LOCAL_STORAGE_KEYS.TASK_OFFLINE_PAGE_SIZE,
    );
  }

  async resetLastSync(keepAdhoc) {
    const emptyDate = new Date(0).toISOString();
    await this.asyncStorage.removeItem(LOCAL_STORAGE_KEYS.USER);
    !keepAdhoc &&
      (await this.asyncStorage.setItem(
        LOCAL_STORAGE_KEYS.LAST_SYNC_ADHOC_TASK,
        emptyDate,
      ));
    await this.asyncStorage.setItem(
      LOCAL_STORAGE_KEYS.LAST_SYNC_ADHOC_TASK_FOR_CA,
      emptyDate,
    );
    await this.asyncStorage.setItem(
      LOCAL_STORAGE_KEYS.LAST_SYNC_SCHEDULED_TASK,
      emptyDate,
    );
    await this.asyncStorage.setItem(
      LOCAL_STORAGE_KEYS.LAST_SYNC_CORRECTIVE_ACTION,
      emptyDate,
    );
    await this.asyncStorage.setItem(
      LOCAL_STORAGE_KEYS.LAST_SYNC_TASK_HISTORY,
      emptyDate,
    );
    await this.asyncStorage.setItem(
      LOCAL_STORAGE_KEYS.LAST_SYNC_LOCATION,
      emptyDate,
    );
    await this.asyncStorage.setItem(
      LOCAL_STORAGE_KEYS.LAST_SYNC_WORKORDER,
      emptyDate,
    );
    await this.asyncStorage.setItem(
      LOCAL_STORAGE_KEYS.LAST_SYNC_PROGRAM,
      emptyDate,
    );
    await this.asyncStorage.setItem(LOCAL_STORAGE_KEYS.LAST_SYNC_UI, emptyDate);
    await this.asyncStorage.setItem(
      LOCAL_STORAGE_KEYS.LAST_SYNC_AUTO_SAVE_ADHOC_TASK,
      emptyDate,
    );
  }
  async getItem(key, jsonPrse = true) {
    let data = "";
    try {
      data = await this.asyncStorage.getItem(key);
      data = data ? JSON.parse(data) : "";
    } catch (error) {
      data = "";
    }
    return data;
  }
  async setItem(key, value) {
    await this.asyncStorage.setItem(key, value);
  }
  async removeStorageItem(itemKey) {
    await this.asyncStorage.removeItem(itemKey);
  }
}
