import { LOADING_ACTIONS } from '../../constants/ActionConstants';


const initialState = {
    //dashboard loaders
    workorderStatisticsLoading: false,
    recentSubmissionLoading: false,
    pendingWorkorderLoading: false,
    missedWorkorderLoading: false,
    limitRuleViolationLoading: false,
    activeWorkersLoading: false

    //
};

export function loader(state = initialState, action) {

    switch (action.type) {

        case LOADING_ACTIONS.WORKORDER_STATISTICS_LOADING:
            return {
                ...state,
                workorderStatisticsLoading: action.payload
            }
        /////////////

        case LOADING_ACTIONS.RECENT_SUBMISSION_LOADING:
            return {
                ...state,
                recentSubmissionLoading: action.payload
            }
        /////////////

        case LOADING_ACTIONS.PENDING_WORKORDER_LOADING:
            return {
                ...state,
                pendingWorkorderLoading: action.payload
            }

        /////////////

        case LOADING_ACTIONS.MISSED_WORKORDER_LOADING:
            return {
                ...state,
                missedWorkorderLoading: action.payload
            }

        /////////////

        case LOADING_ACTIONS.LIMIT_RULE_VIOLATION_LOADING:
            return {
                ...state,
                limitRuleViolationLoading: action.payload
            }

        /////////////

        case LOADING_ACTIONS.ACTIVE_WORKERS_LOADING:
            return {
                ...state,
                activeWorkersLoading: action.payload
            }

        /////////////

        default:
            return state;

    }

}