import { REVIEW_HISTORY_ACTIONS } from "../../constants/ActionConstants";
import { getReviewHistoryFilterCount } from "../../helpers/ReviewHistoryHelper";

const initialState = {
  reviewHistory: [],
  getReviewHistoryLoading: false,
  showMessage: false,
};

export function reviewHistory(state = initialState, action) {
  switch (action.type) {
    case REVIEW_HISTORY_ACTIONS.GET_REVIEW_HISTORY_REQUEST:
      return {
        ...state,
        getReviewHistoryLoading: true,
        getReviewHistoryListingError: false,
      };

    case REVIEW_HISTORY_ACTIONS.GET_REVIEW_HISTORY_SUCCESS:
      return {
        ...state,
        getReviewHistoryLoading: false,
        reviewHistory: !!action.payload.result ? action.payload.result.elements : [],
        //reviewHistory: [],
        totalElements: !!action.payload.result
          ? action.payload.result.totalElements
          : 0,
        getReviewHistoryListingError: false,
      };

    case REVIEW_HISTORY_ACTIONS.GET_REVIEW_HISTORY_FAILURE:
      return {
        ...state,
        getReviewHistoryLoading: false,
        getReviewHistoryListingError: true,
        error: action.payload,
      };

    // Review Submissions
    case REVIEW_HISTORY_ACTIONS.GET_REVIEW_HISTORY_SUBMISSIONS_REQUEST:
      return {
        ...state,
        getReviewHistorySubmissionsLoading: true,
        getReviewHistorySubmissionsListingError: false,
      };

    case REVIEW_HISTORY_ACTIONS.GET_REVIEW_HISTORY_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        getReviewHistorySubmissionsLoading: false,
        reviewSubmissions: !!action.payload.result ? action.payload.result.elements : [],
        totalSubmissionElements: !!action.payload.result
          ? action.payload.result.totalElements
          : 0,
        getReviewHistorySubmissionsListingError: false,
      };

    case REVIEW_HISTORY_ACTIONS.GET_REVIEW_HISTORY_SUBMISSIONS_FAILURE:
      return {
        ...state,
        getReviewHistorySubmissionsLoading: false,
        getReviewHistorySubmissionsListingError: true,
        error: action.payload,
      };

    case REVIEW_HISTORY_ACTIONS.EMPTY_REVIEW_HISTORY_LIST:
      return {
        ...state,
        loading: false,
        reviewHistory: [],
        totalElements: 0,
      };

    case REVIEW_HISTORY_ACTIONS.ENABLE_REVIEW_HISTORY_SELECT_LIST:
      return {
        ...state,
        reviewHistoryEnabled: action.payload,
      };

    case REVIEW_HISTORY_ACTIONS.SUBMIT_REVIEW_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case REVIEW_HISTORY_ACTIONS.HANDLE_SUBMIT_REVIEW_HISTORY_SUCCESS:
      return {
        ...state,
        reviewHistoryMessageData: action.payload,
        showMessage: true,
        loading: false,
        checkedRows: [],
      };
    case REVIEW_HISTORY_ACTIONS.SUBMIT_REVIEW_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        reviewHistoryError: action.payload,
      };

    case REVIEW_HISTORY_ACTIONS.SUBMIT_REVIEW_HISTORY_SUCCESS:
      let success = false;
      let reviewHistoryError = null;
      if (
        action.payload.result &&
        action.payload.responseCode === 200 &&
        action.payload.message === "Success"
      ) {
        success = true;
      } else {
        success = false;
        // getting different response from server that is why handling it
        if (action.payload.result && !!action.payload.result.message) {
          reviewHistoryError = action.payload.result.message;
        }
      }
      return {
        ...state,
        loading: false,
        response: action.payload,
        success: success,
        //  showMessage: !!success ? true : false,
        reviewHistoryError: reviewHistoryError,
      };

    case REVIEW_HISTORY_ACTIONS.HANDLE_ADD_REVIEW_HISTORY_MESSAGE:
      return {
        ...state,
        reviewHistoryMessageData: action.payload,
        showMessage: action.payload.showMessage,
      };

    case REVIEW_HISTORY_ACTIONS.GET_REVIEW_HISTORY_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case REVIEW_HISTORY_ACTIONS.GET_REVIEW_HISTORY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        reviewHistoryDetails: action.payload.result,
        recordNotFound: !action.payload.result,
      };

    case REVIEW_HISTORY_ACTIONS.GET_REVIEW_HISTORY_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case REVIEW_HISTORY_ACTIONS.UPSERT_REVIEW_HISTORY_LIST_FILTERS:
      return {
        ...state,
        reviewHistoryFilter: {
          filterCount: getReviewHistoryFilterCount(action.payload),
          reviewHistoryFilterValues: action.payload,
        },
      };

    case REVIEW_HISTORY_ACTIONS.REMOVE_REVIEW_HISTORY_LIST_FILTERS:
      return {
        ...state,
        reviewHistoryFilter: { filterCount: 0, reviewHistoryFilterValues: {} },
      };

    case REVIEW_HISTORY_ACTIONS.CHANGE_REVIEW_HISTORY_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case REVIEW_HISTORY_ACTIONS.CHANGE_REVIEW_HISTORY_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        reviewHistoryMessageData: action.payload.message,
        showMessage: true,
      };

    case REVIEW_HISTORY_ACTIONS.CHANGE_REVIEW_HISTORY_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case REVIEW_HISTORY_ACTIONS.HANDLE_EDIT_REVIEW_HISTORY_MESSAGE:
      return {
        ...state,
        reviewHistoryMessageData: action.payload.message,
        showMessage: true,
      };

    case REVIEW_HISTORY_ACTIONS.ASSOCIATE_DIVISION_TO_REVIEW_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REVIEW_HISTORY_ACTIONS.HANDLE_ASSOCIATE_DIVISION_TO_REVIEW_HISTORY_SUCCESS:
      return {
        ...state,
        reviewHistoryMessageData: action.payload,
        showMessage: true,
        loading: false,
        checkedRows: [],
      };
    case REVIEW_HISTORY_ACTIONS.ASSOCIATE_DIVISION_TO_REVIEW_HISTORY_SUCCESS:
      success = false;
      if (
        action.payload.result &&
        action.payload.responseCode === 200 &&
        action.payload.message === "Success"
      ) {
        success = true;
      }
      return {
        ...state,
        loading: false,
        response: action.payload,
        success: success,
        checkedRows: [],
      };

    case REVIEW_HISTORY_ACTIONS.ASSOCIATE_DIVISION_TO_REVIEW_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        reviewHistoryError: action.payload,
      };

    /**
     * TABLE ACTIONS
     */

    case REVIEW_HISTORY_ACTIONS.ON_SORT_REVIEW_HISTORY_COLUMN_REQUEST:
      return {
        ...state,
        getAllReviewHistoryOnSortingLoading: true,
      };

    case REVIEW_HISTORY_ACTIONS.ON_SORT_REVIEW_HISTORY_COLUMN_SUCCESS:
      return {
        ...state,
        getAllReviewHistoryOnSortingLoading: false,
        reviewHistory: !!action.payload.result ? action.payload.result.elements : [],
        totalElements: !!action.payload.result
          ? action.payload.result.totalElements
          : 0,
      };

    case REVIEW_HISTORY_ACTIONS.ON_SORT_REVIEW_HISTORY_COLUMN_FAILURE:
      return {
        ...state,
        getAllReviewHistoryOnSortingLoading: false,
        error: action.payload,
      };

    //EO | TABLE ACTIONS

    //Reset task detail from redux
    case REVIEW_HISTORY_ACTIONS.RESET_REVIEW_HISTORY_DETAIL_STATE:
      return {
        ...state,
        reviewHistoryDetails: null,
      };

    default:
      return state;
  }
}
