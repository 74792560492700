import { PageData, SortData, SearchTerm } from "./General";
import { GENERIC_CONSTANTS } from '../constants/GenericConstants';

export const WorkorderFilterModel = (filterData = {}, sortData = {}, pageData = {}, searchTerm = "", isQA) => {

    sortData.sortColumn = sortData.sortColumn === "program" ? "programTitle" : sortData.sortColumn;

    return ({
        ...new PageData(pageData),
        ...new SortData(sortData),
        ...new SearchTerm(searchTerm),
        programs: filterData.programs || [],
        status: !isQA ? (filterData.status || []) : [1], //1 for published
        reviewStatus: filterData.reviewStatuses || [],
        endDate: filterData.endDate || null,
        state: filterData.state || [],
        startDate: filterData.startDate || null
    })
};

export const WorkorderFilterModelForOffline = (timestamp) => {
    return ({
        ...new PageData({ pageNumber: 1, pageSize: GENERIC_CONSTANTS.TABLE_OPTIONS.MAX_PAGE_SIZE }),
        ...new SortData({ sortDirection: 1, orderByProp: "UpdatedOn" }),
        LatestByDate: timestamp
    })
};
