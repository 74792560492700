const MapApiToDB = (task) => ({
  id: task.id,
  title: task.title,
  instruction: task.instruction,
  submissionCount: task.submissionCount,
  frequency: task.frequency,
  taskPriorityEnumId: task.taskPriorityEnumId,
  priority: task.priority,
  isActive: task.isActive,
  taskActivityStatusEnumId: task.taskActivityStatusEnumId,
  taskDetailCorrectionApprovers: JSON.stringify(
    task.taskDetailCorrectionApprovers || []
  ),
  taskDetailCorrectionFilers: JSON.stringify(
    task.taskDetailCorrectionFilers || []
  ),
  taskReviewReviewers: JSON.stringify(task.taskReviewReviewers || []),
  taskDetailMedias: JSON.stringify(task.taskDetailMedias || []),
  programManualDetail: JSON.stringify(task.programManualDetail || []),
  taskGroups: JSON.stringify(task.taskGroups || []),
});

const MapDBToState = (task) => ({
  id: task.id,
  title: task.title,
  instruction: task.instruction,
  submissionCount: task.submissionCount,
  frequency: task.frequency,
  taskPriorityEnumId: task.taskPriorityEnumId,
  priority: task.priority,
  isActive: task.isActive,
  taskActivityStatusEnumId: task.taskActivityStatusEnumId,
  taskDetailCorrectionApprovers: JSON.parse(
    task.taskDetailCorrectionApprovers || "[]"
  ),
  taskReviewReviewers: JSON.parse(task.taskReviewReviewers || "[]"),
  taskDetailMedias: JSON.parse(task.taskDetailMedias || "[]"),
  programManualDetail: JSON.parse(task.programManualDetail || "[]"),
  taskGroups: JSON.parse(task.taskGroups || "[]"),
});

export { MapApiToDB, MapDBToState };
