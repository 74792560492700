import { WORKORDER_ACTIONS } from '../../constants/ActionConstants';
import { getWorkorderFilterCount } from '@ecodocs/common/src/helpers/WorkorderHelper';

const initialState = { workorders: [], checkedRows: [], showMessage: false };

export function workorder(state = initialState, action) {

    switch (action.type) {

        case WORKORDER_ACTIONS.GET_WORKORDERS_REQUEST:
            return {
                ...state,
                getWorkordersLoading: true,
                getWorkordersListingError:false,
                workorders:[],
                totalElements: 0
            };

        case WORKORDER_ACTIONS.GET_WORKORDERS_SUCCESS:
            return {
                ...state,
                getWorkordersLoading: false,
                getWorkordersListingError:false,
                workorders: !!action.payload.result ? action.payload.result.elements : [],
                totalElements: !!action.payload.result ? action.payload.result.totalElements : 0
            };

        case WORKORDER_ACTIONS.GET_WORKORDERS_FAILURE:
            return {
                ...state,
                getWorkordersLoading: false,
                getWorkordersListingError:true,
                workorders:[],
                totalElements: 0,
                error: action.payload
            };

        case WORKORDER_ACTIONS.EMPTY_WORKORDER_LIST:
            return {
                ...state,
                getWorkordersLoading: false,
                workorders: [],
                totalElements: 0
            };
        
        case WORKORDER_ACTIONS.EMPTY_WORKORDER_SELECT_LIST:
            return {
                ...state,
                emptyWorkordersSelectList: action.payload,
            };
            
        case WORKORDER_ACTIONS.EMPTY_PROGRAM_SELECT_LIST:
            return {
                ...state,
                emptyProgramsSelectList: action.payload,
            };

        case WORKORDER_ACTIONS.UPSERT_WORKORDER_LIST_FILTERS:
            return {
                ...state,
                workorderFilter: { filterCount: getWorkorderFilterCount(action.payload), workorderFilterValues: action.payload }
            };

        case WORKORDER_ACTIONS.REMOVE_WORKORDER_LIST_FILTERS:
            return {
                ...state,
                workorderFilter: { filterCount: 0, workorderFilterValues: {} }
            };

        case WORKORDER_ACTIONS.SUBMIT_WORKORDER_REQUEST:
            return {
                ...state,
                loading: true
            };

        case WORKORDER_ACTIONS.HANDLE_SUBMIT_WORKORDER_SUCCESS:
            return {
                ...state,
                workorderMessageData: action.payload,
                showMessage: true,
                loading: false,
                checkedRows: []

            }

        case WORKORDER_ACTIONS.SUBMIT_WORKORDER_SUCCESS:
            let success = false;
            let workorderError = null;
            if (action.payload.result &&
                action.payload.responseCode === 200 &&
                action.payload.message === "Success") {
                success = true;
            } else {
                success = false;
                // getting different response from server that is why handling it
                if (action.payload.result && !!action.payload.result.message) {
                    workorderError = action.payload.result.message;
                } else {
                    workorderError = action.payload.result;
                }
            }
            return {
                ...state,
                loading: false,
                response: action.payload,
                success: success,
                // showMessage: !!success ? true : false,
                workorderError: workorderError
            };

        case WORKORDER_ACTIONS.SUBMIT_WORKORDER_FAILURE:
            return {
                ...state,
                loading: false,
                workorderError: action.payload
            };

        case WORKORDER_ACTIONS.HANDLE_ADD_WORKORDER_MESSAGE:
            return {
                ...state,
                workorderMessageData: action.payload,
                showMessage: action.payload.showMessage
            };

        case WORKORDER_ACTIONS.GET_WORKORDER_BY_ID_REQUEST:
            return {
                ...state,
                loading: true
            };

        case WORKORDER_ACTIONS.GET_WORKORDER_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                workorderDetails: action.payload.result,
                recordNotFound: !action.payload.result
            };

        case WORKORDER_ACTIONS.GET_WORKORDER_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case WORKORDER_ACTIONS.ENABLE_WORKORDER_SELECT_LIST:
            return {
                ...state,
                workorderEnabled: action.payload
            };
        case WORKORDER_ACTIONS.SAVE_WORKORDER_ID:
            let lstChecked = [...state.checkedRows];
            let index = lstChecked.indexOf(action.payload.workorderId);

            if (action.payload.checked) {
                if (index < 0)
                    lstChecked.push(action.payload.workorderId);
            }
            else {
                let copyList = lstChecked.slice();
                copyList.splice(index, 1);
                lstChecked = copyList;
            }

            return {
                ...state,
                checkedRows: lstChecked
            };
        case WORKORDER_ACTIONS.ASSOCIATE_PROGRAM_TO_WORKORDERS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case WORKORDER_ACTIONS.HANDLE_ASSOCIATE_PROGRAM_TO_WORKORDERS_SUCCESS:
            return {
                ...state,
                workorderMessageData: action.payload,
                checkedRows: [],
                showMessage: true
            };
        case WORKORDER_ACTIONS.ASSOCIATE_PROGRAM_TO_WORKORDERS_SUCCESS:
            success = false;
            workorderError = null;
            if (action.payload.result &&
                action.payload.responseCode === 200 &&
                action.payload.message === "Success") {
                success = true;
            } else {
                success = false;
                // getting different response from server that is why handling it
                if (action.payload.result && !!action.payload.result.message) {
                    workorderError = action.payload.result.message;
                } else {
                    workorderError = action.payload.result;
                }
            }
            return {
                ...state,
                loading: false,
                response: action.payload,
                success: success,
                // showMessage: !!success ? true : false,
                workorderError: workorderError
            };
        case WORKORDER_ACTIONS.ASSOCIATE_PROGRAM_TO_WORKORDERS_FAILURE:
            return {
                ...state,
                loading: false,
                workorderError: action.payload,
                workorderMessageData: action.payload,
                showMessage: true
            };

        case WORKORDER_ACTIONS.CHANGE_WORKORDER_STATUS_REQUEST:
            return {
                ...state,
                loading: true
            };

        case WORKORDER_ACTIONS.CHANGE_WORKORDER_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                workorderMessageData: action.payload.message,
                showMessage: true
            };

        case WORKORDER_ACTIONS.CHANGE_WORKORDER_STATUS_FAILURE:
            return {
                ...state,
                loading: false
            };

        /**
         * TABLE ACTIONS
         */

        case WORKORDER_ACTIONS.ON_SORT_WORKORDER_COLUMN_REQUEST:
            return {
                ...state,
                getAllWorkordersOnSortingLoading: true
            };

        case WORKORDER_ACTIONS.ON_SORT_WORKORDER_COLUMN_SUCCESS:
            return {
                ...state,
                getAllWorkordersOnSortingLoading: false,
                workorders: !!action.payload.result ? action.payload.result.elements : [],
                totalElements: !!action.payload.result ? action.payload.result.totalElements : 0
            };

        case WORKORDER_ACTIONS.ON_SORT_WORKORDER_COLUMN_FAILURE:
            return {
                ...state,
                getAllWorkordersOnSortingLoading: false,
                error: action.payload
            };

        //EO | TABLE ACTIONS
        case WORKORDER_ACTIONS.HANDLE_EDIT_WORKORDER_MESSAGE:
            return {
                ...state,
                workorderMessageData: action.payload,
                showMessage: true
            }

        //Reset task detail from redux
        case WORKORDER_ACTIONS.RESET_WORKORDER_DETAIL_STATE:
            return {
                ...state,
                workorderDetails: null
            }

        default:
            return state;
    }
}