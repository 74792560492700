import {
  WORKORDER_ACTIONS,
  PROGRAM_ACTIONS,
  BREAKCRUMB_ACTIONS,
} from "../../constants/ActionConstants";
import { workorderService, programService } from "../../sevices/api";
import StorageService from "../../sevices/storageService";
import { request, success, failure } from "./index";
import { PageData, SearchTerm } from "@ecodocs/common/src/models/General";
import {
  WorkorderFilterModel,
  WorkorderFilterModelForOffline,
} from "@ecodocs/common/src/models/Workorder";
import {
  GENERIC_CONSTANTS,
  LOCAL_STORAGE_KEYS,
} from "../../constants/GenericConstants";
import { setSyncingWorkorder } from "./AppActions";
import WorkorderManager from "../../database/dataManagers/WorkorderManager";
// localization service
import LocalizationService from "@ecodocs/common/src/sevices/localizationService";

//import LOCALIZATION_CONSTANTS from "../../constants/AppConstants";
import { getDetectedPlatformLocalization } from "../../helpers/GeneralHelper";
const LOCALIZATION_CONSTANTS = getDetectedPlatformLocalization();


export function getWorkOrdersByProgramId(params) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {

    dispatch(request(WORKORDER_ACTIONS.GET_WORKORDERS_REQUEST, params));
    workorderService.getWorkOrdersByProgramId(params).then(
      (data) => {
        dispatch(success(WORKORDER_ACTIONS.GET_WORKORDERS_SUCCESS, data));
        dispatch(request(WORKORDER_ACTIONS.ENABLE_WORKORDER_SELECT_LIST, true));
        resolve(data)
      },
      (error) => {
        dispatch(
          failure(WORKORDER_ACTIONS.GET_WORKORDERS_FAILURE, error.toString())
        );
        reject(error)
      }
    );
    })
  };
}

export function enableDisableWorkordersSelectList(flag) {
  return function (dispatch) {
    dispatch(request(WORKORDER_ACTIONS.ENABLE_WORKORDER_SELECT_LIST, flag));
  };
}

export function emptyWorkordersSelectList(flag) {
  return function (dispatch) {
    dispatch(request(WORKORDER_ACTIONS.EMPTY_WORKORDER_SELECT_LIST, flag));
  };
}

export function emptyProgramsSelectList(flag) {
  return function (dispatch) {
    dispatch(request(WORKORDER_ACTIONS.EMPTY_PROGRAM_SELECT_LIST, flag));
  };
}

export function getWorkOrders() {
  return function (dispatch) {
    dispatch(request(WORKORDER_ACTIONS.GET_WORKORDERS_REQUEST));
    workorderService.getWorkOrdersByProgramId().then(
      (data) => {
        dispatch(success(WORKORDER_ACTIONS.GET_WORKORDERS_SUCCESS, data));
      },
      (error) => {
        dispatch(
          failure(WORKORDER_ACTIONS.GET_WORKORDERS_FAILURE, error.toString())
        );
      }
    );
  };
}

export function getWorkorders(filterModel) {
  return function (dispatch) {
    dispatch(
      request(WORKORDER_ACTIONS.ON_SORT_WORKORDER_COLUMN_REQUEST, filterModel)
    );
    workorderService.getFilterWorkorders(filterModel).then(
      (data) => {
        dispatch(
          success(WORKORDER_ACTIONS.ON_SORT_WORKORDER_COLUMN_SUCCESS, data)
        );
      },
      (error) => {
        dispatch(
          failure(
            WORKORDER_ACTIONS.ON_SORT_WORKORDER_COLUMN_FAILURE,
            error.toString()
          )
        );
      }
    );
  };
}

//TODO: will be removed later and replaced with getWorkorders
export function getFilteredWorkorders(
  paginationData,
  searchTerms,
  filters,
  sortObject,
  isQA
) {
  return function (dispatch) {
    dispatch(
      request(WORKORDER_ACTIONS.GET_WORKORDERS_REQUEST, {
        paginationData,
        searchTerms,
        filters,
        sortObject,
      })
    );
    workorderService
      .getFilteredWorkorders(
        paginationData,
        searchTerms,
        filters,
        sortObject,
        isQA
      )
      .then(
        (data) => {
          dispatch(success(WORKORDER_ACTIONS.GET_WORKORDERS_SUCCESS, data));
        },
        (error) => {
          dispatch(
            failure(WORKORDER_ACTIONS.GET_WORKORDERS_FAILURE, error.toString())
          );
        }
      );
  };
}

export function syncWorkorders() {
  return async function (dispatch, getStore) {
    try {
      const store = getStore();
      let lastRecordTimestamp =
        (await StorageService.instance.getLastSync(
          LOCAL_STORAGE_KEYS.LAST_SYNC_WORKORDER
        )) || new Date(0).toISOString();

      // Only sync data when app is online and sync has not already started
      if (
        store &&
        store.app &&
        !!store.app.isConnected &&
        !store.app.isSyncingWorkorder
      ) {
        dispatch(setSyncingWorkorder(true));

        const response = await workorderService.getFilterWorkorders(
          new WorkorderFilterModelForOffline(lastRecordTimestamp)
        );

        if (
          response &&
          response.result &&
          response.result.elements &&
          !!response.result.elements.length
        ) {
          WorkorderManager.saveWorkorders(response.result.elements);

          await StorageService.instance.setLastSync(
            LOCAL_STORAGE_KEYS.LAST_SYNC_WORKORDER,
            response.result.elements[response.result.elements.length - 1]
              .updatedOn
          );
        }
      }
    } catch (error) {
      console.log("syncWorkorders error", error);
      dispatch(failure(WORKORDER_ACTIONS.GET_WORKORDERS_FAILURE, error));
    } finally {
      dispatch(
        success(
          WORKORDER_ACTIONS.GET_WORKORDERS_SUCCESS,
          await WorkorderManager.getWorkorders()
        )
      );
      dispatch(setSyncingWorkorder(false));
      return true;
    }
  };
}

export function handleUpsertWorkorderMessages(messageObject) {
  return function (dispatch) {
    dispatch(
      request(WORKORDER_ACTIONS.HANDLE_ADD_WORKORDER_MESSAGE, messageObject)
    );
  };
}

export function editWorkorder(tasksData, id) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(
        request(WORKORDER_ACTIONS.SUBMIT_WORKORDER_REQUEST, { tasksData })
      );
      workorderService.editWorkorder(tasksData, id).then(
        (data) => {
          var newTaskId = !!data.result ? data.result.id : null;
          dispatch(
            request(WORKORDER_ACTIONS.HANDLE_EDIT_WORKORDER_MESSAGE, {
              message:
                LOCALIZATION_CONSTANTS.SUCCESS_MESSAGES.WORKORDER_UPDATED_SUCCESFULLY,
            })
          );
          dispatch(success(WORKORDER_ACTIONS.SUBMIT_WORKORDER_SUCCESS, data));

          resolve(data);
        },
        (error) => {
          dispatch(
            failure(
              WORKORDER_ACTIONS.SUBMIT_WORKORDER_FAILURE,
              error.toString()
            )
          );
          reject(error.toString());
        }
      );
    });
  };
}

export function removeWorkorderFilters() {
  return function (dispatch) {
    dispatch(request(WORKORDER_ACTIONS.REMOVE_WORKORDER_LIST_FILTERS));
  };
}

export function upsertWorkorderFilters(filterParams) {
  return function (dispatch) {
    dispatch(
      request(WORKORDER_ACTIONS.UPSERT_WORKORDER_LIST_FILTERS, filterParams)
    );
  };
}

export function emptyWorkordersList(params) {
  return function (dispatch) {
    dispatch(request(WORKORDER_ACTIONS.EMPTY_WORKORDER_LIST, params));
  };
}

export function submitWorkorder(workorderData) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {

    dispatch(
      request(WORKORDER_ACTIONS.SUBMIT_WORKORDER_REQUEST, { workorderData })
    );
    workorderService.submitWorkorderData(workorderData).then(
      (data) => {
        dispatch(success(WORKORDER_ACTIONS.SUBMIT_WORKORDER_SUCCESS, data));
        resolve(data)

      },
      (error) => {
        dispatch(
          failure(WORKORDER_ACTIONS.SUBMIT_WORKORDER_FAILURE, error.toString())
        );
        reject(error)
      }
    );
    })
  };
}

export function getWorkorderDetails(id, breadcrumbArray = []) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(WORKORDER_ACTIONS.GET_WORKORDER_BY_ID_REQUEST, { id }));
      workorderService.getWorkorderDetails(id).then(
        (data) => {
          const { result } = data;
          breadcrumbArray.push({ label: result.workorderNumber || "" });
          dispatch(
            request(BREAKCRUMB_ACTIONS.UPDATE_BREADCRUMB_ARRAY, breadcrumbArray)
          );
          const workorderObject = !!data.result ? data.result.workorder : {};
          const programObject = !!workorderObject
            ? workorderObject.program
            : {};
          const divisionObject = !!programObject ? programObject.division : {};
          const divisionId = !!divisionObject ? divisionObject.id : null;
          const programId = !!programObject ? programObject.id : null;

          //nesting for programs
          dispatch(request(PROGRAM_ACTIONS.GET_PROGRAMS_REQUEST, divisionId));
          programService
            .getProgramsByDivisionId(divisionId)
            .then(
              (programs) => {
                dispatch(
                  success(PROGRAM_ACTIONS.GET_PROGRAMS_SUCCESS, programs)
                );
                //nesting for work orders
                dispatch(
                  request(WORKORDER_ACTIONS.GET_WORKORDERS_REQUEST, programId)
                );
              },
              (error) => {
                dispatch(
                  failure(
                    PROGRAM_ACTIONS.GET_PROGRAMS_FAILURE,
                    error.toString()
                  )
                );
              }
            )
            .catch((error) => error);
          dispatch(
            success(WORKORDER_ACTIONS.GET_WORKORDER_BY_ID_SUCCESS, data)
          );
          resolve(data);
        },
        (error) => {
          dispatch(
            failure(
              WORKORDER_ACTIONS.GET_WORKORDER_BY_ID_FAILURE,
              error.toString()
            )
          );
          reject(error.toString());
        }
      );
    });
  };
}

export function saveWorkorderId(checked, workorderId) {
  return function (dispatch) {
    dispatch(
      request(WORKORDER_ACTIONS.SAVE_WORKORDER_ID, { checked, workorderId })
    );
  };
}

export function associateProgramToWorkorders(programId, checkedRows) {
  return function (dispatch) {
    dispatch(
      request(WORKORDER_ACTIONS.ASSOCIATE_PROGRAM_TO_WORKORDERS_REQUEST)
    );
    workorderService
      .associateProgramToWorkorders({
        workorders: checkedRows,
        programId: programId,
      })
      .then(
        (data) => {
          dispatch(
            request(
              WORKORDER_ACTIONS.HANDLE_ASSOCIATE_PROGRAM_TO_WORKORDERS_SUCCESS,
              {
                message:
                  LOCALIZATION_CONSTANTS.SUCCESS_MESSAGES
                    .PROGRAM_ASSOCIATED_SUCCESFULLY,
              }
            )
          );
          dispatch(
            success(
              WORKORDER_ACTIONS.ASSOCIATE_PROGRAM_TO_WORKORDERS_SUCCESS,
              data
            )
          );

          //////reset table with new data page data
          const paginationData = new PageData();
          const searchTerms = new SearchTerm().SearchTerm;
          const filters = new WorkorderFilterModel();
          const sortObject = {
            sortColumn: GENERIC_CONSTANTS.DEFAULT_SORT_KEY,
            sortOrder: GENERIC_CONSTANTS.SORT_ORDER.DESC,
          };
          dispatch(
            request(WORKORDER_ACTIONS.GET_WORKORDERS_REQUEST, {
              paginationData,
              searchTerms,
              filters,
              sortObject,
            })
          );
          workorderService
            .getFilteredWorkorders(
              paginationData,
              searchTerms,
              filters,
              sortObject
            )
            .then(
              (data) => {
                dispatch(
                  success(WORKORDER_ACTIONS.GET_WORKORDERS_SUCCESS, data)
                );
              },
              (error) => {
                dispatch(
                  failure(
                    WORKORDER_ACTIONS.GET_WORKORDERS_FAILURE,
                    error.toString()
                  )
                );
              }
            );
        },
        (error) => {
          dispatch(
            failure(WORKORDER_ACTIONS.ASSOCIATE_PROGRAM_TO_WORKORDERS_FAILURE, {
              showMessage: true,
              message: error.data.message.toString(),
              class: "success",
            })
          );
        }
      );
  };
}

export function changeWorkorderState(id, value) {
  return function (dispatch) {
    dispatch(
      request(WORKORDER_ACTIONS.CHANGE_WORKORDER_STATUS_REQUEST, { id, value })
    );
    workorderService.changeState(id, value).then(
      (data) => {
        dispatch(
          success(WORKORDER_ACTIONS.CHANGE_WORKORDER_STATUS_SUCCESS, {
            message:
              LOCALIZATION_CONSTANTS.SUCCESS_MESSAGES.WORKORDER_STATUS_UPDATED,
          })
        );
      },
      (error) => {
        dispatch(
          failure(
            WORKORDER_ACTIONS.CHANGE_WORKORDER_STATUS_FAILURE,
            error.toString()
          )
        );
      }
    );
  };
}

export function resetWorkorderDetails() {
  return function (dispatch) {
    dispatch(request(WORKORDER_ACTIONS.RESET_WORKORDER_DETAIL_STATE));
  };
}
