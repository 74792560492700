import { GENERIC_CONSTANTS } from "../constants/GenericConstants";

export const PageData = (props = {}) => ({
  pageSize: props.pageSize || GENERIC_CONSTANTS.TABLE_OPTIONS.PAGE_SIZE,
  pageNumber: props.pageNumber || GENERIC_CONSTANTS.TABLE_OPTIONS.PAGE_NUMBER,
});

export const SortData = (props = {}) => ({
  sortDirection: props.sortOrder || null,
  orderByProp: props.sortColumn || null,
});

export const SearchTerm = (props = {}) => ({
  SearchText: props.searchTerms || GENERIC_CONSTANTS.TABLE_OPTIONS.SEARCH_TERM,
});
