class ProgramModel {}

ProgramModel.schema = {
  name: "Program",
  primaryKey: "id",
  properties: {
    id: "int",
    number: "string?",
    name: "string?",
    updatedOn: "string?",
    status: "int?",
  },
};

export default ProgramModel;
