import { PageData, SortData, SearchTerm } from "./General";
import { GENERIC_CONSTANTS } from "../constants/GenericConstants";

export const ProgramFilterModel = (
  filterData = {},
  sortData = {},
  pageData = {},
  searchTerm = ""
) => {
  sortData.sortColumn =
    sortData.sortColumn === "division" ? "divisionTitle" : sortData.sortColumn;

  return {
    ...new PageData(pageData),
    ...new SortData(sortData),
    ...new SearchTerm(searchTerm),
    divisions: filterData.divisions || [],
    state: filterData.state || [],
    status: filterData.status || [],
    startDate: filterData.startDate || "",
    endDate: filterData.endDate || "",
  };
};

export const ProgramFilterModelForOffline = (timestamp) => {
  return {
    // ...new PageData({ pageNumber: 1, pageSize: GENERIC_CONSTANTS.TABLE_OPTIONS.MAX_PAGE_SIZE }),
    // ...new SortData({ sortDirection: 1, orderByProp: "UpdatedOn" }),
    name: "Program",
    status: [GENERIC_CONSTANTS.PROGRAM.STATUS.PUBLISH],
    latestByDate: timestamp,
  };
};
