import React, { Component } from 'react';

class Loading extends Component {
  render() {
    const { show } = this.props;
    return (
      <>
        {!!show &&
          <div className="page-loader">
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            {/* <div className='loader-img'></div> */}
            {/* <small>Loading...</small> */}
          </div>
        }
      </>
    );
  }
}

export default Loading;
