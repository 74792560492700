import { logout } from "../../store/actions/UserActions";

export default class ReactNativeNavigationService {
  static globalStore;
  constructor(navigator) {
    this.navigator = navigator;
  }

  redirectToLogin() {
    logout()(ReactNativeNavigationService.globalStore.dispatch); // dispatching logout action
  }
}
