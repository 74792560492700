import {
  APP_ACTIONS,
  USER_ACTIONS,
  ENUM_ACTIONS,
} from "../../constants/ActionConstants";

export const initialState = {
  isConnected: true,
  isSyncInProgress: false,
  isSyncingMetadata: false,
  isSyncingScheduledTasks: false,
  isSyncingAutosaveAdhocTask: false,
  isSyncingCorrectiveActions: false,
  isSyncingAdhocTasks: false,
  isSyncingAdhocTasksForCA: false,
  isSyncingTaskHistory: false,
  isSyncingLocation: false,
  isSyncingWorkorder: false,
  isSyncingProgram: false,
  syncProgressAdhocTasks: {},
  syncProgressScheduledTasks: {},
  syncProgressAutoSavedAdhocTasks: {},
  syncProgressCorrectiveActions: {},
  syncProgressTaskHistory: {},
  isPushInProgress: false,
  lastSyncUI: new Date(0).toISOString(),
};

export function app(state = initialState, action) {
  switch (action.type) {
    case APP_ACTIONS.SET_APP_CONNECTIVITY_STATUS_SUCCESS:
      return {
        ...state,
        isConnected: action.payload.isConnected,
      };
    case APP_ACTIONS.SET_IS_SYNC_IN_PROGRESS:
      return {
        ...state,
        isSyncInProgress: !!action.payload,
      };
    case APP_ACTIONS.SET_SYNCING_AUTO_SAVED_ADHOC_TASKS:
      return {
        ...state,
        isSyncingAutosaveAdhocTask: !!action.payload,
      };
    case APP_ACTIONS.SET_SYNCING_SCHEDULED_TASKS:
      return {
        ...state,
        isSyncingScheduledTasks: !!action.payload,
      };
    case APP_ACTIONS.SET_SYNCING_CORRECTIVE_ACTIONS:
      return {
        ...state,
        isSyncingCorrectiveActions: !!action.payload,
      };
    case APP_ACTIONS.SET_SYNCING_ADHOC_TASKS:
      return {
        ...state,
        isSyncingAdhocTasks: !!action.payload,
      };
    case APP_ACTIONS.SET_SYNCING_ADHOC_TASKS_FOR_CA:
      return {
        ...state,
        isSyncingAdhocTasksForCA: !!action.payload,
      };
    case APP_ACTIONS.SET_SYNCING_TASK_HISTORY:
      return {
        ...state,
        isSyncingTaskHistory: !!action.payload,
      };
    case APP_ACTIONS.SET_SYNCING_TASK_LOCATION:
      return {
        ...state,
        isSyncingLocation: !!action.payload,
      };
    case APP_ACTIONS.SET_SYNCING_TASK_WORKORDER:
      return {
        ...state,
        isSyncingWorkorder: !!action.payload,
      };
    case APP_ACTIONS.SET_SYNCING_TASK_PROGRAM:
      return {
        ...state,
        isSyncingProgram: !!action.payload,
      };
    case APP_ACTIONS.SET_PUSH_IN_PROGRESS:
      return {
        ...state,
        isPushInProgress: !!action.payload,
      };
    case APP_ACTIONS.SET_SYNC_PROGRESS_ADHOC_TASKS:
      return {
        ...state,
        syncProgressAdhocTasks: action.payload,
      };
    case APP_ACTIONS.SET_SYNC_PROGRESS_AUTO_SAVED_ADHOC_TASKS:
      return {
        ...state,
        syncProgressAutoSavedAdhocTasks: action.payload,
      };
    case APP_ACTIONS.SET_SYNC_PROGRESS_SCHEDULED_TASKS:
      return {
        ...state,
        syncProgressScheduledTasks: action.payload,
      };
    case APP_ACTIONS.SET_SYNC_PROGRESS_CORRECTIVE_ACTIONS:
      return {
        ...state,
        syncProgressCorrectiveActions: action.payload,
      };
    case APP_ACTIONS.SET_SYNC_PROGRESS_TASK_HISTORY:
      return {
        ...state,
        syncProgressTaskHistory: action.payload,
      };
    case ENUM_ACTIONS.GET_METADATA_VALUES_REQUEST:
      return {
        ...state,
        isSyncingMetadata: true,
      };
    case ENUM_ACTIONS.GET_METADATA_VALUES_SUCCESS:
    case ENUM_ACTIONS.GET_METADATA_VALUES_FAILURE:
      return {
        ...state,
        isSyncingMetadata: false,
      };
    case APP_ACTIONS.SET_LAST_SYNC_UI:
      return {
        ...state,
        lastSyncUI: action.payload,
      };

    default:
      return state;
  }
}
