export const API_CONSTANTS = {
  CONTENT_TYPE: "Content-Type",
  CONTENT_TYPES: {
    APPLICATION_JSON: "application/json",
  },
  HTTP_REQUEST_METHOD: {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
  },
  API_ROUTES: {
    Edit_WORKORDER: "/workorder/addRevision",
    GET_ALL_PROGRAMS: "/program",
    GET_ALL_DIVISIONS: "/division",
    GET_ALL_WORKORDERS: "/workorder/",
    GET_ALL_TASK_GROUPS: "/taskgroup",
    REGISTER_USER: "/users/register",
    AUTHENTICATE_USER: "/user/authenticate",
    REFRESH_TOKEN: "/User/refreshToken",
    GET_TASK_BY_ID: "/task/",
    GET_TASK_DESIGNER_BY_ID: "/TaskDesigner/",
    GET_ADHOC_TASK_DESIGNER_BY_ID: "/TaskDesigner/GetAdhocById/",
    GET_DOCUMENT_BY_ID: "/Metadata/GetDocument/",
    GET_TASK_BY_SUBMISSION_ID: "/task/GetTaskBySubmission/",
    GET_ADHOC_TASK_DETAIL: "/Task/",
    GET_TASK_DESIGNER_BY_SUBMISSION_ID:
      "/FormSubmissionTaskDesigner/GetTaskSubmissionById/",
    GET_TASK_DETAILS: "/task/GetTaskDetail/",
    GET_USER_BY_ID: "/user/",
    GET_WORKORDER_BY_ID: "/workorder/",
    TASK_EXISTS: "/task/doesExist",
    TASK_DESIGNER_EXISTS: "/taskDesigner/doesExist",
    GET_FILTERED_LIVESTOCK_EAR_TAG_ID: "/Livestock/filter",
    GET_LIVESTOCK_DETAILS: "/Livestock?id=",
    WORKORDER_EXISTS: "/workorder/doesExist",
    ADD_TASK: "/task",
    ADD_TASK_DESIGNER: "/taskDesigner",
    ADD_USER: "/user",
    EDIT_USER: "/user/",
    ADD_WORKORDER: "/workorder",
    EDIT_TASK: "/task/addRevision",
    EDIT_TASK_DESIGNER: "/taskDesigner/addRevision",
    DUPLICATE_TASK_DESIGNER: "/taskDesigner/AddDuplicateTask",
    GET_ALL_TASKS: "/task",
    GET_FILTERED_USER: "/user/filter",
    GET_USERS_BY_LOCATION_WORKORDER: "/user/filter",
    GET_ASSIGNED_TASK: "/Task/GetAssignedTasks",
    GET_ASSIGNED_TASK_WITH_DETAILS: "/TaskDesigner/GetTasksWithDetails",
    GET_ASSIGNED_TASK_DESIGNER: "/taskDesigner/GetAssignedTasks",
    GET_ASSIGNED_TASK_BY_ID: "/Task/",
    DELETE_ADHOC_ENTRY: "/FormSubmission?id=",
    DELETE_TASK_DESIGNER_ADHOC_ENTRY: "/FormSubmissionTaskDesigner?id=",
    GET_ALL_ENUMS: "/enums/getAll",
    GET_FILTERED_TASK_DESIGNER: "/TaskDesigner/filter",
    GET_FILTERED_TASK: "/task/filter",
    GET_SUBMISSION_BY_WO_ID: "/FormSubmission/GetSubmissionsByWordOrderId",
    GET_SUBMISSION_BY_PARENT_SUBMISSION_ID:
      "/FormSubmission/GetSubmissionsByParentSubmissionId",
    GET_TASK_DESIGNER_SUBMISSION_BY_PARENT_SUBMISSION_ID:
      "/FormSubmissionTaskDesigner/GetSubmissionsByParentSubmissionId",
    GET_FILTERED_WORKORDER: "/workorder/filter",
    GET_FILTERED_FAQ: "/FAQ/filter",
    PROGRAM_EXISTS: "/program/doesExist/",
    APPROVE_SUBMISSION: "/FormSubmissionTaskDesigner/ApproveRejectSubmission",
    ADD_PROGRAM: "/program",
    EDIT_PROGRAM: "/program/addRevision",
    GET_PROGRAM_BY_LOCATION: "/program/GetProgramsByLocation/",
    CHANGE_PROGRAM_STATE: "/program/toggleActive",
    CHANGE_LOCATION_STATE: "/Location/toggleActive",
    CHANGE_USER_STATE: "/User/toggleActive",
    CHANGE_TASK_STATE: "/task/toggleActive",
    CHANGE_TASK_DESIGNER_STATE: "/taskDesigner/toggleActive",
    CHANGE_WORKORDER_STATE: "/workorder/toggleActive",
    GET_PROGRAM_BY_ID: "/program/",
    GET_FILTERED_PROGRAM: "/Program/filter",
    ASSOCIATE_WORKORDER: "/task/associateWorkorder",
    ASSOCIATE_DIVISION: "/program/associateDivision",
    ASSOCIATE_PROGRAM: "/workorder/associateProgram",
    LOGOUT_USER: "/user/logout",
    FORGOT_PASSWORD: "/user/forgotPassword",
    RESET_PASSWORD: "/user/resetPassword",
    ASSOCIATE_DIVISION_TO_USER: "/User/AssociateDivision",
    USER_EXIST: "/user/doesExist/",
    ACTIVEDIRECTORY_USERS: "/user/ActiveDirectoryUsers",
    GET_ALL_TASK_NAMES: "/Metadata/GetMetaDataValues/TaskDetail",
    MEDIA_URL: "/media",
    TASK_WITH_LOCATIONS: "/TaskScheduling/GetLocationByTasks",
    SIGNUP_USER: "/Tenant",

    GET_TASKS_BY_PROGRAM: "/TaskDesigner/GetTasksByProgram/",

    //ProgramManual
    GET_FILTERED_PROGRAM_MANUAL: "/ProgramManual/filter",
    ADD_PROGRAM_MANUAL: "/programManual",
    GET_PROGRAM_MANUAL_BY_ID: "/ProgramManual/details",
    CHANGE_PROGRAM_MANUAL_STATE: "/programManual/toggleActive",
    PROGRAM_MANUAL_EXISTS: "/ProgramManual/doesExist/",

    // Review
    GET_FILTERED_REVIEW: "/Review/filter",
    GET_REVIEW_DETAILS: "/Review/GetReviewDetails",
    FILTER_REVIEW_SUBMISSION: "/Review/filterReviewSubmission",
    CREATE_REVIEW_HISTORY: "/Review/createReviewHistory",
    GET_REVIEW_BY_ID: "/Review/",
    GET_TASK_DETAILS_WITH_SUBMISSION: "/FormSubmissionTaskDesigner/GetTaskDetailWithSubmissions",
    
    //Schedulers
    ADD_SCHEDULER: "/TaskScheduling",
    GET_FILTERED_SCHEDULER: "/TaskScheduling/filter",
    GET_SCHEDULER_DETAILS: "/TaskScheduling",
    GET_TASK_SCHEDULERS: "/TaskScheduling/GetTaskSchedulings",

    //Dashboard
    GET_WORKORDER_STATISTICS: "/Workorder/completionPercentage",
    GET_RECENT_SUBMISSIONS: "/Task/recentSubmissions",
    GET_MISSED_WORKORDERS: "/Workorder/missed",
    GET_PENDING_WORKORDERS: "/Workorder/pending",
    GET_ACTIVE_WORKERS: "/user/WorkersActiveToday",
    GET_RULE_VIOLATIONS: "/Task/ViolatedToday",
    GET_TASK_COMPLETION_STATISTICS: "/DashBoard/GetTaskCompletionStatistics",
    GET_TASK_VIOLATION_STATISTICS: "/DashBoard/GetTaskViolationsStatistics",
    GET_ACTIVE_WORKERS_STATS: "/DashBoard/GetActiveWorkersStatistics",
    GET_MISSED_TASKS: "/DashBoard/GetMissedTasksStatistics",
    GET_TASK_SUMMARY_STATS: "/DashBoard/GetTaskSummaryStatistics",
    GET_TASK_COMPLETION_GRAPH: "/DashBoard/GetTaskCompletionStatisticsGraph",
    MARK_TASK_AS_NOT_PERFORMED: "/FormSubmissionTaskDesigner/MarkAsNotPerformed",
    
    //Qa tech
    QA_FORM_SUBMISSION: "/FormSubmission",
    QA_TASK_DESIGNER_FORM_SUBMISSION: "/FormSubmissionTaskDesigner",
    GET_QA_FORM_SUBMISSION_BY_ID: "/FormSubmission/filter",
    GET_SUBMISSION_BY_TASK_ID: "/FormSubmission/GetSubmissionsByTaskId",

    //Rules
    CREATE_RULE_FOR_FIELD: "/rule",
    UPDATE_RULE_FOR_FIELD: "/rule",
    DELETE_RULE_FOR_FIELD: "/rule",
    GET_RULES_BY_TASK_ID: "/rule/task/",

    // Reports
    GET_USER_SUBMISSION_REPORT: "/ReportsTaskDesginer/GetUserSubmissions",
    GET_TASK_SUBMISSION_REPORT: "/ReportsTaskDesginer/GetTasksSubmissions",
    GET_DAILY_SUBMISSION_REPORT: "/ReportsTaskDesginer/GetDailySubmissions",
    GET_TASK_NAMES: "/Metadata/GetMetaDataValues",
    GET_TASK_SUBMISSION_REPORT_BY_ID: "/ReportsTaskDesginer/GetTaskSubmissionsById",
    WORKER_PERFORMANCE_REPORT: "/ReportsTaskDesginer/GetUserSubmissions",
    WORKER_PERFORMANCE_REPORT_OLD: "/ReportsTaskDesginer/GetUserSubmissionsDetails",

    // task submissions history
    GET_TASK_SUBMISIONS: "/FormSubmission/GetAllTaskSubmissions",
    GET_TASK_SUBMISIONS_WITH_DETAILS:
      "/FormSubmissionTaskDesigner/GetTaskSubmissionsWithDetails",

    //Location
    GET_FILTERED_LOCATIONS: "/location/filter",
    GET_LOCATION: "/Location/filter",
    ADD_LOCATION: "/Location",
    GET_LOCATION_TYPES: "/metadata/getmetadatavalues/LocationType",
    GET_FILTERED_LOCATION: "/location/filter",
    GET_LOCATION_BY_ID: "/location/",
    EDIT_LOCATION: "/location",
    DOES_EXIST: "/Metadata/DoesNameExistsInTable",
    GET_ALL_LOCATION: "/location/GetAllLocationNames",

    // livestock
    SAVE_LIVESTOCK: "/Livestock",
    GET_FILTERED_LIVESTOCK: "/Livestock/filter",
    LIVESTOCK_EXISTS:
      "/Metadata/DoesNameExistsInTable?tableName=Livestock&value=",

    // task submissions history
    GET_TASK_SUBMISIONS: "/FormSubmission/GetAllTaskSubmissions",
    GET_TASK_DESIGNER_SUBMISIONS:
      "/FormSubmissionTaskDesigner/GetAllTaskSubmissions",

    // Notifications
    GET_NOTIFICATIONS: "/notification/filter",
    MARK_READ_NOTIFICATIONS: "/notification/markRead",
    TOGGLE_NOTIFICATIONS: "/notification/ToggleNotificationEnable",
    
    SKIP_TASKS: "/FormSubmissionTaskDesigner/MarkAsNotPerformed",

    // Task Elements By Task Group Id
    GET_ELEMENTS_BY_TASK_GROUP_ID: "/TaskDesigner/GetTaskGroupById",

    // get meta data values
    GET_METADATA_VALUES_ENUMS: "/Metadata/GetMetaDataValues",

    // assets
    GET_FORM_BY_CATEGORY: "/Asset/GetAssetCategoryById?id=",
  },
  API_QUERY_STRING_PARAMS: {
    PAGE_SIZE: "pageSize",
    PAGE_NUMBER: "pageNumber",
  },
};
