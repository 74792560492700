import EcoDocsDB, {
  ADHOC_TASK_SCHEMA_NAME,
  ADHOC_WORKORDER_SCHEMA_NAME,
  WORKORDER_SCHEMA_NAME,
} from "..";
import { TASK_TYPE_ENUM_ID } from "../../constants/GenericConstants";
import { addMetaEnumToTasks } from "../../helpers/Database";

// Database formatting Model
import {
  MapApiToDB,
  MapDBToState,
  MapDBToStateForList,
} from "../../models/databaseModels/adhocTask";

class AdhocTaskManager {
  async saveTasks(tasks) {
    const db = await EcoDocsDB.getConnection();
    await addMetaEnumToTasks(tasks);
    db.write(() => {
      tasks.forEach((task) => {
        const mappedTask = MapApiToDB(task);
        db.create(ADHOC_TASK_SCHEMA_NAME, mappedTask, true);
      });
      console.log("Adhoc tasks saved: ", tasks && tasks.length);
    });
  }

  async getTasks(filters = {}) {
    const { search, department } = filters;
    const args = [];

    const db = await EcoDocsDB.getConnection();
    const query = [
      "isSubtask = false",
      "parentTaskId = null",
      "isDeleted = false",
      "isActive = true",
      `taskTypeEnumId = ${TASK_TYPE_ENUM_ID.TEMPLATE}`, // taskTypeEnumId: Template = 1, CorrectiveAction = 2
      "taskDetails.isActive = true",
      "taskDetails.taskActivityStatusEnumId = 1", // taskActivityStatusEnumId: Published = 1
      "workorderId != null",
    ];

    if (department && department.length > 0) {
      query.push(
        `(${department
          .map((fq, index) => "departmentId = $" + (args.length + index))
          .join(" OR ")})`,
      );
      args.push(...department);
    }

    const woResults = db.objects(WORKORDER_SCHEMA_NAME);
    const workorders = woResults.map((wo) => wo.id);

    if (workorders && workorders.length > 0) {
      query.push(
        `(${workorders
          .map((fq, index) => "workorderId = $" + (args.length + index))
          .join(" OR ")})`,
      );
      args.push(...workorders);
    }

    let results = db
      .objects(ADHOC_TASK_SCHEMA_NAME)
      .filtered(`${query.join(" AND ")}`, ...args);
    results = results.sorted("updatedOn", true).toJSON();

    results = results.map((result) => MapDBToStateForList(result));

    if (search) {
      const textData = search.toUpperCase();
      results = results.filter((task) => {
        const taskName = task.taskName ? task.taskName.toUpperCase() : "";
        const taskNumber = task.taskNumber ? task.taskNumber.toUpperCase() : "";
        return taskName.includes(textData) || taskNumber.includes(textData);
      });
    }
    return results;
  }

  async getTaskById(id) {
    const db = await EcoDocsDB.getConnection();
    const query = [
      `id = ${id}`,
      "isSubtask = false",
      "parentTaskId = null",
      "isDeleted = false",
      "isActive = true",
    ];

    let [task] = db
      .objects(ADHOC_TASK_SCHEMA_NAME)
      .filtered(query.join(" AND "))
      .toJSON();

    // taskActivityStatusEnumId: Published = 1
    task.taskDetails = task.taskDetails.filter(
      (td) => td.isActive && td.taskActivityStatusEnumId == 1,
    );

    return MapDBToState(task);
  }
}

export default new AdhocTaskManager();
