import moment from "moment";
import { request, success, failure } from "./index";
import {
  syncScheduledTasks,
  syncTaskHistory,
  getDashboardData,
  getScheduledTasks,
} from "./TaskSubmissionActions";
import { uploadImagesMobile } from "./TaskActions";
import { uploadImages } from "../../helpers/TaskSubmissionHelper";
import {
  syncAdhocTasks,
  getAdhocTasksMobile,
  deleteTaskDesignerAdhocEntry,
} from "./AdhocTasksActions";
import { syncLocations } from "./LocationActions";
import { syncWorkorders } from "./WorkorderActions";
import { syncPrograms } from "./ProgramActions";
import { onQAFormSubmissionMobile } from "./UserActions";
import {
  setIsSyncInProgress,
  setLastSyncUI,
  setPushInProgress,
} from "./AppActions";
import {
  getTaskCompletionStatistics,
  getTaskViolationsStatistics,
} from "./DashboardActions";
import { syncMetadata } from "./EnumActions";
import { APP_ACTIONS } from "../../constants/ActionConstants";
import StorageService from "../../sevices/storageService";
import {
  FormSubmissionStatusEnums,
  GENERIC_CONSTANTS,
  LOCAL_STORAGE_KEYS,
  SUBMISSION_TYPE,
} from "../../constants/GenericConstants";
import SubmissionManager from "../../database/dataManagers/SubmissionManager";
import EcoDocsDB, { ADHOC_TASK_SCHEMA_NAME, TASK_SUBMISSION_SCHEMA_NAME } from "../../database/index";
import { parseDateForServerForDashboard } from "../../helpers/GeneralHelper";

/**
 * Synchronizer job
 *
 * If app is online
 * - Push
 * - Pull
 * - Update last sync timestamp
 */
export const syncData = () => {
  return async (dispatch, getStore) => {
    try {
      const store = getStore();

      if (store && store.app && store.app.isConnected) {
        dispatch(syncDataRequest());
        await push(dispatch, getStore);
        await pull(dispatch, getStore);
        await purge();
        dispatch(syncDataSuccess());
        getDashboardData()(dispatch, getStore);
        getScheduledTasks()(dispatch, getStore);
        getAdhocTasksMobile()(dispatch, getStore);
        dispatch(setLastSyncUI(new Date().toISOString()));
        await StorageService.instance.setLastSync(
          LOCAL_STORAGE_KEYS.LAST_SYNC_UI,
          new Date().toISOString(),
        );
      }
    } catch (e) {
      console.log("DATA SYNC ERROR", e);
      dispatch(syncDataFailure());
    }
  };
};

/**
 * Push all pending submissions
 */
export async function push(dispatch, getStore) {
  if (!getStore().app.isPushInProgress) {
    dispatch(setIsSyncInProgress(true));
    dispatch(setPushInProgress(true));
    const db = await EcoDocsDB.getConnection();
    const uploadImagesAction = (images) => uploadImagesMobile(images)(dispatch);
    const submissions = await SubmissionManager.getSubmissions();
    for (submission of submissions) {
      console.log("pushing", submission);
      try {
        if (submission.submissionType == SUBMISSION_TYPE.SUBMIT) {
          const payload = JSON.parse(submission.payload);
          await uploadImages(payload, uploadImagesAction);
          await onQAFormSubmissionMobile(payload)(dispatch);
          // const responseId =
          //   (response &&
          //     response.data &&
          //     response.data.result &&
          //     response.data.result[0] &&
          //     response.data.result[0].parentFormSubmission &&
          //     response.data.result[0].parentFormSubmission.id) ||
          //   null;
          // const version =
          //   (response &&
          //     response.data &&
          //     response.data.result &&
          //     response.data.result[0] &&
          //     response.data.result[0].parentFormSubmission &&
          //     response.data.result[0].parentFormSubmission.version) ||
          //   null;

          // if (!!responseId && !!submission.parentId) {
          //   db.write(() => {
          //     try {
          //       const tasks = db
          //         .objects(SUBMISSION_SCHEMA_NAME)
          //         .filtered(`parentId == ${Number(submission.parentId)}`);
          //       for (task of tasks) {
          //         const payload = JSON.parse(task.payload);
          //         if (payload.isSubtask) {
          //           payload.parentFormSumbissionMasterId = responseId;
          //         } else {
          //           payload.formSubmissionMasterId = responseId;
          //         }
          //         payload.version = version;
          //         task.parentId = responseId;
          //         task.payload = JSON.stringify(payload);
          //       }
          //     } catch (error) {
          //       console.log(error);
          //     }
          //   });
          // }
        } else if (submission.submissionType == SUBMISSION_TYPE.SUBMITTED) {
        } else {
          await deleteTaskDesignerAdhocEntry(submission.id)(dispatch);
        }

        db.write(() => {
          if (submission.submissionType == SUBMISSION_TYPE.SUBMIT) {
            const task = db
              .objects(TASK_SUBMISSION_SCHEMA_NAME)
              .filtered(`id == ${Number(submission.parentId)}`)[0];
            if (task && task.status != FormSubmissionStatusEnums.PENDING)
              task.status = FormSubmissionStatusEnums.SUBMITTED;
            submission.submissionType = SUBMISSION_TYPE.SUBMITTED;
          }
        });

        // - Database change needs to be done inside transaction
        // - Delete submission and update/delete corresponding task needs to be done
        //   within the same transaction block
        // db.write(() => {
        //   if (submission.submissionType == SUBMISSION_TYPE.SUBMIT) {
        //     const task = db
        //       .objects(TASK_SUBMISSION_SCHEMA_NAME)
        //       .filtered(`id == ${Number(submission.parentId)}`)[0];
        //     task && db.delete(task);
        //     // const parentTask = db
        //     //   .objects(TASK_SUBMISSION_SCHEMA_NAME)
        //     //   .filtered(
        //     //     `submissionMasterId == ${Number(submission.parentId)}`
        //     //   )[0];
        //     // parentTask && db.delete(parentTask);
        //     db.delete(submission);
        //     // syncTaskHistory()(dispatch, getStore);
        //   }
        // });
      } catch (error) {
        console.log("push fail", error);
      }
    }
    // EcoDocsDB.close(db);
    dispatch(setPushInProgress(false));
    dispatch(setIsSyncInProgress(true));
  }
}

/**
 * Pull all data
 */
export async function pull(dispatch, getStore) {
  dispatch(setIsSyncInProgress(true));

  // metadata is required first, then all other sync services should continue
  await syncMetadata(GENERIC_CONSTANTS.TASK_ELEMENT_META_ENUM)(
    dispatch,
    getStore,
  );
  const endDate = parseDateForServerForDashboard(new Date());
  const startDate = parseDateForServerForDashboard(
    moment().subtract(6, "days").toDate(),
  );
  //sync stats as well on pull
  const promises = [
    getTaskCompletionStatistics(startDate, endDate)(dispatch, getStore),
    getTaskViolationsStatistics(startDate, endDate)(dispatch, getStore),
    syncLocations()(dispatch, getStore),
    syncWorkorders()(dispatch, getStore),
    syncPrograms()(dispatch, getStore),
    getStore().user.permissions.includes(
      GENERIC_CONSTANTS.PERMISSIONS.TASK_SCHEDULES,
    ) && syncScheduledTasks()(dispatch, getStore),
    syncAdhocTasks()(dispatch, getStore),
    syncTaskHistory()(dispatch, getStore),
  ];

  // TODO: Need to implement retry mechanism for the promise which failed

  for (const promise of promises) {
    try {
      await promise;
    } catch (e) {
      // This is deliberately left empty. We need to run all promises even if
      // one of them fails.
      console.log("pull fail", e);
    }
  }

  dispatch(setIsSyncInProgress(false));
}

export async function purge() {
  const db = await EcoDocsDB.getConnection();

  db.write(() => {
    // Delete task history whose submission is more than 5 day old
    const taskHistory = db
      .objects(TASK_SUBMISSION_SCHEMA_NAME)
      .filtered(
        `submittedOn < $0`,
        moment().subtract(5, "day").endOf("day").toDate(),
      );
    db.delete(taskHistory);

    // Delete tasks(not corrective actions) whose due date has passed
    const scheduledTasks = db
      .objects(TASK_SUBMISSION_SCHEMA_NAME)
      .filtered(
        `submissionEndTime <= $0 AND status = $1`,
        moment().subtract(1, "day").toDate(),
        FormSubmissionStatusEnums.PENDING,
      );
    db.delete(scheduledTasks);

    // Delete tasks which are deleted from server
    const deletedTasks = db
      .objects(TASK_SUBMISSION_SCHEMA_NAME)
      .filtered("isDeleted = true");
    db.delete(deletedTasks);

    // Delete adhoc tasks which are deleted or inactive from server
    const deletedOrInActiveAdhocTasks = db
      .objects(ADHOC_TASK_SCHEMA_NAME)
      .filtered("isDeleted = true OR isActive = false");
    db.delete(deletedOrInActiveAdhocTasks);
  });
  // EcoDocsDB.close(db);
}

export function syncDataRequest() {
  return request(APP_ACTIONS.SYNC_DATA_REQUEST);
}

export function syncDataSuccess(payload) {
  return success(APP_ACTIONS.SYNC_DATA_SUCCESS, payload);
}

export function syncDataFailure(payload) {
  return failure(APP_ACTIONS.SYNC_DATA_FAILURE, payload);
}
