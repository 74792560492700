import EcoDocsDB, { LOCATION_SCHEMA_NAME } from "..";
import { GENERAL_MAPPER_DB_TO_STATE } from "../../models/databaseModels/general";

class LocationManager {
  async saveLocations(locations) {
    const db = await EcoDocsDB.getConnection();
    db.write(() => {
      locations.forEach((location) => {
        db.create(LOCATION_SCHEMA_NAME, location, true);
      });
      console.log("Locations saved: ", locations && locations.length);
    });
    // EcoDocsDB.close(db);
  }

  async getLocations() {
    const db = await EcoDocsDB.getConnection();
    const results = db.objects(LOCATION_SCHEMA_NAME);
    const locations = Array.from(results);
    // EcoDocsDB.close(db);

    return new GENERAL_MAPPER_DB_TO_STATE(locations);
  }
}

export default new LocationManager();
