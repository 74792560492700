import EcoDocsDB, {
  TASK_SUBMISSION_SCHEMA_NAME,
  USER_SCHEMA_NAME,
  SUBMISSION_SCHEMA_NAME,
  LOCATION_SCHEMA_NAME,
  WORKORDER_SCHEMA_NAME,
  PROGRAM_SCHEMA_NAME,
} from "..";

class GeneralManager {
  async clearDB() {
    try {
      const db = await EcoDocsDB.getConnection();
      db && db.deleteAll && db.write(db.deleteAll);
    } catch (e) {
      console.log("clear db fail", e);
    }
  }

  async clearDBAndKeepAdhoc() {
    try {
      const db = await EcoDocsDB.getConnection();

      db &&
        db.write(() => {
          let taskSubmissions = db.objects(TASK_SUBMISSION_SCHEMA_NAME);
          let submissions = db.objects(SUBMISSION_SCHEMA_NAME);
          let user = db.objects(USER_SCHEMA_NAME);
          let workorder = db.objects(WORKORDER_SCHEMA_NAME);
          let program = db.objects(PROGRAM_SCHEMA_NAME);

          let tablesToDelete = [
            taskSubmissions,
            submissions,
            user,
            workorder,
            program,
          ];
          tablesToDelete.forEach((element) => {
            db.delete(element);
          });
        });
    } catch (e) {
      console.log("clearDBAndKeepAdhoc fail", e);
    }
  }
}

export default new GeneralManager();
