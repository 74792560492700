import EcoDocsDB, {
  SUBMISSION_SCHEMA_NAME,
  ADHOC_TASK_SCHEMA_NAME,
  TASK_SUBMISSION_SCHEMA_NAME,
} from "..";
import { addMetaEnumToTasks } from "../../helpers/Database";
import moment from "moment";
// Database formatting Model
import {
  MapApiToDB,
  MapDBToState,
} from "../../models/databaseModels/taskSubmission";
import {
  FormSubmissionStatusEnums,
  FORM_SUBMISSION_MASTER_TYPE,
  SUBMISSION_TYPE,
} from "../../constants/GenericConstants";

class TaskHistoryManager {
  async saveTasks(tasks) {
    const db = await EcoDocsDB.getConnection();
    await addMetaEnumToTasks(tasks);
    db.write(() => {
      tasks.forEach((task) => {
        const mappedTask = MapApiToDB(task);

        const submission =
          Number(mappedTask.formSubmissionMasterTypeId) ===
          FORM_SUBMISSION_MASTER_TYPE.ADHOC
            ? db
                .objects(SUBMISSION_SCHEMA_NAME)
                .filtered(`id == ${Number(mappedTask.taskId)}`)[0]
            : db
                .objects(SUBMISSION_SCHEMA_NAME)
                .filtered(`parentId == ${Number(mappedTask.id)}`)[0];

        if (
          submission &&
          submission.submissionType == SUBMISSION_TYPE.SUBMITTED
        ) {
          const task = db
            .objects(TASK_SUBMISSION_SCHEMA_NAME)
            .filtered(`id == ${Number(submission.parentId)}`)[0];
          task && db.delete(task);
          console.log("deleting", submission);
          db.delete(submission);
        }

        db.create(TASK_SUBMISSION_SCHEMA_NAME, mappedTask, true);
      });
      console.log("Task history saved records: ", tasks && tasks.length);
    });
  }

  async getTasks() {
    const db = await EcoDocsDB.getConnection();
    const query = `(status = $0 OR status = $1 OR status = $2 OR status = $3 OR status = $4) AND parentTaskId = null AND updatedOn >= $5 AND isSubtask = false SORT(updatedOn DESC)`;
    const taskHistory = db.objects(TASK_SUBMISSION_SCHEMA_NAME);
    const tasks = taskHistory
      .filtered(
        query,
        FormSubmissionStatusEnums.ACCEPTED,
        FormSubmissionStatusEnums.PENDING_APPROVAL,
        FormSubmissionStatusEnums.REJECTED,
        FormSubmissionStatusEnums.COMPLETED,
        FormSubmissionStatusEnums.NOT_PERFORMED,
        moment().subtract(6, "day").startOf("day").toDate()
      )
      .toJSON();

    const pendingSyncTasks = await this.getPendingSyncTasks();
    return [...pendingSyncTasks, ...tasks.map((task) => MapDBToState(task))];
  }

  async getPendingSyncTasks() {
    const db = await EcoDocsDB.getConnection();
    const query = `status = ${FormSubmissionStatusEnums.PENDING_SYNC} AND parentTaskId = null AND isSubtask = false SORT(submittedOn DESC)`;
    const taskHistory = db.objects(TASK_SUBMISSION_SCHEMA_NAME);
    const tasks = taskHistory.filtered(query);
    const mappedTasksState = Array.from(tasks).map((task) =>
      MapDBToState(task)
    );

    return mappedTasksState;
  }

  async getTaskById(id) {
    const db = await EcoDocsDB.getConnection();
    const [taskSubmission] = db
      .objects(TASK_SUBMISSION_SCHEMA_NAME)
      .filtered(`id = ${id}`)
      .toJSON();

    const [task] = db
      .objects(ADHOC_TASK_SCHEMA_NAME)
      .filtered(`id = ${taskSubmission.taskId}`)
      .toJSON();

    taskSubmission.taskDetails = task.taskDetails.filter(
      (td) => td.id === taskSubmission.taskDetailId
    );

    return MapDBToState(taskSubmission);
  }

  deleteTask() {}
}

export default new TaskHistoryManager();
