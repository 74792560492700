import { API_CONSTANTS } from "../../constants/ApiConstants";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { apiClient } from "./ApiClient";
import { ProgramFilterModel } from "../../models/Program";
import { GENERIC_CONSTANTS } from "../../constants/GenericConstants";

export const programService = {
  getProgramsByDivisionId,
  validateProgramNo,
  getProgramDetails,
  submitProgramData,
  editProgram,
  getFilteredPrograms,
  changeState,
  associateDivisionToPrograms,
  getFilterPrograms,
  getPrograms,
  getProgramsForOffline,
  getProgramsByLocationId,
};

function getPrograms() {
  let url = API_CONSTANTS.API_ROUTES.GET_ALL_PROGRAMS;
  return apiClient().get(url).then(handleResponse).catch(handleError);
}

function getProgramsForOffline(model) {
  return apiClient()
    .post(`${API_CONSTANTS.API_ROUTES.GET_METADATA_VALUES_ENUMS}`, model)
    .then(handleResponse)
    .catch(handleError);
}

function getProgramsByDivisionId(divisionId) {
  let url = API_CONSTANTS.API_ROUTES.GET_FILTERED_PROGRAM;
  let params = {};
  params.divisions = !!divisionId ? [divisionId] : [];
  params.pageNumber = 0; // pageNumber 0 -> returns all programs assocaited with division
  params.state = [true];
  params.status = [1]; // fetch active status

  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function getProgramsByLocationId(locationId) {
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.GET_PROGRAM_BY_LOCATION + locationId)
    .then(handleResponse)
    .catch(handleError);
}

function validateProgramNo(programNo) {
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.PROGRAM_EXISTS + programNo)
    .then(handleResponse)
    .catch(handleError);
}

function associateDivisionToPrograms(data) {
  return apiClient()
    .put(API_CONSTANTS.API_ROUTES.ASSOCIATE_DIVISION, JSON.stringify(data))
    .then(handleResponse)
    .catch(handleError);
}

function getProgramDetails(id) {
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.GET_PROGRAM_BY_ID + id)
    .then(handleResponse)
    .catch(handleError);
}

function submitProgramData(programsData) {
  return apiClient()
    .post(API_CONSTANTS.API_ROUTES.ADD_PROGRAM, JSON.stringify(programsData))
    .then(handleResponse)
    .catch(handleError);
}

function editProgram(workordersData, id) {
  return apiClient()
    .put(
      API_CONSTANTS.API_ROUTES.EDIT_PROGRAM + "/" + id,
      JSON.stringify(workordersData)
    )
    .then(handleResponse)
    .catch(handleError);
}

function getFilteredPrograms(
  paginationData,
  searchTerms,
  filterObject = {},
  sortObject = {}
) {
  const url = API_CONSTANTS.API_ROUTES.GET_FILTERED_PROGRAM;
  const params = new ProgramFilterModel(
    filterObject,
    sortObject,
    paginationData,
    { searchTerms }
  );

  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function changeState(id, isActive) {
  return apiClient()
    .put(API_CONSTANTS.API_ROUTES.CHANGE_PROGRAM_STATE + "/" + id, {
      isActive: isActive,
    })
    .then(handleResponse)
    .catch(handleError);
}

function getFilterPrograms(params) {
  const url = API_CONSTANTS.API_ROUTES.GET_FILTERED_PROGRAM;
  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}
