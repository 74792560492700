import {
  TASK_ACTIONS,
  TASK_SUBMISSION_ACTIONS,
  ENUM_ACTIONS,
} from "../../constants/ActionConstants";
import { adhocTasksService, taskService } from "../../sevices/api";
import { request, success, failure } from "./index";
import {
  setSyncingAdhocTasks,
  setSyncingAdhocTasksForCA,
  setSyncProgressAdhocTasks,
} from "./AppActions";
import {
  LOCAL_STORAGE_KEYS,
  TASK_TYPE_ENUM_ID,
  FORM_SUBMISSION_MASTER_TYPE
} from "../../constants/GenericConstants";

// mobile offline support - data manager
import ScheduledTaskManager from "../../database/dataManagers/ScheduledTaskManager";

// storage service
import StorageService from "../../sevices/storageService";

// localization service
import LocalizationService from "@ecodocs/common/src/sevices/localizationService";
import AdhocTaskManager from "../../database/dataManagers/AdhocTaskManager";
import SubmissionManager from "../../database/dataManagers/SubmissionManager";

//import LOCALIZATION_CONSTANTS from "../../constants/AppConstants";
import { getDetectedPlatformLocalization } from "../../helpers/GeneralHelper";
const LOCALIZATION_CONSTANTS = getDetectedPlatformLocalization();


export function getAdhocTasks(
  paginationData,
  searchTerms,
  filters,
  sortObject,
  workorderId,
  taskId,
  parentTaskOnly,
  todaySubTaskParams
) {
  return function (dispatch) {
    dispatch(
      request(TASK_ACTIONS.GET_ALL_TASKS_REQUEST, {
        paginationData,
        searchTerms,
        filters,
        sortObject,
      })
    );
    adhocTasksService
      .getAdhocTasks(
        paginationData,
        searchTerms,
        filters,
        sortObject,
        workorderId,
        taskId,
        parentTaskOnly,
        todaySubTaskParams
      )
      .then(
        (data) => {
          dispatch(success(TASK_ACTIONS.GET_ALL_TASKS_SUCCESS, data));
        },
        (error) => {
          dispatch(
            failure(TASK_ACTIONS.GET_ALL_TASKS_FAILURE, error.toString())
          );
        }
      );
  };
}

export function getAdhocTasksMobile(filters) {
  return async (dispatch) => {
    dispatch(
      success(
        TASK_ACTIONS.GET_ALL_TASKS_OFFLINE_SUCCESS,
        await AdhocTaskManager.getTasks(filters)
      )
    );
  };
}

export function syncAdhocTasks() {
  return async (dispatch, getStore) => {
    const store = getStore();

    // If app is offline OR sync has already started, return
    if (
      store &&
      store.app &&
      (!store.app.isConnected || store.app.isSyncingAdhocTasks)
    ) {
      return;
    }

    try {
      const pageSize = store.user.taskOfflinePageSize || 0;
      let lastRecordTimestamp =
        (await StorageService.instance.getLastSync(
          LOCAL_STORAGE_KEYS.LAST_SYNC_ADHOC_TASK
        )) || new Date(0).toISOString();

      dispatch(setSyncingAdhocTasks(true));
      for (let pageNumber = 1; ; pageNumber++) {
        let responseSize = 0;
        let totalElements = 0;
        const response = await adhocTasksService.getAdhocTasksForOffline(
          { pageNumber, pageSize },
          lastRecordTimestamp,
          TASK_TYPE_ENUM_ID.TEMPLATE
        );

        // If there is data, save it in database and update store and last sync date time
        if (
          response &&
          response.result &&
          response.result.elements &&
          !!response.result.elements.length
        ) {
          responseSize = response.result.elements.length;
          totalElements = response.result.totalElements;
          
          // Save images locally
          for (const task of response.result.elements) {
            const { taskDetails = [] } = task;
            for (td of taskDetails) {
              if (td && td.taskDetailMedias && !!td.taskDetailMedias.length) {
                for (tdMedia of td.taskDetailMedias) {
                  if (
                    tdMedia &&
                    tdMedia.mediaId &&
                    tdMedia.media &&
                    tdMedia.media.relativePath
                  ) {
                    try {
                      let url = tdMedia.media.relativePath;
                      url = url.split("\\").join("/");
                      const image = await taskService.downloadImagesMobile(
                        url,
                        task.id
                      );
                      tdMedia.media.relativePath = image.path();
                    } catch (error) {
                      console.log(
                        "Error downloading file in task detail",
                        error
                      );
                      // This is deliberately left empty so that if one of the
                      // image download fails due to any reason, rest of them
                      // can continue download
                    }
                  }
                }
              }
            }
          }

          await AdhocTaskManager.saveTasks(response.result.elements);
          await StorageService.instance.setLastSync(
            LOCAL_STORAGE_KEYS.LAST_SYNC_ADHOC_TASK,
            response.result.elements[response.result.elements.length - 1]
              .updatedOn
          );
          dispatch(
            setSyncProgressAdhocTasks({
              totalTasks: response.result.totalElements,
              syncedTasks:
                pageSize * (pageNumber - 1) + response.result.elements.length,
            })
          );
        }

        // If response records are less than page size, this means all the data has been synced
        if (responseSize == 0 || responseSize < pageSize) {
          dispatch(setSyncProgressAdhocTasks({}));
          break;
        }
      }
    } catch (error) {
      console.log("syncAdhocTasks error", error);
      dispatch(success(TASK_ACTIONS.GET_ALL_TASKS_OFFLINE_FAILURE, error));
    } finally {
      dispatch(setSyncingAdhocTasks(false));
      return true;
    }
  };
}

export function getAdhocTaskDesigner(
  paginationData,
  searchTerms,
  filters,
  sortObject,
  workorderId,
  taskId,
  parentTaskOnly,
  todaySubTaskParams
  ) {
    filters.formSubmissionMasterTypes =[FORM_SUBMISSION_MASTER_TYPE.ADHOC];
    return function (dispatch) {
      dispatch(
        request(TASK_ACTIONS.GET_ALL_TASKS_REQUEST, {              
            paginationData,
            searchTerms,
            filters,
            sortObject,
          })
      );
      adhocTasksService.getAdhocTaskDesigner(
        paginationData,
        searchTerms,
        filters,
        sortObject,
        workorderId,
        taskId,
        parentTaskOnly,
        todaySubTaskParams
      )
      .then(
        (data) => {
          dispatch(success(TASK_ACTIONS.GET_ALL_TASKS_SUCCESS, data));
        },
        (error) => {
          dispatch(
            failure(TASK_ACTIONS.GET_ALL_TASKS_FAILURE, error.toString())
          );
        }
      );
  };
}

export function getAdhocTasksDetails(id, breadcrumbArray = []) {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.GET_TASK_BY_ID_REQUEST, { id }));
    adhocTasksService.getAdhocTasksDetails(id).then(
      (data) => {
        dispatch(success(TASK_ACTIONS.GET_TASK_BY_ID_SUCCESS, data));
      },
      (error) => {
        dispatch(
          failure(TASK_ACTIONS.GET_TASK_BY_ID_FAILURE, error.toString())
        );
      }
    );
  };
}

export function getAdhocTaskDesignerDetails(id) {
  return function (dispatch) {
    dispatch(request(TASK_ACTIONS.GET_TASK_BY_ID_REQUEST, { id }));
    adhocTasksService.getAdhocTaskDesignerDetails(id).then(
      (data) => {
        dispatch(success(TASK_ACTIONS.GET_TASK_BY_ID_SUCCESS, data));
      },
      (error) => {
        dispatch(
          failure(TASK_ACTIONS.GET_TASK_BY_ID_FAILURE, error.toString())
        );
      }
    );
  };
}

export function getAdhocTaskDesignerDetailsById(id) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(TASK_ACTIONS.GET_TASK_BY_ID_REQUEST, { id }));
      adhocTasksService.getAdhocTaskDesignerDetailsById(id).then(
        (data) => {
          dispatch(success(TASK_ACTIONS.GET_TASK_BY_ID_SUCCESS, data));
          resolve(data);
        },
        (error) => {
          dispatch(
            failure(TASK_ACTIONS.GET_TASK_BY_ID_FAILURE, error.toString())
          );
          reject(error);
        }
      );
    });
  };
}

export function getDocumentById(id) {
  if (id == 1) {
    const meta = "TaskElementMetaEnum";
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        dispatch(request(ENUM_ACTIONS.GET_METADATA_VALUES_REQUEST));
        adhocTasksService.getDocumentById(id).then(
          (data) => {
            dispatch(
              success(ENUM_ACTIONS.GET_METADATA_VALUES_SUCCESS, { data, meta })
            );
            resolve(data);
          },
          (error) => {
            dispatch(
              failure(
                ENUM_ACTIONS.GET_METADATA_VALUES_FAILURE,
                error.toString()
              )
            );
            reject(error);
          }
        );
      });
    };
  }

  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(TASK_ACTIONS.GET_TASK_BY_ID_REQUEST, { id }));
      adhocTasksService.getDocumentById(id).then(
        (data) => {
          dispatch(success(TASK_ACTIONS.GET_TASK_BY_ID_SUCCESS, data));
          resolve(data);
        },
        (error) => {
          dispatch(
            failure(TASK_ACTIONS.GET_TASK_BY_ID_FAILURE, error.toString())
          );
          reject(error);
        }
      );
    });
  };
}

export function isAdhocEntryDelete(id) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(TASK_ACTIONS.DELETE_IS_ADHOC_ENTRY_REQUEST, { id }));
      adhocTasksService.isAdhocEntryDelete(id).then(
        (data) => {
          dispatch(success(TASK_ACTIONS.DELETE_IS_ADHOC_ENTRY_SUCCESS, data));
          resolve(data);
        },
        (error) => {
          dispatch(
            failure(
              TASK_ACTIONS.DELETE_IS_ADHOC_ENTRY_FAILURE,
              error.toString()
            )
          );
          reject(error.toString());
        }
      );
    });
  };
}

export function adhocEntryDeleteOffline(id) {
  return async (dispatch, getStore) => {
    const store = getStore();
    const message = "Task deleted successfully";
    if (store && store.app && !!store.app.isConnected) {
      await deleteTaskDesignerAdhocEntry(id)(dispatch);
      await ScheduledTaskManager.deleteTask(id);
    } else {
      try {
        dispatch(request(TASK_ACTIONS.DELETE_IS_ADHOC_ENTRY_REQUEST, { id }));
        await SubmissionManager.saveDeleteSubmission(id);
        await ScheduledTaskManager.deleteTask(id);
        dispatch(success(TASK_ACTIONS.DELETE_IS_ADHOC_ENTRY_SUCCESS, message));
      } catch (error) {
        dispatch(
          failure(TASK_ACTIONS.DELETE_IS_ADHOC_ENTRY_FAILURE, error.toString())
        );
        throw error;
      }
    }
    dispatch(
      success(
        TASK_SUBMISSION_ACTIONS.GET_ALL_TASK_SUBMISSIONS_OFFLINE_SUCCESS,
        await ScheduledTaskManager.getTasks(getStore().taskSubmission.filters)
      )
    );
    return message;
  };
}
export function deleteTaskDesignerAdhocEntry(id) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(request(TASK_ACTIONS.DELETE_IS_ADHOC_ENTRY_REQUEST, { id }));
      adhocTasksService.deleteTaskDesignerAdhocEntry(id).then(
        (data) => {
          dispatch(success(TASK_ACTIONS.DELETE_IS_ADHOC_ENTRY_SUCCESS, data));
          resolve(data);
        },
        (error) => {
          failure(TASK_ACTIONS.DELETE_IS_ADHOC_ENTRY_FAILURE, error.toString());
          reject(error.toString());
        }
      );
    });
  };
}

export function clearTasks() {
  return { type: TASK_ACTIONS.CLEAR_TASKS };
}
