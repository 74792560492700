import { GENERIC_CONSTANTS } from "../constants/GenericConstants";
import { parseDateForServer, parseDateForDatePicker, showFormulaField, showFirstXCharacters, parseDateTime, parseTime12H } from "./GeneralHelper";
import _ from 'lodash';
import React from 'react';

export function parseProgramListingData(programListingData, ENUM = {}) {
    let parsedProgramList = [];
    const status = ENUM.STATUS;
    if (!!programListingData) {
        for (var i = 0; i < programListingData.length; i++) {

            let programObject = {};
            const data = programListingData[i];
            let statusObj = _.find(status, (obj) => obj.value === data.status) || {};
            let isActive = data.isActive;
            let statusIcon = isActive ? "activeIcon" : "inactiveIcon";
            programObject.programNumber = < div > <i
                className={statusIcon}></i>{data.programNumber}</div >;

            programObject.title = showFirstXCharacters(data.title, 110);
            programObject.id = data.id;
            programObject.revisionDateTime = !!data.revisionDateTime ? parseDateForDatePicker(data.revisionDateTime) : "N/A";
            // programObject.createdOn = !!data.createdOn ? parseDateForDatePicker(data.createdOn) : "N/A";
            programObject.createdBy = !!data.createdByUserLongName ? data.createdByUserLongName  : "N/A";
            programObject.updatedOn = !!data.updatedOn ? parseDateForDatePicker(data.updatedOn) : "N/A";
            programObject.status = statusObj.label || "";
            programObject.division = !!data.division ? data.division.divisionNumber : "";
            parsedProgramList.push(programObject);
        }
    }

    return parsedProgramList;
}

export function parseProgramEditFormData(programData) {
    const parsedData = {};
    const programObject = programData || {};
    const divisionObject = !!programObject ? programObject.division : {};

    parsedData.revisionDateTime = !!programData.revisionDateTime ?
        parseDateForDatePicker(programData.revisionDateTime) : "";
    parsedData.revisionNote = programData.revisionNote;
    parsedData.formFields = [];
    if (!!programData.formFields && programData.formFields.length > 0) {
        parsedData.formFields = programData.formFields.map((obj) => {
            let fieldsObject = {};
            fieldsObject.specification = obj.specification || "";
            fieldsObject.title = obj.title;
            return fieldsObject;
        });
    }

    parsedData.division = !!divisionObject ? divisionObject.id : null;
    parsedData.programNumber = programData.programNumber;
    parsedData.program = !!programObject ? programObject.id : "";
    parsedData.title = programData.title;
    return parsedData;
}

export function parseDivisionDropdownData(divisionListingData, ENUM = {}) {
    let parsedDivisionList = [];
    const status = ENUM.STATUS;
    if (!!divisionListingData) {
        for (var i = 0; i < divisionListingData.length; i++) {
            const data = divisionListingData[i];
            let statusObj = _.find(status, (obj) => obj.value === data.status) || {};
            let obj = {};
            obj.divisionNumber = data.divisionNumber;
            obj.title = data.title;
            obj.value = data.id;
            obj.label = data.divisionNumber;
            obj.id = data.id;
            obj.revisionDateTime = !!data.revisionDateTime ? parseDateForDatePicker(data.revisionDateTime) : "";
            obj.status = statusObj.label || "";
            parsedDivisionList.push(obj);
        }
    }

    return parsedDivisionList;
}

export function parsedProgramFilters(filterParams) {
    let filters = {};
    let statuses = filterParams.status;
    let divisions = filterParams.divisions;
    let states = filterParams.state;

    states = _.map(states, "value");
    statuses = _.map(statuses, "value");
    divisions = _.map(divisions, "value");
    // filters.startDate = !!filterParams.startDate ? parseDateForServer(filterParams.startDate) : null;
    // filters.endDate = !!filterParams.endDate ? parseDateForServer(filterParams.endDate) : null;
    filters.divisions = divisions;
    filters.status = statuses;
    filters.state = states;
    return filters;
}

export function parseProgramData(formValues, status) {
    let parsedResponse = {};
    parsedResponse.revisionDateTime = parseDateForServer(formValues.revisionDateTime) || "";
    parsedResponse.status = status || "";
    parsedResponse.revisionNote = formValues.revisionNote;
    parsedResponse.divisionId = !!formValues.division ? formValues.division : "";
    parsedResponse.title = formValues.title.trim() || "";
    parsedResponse.programNumber = formValues.programNumber.trim();

    let formFields = formValues.formFields || [];
    formFields = formFields.map((obj) => {
        return {
            fieldType: obj.fieldType,
            specification: obj.specification,
            isRequired: obj.isRequired,
            title: obj.title
        }
    });
    parsedResponse.formFields = formFields;

    return parsedResponse;
}

export function parseProgramViewFormData(programData) {
    const parsedData = {};
    const programObject = programData || {};
    const divisionObject = !!programObject.division ? programObject.division : {};

    parsedData.revisionDateTime = !!programData.revisionDateTime ?
        parseDateForDatePicker(programData.revisionDateTime) : "";

    parsedData.formFields = [];
    if (!!programData.formFields && programData.formFields.length > 0) {
        parsedData.formFields = programData.formFields.map((obj) => {
            let fieldsObject = {};
            fieldsObject.fieldType = obj.fieldType;
            fieldsObject.specification = obj.specification || "";
            fieldsObject.isRequired = obj.isRequired;
            fieldsObject.title = obj.title;
            fieldsObject.enabled = showFormulaField(obj.fieldType);
            return fieldsObject;
        });
    }
    parsedData.isActive = programData.isActive;
    parsedData.stateTitle = parsedData.isActive ? "Active" : "Inactive";
    parsedData.totalActivities = !!programData.formFields ? programData.formFields.length : 0; //formFields applied
    parsedData.revisionNote = programData.revisionNote;
    parsedData.division = !!divisionObject ? divisionObject.id : "";
    parsedData.program = !!programObject ? programObject.id : "";
    parsedData.programNumber = programData.programNumber;
    parsedData.title = programData.title;
    parsedData.status = programData.status;
    parsedData.divisionSublist = [];

    if (!!divisionObject && divisionObject.id) {
        parsedData.divisionSublist.push({
            id: divisionObject.id,
            divisionNumber: divisionObject.divisionNumber
        });
    }
    return parsedData;
}

export function getProgramFilterCount(filters) {
    let dateRangePresent = false;
    let filterCount = 0;

    _.each(filters, (obj, key) => {
        if ((!!obj)) {
            if (key === GENERIC_CONSTANTS.FIELD_NAME.START_DATE || key === GENERIC_CONSTANTS.FIELD_NAME.END_DATE) {
                dateRangePresent = true;
                filterCount += 1;
            } else if (obj.length > 0) {
                filterCount += 1;
            }
        }
    });
    if (dateRangePresent) { //subtracting 1 because using start date and end date as single filter
        filterCount -= 1;
    }
    return filterCount;
}