import { API_CONSTANTS } from "../../constants/ApiConstants";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { apiClient } from "./ApiClient";
import {
  TaskFilterModel,
  AssignedTaskForOfflineModel,
} from "../../models/Task";

export const adhocTasksService = {
  getAdhocTasks,
  getAdhocTasksDetails,
  getAdhocTasksForOffline,
  isAdhocEntryDelete,
  deleteTaskDesignerAdhocEntry,
  getAdhocTaskDesigner,
  getAdhocTaskDesignerDetails,
  getAdhocTaskDesignerDetailsById,
  getDocumentById,
};

function deleteTaskDesignerAdhocEntry(id) {
  return apiClient()
    .delete(API_CONSTANTS.API_ROUTES.DELETE_TASK_DESIGNER_ADHOC_ENTRY + id)
    .then(handleResponse)
    .catch(handleError);
}

function getAdhocTaskDesigner(
  paginationData,
  searchTerms,
  filterObject = {},
  sortObject = {},
  workorderId,
  taskId,
  parentTaskOnly,
  todaySubTaskParams = {}
) {
  let params = new TaskFilterModel(
    filterObject,
    sortObject,
    paginationData,
    { searchTerms },
    workorderId,
    taskId,
    parentTaskOnly
  );
  let url = API_CONSTANTS.API_ROUTES.GET_ASSIGNED_TASK_DESIGNER;

  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function getAdhocTaskDesignerDetails(id) {
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.GET_TASK_DESIGNER_BY_ID + id)
    .then(handleResponse)
    .catch(handleError);
}

function getAdhocTaskDesignerDetailsById(id) {
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.GET_ADHOC_TASK_DESIGNER_BY_ID + id)
    .then(handleResponse)
    .catch(handleError);
}

function getDocumentById(id) {
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.GET_DOCUMENT_BY_ID + id)
    .then(handleResponse)
    .catch(handleError);
}

function getAdhocTasks(
  paginationData,
  searchTerms,
  filterObject = {},
  sortObject = {},
  workorderId,
  taskId,
  parentTaskOnly,
  todaySubTaskParams = {}
) {
  let url = null;
  let params = null;
  // task & subtask listing
  params = new TaskFilterModel(
    filterObject,
    sortObject,
    paginationData,
    { searchTerms },
    workorderId,
    taskId,
    parentTaskOnly
  );
  url = API_CONSTANTS.API_ROUTES.GET_ASSIGNED_TASK;

  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function getAdhocTasksForOffline(paginationData, timestamp, taskTypeEnumId) {
  let url = API_CONSTANTS.API_ROUTES.GET_ASSIGNED_TASK_WITH_DETAILS;
  let params = new AssignedTaskForOfflineModel(
    paginationData,
    timestamp,
    taskTypeEnumId
  );

  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function getAdhocTasksDetails(id) {
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.GET_ASSIGNED_TASK_BY_ID + id)
    .then(handleResponse)
    .catch(handleError);
}

function isAdhocEntryDelete(id) {
  return apiClient()
    .delete(API_CONSTANTS.API_ROUTES.DELETE_ADHOC_ENTRY + id)
    .then(handleResponse)
    .catch(handleError);
}
