import { API_CONSTANTS } from "../../constants/ApiConstants";
import { FREQUENCY_CALENDAR } from "../../constants/GenericConstants";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { apiClient } from "./ApiClient";
import { SubmisionHistoryFilterModel } from "../../models/Task";
import {
  parseDateForServerForDashboard,
  parseDateTimeForServerForDashboard,
} from "@ecodocs/common/src/helpers/GeneralHelper";
import moment from "moment";
import ApiHelper from "../../helpers/ApiHelper";

export const dashboardService = {
  getRecentSubmissions,
  getRuleViolations,
  getActiveWorkers,
  getPendingWorkorders,
  getMissedTasks,
  getTaskCompletionStatistics,
  getTaskViolationsStatistics,
  getDueTasks,
  getTaskSummaryStatistics,
  getTaskCompletionStatisticsGraph,
  getAllDueTasks,
  getAllDueTasksDesigner,
  getDueTaskDesigner,
};

function getDueTaskDesigner(
  paginationData,
  searchTerms,
  filterObject = {},
  sortObject = {},
  dueToday = true
) {
  let url = API_CONSTANTS.API_ROUTES.GET_TASK_DESIGNER_SUBMISIONS;
  let params = new SubmisionHistoryFilterModel(
    paginationData,
    { searchTerms },
    filterObject,
    sortObject
  );

  // from & to due date set
  // let fromDueDate = parseDateForServerForDashboard(new Date());
  // let toDueDate = parseDateForServerForDashboard(new Date());
  let fromDueDate = parseDateTimeForServerForDashboard(moment().toDate());
  let toDueDate = parseDateTimeForServerForDashboard(
    moment().set("hour", 23).set("minute", 59).toDate()
  );
  // dueToday: true for 'today', false for 'soon'
  if(!dueToday){
      fromDueDate = parseDateTimeForServerForDashboard(
                  moment()
                      .add(1, 'd')
                      .set("hour", 0)
                      .set("minute", 0)
                      .toDate(),
                  );

      toDueDate = parseDateTimeForServerForDashboard(
                  moment()
                      .add(7, 'd')
                      .set("hour", 23)
                      .set("minute", 59)
                      .toDate(),
                  );
  }

  params.fromDueDate = fromDueDate;
  params.toDueDate = toDueDate;
  params.IncludePendingOnly = true;

  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function getAllDueTasksDesigner(
  paginationData,
  searchTerms,
  filterObject = {},
  sortObject = {}
) {
  let url = API_CONSTANTS.API_ROUTES.GET_TASK_DESIGNER_SUBMISIONS;
  let params = new SubmisionHistoryFilterModel(paginationData, {searchTerms}, filterObject, sortObject);
  
  // params.submissionStartTime = filterObject.fromDueDate? new Date(filterObject.fromDueDate): null;
  // params.submissionEndTime = filterObject.toDueDate? new Date(filterObject.toDueDate): null;
  params.fromDueDate = filterObject.fromDueDate? new Date(filterObject.fromDueDate): null;
  params.toDueDate = filterObject.toDueDate? new Date(filterObject.toDueDate): null;
  params.IncludePendingOnly = true;

  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function getRecentSubmissions(params = {}) {
  // const url = ApiHelper.getPageableUrl(API_CONSTANTS.API_ROUTES.GET_RECENT_SUBMISSIONS, params) //TODO: will be uncommented later
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.GET_RECENT_SUBMISSIONS)
    .then(handleResponse)
    .catch(handleError);
}

function getRuleViolations(params) {
  const url = API_CONSTANTS.API_ROUTES.GET_RULE_VIOLATIONS;
  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function getActiveWorkers() {
  const url = API_CONSTANTS.API_ROUTES.GET_ACTIVE_WORKERS_STATS;
  return apiClient().get(url).then(handleResponse).catch(handleError);
}

function getMissedTasks() {
  const url = API_CONSTANTS.API_ROUTES.GET_MISSED_TASKS;
  return apiClient().get(url).then(handleResponse).catch(handleError);
}

function getTaskSummaryStatistics() {
  const url = API_CONSTANTS.API_ROUTES.GET_TASK_SUMMARY_STATS;
  return apiClient().get(url).then(handleResponse).catch(handleError);
}

function getTaskCompletionStatisticsGraph() {
  const url = API_CONSTANTS.API_ROUTES.GET_TASK_COMPLETION_GRAPH;
  return apiClient().get(url).then(handleResponse).catch(handleError);
}

function getPendingWorkorders(params = {}) {
  const url = API_CONSTANTS.API_ROUTES.GET_PENDING_WORKORDERS;
  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function getTaskCompletionStatistics(FromDate, ToDate) {
  const url = API_CONSTANTS.API_ROUTES.GET_TASK_COMPLETION_STATISTICS;
  const params = { FromDate, ToDate };
  return apiClient()
    .get(url, { params })
    .then(handleResponse)
    .catch(handleError);
}

function getTaskViolationsStatistics(FromDate, ToDate) {
  const url = API_CONSTANTS.API_ROUTES.GET_TASK_VIOLATION_STATISTICS;
  const params = { FromDate, ToDate };
  return apiClient()
    .get(url, { params })
    .then(handleResponse)
    .catch(handleError);
}

function getDueTasks(
  paginationData,
  searchTerms,
  filterObject = {},
  sortObject = {},
  dueToday = true
) {
  let url = API_CONSTANTS.API_ROUTES.GET_TASK_SUBMISIONS;
  let params = new SubmisionHistoryFilterModel(
    paginationData,
    { searchTerms },
    filterObject,
    sortObject
  );

  // from & to due date set
  let fromDueDate = parseDateForServerForDashboard(new Date());
  let toDueDate = parseDateForServerForDashboard(new Date());

  // dueToday: true for 'today', false for 'soon'
  if (!dueToday) {
    fromDueDate = parseDateForServerForDashboard(moment().add(1, "d").toDate());

    toDueDate = parseDateForServerForDashboard(moment().add(7, "d").toDate());
  }

  params.fromDueDate = fromDueDate;
  params.toDueDate = toDueDate;
  params.IncludePendingOnly = true;

  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function getAllDueTasks(
  paginationData,
  searchTerms,
  filterObject = {},
  sortObject = {}
) {
  let url = API_CONSTANTS.API_ROUTES.GET_TASK_SUBMISIONS;
  let params = new SubmisionHistoryFilterModel(
    paginationData,
    { searchTerms },
    filterObject,
    sortObject
  );

  params.fromDueDate = filterObject.fromDueDate;
  params.toDueDate = filterObject.toDueDate;
  params.IncludePendingOnly = true;

  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}
