import _ from "lodash";
import { parseDateForDatePicker } from "./GeneralHelper";
import StorageService from "@ecodocs/common/src/sevices/storageService";
import { GENERIC_CONSTANTS } from "@ecodocs/common/src/constants/GenericConstants";
import React from 'react';

/**
 * This method will parse data
 * for user/add data
 * @param {Object} userData
 */
export function parseUserData(formValues, ifADUserSetEmail, ADUserTypeId) {
    let parsedData = {};
    ifADUserSetEmail = ifADUserSetEmail || false;
    parsedData.email = ifADUserSetEmail != null && ifADUserSetEmail == true && formValues.type  && formValues.type == ADUserTypeId ? formValues.username : formValues.email;
    parsedData.firstName = formValues.firstName.trim();
    parsedData.divisionId = formValues.division;
    parsedData.lastName = formValues.lastName.trim();
    parsedData.role = formValues.role;
    if (formValues.status !== 'false') {
        //parsedData.status = true;
        parsedData.isActive = true;
    } else {
        //parsedData.status = false;
        parsedData.isActive = false;
    }
    parsedData.type = formValues.type;
    parsedData.username = formValues.username.trim();
    if (!!formValues.password && formValues.password.length > 0) {
        parsedData.password = formValues.password;
    }
    return parsedData;
}

/**
 * Parsing Filter Params for UserListing
 * @param {Object} filterParams
 */
export function parsedUserFilters(filterParams) {
  let filters = {};
  let roles = filterParams.role;
  let divisions = filterParams.divisions;
  let types = filterParams.type;
  roles = _.map(roles, "value");
  divisions = _.map(divisions, "value");
  types = _.map(types, "value");
  filters.divisions = divisions;
  filters.types = types;
  filters.roles = roles;
  return filters;
}

/**
 * Payload Data For Edit User Screen
 * @param {Object} userData
 */
export function parseUserEditFormData(userData) {
  const parsedData = {};
  parsedData.firstName = userData.firstName || "";
  parsedData.lastName = userData.lastName || "";
  parsedData.username = userData.username || "";
  parsedData.email = userData.email || "";
  parsedData.role = userData.role || "";
  parsedData.type = userData.type || "";
  // if (userData.status !== false) {
  //     parsedData.status = true;
  // } else {
  //     parsedData.status = false;
  // }
  parsedData.status = !!userData.isActive;
  parsedData.id = userData.id || "";
  return parsedData;
}

/**
 * Payload Data For View User Screen
 * @param {Object} userData
 */
export function parseUserViewFormData(userData) {
  const parsedData = {};
  parsedData.firstName = userData.firstName || "";
  parsedData.lastName = userData.lastName || "";
  parsedData.username = userData.username || "";
  parsedData.password = "Dummy Text";
  parsedData.email = userData.email || "";
  parsedData.role = userData.role || "";
  parsedData.type = userData.type || "";
  parsedData.status = !!userData.isActive;
  parsedData.id = userData.id || "";
  return parsedData;
}

/**
 * Parsing divisions array for multiselect array
 * @param {ObjectArray} workorders
 */
export function parseDivisionsForSelect(divisions) {
  let parsedWorKorders = [];
  if (!!divisions && divisions.length > 0) {
    for (var i = 0; i < divisions.length; i++) {
      parsedWorKorders.push({
        value: divisions[i].id,
        label: divisions[i].divisionNumber,
        color: "#00B8D9",
      });
    }
  }
  return parsedWorKorders;
}

/**
 * Parsing divisions array for multiselect array
 * @param {ObjectArray} workorders
 */
export function parseUsersForSelect(users) {
    let parsedData = [];
    if (!!users && users.length > 0) {
        for (var i = 0; i < users.length; i++) {
            parsedData.push({
              value: users[i].id,
              label: users[i].fullName,
              color: "#00B8D9",
              isManager:
                users[i].role == GENERIC_CONSTANTS.USER_ROLES.MANAGER,
              ...users[i],
            });
        }
    }
    return parsedData;
}

export function parseFilteredUsersForSelect(users) {
    let parsedData = [];
    if (!!users && users.length > 0) {
        for (var i = 0; i < users.length; i++) {
            parsedData.push({
                value: users[i].id,
                label: users[i].fullName,
                color: '#00B8D9',
            });
        }
    }
  return parsedData;
}

/**
 * Parsing data for user listing
 * @param {Array} userListingData
 */
export function parseUserListingData(userListingData, ENUM = {}) {
  let parsedUserList = [];
  const userRoles = ENUM.USER_ROLE || [];
  const userTypes = ENUM.USER_TYPE || [];

  if (!!userListingData) {
    for (var i = 0; i < userListingData.length; i++) {
      const data = userListingData[i];
      let divisions = data.divisions || [];
      let roleObj =
        _.find(userRoles, (obj) => obj.value === parseInt(data.role)) || {};
      let typeObj =
        _.find(userTypes, (obj) => obj.value === parseInt(data.type)) || {};
      divisions = _.map(divisions, (division) => division.divisionNumber);
      divisions = data.divisionNumber || divisions.join(",");

      let userObj = {};
      let isActive = data.isActive;
      let statusIcon = isActive ? "activeIcon" : "inactiveIcon";
      userObj.fullName = (
        <div>
          <span className={statusIcon}></span>
          { data.fullName }
        </div>
      );
      userObj.id = data.id;
      userObj.division =
        data && data.division ? data.division.divisionNumber : "N/A";
      let lastActive = !!data.lastActive
        ? parseDateForDatePicker(data.lastActive)
        : "";
      userObj.lastActive =
        !!lastActive && lastActive !== parseDateForDatePicker("0001-01-01")
          ? lastActive
          : "N/A";
      userObj.role = roleObj.label || "";
      userObj.type = typeObj.label || "";
      parsedUserList.push(userObj);
    }
  }

  return parsedUserList;
}

/**
 * Getting Filter Count For
 * User List Page
 * @param {Object} filters
 */
export function getUserFilterCount(filters) {
  let filterCount = 0;
  _.each(filters, (obj, key) => {
    if (!!obj && obj.length > 0) {
      filterCount += 1;
    }
  });

  return filterCount;
}

export function isWorker(){
    const userData = StorageService.instance.getUserData();
    let userRole = !!userData.role? parseInt(userData.role): "";
    let isWorker =
      userRole === GENERIC_CONSTANTS.USER_ROLES.WORKER;
      // ||
      // userRole === GENERIC_CONSTANTS.USER_ROLES.VIEW_ONLY ||
      // userRole === GENERIC_CONSTANTS.USER_ROLES.REVIEW_ONLY ||
      // userRole === GENERIC_CONSTANTS.USER_ROLES.MANAGER ||
      // userRole === GENERIC_CONSTANTS.USER_ROLES.USDA_INSPECTOR;
    return isWorker;
}
