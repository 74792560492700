import React, { Component } from "react";
import App from "./App";
import StorageService from "@ecodocs/common/src/sevices/storageService";
import LocalizationService from "@ecodocs/common/src/sevices/localizationService";
import { PLATFORMS } from "@ecodocs/common/src/constants/GenericConstants";
import { Provider } from "react-redux";
import createHistory from "history/createBrowserHistory";
import configureStore from "@ecodocs/common/src/store/configureStore";
import NavigationService from "@ecodocs/common/src/sevices/navigationService";

const store = configureStore();
const history = createHistory();

export default class BootSetup extends Component {
  constructor(props) {
    super(props);
    StorageService.instance = new StorageService(PLATFORMS.WEB, localStorage);
    LocalizationService.instance = new LocalizationService(
      PLATFORMS.WEB,
      window.LOCALIZATION_CONSTANTS
    );
    NavigationService.instance = new NavigationService(PLATFORMS.WEB, window);
  }

  render() {
    return (
      <>
        <Provider store={store} history={history}>
          <App />
        </Provider>
      </>
    );
  }
}
