import { ASSET_ACTIONS } from '../../constants/ActionConstants';

const initialState = { assetCategoryForm: null, loading: false, showMessage: false };

export function asset(state = initialState, action) {
    switch (action.type) {
        case ASSET_ACTIONS.GET_FORM_BY_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            };

        case ASSET_ACTIONS.GET_FORM_BY_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                assetCategoryForm: !!action.payload && !!action.payload.result? action.payload.result : null,
            };

        case ASSET_ACTIONS.GET_FORM_BY_CATEGORY_FAILURE:
            return {
                ...state,
                loading: false,
                assetCategoryForm: {},
                error: action.payload
            };

        default:
            return state;
    }
}