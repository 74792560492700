import { SUBMISSION_TYPE } from "../../constants/GenericConstants";

class SubmissionModel { }

SubmissionModel.schema = {
  name: "Submission",
  properties: {
    id: "int",
    parentId: "int?",
    payload: "string?",
    taskType: "string?",
    isSubtask: "bool?",
    submissionType: { type: 'string?', default: SUBMISSION_TYPE.SUBMIT },
  },
};

export default SubmissionModel;
