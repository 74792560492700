import ReactNativeLocalizationService from "./react-native";
import ReactLocalizationService from "./react";
import { PLATFORMS } from "../../constants/GenericConstants";

export default class LocalizationService {
  static instance = null;

  constructor(platform, localization) {
    this.service =
      platform === PLATFORMS.WEB
        ? new ReactLocalizationService(localization)
        : new ReactNativeLocalizationService(localization);
  }

  getLocalization() {
    return this.service.getLocalization();
  }
}
