import { API_CONSTANTS } from "../../constants/ApiConstants";
import { handleResponse, handleError } from "./GenericResponseHandler";
import AccessToken, { apiClient, BarnManagerEnvironment } from "./ApiClient";
import {
  TaskFilterModel,
  QATaskHistoryModel,
  TaskSubmisionFilterModel,
  SubTaskSubmisionFilterModel,
} from "../../models/Task";
import {
  TASK_VIEW_TYPE,
  MOBILE_IMAGE_SESSION,
} from "../../constants/GenericConstants";

export const taskService = {
  getAllTasks,
  submitTaskData,
  validateTaskNo,
  getTaskDetails,
  editTask,
  getFilteredTasks, //will be removed later
  getFilterTaskSubmissions,
  getFilterTasks,
  associateWorkorderToTasks,
  changeState,
  createRuleForField,
  getTaskRuleDetails,
  getTaskWithSubmissionDetail,
  updateRulesForField,
  deleteRuleForField,
  getTasksByWorkorderId,
  getTaskSubmissionDetails,
  getTaskSubmissionDetailsForOffline,
  getTaskDetailsById,
  getAdhocTasks,
  getAllTaskNames,
  uploadImages,
  downloadImagesMobile,
  uploadImagesMobile,
  deleteImages,
  submitTaskDesigner,
  getTaskDesignerDetails,
  editTaskDesigner,
  getFilteredTaskDesigner,
  validateTaskDesignerNo,
  changeStateTaskDesigner,
  getFilterTaskDesigner,
  getTaskDesignerByWorkorderId,
  getTaskDesignerSubmissionDetails,
  duplicateTaskDesigner,
  approveSubmission,
  getTaskLocations,
  skipTasks,
  getElementsByGroupId,
  getTaskDesignerByProgramId,
  markTaskAsNotPerformed
};

function duplicateTaskDesigner(data) {
  return apiClient()
    .post(
      API_CONSTANTS.API_ROUTES.DUPLICATE_TASK_DESIGNER,
      JSON.stringify(data)
    )
    .then(handleResponse)
    .catch(handleError);
}

function approveSubmission(data) {
  return apiClient()
    .post(API_CONSTANTS.API_ROUTES.APPROVE_SUBMISSION, data)
    .then(handleResponse)
    .catch(handleError);
}

function getTaskDesignerSubmissionDetails(id) {
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.GET_TASK_DESIGNER_BY_SUBMISSION_ID + id)
    .then(handleResponse)
    .catch(handleError);
}

function getTaskDesignerByProgramId(id) {
  return apiClient().get(API_CONSTANTS.API_ROUTES.GET_TASKS_BY_PROGRAM + id)
      .then(handleResponse)
      .catch(handleError);
}

function submitTaskDesigner(tasksData) {
  return apiClient()
    .post(API_CONSTANTS.API_ROUTES.ADD_TASK_DESIGNER, JSON.stringify(tasksData))
    .then(handleResponse)
    .catch(handleError);
}

function getTaskDesignerDetails(id) {
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.GET_TASK_DESIGNER_BY_ID + id)
    .then(handleResponse)
    .catch(handleError);
}

function editTaskDesigner(tasksData, id) {
  return apiClient()
    .put(
      API_CONSTANTS.API_ROUTES.EDIT_TASK_DESIGNER + "/" + id,
      JSON.stringify(tasksData)
    )
    .then(handleResponse)
    .catch(handleError);
}

function getFilteredTaskDesigner(
  paginationData,
  searchTerms,
  filterObject = {},
  sortObject = {},
  workorderId,
  taskId,
  parentTaskOnly,
  todaySubTaskParams = {}
) {
  let url = null;
  let params = null;

  if (!!workorderId) {
    // parent task submission listing case
    params = new TaskSubmisionFilterModel(
      filterObject,
      sortObject,
      paginationData,
      { searchTerms },
      workorderId
    );
    url = API_CONSTANTS.API_ROUTES.GET_SUBMISSION_BY_WO_ID;
  } else if (
    !!todaySubTaskParams &&
    todaySubTaskParams.parentSubmissionId &&
    todaySubTaskParams.subTaskId
  ) {
    // sub task submission listing case
    params = new SubTaskSubmisionFilterModel(
      filterObject,
      sortObject,
      paginationData,
      { searchTerms },
      todaySubTaskParams
    );
    url =
      API_CONSTANTS.API_ROUTES
        .GET_TASK_DESIGNER_SUBMISSION_BY_PARENT_SUBMISSION_ID;
  } else {
    // task & subtask listing
    params = new TaskFilterModel(
      filterObject,
      sortObject,
      paginationData,
      { searchTerms },
      workorderId,
      taskId,
      parentTaskOnly
    );
    url = API_CONSTANTS.API_ROUTES.GET_FILTERED_TASK_DESIGNER;
  }

  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function getTaskLocations(ids) {
  let url = API_CONSTANTS.API_ROUTES.TASK_WITH_LOCATIONS;
  let params = { taskIds: ids || [] };

  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function validateTaskDesignerNo(taskNo) {
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.TASK_DESIGNER_EXISTS + "/" + taskNo)
    .then(handleResponse)
    .catch(handleError);
}

function changeStateTaskDesigner(id, isActive) {
  return apiClient()
    .put(API_CONSTANTS.API_ROUTES.CHANGE_TASK_DESIGNER_STATE + "/" + id, {
      isActive: isActive,
    })
    .then(handleResponse)
    .catch(handleError);
}

function getFilterTaskDesigner(model, workorderId, todaySubTaskParams) {
  let url = API_CONSTANTS.API_ROUTES.GET_FILTERED_TASK;

  if (!!workorderId) {
    // parent task submission listing case
    url = API_CONSTANTS.API_ROUTES.GET_SUBMISSION_BY_WO_ID;
  } else if (
    todaySubTaskParams.parentSubmissionId &&
    todaySubTaskParams.subTaskId
  ) {
    // sub task submission listing case
    url = API_CONSTANTS.API_ROUTES.GET_SUBMISSION_BY_PARENT_SUBMISSION_ID;
  } else {
    // task & subtask listing
    url = API_CONSTANTS.API_ROUTES.GET_FILTERED_TASK_DESIGNER;
  }

  return apiClient().post(url, model).then(handleResponse).catch(handleError);
}

function getTaskDesignerByWorkorderId(workorderId) {
  let url = API_CONSTANTS.API_ROUTES.GET_FILTERED_TASK_DESIGNER;
  return apiClient()
    .post(url, {
      workorders: [workorderId],
      pageSize: 9999,
      parentTasksOnly: true,
    }) // pageNumber 0 -> returns all workorders assocaited with program
    .then(handleResponse)
    .catch(handleError);
}

function getTasksByWorkorderId(workorderId) {
  let url = API_CONSTANTS.API_ROUTES.GET_FILTERED_TASK;
  return apiClient()
    .post(url, {
      workorders: [workorderId],
      pageSize: 9999,
      parentTasksOnly: true,
    }) // pageNumber 0 -> returns all workorders assocaited with program
    .then(handleResponse)
    .catch(handleError);
}

function getAllTasks(paginationData) {
  // Api endpoint for task list with page size and pagenumber param
  const url =
    API_CONSTANTS.API_ROUTES.GET_ALL_TASKS +
    "?" +
    API_CONSTANTS.API_QUERY_STRING_PARAMS.PAGE_SIZE +
    "=" +
    paginationData.pageSize +
    "&" +
    API_CONSTANTS.API_QUERY_STRING_PARAMS.PAGE_NUMBER +
    "=" +
    paginationData.pageNumber;

  return apiClient().get(url).then(handleResponse).catch(handleError);
}

function editTask(tasksData, id) {
  return apiClient()
    .put(
      API_CONSTANTS.API_ROUTES.EDIT_TASK + "/" + id,
      JSON.stringify(tasksData)
    )
    .then(handleResponse)
    .catch(handleError);
}

function getAdhocTasks() {
  let url = API_CONSTANTS.API_ROUTES.GET_ASSIGNED_TASK;
  return apiClient()
    .post(url, { adhocOnly: false })
    .then(handleResponse)
    .catch(handleError);
}

function submitTaskData(tasksData) {
  return apiClient()
    .post(API_CONSTANTS.API_ROUTES.ADD_TASK, JSON.stringify(tasksData))
    .then(handleResponse)
    .catch(handleError);
}

function validateTaskNo(taskNo) {
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.TASK_EXISTS + "/" + taskNo)
    .then(handleResponse)
    .catch(handleError);
}

function getTaskDetails(id) {
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.GET_TASK_BY_ID + id)
    .then(handleResponse)
    .catch(handleError);
}

function getTaskSubmissionDetails(id, taskType) {
  let url =
    taskType === TASK_VIEW_TYPE.ADHOC
      ? API_CONSTANTS.API_ROUTES.GET_ADHOC_TASK_DETAIL
      : API_CONSTANTS.API_ROUTES.GET_TASK_BY_SUBMISSION_ID;
  return apiClient()
    .get(url + id)
    .then(handleResponse)
    .catch(handleError);
}

function getTaskSubmissionDetailsForOffline(
  formSubmissionMasterID,
  requestPage
) {
  let url = API_CONSTANTS.API_ROUTES.GET_TASK_SUBMISIONS_WITH_DETAILS;
  return apiClient()
    .post(url, { ids: [formSubmissionMasterID], requestPage })
    .then(handleResponse)
    .catch(handleError);
}

function getTaskDetailsById(id, requestPage, taskDetailIds) {
  let url = API_CONSTANTS.API_ROUTES.GET_ASSIGNED_TASK_WITH_DETAILS;
  return apiClient()
    .post(url, { ids: [id], requestPage, taskDetailIds })
    .then(handleResponse)
    .catch(handleError);
}

function associateWorkorderToTasks(data) {
  return apiClient()
    .put(API_CONSTANTS.API_ROUTES.ASSOCIATE_WORKORDER, JSON.stringify(data))
    .then(handleResponse)
    .catch(handleError);
}

function getFilteredTasks(
  paginationData,
  searchTerms,
  filterObject = {},
  sortObject = {},
  workorderId,
  taskId,
  parentTaskOnly,
  todaySubTaskParams = {}
) {
  let url = null;
  let params = null;

  if (!!workorderId) {
    // parent task submission listing case
    params = new TaskSubmisionFilterModel(
      filterObject,
      sortObject,
      paginationData,
      { searchTerms },
      workorderId
    );
    url = API_CONSTANTS.API_ROUTES.GET_SUBMISSION_BY_WO_ID;
  } else if (
    !!todaySubTaskParams &&
    todaySubTaskParams.parentSubmissionId &&
    todaySubTaskParams.subTaskId
  ) {
    // sub task submission listing case
    params = new SubTaskSubmisionFilterModel(
      filterObject,
      sortObject,
      paginationData,
      { searchTerms },
      todaySubTaskParams
    );
    url = API_CONSTANTS.API_ROUTES.GET_SUBMISSION_BY_PARENT_SUBMISSION_ID;
  } else {
    // task & subtask listing
    params = new TaskFilterModel(
      filterObject,
      sortObject,
      paginationData,
      { searchTerms },
      workorderId,
      taskId,
      parentTaskOnly
    );
    url = API_CONSTANTS.API_ROUTES.GET_FILTERED_TASK;
  }

  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function getFilterTaskSubmissions(
  paginationData,
  searchTerms,
  filterObject = {},
  sortObject = {},
  TaskId
) {
  const url = API_CONSTANTS.API_ROUTES.GET_QA_FORM_SUBMISSION_BY_ID;
  const params = new QATaskHistoryModel(
    filterObject,
    sortObject,
    paginationData,
    { searchTerms },
    TaskId
  );
  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function changeState(id, isActive) {
  return apiClient()
    .put(API_CONSTANTS.API_ROUTES.CHANGE_TASK_STATE + "/" + id, {
      isActive: isActive,
    })
    .then(handleResponse)
    .catch(handleError);
}

function createRuleForField(params) {
  const url = API_CONSTANTS.API_ROUTES.CREATE_RULE_FOR_FIELD;
  // let params = {};
  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function updateRulesForField(params, taskRuleId) {
  const url = API_CONSTANTS.API_ROUTES.UPDATE_RULE_FOR_FIELD + `/${taskRuleId}`;
  // let params = {};
  return apiClient().put(url, params).then(handleResponse).catch(handleError);
}

function getFilterTasks(model, workorderId, todaySubTaskParams) {
  let url = API_CONSTANTS.API_ROUTES.GET_FILTERED_TASK;

  if (!!workorderId) {
    // parent task submission listing case
    url = API_CONSTANTS.API_ROUTES.GET_SUBMISSION_BY_WO_ID;
  } else if (
    todaySubTaskParams.parentSubmissionId &&
    todaySubTaskParams.subTaskId
  ) {
    // sub task submission listing case
    url = API_CONSTANTS.API_ROUTES.GET_SUBMISSION_BY_PARENT_SUBMISSION_ID;
  } else {
    // task & subtask listing
    url = API_CONSTANTS.API_ROUTES.GET_FILTERED_TASK;
  }

  return apiClient().post(url, model).then(handleResponse).catch(handleError);
}

function getTaskRuleDetails(id) {
  const url = API_CONSTANTS.API_ROUTES.GET_RULES_BY_TASK_ID + id;
  return apiClient().get(url).then(handleResponse).catch(handleError);
}

function getAllTaskNames() {
  // const url = API_CONSTANTS.API_ROUTES.GET_ALL_TASK_NAMES;
  const url = API_CONSTANTS.API_ROUTES.GET_METADATA_VALUES_ENUMS;
  return apiClient()
    .post(url, { name: "TaskDetail" })
    .then(handleResponse)
    .catch(handleError);
}

function getTaskWithSubmissionDetail(id, masterId) {
  const url = API_CONSTANTS.API_ROUTES.GET_TASK_DETAILS + id + "/" + masterId;
  return apiClient().get(url).then(handleResponse).catch(handleError);
}

function deleteRuleForField(taskRuleId) {
  const url = API_CONSTANTS.API_ROUTES.DELETE_RULE_FOR_FIELD + `/${taskRuleId}`;
  return apiClient().delete(url).then(handleResponse).catch(handleError);
}

function uploadImages(files, type) {
  const formData = new FormData();
  formData.append("MediaUploadValidatorId", type);

  for (const property in files) {
    formData.append("FormFiles", files[property]);
  }

  return apiClient("multipart/form-data")
    .post(API_CONSTANTS.API_ROUTES.MEDIA_URL, formData)
    .then(handleResponse)
    .catch(handleError);
}

function downloadImagesMobile(url, session = MOBILE_IMAGE_SESSION) {
  const ext = url.split(".").pop();
  return window.RNFetchBlob.config({
    fileCache: true,
    session,
    appendExt: ext,
    timeout: 60000,
  }) // timeout of 1 minute
    .fetch("GET", url, { Authorization: `Bearer ${AccessToken.token}` });
}

function uploadImagesMobile(data) {
  return window.RNFetchBlob.fetch(
    "POST",
    `${BarnManagerEnvironment.config().baseURL}${
      API_CONSTANTS.API_ROUTES.MEDIA_URL
    }`,
    {
      Authorization: `Bearer ${AccessToken.token}`,
      "Content-Type": "multipart/form-data",
    },
    data
  );
}

function deleteImages(id) {
  return apiClient()
    .delete(API_CONSTANTS.API_ROUTES.MEDIA_URL + `?mediaId=${id}`)
    .then(handleResponse)
    .catch(handleError);
}

function markTaskAsNotPerformed(formSubmissionIds, reason, comment) {
  return apiClient()
    .post(API_CONSTANTS.API_ROUTES.MARK_TASK_AS_NOT_PERFORMED, {
      formSubmissionIds,
      reason,
      comment,
    })
    .then(handleResponse)
    .catch(handleError);
}

function skipTasks(data){
  return apiClient()
  .post(API_CONSTANTS.API_ROUTES.SKIP_TASKS, data)
  .then(handleResponse)
  .catch(handleError)
}

function getElementsByGroupId(data){
  return apiClient()
  .post(API_CONSTANTS.API_ROUTES.GET_ELEMENTS_BY_TASK_GROUP_ID, data)
  .then(handleResponse)
  .catch(handleError)
}
