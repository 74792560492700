import rar from './file-icons/icon-rar.png';
import pdf from './file-icons/icon-pdf.svg';
import doc from './file-icons/icon-doc.svg';
import ppt from './file-icons/icon-ppt.svg';
import xls from './file-icons/icon-xls.svg';
import txt from './file-icons/icon-txt.svg';
import csv from './file-icons/icon-csv.svg';
import zip from './file-icons/icon-zip.svg';
import odt from './file-icons/icon-odt.png';
import rtf from './file-icons/icon-rtf.svg';
import otf from './file-icons/icon-otf.svg';
import file from './file-icons/icon-file.svg';

export const FILES_ICONS = {
  RAR: rar,
  PDF: pdf,
  DOC: doc,
  PPT: ppt,
  XLS: xls,
  TXT: txt,
  CSV: csv,
  ZIP: zip,
  ODT: odt,
  RTF: rtf,
  OTF: otf,
  FILE: file
}