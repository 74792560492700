import { DASHBOARD_ACTIONS, USER_ACTIONS } from "../../constants/ActionConstants";
import { WORKORDER_PERIOD_TYPES } from "../../constants/GenericConstants";

export const initialState = {
  loading: false,
  selectedPeriod: WORKORDER_PERIOD_TYPES.TODAY,
  workordePercentage: null,
  recentSubmissions: null,
  ruleViolations: null,
  activeWorkers: [],
  missedTasks: [],
  pendingWorkorders: [],
  dueTasks: [],
  taskSummaryStats: {
    openTasks: 0,
    completedTasks: 0,
    completedTaskList: [],
  },
  taskCompletionGraph: {},
  taskCompletionStatistics: {
    completedTask: 0,
    missedTask: 0,
  },
  taskViolationStatistics: {
    completedTask: 0,
    violatedTask: 0,
  },
  taskDueSoon: {
    completed: 0,
    remaining: 0,
    totalTask: 0,
    elements: [],
    totalElements: 0,
  },
  taskDueToday: {
    completed: 0,
    remaining: 0,
    totalTask: 0,
    elements: [],
    totalElements: 0,
  },
};

export function dashboard(state = initialState, action) {
  switch (action.type) {

    /**
     * WORKORDER STATISTICS
     */

    case DASHBOARD_ACTIONS.WORKORDER_STAT_REQUEST:
      return {
        ...state,
      };

    case DASHBOARD_ACTIONS.WORKORDER_STAT_SUCCESS:
      return {
        ...state,
        workordePercentage: action.payload.data.result,
        selectedPeriod: action.payload.params.type,
      };

    case DASHBOARD_ACTIONS.WORKORDER_STAT_FAILURE:
      return {
        ...state,
        selectedPeriod: action.payload.params.type,
      };

    //EO | WORKORDER STATISTICS

    /**
     * RECENT SUBMISSIONS
     */

    case DASHBOARD_ACTIONS.GET_RECENT_SUBMISSIONS_REQUEST:
      return {
        ...state,
      };

    case DASHBOARD_ACTIONS.GET_RECENT_SUBMISSIONS_SUCCESS:
      const recentSubmissionResponse = action.payload;
      return {
        ...state,
        recentSubmissions:
          recentSubmissionResponse.data && recentSubmissionResponse.data.result
            ? recentSubmissionResponse.data.result.elements
            : [],
      };

    case DASHBOARD_ACTIONS.GET_RECENT_SUBMISSIONS_FAILURE:
      return {
        ...state,
      };

    //EO | RECENT SUBMISSIONS

    /**
     * RULE VIOLATIONS
     */

    case DASHBOARD_ACTIONS.GET_RULE_VIOLATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DASHBOARD_ACTIONS.GET_RULE_VIOLATION_SUCCESS:
      const violationResponse = action.payload;
      return {
        ...state,
        loading: false,
        ruleViolations:
          violationResponse.data && violationResponse.data.result
            ? violationResponse.data.result.elements
            : [],
      };

    case DASHBOARD_ACTIONS.GET_RULE_VIOLATION_FAILURE:
      return {
        ...state,
        loading: false,
      };

    //EO | RULE VIOLATIONS

    /**
     * ACTIVE WORKERS
     */

    case DASHBOARD_ACTIONS.GET_ACTIVE_WORKER_REQUEST:
      return {
        ...state,
      };

    case DASHBOARD_ACTIONS.GET_ACTIVE_WORKER_SUCCESS:
      const activeWorkersResponse = action.payload;
      return {
        ...state,
        activeWorkers:
          activeWorkersResponse.data && activeWorkersResponse.data.result
            ? activeWorkersResponse.data.result
            : {},
      };

    case DASHBOARD_ACTIONS.GET_ACTIVE_WORKER_FAILURE:
      return {
        ...state,
      };

    //EO | ACTIVE WORKERS

    /**
     * PENDING WORKORDERS
     */

    case DASHBOARD_ACTIONS.GET_PENDING_WORKORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DASHBOARD_ACTIONS.GET_PENDING_WORKORDER_SUCCESS:
      const pendingWorkorderResponse = action.payload;
      return {
        ...state,
        loading: false,
        pendingWorkorders:
          pendingWorkorderResponse.data && pendingWorkorderResponse.data.result
            ? pendingWorkorderResponse.data.result.elements
            : [],
      };

    case DASHBOARD_ACTIONS.GET_PENDING_WORKORDER_FAILURE:
      return {
        ...state,
        loading: false,
      };

    //EO | PENDING WORKORDERS

    /**
     * MISSED WORKORDERS
     */

    case DASHBOARD_ACTIONS.GET_MISSED_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DASHBOARD_ACTIONS.GET_MISSED_TASK_SUCCESS:
      const missedWorkorderResponse = action.payload;
      return {
        ...state,
        loading: false,
        missedTasks:
          missedWorkorderResponse.data && missedWorkorderResponse.data.result
            ? missedWorkorderResponse.data.result
            : [],
      };

    case DASHBOARD_ACTIONS.GET_MISSED_TASK_FAILURE:
      return {
        ...state,
        loading: false,
      };

    //EO | MISSED WORKORDERS

    /**
     * TASK SUMMARY STATISTICS
     */

    case DASHBOARD_ACTIONS.TASK_SUMMARY_STAT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DASHBOARD_ACTIONS.TASK_SUMMARY_STAT_SUCCESS:
      return {
        ...state,
        loading: false,
        taskSummaryStats:
          !!action.payload.data && !!action.payload.data.result
            ? action.payload.data.result
            : initialState.taskSummaryStats,
      };

    case DASHBOARD_ACTIONS.TASK_SUMMARY_STAT_FAILURE:
      return {
        ...state,
        loading: false,
        taskSummaryStats: initialState.taskSummaryStats, // emptying the data on failure
      };

    //EO | TASK COMPLETION STATISTICS

    /**
     * TASK COMPLETION GRAPH
     */

    case DASHBOARD_ACTIONS.TASK_COMPLETION_GRAPH_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DASHBOARD_ACTIONS.TASK_COMPLETION_GRAPH_SUCCESS:
      return {
        ...state,
        loading: false,
        taskCompletionGraph:
          !!action.payload.data && !!action.payload.data.result
            ? action.payload.data.result
            : {},
      };

    case DASHBOARD_ACTIONS.TASK_COMPLETION_GRAPH_FAILURE:
      return {
        ...state,
        loading: false,
        taskCompletionGraph: {}, // emptying the data on failure
      };

    //EO | TASK COMPLETION GRAPH

    /**
     * TASK COMPLETION STATISTICS
     */

    case DASHBOARD_ACTIONS.GET_TASK_COMPLETION_STATISTICS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DASHBOARD_ACTIONS.GET_TASK_COMPLETION_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        taskCompletionStatistics:
          !!action.payload.data && !!action.payload.data.result
            ? action.payload.data.result
            : initialState.taskCompletionStatistics,
      };

    case DASHBOARD_ACTIONS.GET_TASK_COMPLETION_STATISTICS_FAILURE:
      return {
        ...state,
        loading: false,
        taskCompletionStatistics: initialState.taskCompletionStatistics, // emptying the data on failure
      };

    //EO | TASK COMPLETION STATISTICS

    /**
     * TASK VIOLATION STATISTICS
     */

    case DASHBOARD_ACTIONS.GET_TASK_VIOLATION_STATISTICS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DASHBOARD_ACTIONS.GET_TASK_VIOLATION_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        taskViolationStatistics:
          !!action.payload.data && !!action.payload.data.result
            ? action.payload.data.result
            : initialState.taskViolationStatistics,
      };

    case DASHBOARD_ACTIONS.GET_TASK_VIOLATION_STATISTICS_FAILURE:
      return {
        ...state,
        loading: false,
        taskViolationStatistics: initialState.taskViolationStatistics, // emptying the data on failure
      };

    //EO | TASK VIOLATION STATISTICS

    /**
     * Due Tasks
     */

    case DASHBOARD_ACTIONS.GET_DUE_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DASHBOARD_ACTIONS.GET_DUE_TASK_SUCCESS:
      let dueTodaySuccess = !!action.payload.dueToday;
      let keySuccess = "taskDueSoon";
      if (!!dueTodaySuccess) {
        keySuccess = "taskDueToday";
      }

      let data =
        !!action.payload.data && !!action.payload.data.result
          ? action.payload.data.result
          : {};

      return {
        ...state,
        loading: false,
        [keySuccess]: data,
      };

    case DASHBOARD_ACTIONS.GET_DUE_TASK_FAILURE:
      let dueTodayFailure = !!action.payload.dueToday;

      let keyFailure = "taskDueSoon";
      if (!!dueTodayFailure) {
        keyFailure = "taskDueToday";
      }

      return {
        ...state,
        loading: false,
        [keyFailure]: initialState[keyFailure], // emptying the data on failure
      };

    //EO | Due Tasks

    /**
     * Due Tasks All
     */

    case DASHBOARD_ACTIONS.GET_ALL_DUE_TASK_REQUEST:
      return {
        ...state,
        loading: true,
        dueTaskError: false,
      };

    case DASHBOARD_ACTIONS.GET_ALL_DUE_TASK_SUCCESS:
      let dueTasks =
        !!action.payload && !!action.payload.result
          ? action.payload.result
          : {};
      return {
        ...state,
        loading: false,
        dueTaskError: false,
        dueTasks: dueTasks,
        taskDueSoon: dueTasks,
        taskDueToday: dueTasks,
      };

    case DASHBOARD_ACTIONS.GET_ALL_DUE_TASK_FAILURE:
      return {
        ...state,
        loading: false,
        dueTaskError: true,
        dueTasks: [], // emptying the data on failure
      };

    //EO | Due Tasks

    default:
      return state;
  }
}
