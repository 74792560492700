import { PageData, SortData, SearchTerm } from "./General";

export const UserLoginRequest = (props) => ({
    Username: props.username || "",
    Password: props.password || ""
});

export const UserForgotPasswordRequest = (props) => ({
    Email: props.email || ""
});

export const UserResetPasswordRequest = (props) => ({
    UserId: props.userId || "",
    Token: props.token ? props.token.split(" ").join("+") : "", //extract original token val
    NewPassword: props.password || ""
});

export const AssociateUserDivisionRequest = (props) => ({
    users: props.users || [],
    divisionID: props.divisionId || null
})

export const UserFilterModel = (filterData = {}, sortData = {}, pageData = {}, searchTerm = "") => {

    sortData.sortColumn = sortData.sortColumn === "division" ? "divisionTitle" : sortData.sortColumn;

    return ({
        ...new PageData(pageData),
        ...new SortData(sortData),
        ...new SearchTerm(searchTerm),
        locationIds: filterData.locationIds || [],
        workOrderIds: filterData.workOrderIds || [],
        divisions: filterData.divisions || [],
        types: filterData.types || [],
        status: filterData.status || [],
        roles: filterData.roles || [],
        IsCorrectiveActionUsers: filterData.IsCorrectiveActionUsers || false
    })
}