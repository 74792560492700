export default {
  WELCOME: "Welcome",
  SAVE: "Save",
  ACTIVITY_SUBMISSIONS: "Activity Submissions",
  SYSTEM_ACTIVITY: " System Activities",
  LIMIT_VIOLATION: "Limit Violations",
  CONTROL_NOTIFICATIONS: "Control Notification For Forms",
  CONFIRM: "Confirm",
  CANCEL: "Cancel",
  RESET_PASSWORD: "Reset Password",
  CANCEL_CONFIRMATION_MESSAGE: "Are you sure you want to cancel?",
  TASK_ADDED_SUCCESFULLY: "Task Added Succesfully",
  ADD_TASK: "Create A New Task",
  ADD_SUB_TASK: "Create A New Subtask",
  EDIT_TASK: "Edit",
  EDIT_USER: "Edit",
  EDIT_WORKORDER: "Edit Work Order",
  ADD_WORKORDER: "Add Work Order",
  REQUIREMENTS: "General Requirements",
  INSTRUCTIONS: "General Instructions",
  ASSOCIATE_WORKORDER: "Associate Work Order",
  ASSOCIATE_PROGRAM: "Associate Program",
  ASSOCIATE_DIVISION: "Associate Division",
  FILTER: "Filter",
  SEARCH: "Search",
  SUBMIT: "Submit",
  // "SEARCH_BOX_PACEHOLDER": "Search ",
  SEARCH_BOX_PACEHOLDER: "Type here to Search...",
  SEARCH_BOX_PACEHOLDER_TASK: "Search (Task No/Title)",
  SEARCH_BOX_PACEHOLDER_WORKORDER: "Search (Work Order No/Title)",
  SEARCH_BOX_PACEHOLDER_PROGRAM: "Search (Program No/Title)",
  REVISION_DATE: "Revision Date",
  WORKORDER: "Work Orders",
  WORK_ORDER: "Work Order",
  DIVISION: "Divisions",
  ROLE: "Role",
  TYPE: "Type",
  PROGRAM: "Programs",
  DIVISION_LABEL: "Divisions",
  SKIPTASK_REASON_LABEL: "Select Reason",
  SKIPTASK_REASON_COMMENT: "Comments",
  STATUS: "Status",
  CLEAR: "Clear",
  NO_RESULTS_FOUND: "No Results Found",
  SERVER_NOT_RESPONDING: "server is not responding",
  AMR_MONITERING_RECORD: "AMR Monitering Record",
  VIEW_ADD_RULES: "View/Add Rules",
  CURRENT_RULES:
    "Fields: Currently you have $constant_totalActivities fields(s) in System",
  TOTAL_ACTIVITIES_CONST: "$constant_totalActivities",
  REVIEW_STATUS: "Review Status",
  OUT_OF: " of",
  NOT_FOUND_MESSAGE: "We couldn't find what you are looking for",
  PROGRAM_UPDATED_SUCCESFULLY: "Program updated sucessfully",
  APPLY_FILTERS: "Apply Filters",
  SERVER_SUBMISSION_ERROR: "Something Went Wrong",
  SOMETHING_WENT_WRONG: "Something Went Wrong",
  ADD_USER: "Add User",
  SEARCH_BOX_PACEHOLDER_USER: "User Name/Email",
  ADD_PROGRAM: "Add Program",
  EDIT_PROGRAM: "Edit Program",
  CLOSE: "Close",
  ASSOCIATE: "Associate",
  DIVISION_ASSOCIATED_SUCCESFULLY: "Division associated successfully",
  ACCOUNT_DETAILS: "Account Details",
  NOTIFICATION_SETTINGS: "Notification Settings",
  CHANGE_PASSWORD: "Change Password",
  STATE: "State",
  EDIT: "Edit",
  ADD: "Add",
  DELETE_RULES_CONFIRMATION: "Are You Sure You Want To Delete The Rule",
  DELETE: "Delete",
  SELECT_FIELD: "Select Field",
  SELECT: "Select",
  SAVE_DRAFT: "Save Draft",
  SAVE_AND_PUBLISH: "Save & Publish",
  PUBLISHED: "Publish",
  PREVIEW: "Preview",
  ACTIVE: "Active",
  NA: "N/A",

  //Location constants
  LOCATION: "Location",
  EDIT_LOCATION: "Edit Location",
  LOCATION_TYPE: "Location Type",
  LOCATION_MANAGER: "Location Manager",

  LOCATION_FIELDS: {
    "ADD_LOCATION": "Add Location",
    "EDIT_LOCATION": "Edit Location",
    "LOCATION_ID": "Location ID",
    "LOCATION_NAME": "Location Name",
    "LOCATION_TYPE": "Location Type",
    "LOCATION_MANAGER": "Location Manager",
    "ADDRESS": "Address",
    "STATUS": "Status",
    "MANAGER_NAME": "Manager's Name",
    "WORKERS": "Workers",
  },
  LOGIN: {
    INCORRECT_EMAIL_PASSWORD: "Incorrect email or password",
  },
  TASK_FIELDS: {
    TASK_NO: "Task #",
    SUB_TASK_NO: "Subtask #",
    REVISION_NOTE: "Revision Note",
    REVISION_DATE: "Revision Date",
    DIVISION: "Division",
    PROGRAM: "Program",
    WORKORDER: "Work Order",
    TASK: "Task",
    TASK_RULES: "Task Rules",
    SUB_TASK: "Subtask",
    FREQUENCY_CALENDER: "Frequency",
    SUBMISSION_COUNT: "Minimum Submission(s)",
    TITLE: "Task Title",
    SUB_TASK_TITLE: "Subtask Title",
    ADD_GROUP: "Add Group",
    REMOVE_GROUP: "Remove Group",
    GROUP: "Group",
    GROUP_NAME: "Group Name",
    FORM_FIELDS: "Form Fields",
    TASK_SUMMARY: "Task Summary",
    EDIT_TASK: "Edit Task",
    EDIT_SUB_TASK: "Edit Subtask",
    TASKS: "Task",
    SUB_TASKS: "Subtask",
    TODAYS_TASKS: "Today's Task",
    TODAYS_SUB_TASK: "Todays Subtask",
    SUB_TASK_HISTORY: "Subtask History",
    SUB: "Sub",
    BACK_TO_MAIN_TASK: "Back To Main Task",
    ADD_NEW_SUBMISSION: "Add New Submission",
    CORRECTIVE_MEASURES: "Corrective Measures",
    PREVENTIVE_MEASURES: "Preventive Measures",
  },
  TASK_HELPTEXT: {
    BASIT_SECTION:
      "Add necessary information for your task here to uniquely identify it later",
    SCHEDULE_SECTION:
      "Schedule the task according to your needs and set required number of submissions here",
    DESIGNER_SECTION:
      "Add fields to your task and group similar fields together here",
    TASK: {
      NO: "Unique number for task",
      TITLE: "Name of the task",
      REVISION_DATE: "Task’s next update date",
      REVISION_NOTE: "Small text to add with the update",
      DIVISION: "Division No. to associate with the task",
      PROGRAM: "Program No. to associate with the task",
      WORKORDER: "Work Order No. to associate with the task",
      INSTRUCTIONS: "Any instructions given to the worker",
      FREQUENCY: "The number of times a task needs to be scheduled",
      MIN_SUBMISSION: "The number of times a task needs to be submitted by the worker"
    },
    SUBTASK: {
      NO: "Unique number for subtask",
      TITLE: "Name of the subtask",
      REVISION_DATE: "Subtask’s next update date",
      REVISION_NOTE: "Small text to add with the update",
      DIVISION: "Division No. to associate with the subtask",
      PROGRAM: "Program No. to associate with the subtask",
      WORKORDER: "Work Order No. to associate with the subtask",
      PARENTTASK: "Parent Task No. to associate with the subtask",
      INSTRUCTIONS: "Any instructions given to the worker",
      FREQUENCY: "The number of times a task needs to be scheduled",
      MIN_SUBMISSION: "The number of times a task needs to be submitted by the worker"
    },
  },
  TASK_SECTIONS: {
    BASIT_TITLE: "Basic Information",
    SCHEDULE_TITLE: "Task Schedule",
    DESIGNER_TITLE: "Task Designer",
  },
  TASK_RULE: {
    CONDITIONS: "Condition(s)",
    CONDITION_MESSAGE:
      "The rule will be violated if the values don't meet this criteria",
  },
  SUBMISSIONS: {
    HISTORY: "Task Submission History",
    LOCATION: "Task Submission (Location)",
    DATE: "Date",
    LOCATION_NAME: "Location Name",
    COMPLETED_BY: "Completed By",
    TASK_TYPE: "Task Type",
    FREQUENCY: "Frequency",
    SHOW_VIOLATION: "Show Violation",
    SHOW_SUBTASKS: "Show Subtasks",
  },
  WORKORDER_FIELDS: {
    WORKORDER_NO: "Work Order #",
    REVISION_NOTE: "Revision Note",
    REVISION_NO: "Revision No.",
    REVISION_DATE: "Revision Date",
    DIVISION: "Division",
    PROGRAM: "Program",
    APPROVAL_REQUIRED: "Approval Required",
    TITLE: "Work Order Title",
    CREATED_BY: "Created By",
    WORKERS: "Workers",
    LOCATIONS: "Locations",
    LOCATION: "Location"
  },
  USER_FIELDS: {
    USER_NAME: "User Name",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    EMAIL: "Email",
    ROLE: "Role",
    TYPE: "Type",
    PASSWORD: "Password",
    CONFIRM_PASSWORD: "Confirm Password",
    STATUS: "Status",
    OLD_PASSWORD: "Old Password",
  },
  PROGRAM_FIELDS: {
    PROGRAM_NO: "Program #",
    REVISION_DATE: "Revision Date",
    REVISION_NOTE: "Revision Note",
    DIVISION: "Division",
    WORKORDER: "Work Order",
    TITLE: "Program Title",
    ASSIGN: "Assign",
  },
  DASHBOARD: {
    WORKRODER_STATISTICS: "Work Order",
    WORKORDER_PERIOD_TODAY: "Today",
    WORKORDER_PERIOD_WEEK: "Week",
    WORKORDER_PERIOD_MONTH: "Month",
    WORKORDER_PERIOD_YEARLY: "Yearly",
    COMPLETION_LABEL: "Timely Completions For",
    COMPLETED_WORKORDERS: "Completed Work Orders",
    MISSED_WORKORDERS: "Missed Work Orders",
    RECENT_SUBMISSIONS: "Recent Submission(s)",
    LIMIT_RULE_VIOLATIONS: "Limit/Rule Violation(s) for Today",
    WORKERS_ACTIVE_TODAY: "Worker(s) Active Today",
    PENDING_WORKORDERS: "Pending Work Orders",
    VIOLATIONS_FOUNDED: "Violation(s) has been found ",
    PENDING: "Pending",
    MISSED: "Missed",
  },
  FORGOT_PASSWORD: {
    NEW_PASSWORD: "New Password",
    FORGOT_PASSWORD: "Forgot Password",
    INCORRECT_EMAIL: "Invalid email"
  },
  REPORT: {
    USER_SUBMISSION_REPORT: "User Submission Reports",
    DAILY_SUBMISSION_REPORT: "Daily Submission Reports",
    TASK_SUBMISSION_REPORT: "Task Submission Reports",
  },
  ERROR_MESSAGES: {
    INVALID_REQUEST: "Invalid request",
    SESSION_EXPIRED: "Session expired",
    PROGRAM_NO_ALREADY_TAKEN: "Program No already taken",
    LOCATION_NO_ALREADY_TAKEN: "Location Id already taken",
    TASK_NO_ALREADY_TAKEN: "Task No already taken",
    WORKRORDER_NO_ALREADY_TAKEN: "Work Order No already taken",
    USERNAME_ALREADY_TAKEN: "Username already taken",
    EMAIL_ALREADY_TAKEN: "Email already taken",
    PLEASE_ADD_ONE_OR_MORE_FORM_FIELDS: "Please add one or more form fields",
    PLEASE_ADD_ONE_OR_MORE_FORM_FIELD_GROUP:
      "Please add one or more form field group",
    EMPTY_FORM_FIELDS: "$count group(s) have zero form fields",
    EMPTY_FORM: "Empty Form, please add values",
    NO_CONDITIONS: "No Conditions",
    FIELD_VIOLATION_OCCUR: "Field Violation(s)",
    EMPTY_CORRECTIVE_PREVENTIVE:
      "Please enter Corrective & Preventive Measures",
  },
  BREADCRUMB_LABELS: {
    TASK: "Task",
    WORKORDER: "Work Order",
    PROGRAM: "Program",
    LOCATION: "Location",
    ADD_LOCATION: "Add Location",
    USER: "User",
    DASHBOARD: "Dashboard",
    ADD_TASK: "Create A New Task",
    ADD_USER: "Add User",
    ADD_WORKORDER: "Add Work Order",
    ADD_PROGRAM: "Add Program",
    SETTING: "Setting",
    LOCATION: "Location"
  },
  VALIDATION_MESSAGES: {
    REQUIRED: "Required",
    NO_SPACES_ALLOWED: "Spaces not allowed",
    CHARACTERS_LIMIT: "$START-$END characters only",
    BETWEEN_1_1000: "Submission count should be in-between 1-1000",
    MAX_250_ALLOW: "Maximum $LENGTH characters allowed",
    AZ_09_HYPHEN_ALLOW: "A-Z, 0-9, Hyphen allowed",
    INVALID_FORMULA: "Invalid Formula",
    DUPLICATE_GROUP_NAME: "Duplicate Group Name",
  },
  GENERAL_BUTTONS: {
    YES: "Yes",
    NO: "No",
  },
  ASSOCIATION_MODAL: {
    ASSOCIATE_PROGRAM_TITLE: "Associate Program",
    ASSOCIATE_WORKORDER_TITLE: "Associate Work Order",
    ASSOCIATE_DIVISION_TITLE: "Associate Division",
  },
  SKIPTASK_MODAL:{
    SKIPTASK_TITLE: "Reason for Marking as Not Performed"
  },
  SUCCESS_MESSAGES: {
    LOCATION_ADDED: "Location Added Successfully",
    LOCATION_STATUS_UPDATED: "Location status updated",
    USER_STATUS_UPDATED: "User status updated",
    QA_FORM_SUBMSSION_SUBMITTED_SUCCESSFULLY: "Form Submitted Successfully",
    USER_ASSOICATED_SUCCESSFULLY: "User Associated Successfully",
    TASK_ADDED_SUCCESSFULLY: "Task Added Successfully",
    TASK_UPDATED_SUCCESFULLY: "Task Updated Succesfully",
    DIVISION_ASSOCIATED_SUCCESSFULLY: "Division Associated Successfully",
    PROGRAM_ADDED: "Program Added Successfully",
    PROGRAM_STATUS_UPDATED: "Program status updated",
    WORKORDER_UPDATED_SUCCESFULLY: "Work Order updated sucessfully",
    WORKORDER_ADDED_SUCCESFULLY: "Work Order Added Succesfully",
    PROGRAM_ASSOCIATED_SUCCESFULLY: "Program associated successfully",
    WORKORDER_STATUS_UPDATED: "Workorder status updated",
    WORKORDER_ASSOCIATED_SUCCESFULLY: "Work Order associated successfully",
    TASK_STATUS_UPDATED: "Task Status Updated Successfully",
  },
  CONFIRM_MESSAGES: {
    REMOVE_FIELD_GROUP: "Are you sure you want to remove this group?",
  },
};
