import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import { user } from "./UserReducer";
import { app, initialState as appInitialState } from "./AppReducer";
import { dashboard, initialState as dashboardInitialState } from "./DashboardReducer";
import { task, initialState as taskInitialState } from "./TaskReducer"; //change tasks to task
import { taskSubmission, initialState as taskSubmissionInitialState } from "./TaskSubmissionReducer";
import { program } from "./ProgramReducer";
import { location } from "./LocationReducer";
import { livestock } from "./LivestockReducer";
import { division } from "./DivisionReducer";
import { workorder } from "./WorkorderReducer";
import { enums } from "./EnumReducer";
import { table } from "./TableReducer";
import { loader } from "./LoadingReducer";
import { asset } from "./AssetReducer";
import { breadcrumb } from "./BreadcrumbReducer";
import { report } from "./ReportReducer";
import { notification } from "./NotificationReducer";
import { scheduler } from "./SchedulerReducer";
import { programManual } from "./ProgramManualReducer";
import { review } from "./ReviewReducer";
import { reviewHistory } from "./ReviewHistoryReducer";
import { USER_ACTIONS } from "../../constants/ActionConstants";

const appReducer = combineReducers({
  dashboard,
  user,
  task,
  taskSubmission,
  program,
  division,
  workorder,
  enums,
  table,
  breadcrumb,
  loader,
  asset,
  report,
  location,
  livestock,
  notification,
  scheduler,
  app,
  programManual,
  review,
  reviewHistory,
  form: reduxFormReducer,
});

const rootReducer = (state, action) => {
  if (action.type === USER_ACTIONS.LOGIN_SUCCESS) {
    state.app = appInitialState;
    state.dashboard = dashboardInitialState;
    state.task = taskInitialState;
    state.taskSubmission = taskSubmissionInitialState;
  }
  if (action.type === USER_ACTIONS.LOGOUT_SUCCESS) {
    state = undefined
  }
  return appReducer(state, action)
}
export default rootReducer