

import { BREAKCRUMB_ACTIONS } from '../../constants/ActionConstants';
const initialState = {
    breadcrumbLinkArray: [{
        label: "Home",
        landingUrl: "/"
    }, {
        label: "Home",
        landingUrl: ""
    }]
};

export function breadcrumb(state = initialState, action) {
    switch (action.type) {
        case BREAKCRUMB_ACTIONS.UPDATE_BREADCRUMB_ARRAY:
            return { ...state, breadcrumbLinkArray: action.payload };
        default:
            return { ...state };
    }
}