import { API_CONSTANTS } from "../../constants/ApiConstants";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { apiClient } from "./ApiClient";
import { UserFilterModel } from "../../models/User";

const UserPropertyType = {
  USERNAME: 1,
  EMAIL: 2,
};

export const userService = {
  login,
  logout,
  register,
  getFilterUsers,
  getFilteredUsers,
  addUser,
  getUserDetails,
  editUser,
  getUserSettings,
  validatePassword,
  changeNotificationPreference,
  forgotPassword,
  resetPassword,
  changePassword,
  associateUserToDivision,
  qaFormSubmission,
  qaTaskDesignerFormSubmission,
  validateUsername,
  activeDirectoryUsers,
  validateEmail,
  changeState,
  signup,
  getUserByLocationOrWorkOrder,
  toggleNotifcations,
};

function validateUsername(username) {
  return apiClient()
    .get(
      `${API_CONSTANTS.API_ROUTES.USER_EXIST}${username}/${UserPropertyType.USERNAME}`
    )
    .then(handleResponse)
    .catch(handleError);
}

function activeDirectoryUsers(username) {
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.ACTIVEDIRECTORY_USERS)
    .then(handleResponse)
    .catch(handleError);
}

function validateEmail(email) {
  return apiClient()
    .get(
      `${API_CONSTANTS.API_ROUTES.USER_EXIST}${email}/${UserPropertyType.EMAIL}`
    )
    .then(handleResponse)
    .catch(handleError);
}

function associateUserToDivision(model) {
  return apiClient()
    .post(API_CONSTANTS.API_ROUTES.ASSOCIATE_DIVISION_TO_USER, model)
    .then(handleResponse)
    .catch(handleError);
}

function resetPassword(model) {
  return apiClient()
    .post(API_CONSTANTS.API_ROUTES.RESET_PASSWORD, model)
    .then(handleResponse)
    .catch(handleError);
}

function forgotPassword(model) {
  return apiClient()
    .post(API_CONSTANTS.API_ROUTES.FORGOT_PASSWORD, model)
    .then(handleResponse)
    .catch(handleError);
}

function changePassword(userData, userId) {
  return apiClient()
    .post(API_CONSTANTS.API_ROUTES.EDIT_USER + userId, JSON.stringify(userData))
    .then(handleResponse)
    .catch(handleError);
}

function login(model) {
  return apiClient()
    .post(API_CONSTANTS.API_ROUTES.AUTHENTICATE_USER, model)
    .then(handleResponse)
    .catch(handleError);
}

function addUser(userData) {
  return apiClient()
    .post(API_CONSTANTS.API_ROUTES.ADD_USER, JSON.stringify(userData))
    .then(handleResponse)
    .catch(handleError);
}

function editUser(userData, userId) {
  return apiClient()
    .put(API_CONSTANTS.API_ROUTES.EDIT_USER + userId, JSON.stringify(userData))
    .then(handleResponse)
    .catch(handleError);
}

function validatePassword(userId, oldPassword) {
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.GET_USER_BY_ID + userId)
    .then(handleResponse)
    .catch(handleError);
}

function logout() {
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.LOGOUT_USER)
    .then(handleResponse)
    .catch(handleError);
}

function register(user) {
  return apiClient()
    .post(API_CONSTANTS.API_ROUTES.REGISTER_USER, JSON.stringify(user))
    .then(handleResponse)
    .catch(handleError);
}

function getUserSettings(userId) {
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.GET_USER_BY_ID + userId)
    .then(handleResponse)
    .catch(handleError);
}

function getFilteredUsers(
  paginationData,
  searchTerms,
  filterObject = {},
  sortObj = {}
) {
  const url = API_CONSTANTS.API_ROUTES.GET_FILTERED_USER;
  const params = new UserFilterModel(filterObject, sortObj, paginationData, {
    searchTerms,
  });

  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function getUserDetails(userId) {
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.GET_USER_BY_ID + userId)
    .then(handleResponse)
    .catch(handleError);
}

export function handleLoginResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        //Logging Out If unauthorized is Returned
        logout();
      }
      const error = "GET ERROR MESSAGE";
      return Promise.reject(error);
    }

    return data;
  });
}

export function changeNotificationPreference(data, userId) {
  return apiClient()
    .put(
      API_CONSTANTS.API_ROUTES.EDIT_USER + "/" + userId,
      JSON.stringify(data)
    )
    .then(handleResponse)
    .catch(handleError);
}

function signup(user) {
  return apiClient()
    .post(API_CONSTANTS.API_ROUTES.SIGNUP_USER, JSON.stringify(user))
    .then(handleResponse)
    .catch(handleError);
}

/**
 * QA Tech Services
 */

function qaFormSubmission(model, isEdit = false) {
  let type = "post";
  // if its edit mode
  if (!!isEdit) {
    type = "put";
  }

  return apiClient()
  [type](API_CONSTANTS.API_ROUTES.QA_FORM_SUBMISSION, model)
    .then(handleResponse)
    .catch(handleError);
}

function qaTaskDesignerFormSubmission(model, isEdit = false) {
  let type = "post";

  return apiClient()
  [type](API_CONSTANTS.API_ROUTES.QA_TASK_DESIGNER_FORM_SUBMISSION, model)
    .then(handleResponse)
    .catch(handleError);
}

function changeState(id, isActive) {
  return apiClient()
    .put(API_CONSTANTS.API_ROUTES.CHANGE_USER_STATE + "/" + id, {
      isActive: isActive,
    })
    .then(handleResponse)
    .catch(handleError);
}

//QA Tech Services

function getFilterUsers(model) {
  const url = API_CONSTANTS.API_ROUTES.GET_FILTERED_USER;
  return apiClient().post(url, model).then(handleResponse).catch(handleError);
}


function getUserByLocationOrWorkOrder(model) {
  const url = API_CONSTANTS.API_ROUTES.GET_USERS_BY_LOCATION_WORKORDER;
  return apiClient().post(url, model).then(handleResponse).catch(handleError);
}

function toggleNotifcations() {
  const url = API_CONSTANTS.API_ROUTES.TOGGLE_NOTIFICATIONS;
  return apiClient().post(url).then(handleResponse).catch(handleError);
}
