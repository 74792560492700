import ReactNativeStorageService from "./react-native";
import ReactStorageService from "./react";
import { PLATFORMS } from "../../constants/GenericConstants";

export default class StorageService {
  static instance = null;

  constructor(platform, storage) {
    this.service =
      platform === PLATFORMS.WEB
        ? new ReactStorageService(storage)
        : new ReactNativeStorageService(storage);
  }

  setUserData(user) {
    this.service.setUserData(user);
  }

  setUserPermissions(permissions) {
    this.service.setUserPermissions(permissions);
  }

  setTaskOfflinePageSize(taskOfflinePageSize) {
    this.service.setTaskOfflinePageSize(taskOfflinePageSize);
  }

  setUserConfiguration(config) {
    this.service.setUserConfiguration(config);
  }

  setAccessToken(token) {
    this.service.setAccessToken(token);
  }

  setDepartment(department) {
    this.service.setDepartment(department);
  }

  setLastSync(key, value) {
    this.service.setLastSync && this.service.setLastSync(key, value);
  }

  getLastSync(key) {
    return this.service.getLastSync && this.service.getLastSync(key);
  }

  resetLastSync(keepAdhoc) {
    this.service.resetLastSync && this.service.resetLastSync(keepAdhoc);
  }

  deleteLoginData() {
    this.service.deleteLoginData();
  }

  getAccessToken() {
    return this.service.getAccessToken();
  }

  getUserData() {
    return this.service.getUserData();
  }

  getDepartment() {
    return this.service.getDepartment();
  }

  getTaskOfflinePageSize() {
    return this.service.getTaskOfflinePageSize();
  }

  getUserConfiguration() {
    return this.service.getUserConfiguration();
  }

  getUserPermissions() {
    return this.service.getUserPermissions();
  }

  removeStorageItem(itemKey) {
    this.service.removeStorageItem(itemKey);
  }
  getItem(itemKey, jsonParse = true) {
    return this?.service?.getItem(itemKey, jsonParse);
  }
  removeItem(itemKey) {
    this.service?.removeStorageItem(itemKey);
  }
  setItem(itemKey, value) {
    this.service.setItem(itemKey, value);
  }
}
