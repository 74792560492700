import {
  GENERIC_CONSTANTS,
  ADMIN_CONFIGURATION_KEYS,
} from "../constants/GenericConstants";
import {
  parseDateForDatePicker,
  parseDateForServer,
  showFormulaField,
  getConfigValue,
  showFirstXCharacters,
  getConfigValueFromStore,
} from "./GeneralHelper";
import _ from "lodash";
import React from "react";
import { BMMultiSelect } from "../models/Form";

/**
 * @param {*} wororderListingData
 * @param {*} status
 */
export function parseWorkorderListingData(workorders, ENUM = {}, isQA = false) {
  let parsedWorkorderList = [];
  const workorderStatus = ENUM.STATUS;
  const reviewStatus = ENUM.REVIEW_STATUS;

  if (!!workorders) {
    for (var i = 0; i < workorders.length; i++) {
      const data = workorders[i];
      let isActive = data.isActive;
      let statusIcon = isActive ? "activeIcon" : "inactiveIcon";
      let statusObj =
        _.find(workorderStatus, (obj) => obj.value === data.status) || {};
      let reviewStatusObj =
        _.find(reviewStatus, (obj) => obj.value === data.reviewStatus) || {};
      let workorderObj = {};

      workorderObj.workorderNumber = !!isQA ? (
        <div>{data.workorderNumber}</div>
      ) : (
        <div>
          {" "}
          <span className={statusIcon}></span>
          {data.workorderNumber}
        </div>
      );

      workorderObj.title = showFirstXCharacters(data.title, 50);
      workorderObj.id = data.id;
      workorderObj.reviewStatus = reviewStatusObj.label;
      workorderObj.revisionDateTime = !!data.revisionDateTime
        ? parseDateForDatePicker(data.revisionDateTime)
        : "N/A";
      workorderObj.status = statusObj.label || "";
      workorderObj.program = !!data.program ? data.program.programNumber : "";
      workorderObj.createdBy = !!data.createdByUserLongName
        ? data.createdByUserLongName
        : "N/A";
      workorderObj.updatedOn = !!data.updatedOn
        ? parseDateForDatePicker(data.updatedOn)
        : "N/A";

      parsedWorkorderList.push(workorderObj);
    }
  }

  return parsedWorkorderList;
}

/**
 * Payload Data For Edit Workorder Screen
 * @param {Object} workorderData
 */
export function parseWorkorderEditFormData(workorderData) {
  const parsedData = {};
  const workorderObject = workorderData || {};
  const programObject = !!workorderData.program ? workorderData.program : {};
  const divisionObject = !!programObject ? programObject.division : {};

  parsedData.revisionDateTime = !!workorderData.revisionDateTime
    ? parseDateForDatePicker(workorderData.revisionDateTime)
    : "";
  parsedData.revisionNote = workorderData.revisionNote;
  parsedData.formFields = [];
  if (!!workorderData.formFields && workorderData.formFields.length > 0) {
    parsedData.formFields = workorderData.formFields.map((obj) => {
      let fieldsObject = {};
      fieldsObject.specification = obj.specification || "";
      fieldsObject.title = obj.title;
      return fieldsObject;
    });
  }
  parsedData.userIDs =
    workorderData.users && workorderData.users.length
      ? workorderData.users.map(
          (user) => new BMMultiSelect({ value: user.id, label: user.fullName })
        )
      : [];
  parsedData.locationIds =
    workorderData.locations && workorderData.locations.length
      ? workorderData.locations.map(
          (location) =>
            new BMMultiSelect({
              value: location.id,
              label: location.locationName,
            })
        )
      : [];

  parsedData.approvalRequired = workorderObject.approvalRequired;
  parsedData.division = !!divisionObject ? divisionObject.id : "";
  parsedData.program = !!programObject ? programObject.id : "";
  parsedData.workorderNumber = workorderData.workorderNumber;
  parsedData.workorder = !!workorderObject ? workorderObject.id : "";
  parsedData.title = workorderData.title;
  return parsedData;
}

/**
 * Getting Filter Count For
 * Workorder List Page
 * @param {Object} filters
 */
export function getWorkorderFilterCount(filters) {
  let dateRangePresent = false;
  let filterCount = 0;

  _.each(filters, (obj, key) => {
    if (!!obj) {
      if (
        key === GENERIC_CONSTANTS.FIELD_NAME.START_DATE ||
        key === GENERIC_CONSTANTS.FIELD_NAME.END_DATE
      ) {
        dateRangePresent = true;
        filterCount += 1;
      } else if (obj.length > 0) {
        filterCount += 1;
      }
    }
  });
  if (dateRangePresent) {
    //subtracting 1 because using start date and end date as single filter
    filterCount -= 1;
  }
  return filterCount;
}

/**
 * List of Parsed Programs
 * @param {Array|Object} programs
 */
export function parseProgramsForSelect(programs) {
  let parsedWorKorders = [];
  if (!!programs && programs.length > 0) {
    for (var i = 0; i < programs.length; i++) {
      parsedWorKorders.push({
        value: programs[i].id,
        label: programs[i].programNumber,
        color: "#00B8D9",
      });
    }
  }
  return parsedWorKorders;
}

export function parseMetaDataProgramsForSelect(programs) {
    let parsedPrograms = [];
    if (!!programs && programs.length > 0) {
        for (var i = 0; i < programs.length; i++) {
            parsedPrograms.push({
                value: programs[i].id,
                label: programs[i].name, color: '#00B8D9'
            });
        }
    }
    return parsedPrograms;
}

/**
 * Parsing Filter Params for WorkorderListing
 * @param {Object} filterParams
 */
export function parsedWorkorderFilters(filterParams) {
  let filters = {};
  let statuses = filterParams.status;
  let programs = filterParams.programs;
  let reviewStatuses = filterParams.reviewStatus;
  let states = filterParams.state;

  states = _.map(states, "value");
  statuses = _.map(statuses, "value");
  programs = _.map(programs, "value");
  reviewStatuses = _.map(reviewStatuses, "value");
  filters.endDate = !!filterParams.endDate
    ? parseDateForServer(filterParams.endDate)
    : null;
  filters.startDate = !!filterParams.startDate
    ? parseDateForServer(filterParams.startDate)
    : null;
  filters.reviewStatuses = reviewStatuses;
  filters.programs = programs;
  filters.status = statuses;
  filters.state = states;

  return filters;
}

export function parseWorkorderData(formValues, status) {
  let parsedResponse = {};
  let formFields = formValues.formFields || [];

  parsedResponse.workorderNumber = formValues.workorderNumber.trim();
  parsedResponse.revisionDateTime = parseDateForServer(formValues.revisionDateTime);
  parsedResponse.revisionNote = formValues.revisionNote;
  //parsedResponse.divisionId = formValues.division;
  parsedResponse.status = status;
  //parsedResponse.programId = formValues.program;
  parsedResponse.userIDs = formValues.userIDs && formValues.userIDs.length ? formValues.userIDs.map((user) => user.value) : [];
  parsedResponse.locationIds = _.map(formValues.locationIds, "value");
  
  parsedResponse.approvalRequired = !!formValues.approvalRequired;
  parsedResponse.title = formValues.title.trim();

  formFields = formFields.map((obj) => {
      return {
          fieldType: obj.fieldType,
          specification: obj.specification,
          isRequired: !!(obj.isRequired),
          title: obj.title
      }
  });
  parsedResponse.formFields = formFields;

  formFields = formFields.map((obj) => {
    return {
      fieldType: obj.fieldType,
      specification: obj.specification,
      isRequired: !!obj.isRequired,
      title: obj.title,
    };
  });
  parsedResponse.formFields = formFields;

  // if program visibility = false, then default program show
  let programVisibility = getConfigValue(
    ADMIN_CONFIGURATION_KEYS.PROGRAM.VISIBILITY
  );

  if (!!isProgramVisible()) {
    parsedResponse.divisionId = formValues.division;
    parsedResponse.programId = formValues.program;
  } else {
    parsedResponse.programId = getConfigValue(
      ADMIN_CONFIGURATION_KEYS.DEFAULT_IDS.PROGRAM
    );
  }

  return parsedResponse;
}

/**
 * Payload Data For View Workorder Screen
 * @param {Object} workorderData
 */
export function parseWorkorderViewFormData(workorderData) {
  const parsedData = {};
  const workorderObject = workorderData || {};
  const programObject = !!workorderData.program ? workorderData.program : {};
  const divisionObject = !!programObject.division ? programObject.division : {};

  parsedData.revisionDateTime = !!workorderData.revisionDateTime
    ? parseDateForDatePicker(workorderData.revisionDateTime)
    : "";

  parsedData.formFields = [];
  if (!!workorderData.formFields && workorderData.formFields.length > 0) {
    parsedData.formFields = workorderData.formFields.map((obj) => {
      let fieldsObject = {};
      fieldsObject.fieldType = obj.fieldType;
      fieldsObject.specification = obj.specification || "";
      fieldsObject.isRequired = obj.isRequired;
      fieldsObject.title = obj.title;
      fieldsObject.enabled = showFormulaField(obj.fieldType);
      return fieldsObject;
    });
  }
  parsedData.userIDs =
    workorderData.users && workorderData.users.length
      ? workorderData.users.map(
          (user) => new BMMultiSelect({ value: user.id, label: user.fullName })
        )
      : [];
  parsedData.locationIds =
    workorderData.locations && workorderData.locations.length
      ? workorderData.locations.map(
          (location) =>
            new BMMultiSelect({
              value: location.id,
              label: location.locationName,
            })
        )
      : [];

  parsedData.isActive = workorderData.isActive;
  parsedData.stateTitle = parsedData.isActive ? "Active" : "Inactive";
  parsedData.totalActivities = !!workorderData.formFields
    ? workorderData.formFields.length
    : 0; //formFields applied
  parsedData.revisionNote = workorderData.revisionNote;
  parsedData.division = !!divisionObject ? divisionObject.id : "";
  parsedData.program = !!programObject ? programObject.id : "";
  parsedData.workorderNumber = workorderData.workorderNumber;
  parsedData.approvalRequired = workorderData.approvalRequired;
  parsedData.workorder = !!workorderObject ? workorderObject.id : "";
  parsedData.title = workorderData.title;
  parsedData.status = workorderData.status;
  parsedData.reviewStatus = workorderData.reviewStatus;
  parsedData.divisionSublist = [];
  parsedData.programSublist = [];

  if (!!programObject && programObject.id) {
    parsedData.programSublist.push({
      id: programObject.id,
      programNumber: programObject.programNumber,
    });
  }
  if (!!divisionObject && divisionObject.id) {
    parsedData.divisionSublist.push({
      id: divisionObject.id,
      divisionNumber: divisionObject.divisionNumber,
    });
  }
  return parsedData;
}

export function isProgramVisible(fromStore, config) {
  let visibility = fromStore
    ? getConfigValueFromStore(config, ADMIN_CONFIGURATION_KEYS.PROGRAM.VISIBILITY) || "true"
    : getConfigValue(ADMIN_CONFIGURATION_KEYS.PROGRAM.VISIBILITY) || "true";
  return !!visibility && visibility.toLowerCase() === "true";
}
