import { API_CONSTANTS } from '../../constants/ApiConstants';
import { handleResponse, handleError } from './GenericResponseHandler';
import { apiClient } from './ApiClient';
import {
  SubmisionHistoryFilterModel,
  TaskSubmissionsForOfflineModel,
  TaskHistoryForOfflineModel,
  SubmisionHistoryFilterModelMobile,
  TaskSubmissionsAdhocAutoSaveForOfflineModel
} from "../../models/Task";

export const TaskSubmissionService = {
  getTaskSubmissions,
  getTaskSubmissionsForOffline,
  getTaskHistoryForOffline,
  getTaskDesignerSubmissions,
  getTaskHistoryForOfflineWithFilter,
};

function getTaskDesignerSubmissions(
  paginationData,
  searchTerms,
  filterObject = {},
  sortObject = {},
  locationId = null,
  taskId = null,
) {
  let url = API_CONSTANTS.API_ROUTES.GET_TASK_DESIGNER_SUBMISIONS;
  let params = new SubmisionHistoryFilterModel(
    paginationData,
    { searchTerms },
    filterObject,
    sortObject,
    locationId,
    taskId,
  );

  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function getTaskSubmissions(
  paginationData,
  searchTerms,
  filterObject = {},
  sortObject = {},
  locationId = null,
) {
  let url = API_CONSTANTS.API_ROUTES.GET_TASK_SUBMISIONS;
  let params = new SubmisionHistoryFilterModel(
    paginationData,
    { searchTerms },
    filterObject,
    sortObject,
    locationId
  );

  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function getTaskSubmissionsForOffline(
  paginationData,
  timestamp,
  departmentIds,
  isAutosaveAdhocCall = false
) {
  let url = API_CONSTANTS.API_ROUTES.GET_TASK_SUBMISIONS_WITH_DETAILS;
  let params = !isAutosaveAdhocCall
    ? new TaskSubmissionsForOfflineModel(
      paginationData,
      timestamp,
      departmentIds
    )
    : new TaskSubmissionsAdhocAutoSaveForOfflineModel(
      paginationData,
      timestamp,
      departmentIds
    );

  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function getTaskHistoryForOffline(paginationData, timestamp) {
  let url = API_CONSTANTS.API_ROUTES.GET_TASK_SUBMISIONS_WITH_DETAILS;
  let params = new TaskHistoryForOfflineModel(paginationData, timestamp);

  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}

function getTaskHistoryForOfflineWithFilter(
  paginationData,
  searchTerms,
  filterObject = {},
  sortObject = {},
  locationId = null,
  taskId = null,
) {
  let url = API_CONSTANTS.API_ROUTES.GET_TASK_SUBMISIONS_WITH_DETAILS;
  let params = new SubmisionHistoryFilterModelMobile(
    paginationData,
    { searchTerms },
    filterObject,
    sortObject,
    locationId,
    taskId,
  );

  return apiClient().post(url, params).then(handleResponse).catch(handleError);
}
