class LocationModel { }

LocationModel.schema = {
  name: "Location",
  primaryKey: "id",
  properties: {
    id: "int",
    locationId: "string?",
    locationName: "string?",
  },
};

export default LocationModel;
