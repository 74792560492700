import { API_CONSTANTS } from "../../constants/ApiConstants";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { apiClient } from "./ApiClient";

export const assetService = {
  getFormByCategory,
};

function getFormByCategory(id) {
  return apiClient()
    .get(API_CONSTANTS.API_ROUTES.GET_FORM_BY_CATEGORY + id)
    .then(handleResponse)
    .catch(handleError);
}
