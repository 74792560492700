import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { LOCAL_STORAGE_KEYS } from '@ecodocs/common/src/constants/GenericConstants';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props => {
            return (
                localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
                    ? <Component {...props} {...rest} />
                    : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )
        }
        } />
    )
}