const MapAPIToDB = (program) => ({
  id: program.id,
  name: program.name || "",
  number: program.number || "",
  updatedOn: program.updatedOn || "",
  status: program.status || null,
});

const MapDBToState = (program) => ({
  id: program.id,
  name: program.name || "",
  number: program.number || "",
  updatedOn: program.updatedOn || "",
  status: program.status || null,
});

export { MapAPIToDB, MapDBToState };
