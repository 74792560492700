// Schema Models
import TaskSubmissionModel from "./models/TaskSubmission";
import UserModel from "./models/User";
import WorkorderModel from "./models/Workorder";
import LocationModel from "./models/Location";
import ProgramModel from "./models/Program";
import AdhocTaskModel from "./models/AdhocTask";
import SubmissionModel from "./models/Submission";
import MetadataModel from "./models/Metadata";
import TaskDetailModel from "./models/TaskDetail";

let Realm = null;

if (process.env.REACT_APP_PLATFORM !== "WEB") {
  import("realm").then((rm) => {
    Realm = rm;
  });
}

// .schema is used to resolve realm's delete issue as per following comment:
// https://github.com/realm/realm-js/issues/2848#issuecomment-631485221
export default class EcoDocsDB {
  static async getConnection() {
    if (process.env.REACT_APP_PLATFORM !== "WEB") {
      // waiting for the dynamic import of Realm to resolve
      while (Realm === null) {
        await new Promise((resolve) => {
          setTimeout(resolve, 10);
        });
      }

      // for mobile
      return Realm.open({
        schema: [
          TaskSubmissionModel.schema,
          AdhocTaskModel.schema,
          UserModel.schema,
          WorkorderModel.schema,
          LocationModel.schema,
          ProgramModel.schema,
          SubmissionModel.schema,
          MetadataModel.schema,
          TaskDetailModel.schema,
        ],
        schemaVersion: 0,
        deleteRealmIfMigrationNeeded: true,
      });
    }

    // for web
    return Promise.resolve();
  }

  static close(db) {
    if (db !== null && !db.isClosed) {
      db.close();
    }
  }
}

export const TASK_SUBMISSION_SCHEMA_NAME = TaskSubmissionModel.schema.name;
export const ADHOC_TASK_SCHEMA_NAME = AdhocTaskModel.schema.name;
export const USER_SCHEMA_NAME = UserModel.schema.name;
export const WORKORDER_SCHEMA_NAME = WorkorderModel.schema.name;
export const LOCATION_SCHEMA_NAME = LocationModel.schema.name;
export const PROGRAM_SCHEMA_NAME = ProgramModel.schema.name;
export const SUBMISSION_SCHEMA_NAME = SubmissionModel.schema.name;
export const METADATA_SCHEMA_NAME = MetadataModel.schema.name;
export const TASK_DETAIL_SCHEMA_NAME = TaskDetailModel.schema.name;
