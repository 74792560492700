class TaskDetailModel {}

TaskDetailModel.schema = {
  name: "TaskDetail",
  primaryKey: "id",
  properties: {
    id: "int?",
    title: "string?",
    instruction: "string?",
    submissionCount: "int?",
    frequency: "int?",
    taskPriorityEnumId: "int?",
    priority: "string?",
    isActive: "bool?",
    taskActivityStatusEnumId: "int?",
    taskDetailCorrectionApprovers: "string?",
    taskDetailCorrectionFilers: "string?",
    taskReviewReviewers: "string?",
    taskDetailMedias: "string?",
    programManualDetail: "string?",
    taskGroups: "string?",
  },
};

export default TaskDetailModel;
